import request from "../../utils/request"

export function canteenList(data: any) {
  return request({
    url: "/app/canteen/all",
    method: "get",
    params: data,
  })
}
export function listData(data: any) {
  return request({
    url: "/admin/inspection-tasks/list",
    method: "get",
    params: data,
  })
}
// 风险监管列表数据
export function TasksList(data: any) {
  return request({
    url: "/app/inspection-tasks/list",
    method: "get",
    params: data,
  })
}

export function addTask(data: any) {
  return request({
    url: "/admin/inspection-tasks/add",
    method: "post",
    data,
  })
}
export function taskDetail(data: any) {
  return request({
    url: "/admin/inspection-tasks/details",
    method: "get",
    params: data,
  })
}
export function taskDetail1(data: any) {
  return request({
    url: "/admin/inspection-tasks/details1",
    method: "get",
    params: data,
  })
}
export function warningList(data: any) {
  return request({
    url: "/admin/inspection-tasks/warning-list",
    method: "get",
    params: data,
  })
}
export function selfCheckListData(data: any) {
  return request({
    url: "/admin/assessment-result/index",
    method: "get",
    params: data,
  })
}
export function selfCheckDetail(data: any) {
  return request({
    url: "/admin/assessment-result/info",
    method: "get",
    params: data,
  })
}
export function selfChecked(data: any) {
  return request({
    url: "/admin/assessment-result/check",
    method: "post",
    params: data,
  })
}
export function downloadExport(data: any) {
  return request({
    url: "/admin/assessment-result/export",
    method: "get",
    params: data,
  })
}
export function listSupervise(data: any) {
  return request({
    url: "/app/supervise-tasks/list",
    method: "get",
    params: data,
  })
}
export function delSupervise(data: any) {
  return request({
    url: "/app/supervise-tasks/del",
    method: "get",
    params: data,
  })
}
export function superviseType(data: any) {
  return request({
    url: "/app/supervise-tasks/user-info",
    method: "get",
    params: data,
  })
}
export function superviseDetail(data: any) {
  return request({
    url: "/app/supervise-tasks/info",
    method: "get",
    params: data,
  })
}
export function addSuperviseTask(data: any) {
  return request({
    url: "/app/supervise-tasks/save",
    method: "post",
    data,
  })
}
export function resultSuperviseTask(data: any) {
  return request({
    url: "/app/supervise-tasks/result",
    method: "post",
    data,
  })
}
//督办列表添加页面下单位列表)
export function superviseCanteenList(data: any) {
  return request({
    url: "/app/supervise-tasks/push-users-list", //push_users-list
    method: "get",
    params: data,
  })
}
//督办列表页面区域单位列表(发布方)
export function superviseCanteenListIndex0(data: any) {
  return request({
    url: "/app/supervise-tasks/unit-name-list", //push_users-list
    method: "get",
    params: data,
  })
}
//督办列表页面区域单位列表(接收方)
export function superviseCanteenListIndex1(data: any) {
  return request({
    url: "/app/supervise-tasks/unit-name-belongs-list",
    method: "get",
    params: data,
  })
}

//巡检任务巡检单位列表
export function mineCanteensList(data: any) {
  return request({
    url: "/app/inspection-tasks/mine-canteens-list",
    method: "get",
    params: data,
  })
}
