import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Image, Modal, message } from "antd";
import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { contentsList, contentsDelete, contentsSave } from '../../../apis/information/information'


export default function InformationIndex() {
  const { confirm } = Modal;
  const { Search } = Input
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1); //当前页码
  const [totalCount, setTotalCount] = useState(0); //总数
  const [perPage, setPerPage] = useState(15); //每页条数
  const [dataSource, setdataSource] = useState([]); //列表数据
  let history = useHistory();
  // //文章标题
  // function setName(val: any) {
  //   console.log('文章标题', val)
  // }
  // //查询
  function onSearch(val: any) {
    console.log("查询", val)
    setSearchText(val)
  }
  //公开/隐藏
  function checShow(data: any) {
    console.log("显示隐藏", data)
    contentsSave({ id: data.id, is_show: data.is_show === 1 ? 0 : 1 }).then(res => {
      message.success('修改成功');
      to_get_List()
    })
    // if (data.is_show === 1) {
    //   contentsSave({ id: data.id, is_show: 0 }).then(res => {
    //     message.success('修改成功');
    //     to_get_List()
    //   })
    // } else if (data.is_show === 0) {
    //   contentsSave({ id: data.id, is_show: 1 }).then(res => {
    //     message.success('修改成功');
    //     to_get_List()
    //   })
    // }
  }

  // 查看详情
  function checkDetail(id: any) {
    console.log(id)
    history.push("/information/list/detail", id);
  }
  // 新增 新闻
  function to_add() {
    history.push("/information/list/add");
  }
  //修改
  function checkUp(id: any) {
    history.push("/information/list/add", id);
  }

  const to_get_List = useCallback(() => {
    return contentsList({ title: searchText }).then(res => {
      console.log("res", res.data.data)
      setTotalCount(res.data.total)
      setdataSource(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [searchText])
  useEffect(() => {
    to_get_List()
  }, [to_get_List])


  // 分页数据
  function changePage(val: number) {
    setCurrentPage(val)
  }


  function checkDel(id: any) {
    console.log("删除id", id)
    confirm({
      title: '是否确认删除？',
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        contentsDelete({ id: id }).then(res => {
          console.log(res)
          message.success('删除成功');
          to_get_List()
        }).catch(e => {
          console.log(e)
          message.error('删除失败');
        })


      },
      onCancel() {
        message.warning('取消删除');
        console.log('Cancel');
      },
    });
  }


  const columns = [
    {
      title: '序号',
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
      width: 80,
    },
    {
      title: '缩略图',
      dataIndex: 'http_url_image_appends',
      key: 'http_url_image_appends',
      render: (pic_appends: any) => (
        pic_appends.split(',').map((e: any) => {
          return pic_appends ? <Image key={e} className="img" width={50} height={50} src={e} /> : ""
        })
      )
    },
    // {
    //   title: '投诉内容',
    //   dataIndex: 'type_name',
    //   key: 'type_name',
    // },
    {
      title: '文章标题',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: '摘要',
      dataIndex: 'descr',
      key: 'descr',
      // render: (record: any) => ()
      width: '30%',
    },
    {
      title: '发布人',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 100,
    },
    {
      title: '发布时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '状态',// 0 不显示，1 显示
      dataIndex: 'is_show',
      width: 80,
      key: 'is_show',
      render: (is_show: any) => (
        is_show === 0 ? <span style={{ color: "#949494 " }} >  已隐藏 </span> : <span style={{ color: "#28a0de " }} >公开</span >
      )
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <>
          <Button
            type="link"
            onClick={() => checShow(record)}
          >
            {record.is_show === 1 ? '隐藏' : '公开'}
          </Button>
          <Button
            type="link"
            onClick={() => checkUp(record.id)}
          >
            编辑
          </Button>
          <Button
            type="link" danger
            onClick={() => checkDel(record.id)}
          >
            删除
          </Button>
          <Button
            type="link"
            onClick={() => checkDetail(record.id)}
          >
            <span style={{ color: '#000000' }}>查看</span>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ overflow: "auto", height: "100%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row>
            <Col span={20}>
              <div className="search">
                <div>标题：</div>
                <div className="search-wrap">
                  <Search
                    placeholder="请输入文章标题"
                    allowClear
                    enterButton="查询"
                    onSearch={onSearch}
                  />
                </div>
                <div>
                  <Button icon={<PlusOutlined />} onClick={to_add}>
                    新增
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataSource}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />

            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}