import React, { useEffect, useState } from "react";

import styles1 from "../pop.module.css";
import Title from "./title";
import IconFont from "../../../components/icon-font";
import { Image } from "antd";
import noDataImg from "../../../assets/images/noData.png";
import ZgColumn from "../../../components/zg-column";
import { getStacRecord } from "../../../apis/canteen-info/proxy-info";
import { getCanteenInfo } from "../../../apis/board";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../utils/getTheme";

export default function Pop(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  function to_detail() {
    history.push("/canteen/info/index?number=" + props.number);
  }
  // 晨检
  function to_morning() {
    history.push("/canteen/info/morning?number=" + props.number);
  }
  // 留样
  function to_sample() {
    history.push("/canteen/info/sample?number=" + props.number);
  }
  // 餐具消毒
  function to_tableware() {
    history.push("/canteen/info/tableware?number=" + props.number);
  }
  // 检疫检测
  function to_quarentine() {
    history.push("/canteen/info/quarentine?number=" + props.number);
  }
  // 添加剂使用
  function to_addictive() {
    history.push("/canteen/info/addictive?number=" + props.number);
  }
  // 安全自查
  function to_safe() {
    history.push("/canteen/info/safe?number=" + props.number);
  }
  // 环境消毒
  function to_environment() {
    history.push("/canteen/info/environment?number=" + props.number);
  }
  // 废弃物处理
  function to_waste() {
    history.push("/canteen/info/waste?number=" + props.number);
  }
  // 食堂陪餐
  function to_accompany() {
    history.push("/canteen/info/accompany?number=" + props.number);
  }
  // 领导视察
  function to_leader() {
    history.push("/canteen/info/leader?number=" + props.number);
  }
  // 食堂清洁
  function to_clean() {
    history.push("/canteen/info/clean?number=" + props.number);
  }

  // 视频预警
  function to_correction() {
    history.push("/canteen/info/correction?number=" + props.number);
  }
  // 物联预警
  function to_earlywarning() {
    history.push("/canteen/info/earlywarning?number=" + props.number);
  }
  // 原料预警
  function to_rawmaterial() {
    history.push("/canteen/info/rawmaterial?number=" + props.number);
  }
  // 证件预警
  function to_document() {
    history.push("/canteen/info/document?number=" + props.number);
  }
  // 餐饮证件预警
  function to_foodDocument() {
    history.push("/canteen/info/foodDocument?number=" + props.number);
  }

  // 从业人员
  function to_practitioners() {
    history.push("/canteen/info/worker?number=" + props.number);
  }
  let recordInfoTemp: any = {
    workers_number: 0,
    correct_not_count: 0,
    goods_expired_not_count: 0,
    iot_warning_not_handle_count: 0,
    health_certificate_expired_not_count: 0,
    canteen_cert_expired_count: 0,
    accompany_dinner_count: 0,
    additive_count: 0,
    clean_checks_count: 0,
    disinfections_count: 0,
    environ_disinfections_count: 0,
    leader_inspect_count: 0,
    log_count: 0,
    morning_check_finish_day: 0,
    quarantine_inspection_count: 0,
    safety_checks_count: 0,
    samples_count: 0,
    waste_disposals_count: 0,
  };
  const [recordInfo, setRecordInfo] = useState(recordInfoTemp);
  // 获取食堂日常检查数据
  const to_get_work = () => {
    let date = dayjs().format("YYYY-MM-DD"); // 取今日
    return getStacRecord({ date: date, number: props.number }).then((res) => {
      setRecordInfo(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  let info = {
    canteen_name: "",
    canteen_number: "",
    type: "",
    unit_name: "",
    customers_number: "",
    area: "",
    province: "",
    city: "",
    district: "",
    address: "",
    trusteeship_name: "",
    engage_type: "",
    level: "",
    workers_number: "",
    first_liable: "",
    specific_liable: "",
    canteen_phone: "",
    http_url_license_image_appends: "",
    http_url_insurance_image_appends: "",
    license_date: "",
    insurance_number: "",
  };
  const [detailInfo, setDetailInfo] = useState(info);
  const to_get_info = () => {
    if (!props.number) return;

    getCanteenInfo({ number: props.number }).then((res) => {
      setDetailInfo(res.data);
    });
  };
  useEffect(() => {
    if (!props.number) return;
    to_get_info();
    to_get_work();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.number]);
  return (
    <>
      <div className={styles.flexRow + " " + styles1.popBox}>
        <div className={styles1.leftBox}>
          <Title title="查看详情"></Title>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>餐饮名称：</span>
            <span className={styles1.value}>{detailInfo.canteen_name}</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>就餐人数：</span>
            <span className={styles1.value}>
              {detailInfo.customers_number}人
            </span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>食堂面积：</span>
            <span className={styles1.value}>{detailInfo.area}m²</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>详细地址：</span>
            <span className={styles1.value}>{detailInfo.address}</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>托管公司：</span>
            <span className={styles1.value}>{detailInfo.trusteeship_name}</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>经营类型：</span>
            <span className={styles1.value}>{detailInfo.engage_type}</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>第一责任人：</span>
            <span className={styles1.value}>{detailInfo.first_liable}</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>具体责任人：</span>
            <span className={styles1.value}>{detailInfo.specific_liable}</span>
          </div>
          <div className={styles.flexRow + " " + styles1.infoLine}>
            <span className={styles.purpleColor}>联系电话：</span>
            <span className={styles1.value}>{detailInfo.canteen_phone}</span>
          </div>
          <div className={styles1.infoLine}>
            <span className={styles.purpleColor}>食品经营许可证：</span>
            <div>
              <Image
                style={{
                  display:
                    detailInfo.http_url_license_image_appends === ""
                      ? "none"
                      : "block",
                }}
                className={styles1.infoImg}
                src={detailInfo.http_url_license_image_appends}
                alt=""
              />
              <ZgColumn
                className={styles1.noWrap}
                style={{
                  display:
                    detailInfo.http_url_license_image_appends === ""
                      ? "flex"
                      : "none",
                }}
              >
                <Image
                  className={styles1.noImg}
                  preview={false}
                  src={noDataImg}
                ></Image>
                <span className={styles.purpleColor}>暂无图片</span>
              </ZgColumn>
            </div>
          </div>
        </div>
        <div className={styles1.rightBox}>
          <div className={styles.flexRow}>
            <div className={styles.divBlock + " " + styles1.recBlock}>
              <div>量化分级</div>
              <div className={styles1.level}>{detailInfo.level}</div>
            </div>
            <div className={styles.divBlock + " " + styles1.recBlock}>
              <div>餐饮风险指数评估</div>
              <div className={styles1.scoreLine + " " + styles.flexRow}>
                <span className={styles1.score}>{recordInfo.score}</span>
                <span className={styles.purpleColor + " " + styles1.unit}>
                  分
                </span>
              </div>
              <div className={styles.purpleColor}>今日评分</div>
            </div>
            <div className={styles.divBlock + " " + styles1.recBlock} onClick={to_practitioners}>
              <div>从业人员</div>
              <div className={styles1.scoreLine + " " + styles.flexRow}>
                <span className={styles1.score}>
                  {detailInfo.workers_number}
                </span>
                <span className={styles.purpleColor + " " + styles1.unit}>
                  人
                </span>
              </div>
            </div>
          </div>
          <div className={styles1.workBox}>
            <Title title="今日日常工作完成情况"></Title>
            <div className={styles.flexRow + " " + styles1.workList}>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_morning}>
                <span className={styles1.workLabel}>今日晨检</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.morning_check_finish_day > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.morning_check_finish_day > 0
                    ? recordInfo.morning_check_finish_day
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_sample}>
                <span className={styles1.workLabel}>今日留样</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.samples_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.samples_count > 0
                    ? recordInfo.samples_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_tableware}>
                <span className={styles1.workLabel}>餐具消毒</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.disinfections_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.disinfections_count > 0
                    ? recordInfo.disinfections_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_quarentine}>
                <span className={styles1.workLabel}>检疫检测</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.quarantine_inspection_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.quarantine_inspection_count > 0
                    ? recordInfo.quarantine_inspection_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_addictive}>
                <span className={styles1.workLabel}>添加剂使用</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.additive_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.additive_count > 0
                    ? recordInfo.additive_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_safe}>
                <span className={styles1.workLabel}>安全自查</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.safety_checks_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.safety_checks_count > 0
                    ? recordInfo.safety_checks_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_environment}>
                <span className={styles1.workLabel}>场所消毒</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.environ_disinfections_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.environ_disinfections_count > 0
                    ? recordInfo.environ_disinfections_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_waste}>
                <span className={styles1.workLabel}>废弃物处理</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.waste_disposals_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.waste_disposals_count > 0
                    ? recordInfo.waste_disposals_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_accompany}>
                <span className={styles1.workLabel}>食堂陪餐</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.accompany_dinner_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.accompany_dinner_count > 0
                    ? recordInfo.accompany_dinner_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_leader}>
                <span className={styles1.workLabel}>领导视察</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.leader_inspect_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.leader_inspect_count > 0
                    ? recordInfo.leader_inspect_count
                    : "无"}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.workItem} onClick={to_clean}>
                <span className={styles1.workLabel}>食堂清洁</span>
                <span
                  className={
                    styles1.workValue +
                    " " +
                    (recordInfo.clean_checks_count > 0
                      ? styles.greenBackground
                      : styles.yellowBackground)
                  }
                >
                  {recordInfo.clean_checks_count > 0
                    ? recordInfo.clean_checks_count
                    : "无"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles1.warnBox}>
            <Title title="待处理预警信息"></Title>
            <div className={styles.flexRow + " " + styles1.warnList}>
              <div className={styles.flexRow + " " + styles1.warnItem} onClick={to_correction}>
                <span
                  className={
                    styles1.warnIcon +
                    " " +
                    styles.blueColor +
                    " " +
                    styles1.warnIcon1
                  }
                >
                  <IconFont type="iconshipin" />
                </span>
                <span className={styles1.workLabel}>智能纠偏</span>
                <span className={styles.yellowColor}>
                  {recordInfo.correct_not_count}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.warnItem} onClick={to_earlywarning}>
                <span className={styles1.warnIcon + " " + styles.greenColor}>
                  <IconFont type="iconwulianwang1" />
                </span>
                <span className={styles1.workLabel}>物联预警</span>
                <span className={styles.yellowColor}>
                  {recordInfo.iot_warning_not_handle_count}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.warnItem} onClick={to_rawmaterial}>
                <span className={styles1.warnIcon + " " + styles.yellowColor}>
                  <IconFont type="iconyuanliaoxuqiu" />
                </span>
                <span className={styles1.workLabel}>原料预警</span>
                <span className={styles.yellowColor}>
                  {recordInfo.goods_expired_not_count}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.warnItem} onClick={to_document}>
                <span className={styles1.warnIcon + " " + styles.purpleColor}>
                  <IconFont type="iconzhengjian" />
                </span>
                <span className={styles1.workLabel}>健康证预警</span>
                <span className={styles.yellowColor}>
                  {recordInfo.health_certificate_expired_not_count}
                </span>
              </div>
              <div className={styles.flexRow + " " + styles1.warnItem} onClick={to_foodDocument}>
                <span className={styles1.warnIcon + " " + styles.purpleColor}>
                  <IconFont type="iconzhengjian" />
                </span>
                <span className={styles1.workLabel}>餐饮证件预警</span>
                <span className={styles.yellowColor}>
                  {recordInfo.canteen_cert_expired_count
                    ? recordInfo.canteen_cert_expired_count
                    : 0}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.flexRow + " " + styles1.moreBox}>
            <div
              className={styles.purpleColor + " " + styles1.moreBtn}
              onClick={to_detail}
            >
              查看更多详情
            </div>
          </div>
          <div className={styles1.close} onClick={() => props.close()}>
            X
          </div>
        </div>
      </div>
    </>
  );
}
