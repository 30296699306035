import React from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgRow from "../../../../components/zg-row";
import IconFont from "components/icon-font";
import "../single.less";

export default function FoodSafety() {
  return (
    <>
      <div style={{ backgroundColor: '#ffffff', paddingBottom: '100%' }}>
        <div className="leaderboard">
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="餐饮纠偏排行榜"></ZgTitle>
          </ZgRow>

        </div>
        <div className="lists-item" style={{ backgroundColor: '#F6F7FC', borderRight: '2px solid #A4ABC9' }}>
          <div className="list-row-left">
            <div className={["list-left-tag", "blue-bg"].join(' ')}>1</div>
            <div className="list-left-cln">
              <div className="list-left-ft1">襄阳四中食堂</div>
              <div className="list-left-ft2">较前日<span><IconFont type="iconshangsheng" style={{ color: '#72D7DD', fontSize: '16px' }} /></span>2%</div>
            </div>
          </div>
          <div className="list-row-right">95.1</div>
        </div>
        <div className="lists-item">
          <div className="list-row-left">
            <div className={["list-left-tag", "green-bg"].join(' ')}>2</div>
            <div className="list-left-cln">
              <div className="list-left-ft1">襄阳四中食堂</div>
              <div className="list-left-ft2">较前日<span><IconFont type="iconshangsheng" style={{ color: '#72D7DD', fontSize: '16px' }} /></span>2%</div>
            </div>
          </div>
          <div className="list-row-right">95.1</div>
        </div>
        <div className="lists-item">
          <div className="list-row-left">
            <div className={["list-left-tag", "yellow-bg"].join(' ')}>3</div>
            <div className="list-left-cln">
              <div className="list-left-ft1">襄阳四中食堂</div>
              <div className="list-left-ft2">较前日<span><IconFont type="iconshangsheng" style={{ color: '#72D7DD', fontSize: '16px' }} /></span>2%</div>
            </div>
          </div>
          <div className="list-row-right">95.1</div>
        </div>
        <div className="lists-item">
          <div className="list-row-left">
            <div className={["list-left-tag", "grey-bg"].join(' ')}>4</div>
            <div className="list-left-cln">
              <div className="list-left-ft1">襄阳四中食堂</div>
              <div className="list-left-ft2">较前日<span><IconFont type="iconxiajiang" style={{ color: 'red', fontSize: '16px' }} /></span>2%</div>
            </div>
          </div>
          <div className="list-row-right">95.1</div>
        </div>
      </div>
    </>
  )
}
