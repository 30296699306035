import React, { useState, useCallback, useEffect } from "react"
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Space,
  DatePicker,
  Button,
  Table,
  Tabs,
  Select,
} from "antd"

import { TasksList } from "../../../apis/inspection-check/inspection"
import { useHistory } from "react-router-dom"
import "../../../style/inspection.less"
import ShowModal from "components/show-modal"
import { mineCanteens } from "apis/warningManage/certificates/list"
import ZgCascader from '../../../components/zg-cascader'

const { TabPane } = Tabs
const { Option } = Select
export default function InspectionList() {
  let history = useHistory()
  const { RangePicker } = DatePicker
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [canteen_name, setCanteenName] = useState("") //食堂名称
  const [startdate, setStartdate] = useState()
  const [enddate, setEnddate] = useState()
  const [curType, setType] = useState(0)
  const [inspection, setInspection] = useState('0')
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区


  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
      width: "80",
    },
    {
      title: "巡检主题",
      dataIndex: "title",
      key: "title",
      width: '15%'
    },
    {
      title: "巡检学校",
      dataIndex: "canteens",
      key: "dataIndex",
      render: (canteens: any) => `${canteens.canteen_name}`,
    },
    {
      title: "巡检类型",
      dataIndex: "risk_type",
      key: "risk_type",
    },
    {
      title: "巡检时间",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "巡检人",
      dataIndex: "canteensmanage",
      key: "canteensmanage",
      render: (canteensmanage: any) => `${canteensmanage.realname}`,
    },
    {
      title: "状态",
      dataIndex: "state",
      render: (state: any) =>
        state === 1 ? (
          <span style={{ color: "red" }}>待回复</span>
        ) : (
          <span>已回复</span>
        ),
    },
    {
      title: "操作",
      key: "action",
      render: (state: any, record: any) =>
        record.is_many === 2 ? (
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail1(record.id, record.canteen_number)}
            ghost={true}
          >
            查看
          </Button>
        ) : (
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail(record.id)}
            ghost={true}
          >
            查看
          </Button>
        ),
    },
  ]

  const [list, setList] = useState([])
  const [str, setStr] = useState()
  const showModal = () => {
    mineCanteens({ name: "" }).then((res) => {
      if (res.status === 200) {
        let str: any = (
          <ShowModal
            isShow={true}
            data={res.data}
            closeModal={closeModal}
          ></ShowModal>
        )
        setStr(str)
      }
    })
  }
  const closeModal = () => {
    let str: any = (
      <ShowModal isShow={false} data={[]} closeModal={closeModal}></ShowModal>
    )
    setStr(str)
  }
  //按时间查询
  function handDate(dates: any, date: any) {
    setStartdate(date[0])
    setEnddate(date[1])
    setCurrentPage(1)
  }
  function checkDetail(id: any) {
    history.push("/supervision/detail", id)
  }
  function checkDetail1(id: any, number: any) {
    history.push("/supervision/detail1", [id, number])
  }
  //输入查询
  function handName(value: any) {
    setCanteenName(value.target.value)
    setCurrentPage(1)
  }
  // 巡检类型查询
  const handState = (data: string) => {
    console.log(data)
    setInspection(data)
  }
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  // 获取列表
  const to_get_list = useCallback(() => {
    return TasksList({
      type: 2, // 风险监管
      state: curType,
      page: currentPage,
      sdate: startdate,
      edate: enddate,
      canteen_name: canteen_name,
      risk_type: inspection,
      city: cityName,
      district: districtName,
    }).then((res) => {
      console.log("res", res)
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, startdate, enddate, canteen_name, curType, inspection, cityName, districtName])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  function search() {
    to_get_list()
  }

  function changeType(idx: any) {
    let id = idx - 1
    setType(id)
    setCurrentPage(1)
  }

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管巡查</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/inspection/list">风险监管</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row
        style={{
          fontSize: "20px",
          paddingBottom: "10px",
          background: "white",
          paddingLeft: "40px",
        }}
      >
        <Col span={4}>巡检列表</Col>
      </Row>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row>
            <Col span={24} className="gutter-row">
              <Tabs defaultActiveKey="1" onChange={changeType}>
                <TabPane tab="全部" key="1"></TabPane>
                <TabPane tab="待回复" key="2"></TabPane>
                <TabPane tab="已回复" key="3"></TabPane>
                <TabPane tab="我发起的" key="4"></TabPane>
              </Tabs>
            </Col>
          </Row>

          <Row style={{ marginBottom: "20px" }}>
            <Col span={24} className="gutter-row">
              <span>巡检时间：</span>
              <Space
                direction="vertical"
                size={12}
                style={{ marginRight: "20px" }}
              >
                <RangePicker onChange={handDate} />
              </Space>

              <span>巡检单位：</span>
              <Input
                placeholder="请输入"
                type="text"
                onChange={handName}
                style={{ width: 140, marginRight: "15px" }}
              />
              <span>巡检类型：</span>
              <Select
                placeholder="巡检类型"
                style={{ width: 140, marginRight: "15px" }}
                onChange={handState}
                allowClear
              >
                <Option value="">全部</Option>
                <Option value="视频预警">视频预警</Option>
                <Option value="物联预警">物联预警</Option>
                <Option value="原料预警">原料预警</Option>
                <Option value="健康证预警">健康证预警</Option>
                <Option value="餐饮证件预警">餐饮证件预警</Option>
              </Select>
              {/* 所属区域查询功能 */}
              <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              <Button type="primary" onClick={search}>
                查询
              </Button>
              <Button
                style={{ marginLeft: "15px", float: "right" }}
                type="primary"
                onClick={showModal}
              >
                一键生成整改单
              </Button>
            </Col>
            {/* <Col span={4}><div className="add-btn-wrap"><Button type="default" onClick={to_add}>新增巡检</Button></div></Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={list}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {str}
    </>
  )
}
