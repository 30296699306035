import { Editor } from '@tinymce/tinymce-react'
import { uploadToServer } from '../apis/information/information'

export default function WgEditor(props: any, ref: any) {
  const content = props.getContent

  function handleImgUpload(blobInfo: any, success: any, failure: any) {
    let formdata = new FormData()
    formdata.append('file', blobInfo.blob())
    uploadToServer(formdata).then(res => {
      console.log('res', res)
      if (res.status !== 200) {
        // 上传失败执行此方法，将失败信息填入参数中
        // console.log(res)
        failure('HTTP Error: ' + res)
        return
      }
      // console.log(res.data)
      success(res.data.absolute_path) //线上使用
    })
  }
  function handleEditorChange(content: any, editor: any) {
    console.log('content:', content)
    console.log('editor:', editor)
    props.setcontent(content)
  }

  const editorOpt: any = {
    menubar: 'file edit insert view format table tools',
    toolbar:
      'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat ',
    toolbar_drawer: 'sliding',
    paste_data_images: true, // 允许粘贴图像
    content_style: 'img {width:100%; height:100%;}',
    fontsize_formats: '10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 24px 26px 28px 30px 32px 36px', //字体大小
    quickbars_selection_toolbar:
      'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
    plugins: 'link image media table lists fullscreen quickbars code',
    language: 'zh_CN',
    height: 700,
    images_upload_handler: (blobInfo: any, success: any, failure: any) => {
      //这里写你上传图片的方法
      handleImgUpload(blobInfo, success, failure)
    }
  }

  return (
    <>
      <div className="shop">
        <Editor initialValue={content} inline={false} init={{ ...editorOpt }} onEditorChange={handleEditorChange} />
      </div>
    </>
  )
}
