import React, { useState, useCallback, useEffect } from "react"
import {
  Breadcrumb,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Table,
  Tabs,
  Select,
  message,
} from "antd"
import {
  listSupervise,
  superviseType,
  superviseCanteenListIndex0,
  delSupervise,
} from "../../../apis/inspection-check/inspection"
import { useHistory } from "react-router-dom"
import "../../../style/inspection.less"
import ZgCascader from '../../../components/zg-cascader'

import $storeLocal from "storejs"
import dayjs from "dayjs"
const { TabPane } = Tabs

export default function SuperviseList() {
  let history = useHistory()
  let userInfo = $storeLocal.get("UserBaseInfo")

  const [userType, setUserType] = useState()
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [canteen_name, setCanteenName] = useState("") //食堂名称
  const [startdate, setStartdate] = useState()
  const [enddate, setEnddate] = useState()
  const [curType, setCurType] = useState(0)
  const [unitList, setUnitList] = useState([])
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区

  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
      width: "80",
    },
    {
      title: "督办主题",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "区域单位",
      dataIndex: "unit_name",
      key: "dataIndex",
    },
    {
      title: "督办时间",
      dataIndex: "supervise_time",
      render: (text: any, record: any) =>
        dayjs(record.supervise_time).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "督办人",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "状态",
      dataIndex: "state",
      render: (state: any) =>
        state === 1 ? (
          <span style={{ color: "red" }}>待回复</span>
        ) : (
          <span>已回复</span>
        ),
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) =>
        record.state === 1 && userInfo.id === record.manager_user_id ? (
          <div>
            <Button
              className="btn"
              type="primary"
              onClick={() => checkDetail(record.id)}
              ghost={true}
            >
              查看
            </Button>

            <Button
              danger
              className="btn btn-denger"
              style={{ marginLeft: "10px" }}
              onClick={() => delData(record.id)}
              ghost={true}
            >
              删除
            </Button>
          </div>
        ) : (
          <div>
            <Button
              className="btn"
              type="primary"
              onClick={() => checkDetail(record.id)}
              ghost={true}
            >
              查看
            </Button>
          </div>
        ),
    },
  ]

  const [list, setList] = useState([])

  //按时间查询
  function handDate(dates: any, date: any) {
    setStartdate(date[0])
    setEnddate(date[1])
    setCurrentPage(1)
  }
  function delData(id: any) {
    delSupervise({
      id: id,
    })
      .then((res) => {
        if (res.status === 200) {
          to_get_list()
          message.success("操作成功！")
        }
      })
      .catch((e) => {
        message.warn(e.response.data.message)
      })
  }
  function checkDetail(id: any) {
    history.push("/supervise/detail", id)
  }
  //输入查询
  function handName(value: any) {
    setCanteenName(value)
    setCurrentPage(1)
  }



  // 获取列表
  const to_get_list = useCallback(() => {
    return listSupervise({
      state: curType === 0 ? null : curType,
      page: currentPage,
      sdate: startdate,
      edate: enddate,
      unit_name: canteen_name,
      city: cityName,
      district: districtName,
      type: userType == null || userType === "" ? "" : userType,
    }).then((res) => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, startdate, enddate, canteen_name, curType, userType, cityName, districtName])

  const userInfos = useCallback(() => {
    let ut = userType === "0" ? "" : userType
    superviseType({}).then((res) => {
      superviseCanteenListIndex0({ type: ut }).then((res1) => {
        setUnitList(res1.data)
      })

      to_get_list()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startdate, enddate, canteen_name, curType, userType, cityName, districtName])
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  function changePage(val: number) {
    setCurrentPage(val)
  }
  useEffect(() => {
    userInfos()
  }, [userInfos])

  function search() {
    to_get_list()
  }
  function to_add() {
    history.push("/supervise/add")
  }
  function handType(val: any) {
    setCurType(val)
  }
  function changeType(idx: any) {
    let id: any = idx + ""
    setUserType(id)
  }
  const rightMenu = (
    <Button type="primary" onClick={to_add}>
      新增督办任务
    </Button>
  )
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管督办</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/supervise/list">监管督办</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row
        style={{
          fontSize: "20px",
          paddingBottom: "10px",
          background: "white",
          paddingLeft: "40px",
        }}
      >
        <Col span={4}>监管督办列表</Col>
      </Row>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row>
            <Col span={24} className="gutter-row">
              <Tabs
                defaultActiveKey="0"
                onChange={changeType}
                tabBarExtraContent={rightMenu}
              >
                <TabPane tab="全部" key="0"></TabPane>
                <TabPane tab="我发起的" key="1"></TabPane>
                <TabPane tab="我收到的" key="2"></TabPane>
              </Tabs>
            </Col>
          </Row>

          <Row style={{ marginBottom: "20px" }}>
            <Col span={20} className="gutter-row">
              <span>督办时间：</span>
              <Space
                direction="vertical"
                size={12}
                style={{ marginRight: "20px" }}
              >
                <RangePicker onChange={handDate} />
              </Space>
              <span>区域单位：</span>
              <Select
                optionFilterProp="children"
                placeholder="请选择"
                onChange={handName}
                allowClear
                style={{ marginRight: "20px" }}
              >
                {unitList.map((item: any) => (
                  <Option value={item.unit_name} key={item.unit_name}>
                    {item.unit_name}
                  </Option>
                ))}
              </Select>
              <span>状态：</span>
              <Select
                optionFilterProp="state"
                placeholder="请选择"
                onChange={handType}
                allowClear
                style={{ marginRight: "20px" }}
              >
                <Option value="" key="">
                  全部
                </Option>
                <Option value="1" key="1">
                  待回复
                </Option>
                <Option value="2" key="2">
                  已回复
                </Option>
              </Select>
              {/* 所属区域查询功能 */}
              <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              <Button type="primary" onClick={search}>
                查询
              </Button>
            </Col>
            {/* <Col span={4}><div className="add-btn-wrap"><Button type="default" onClick={to_add}>新增巡检</Button></div></Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={list}
                scroll={{ x: 1280 }}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
