import React, { useState, useEffect, useCallback } from 'react'
import styles from './index.module.css'
import { Select } from "antd"
import dayjs from 'dayjs'
import ZgRow from "../../../components/zg-row";
import Right from './right'
import { riskRank, areaRisks } from '../../../apis/analysis/appraise'
import $storeLocal from "storejs"
const { Option } = Select;
export default function LeftSide(props: any) {
  const timeList = [{ type: 1, name: '昨日' }, { type: 2, name: '3天内' }, { type: 3, name: '30天内' }, { type: 4, name: '180天内' }]
  const [curAreaId, setAreaId] = useState('')
  const [curArea, setArea] = useState('')
  const [curSort, setSort] = useState('')
  const changeArea = (item: any) => {
    setAreaId(item.id)
    setArea(item.name)
  }
  const get_sort = (type: any) => {
    setReload(false)
    setSort(type)
  }
  // 是否显示地图
  const [showMap] = useState(true)
  const [rDateType, setRDateType] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reloadMap, setReload] = useState(true)
  function rDateChange(type: any) {
    setReload(true)
    setRDateType(type)
    setSort('')
  }
  function formatDate(type: any) {
    let date: any = []
    let sdate: any = ''
    let edate: any = ''
    if (type === 1) {
      sdate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      edate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    } else if (type === 2) {
      sdate = dayjs().subtract(3, 'day').format('YYYY-MM-DD')
      edate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    } else if (type === 3) {
      sdate = dayjs().subtract(30, 'day').format('YYYY-MM-DD')
      edate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    } else if (type === 4) {
      sdate = dayjs().subtract(180, 'day').format('YYYY-MM-DD')
      edate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    }
    date = [sdate, edate]
    return date
  }
  let sortList: any = [{ id: 1, name: '---', rate0: 0, rate1: 0, rate2: 0, rate3: 0, score: 0 }]
  const [tableList, setTableList] = useState(sortList)
  const get_rank = useCallback(
    () => {
      riskRank({ sort_type: curSort, sdate: formatDate(rDateType)[0], edate: formatDate(rDateType)[1] }).then(res => {
        let ls: any = []
        let ls2: any = []
        res.data.forEach((item: any) => {
          ls.push({ id: item.id, name: item.name, rate0: item.level_0_ratio, rate1: item.level_1_ratio, rate2: item.level_2_ratio, rate3: item.level_3_ratio, score: parseFloat(item.area_score) === 0 ? '---' : item.area_score })
          ls2.push({ id: item.id, name: item.name, score: parseFloat(item.area_score) === 0 ? '未公示菜谱' : item.area_score + '分' })
        })
        if (curSort === '') {
          areaRisks({ sdate: formatDate(rDateType)[0], edate: formatDate(rDateType)[1] }).then(rst => {
            rst.data.forEach((val: any) => {
              ls2.forEach((val2: any) => {
                if (val.id === val2.id) {
                  val2.level1 = val.level1
                  val2.level2 = val.level2
                  val2.level3 = val.level3
                }
              })
            })
            get_map(ls2)
          })
        }
        setTableList(ls)
        setAreaId(res.data[0].id)
        setArea(res.data[0].name)
      })
    },
    [rDateType, curSort],
  )
  const get_map = (arrtemp: any) => {
    let arr = arrtemp.map((val: any) => {
      if (val.name === '长阳县') {
        val.name = "长阳土家族自治县"
      }
      if (val.name === '五峰县') {
        val.name = "五峰土家族自治县"
      }
      return val
    })
    let switchArr: any = []
    let needIdx: any = []
    arr.forEach((item: any, index: any) => {
      if (item.score !== '未公示菜谱') {
        switchArr.push(item)
      } else {
        needIdx.push(index)
      }
    })
    if (needIdx.length > 0) {
      needIdx.forEach((item: any) => {
        switchArr.splice(1, 0, arr[item])
      })
    }
    console.log(switchArr)
    let mapx = $storeLocal.get("UserBaseInfo").canteens[0].map_x
    let mapy = $storeLocal.get("UserBaseInfo").canteens[0].map_y
    //eslint-disable-next-line
    let am: any = 'AMap'
    let gdMap: any = window[am]
    //eslint-disable-next-line
    var map = new gdMap.Map('setMap', {
      center: [mapx, mapy],
      zoom: 12,
      mapStyle: 'amap://styles/d3333ca0695e800a6a45d66397185933'
    });

    //着色块
    gdMap.plugin('AMap.DistrictSearch', function () {
      // 创建行政区查询对象
      var district = new gdMap.DistrictSearch({
        // 返回行政区边界坐标等具体信息
        extensions: 'all',
        // 设置查询行政区级别为 区 
        level: 'district'
      })
      switchArr.forEach((item: any, index: any) => {

        district.search(item.name, function (status: any, result: any) {
          // 获取边界信息
          if(result.districtList instanceof Array&&result.districtList.length>0){
          var bounds = result.districtList[0].boundaries
          var polygons = []
          if (bounds) {
            for (var i = 0, l = bounds.length; i < l; i++) {
              //生成行政区划polygon
              var polygon = new gdMap.Polygon({
                map: map,
                bubble: true,
                strokeWeight: 1,
                path: bounds[i],
                fillOpacity: 0.6,
                fillColor: index === 0 ? '#1EB38B' : index === (arr.length - 1) ? '#ff3a34' : '#3582fe',
                strokeColor: '#CC66CC',
              })
              polygons.push(polygon)
            }
            // 地图自适应
            map.setFitView()
          }
        }
        })
      })
    })
    //鼠标点击显示信息
    let infoWin: any = new gdMap.InfoWindow({
      closeWhenClickMap: false
    });
    map.on('click', function (e: any) {
      gdMap.plugin(['AMap.Geocoder'], function () {
        let geo: any = new gdMap.Geocoder({ radius: 1000 })
        geo.getAddress([e.lnglat.lng, e.lnglat.lat], (sts: any, rst: any) => {
          if (sts === "complete" && rst.regeocode) {
            let curName = rst.regeocode.addressComponent.district
            let level1: any = 0
            let level2: any = 0
            let level3: any = 0
            let score: any = 0
            arr.forEach((item: any) => {
              if (item.name === curName) {
                score = item.score
                level1 = item.level1
                level2 = item.level2
                level3 = item.level3
              }
            })
            let names: any = arr.map((item: any) => item.name)
            if (names.includes(curName)) {
              var content = [
                '区域名称：' + curName,
                '<br>',
                '平均分数：' + score,
                '<br>',
                '一类风险：' + level1,
                '<br>',
                '二类风险：' + level2,
                '<br>',
                '三类风险：' + level3,
              ];
              infoWin.setContent(content.join(''));
              infoWin.open(map, e.lnglat);
            } else {
              infoWin.close()
            }
          }
        })
      })
    });
  }
  useEffect(() => {
    get_rank()
  }, [get_rank])
  return (
    <div className={styles.container}>
      <div style={{
        width: '100%',
        height: '100%',
      }}>
        {showMap === true ? <div id="setMap" style={{
          width: '100%',
          height: '100%',
        }}></div> : ''}
      </div>
      <div className={styles.left}>
        <ZgRow justify="space-between">
          <div className={styles.leftTitle}>{$storeLocal.get("UserBaseInfo").city}</div>
          <Select onChange={rDateChange} className={'appraiseSelect ' + styles.select} key={timeList[0].type} defaultValue={timeList[0].type}>
            {timeList.map((item: any) => (
              <Option key={item.type} value={item.type}>{item.name}</Option>
            ))}
          </Select>
        </ZgRow>
        <div className={styles.leftTip}>区域风险评估排行榜</div>
        <ZgRow align="flex-start" justify="flex-start" className={styles.opeLine}>
          <div className={styles.leftLabel}>评估占比颜色：</div>
          <div className={styles.opeLineRight}>
            <ZgRow className={styles.colorItem}><span className={styles.greenBg + ' ' + styles.colorBlock}></span><span>优秀</span></ZgRow>
            <ZgRow className={styles.colorItem}><span className={styles.blueBg + ' ' + styles.colorBlock}></span><span>良好</span></ZgRow>
            <ZgRow className={styles.colorItem}><span className={styles.yellowBg + ' ' + styles.colorBlock}></span><span>一般</span></ZgRow>
            <ZgRow><span className={styles.greyBg + ' ' + styles.colorBlock}></span><span>未公示菜谱</span></ZgRow>
          </div>
        </ZgRow>
        <ZgRow justify="flex-start" className={styles.opeLine}>
          <div className={styles.leftLabel}>评估排序方式：</div>
          <Select onChange={get_sort} className={styles.sortSelect} defaultValue=''>
            <Option value=''>按平均分排序</Option>
            <Option value='level_3'>按评分优秀从多到少排序</Option>
            <Option value='level_2'>按评分良好从多到少排序</Option>
            <Option value='level_1'>按评分一般从多到少排序</Option>
            <Option value='level_0'>按未公示菜谱从多到少排序</Option>
          </Select>
        </ZgRow>
        <div className={styles.tableWrap}>
          <ZgRow align="center" justify="space-between" className={styles.tableHeader}>
            <span className={styles.headerItem}>排名</span>
            <span className={styles.headerItem}>区/县</span>
            <span className={styles.headerLine}>餐饮单位评分占比</span>
            <span className={styles.headerItem}>平均分排名</span>
          </ZgRow>
          <div>
            {tableList.map((item: any, index: any) => (
              <ZgRow key={index} align="center" justify="space-between" onClick={() => changeArea(item)} className={styles.tableBodyLine + ' ' + (curAreaId === item.id ? styles.active : '')}>
                <span className={styles.bodyItem}>{index + 1}</span>
                <span className={styles.bodyItem}>{item.name}</span>
                <div className={styles.rateList}>
                  <span className={styles.greenBg + ' ' + styles.rateItem} style={{ width: 100 * item.rate3 * 0.01 }}></span>
                  <span className={styles.blueBg + ' ' + styles.rateItem} style={{ width: 100 * item.rate2 * 0.01 }}></span>
                  <span className={styles.yellowBg + ' ' + styles.rateItem} style={{ width: 100 * item.rate1 * 0.01 }}></span>
                  <span className={styles.greyBg + ' ' + styles.rateItem} style={{ width: 100 * item.rate0 * 0.01 }}></span>
                </div>
                <span className={styles.bodyItem}>{item.score}</span>
              </ZgRow>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.right}><Right curArea={curArea} sdate={formatDate(rDateType)[0]} edate={formatDate(rDateType)[1]} id={curAreaId}></Right></div>
    </div>
  )
}