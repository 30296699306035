import React, { useEffect, useState } from 'react'
import '../../../../style/data-tit.less'
import IconFont from '../../../../components/icon-font';
import { Image } from 'antd'
import Zs from '../../../../assets/images/zs.png'
import { dailyIndexCorrectIndex } from "../../../../apis/intelligence-rectify/intelligence-rectify"

// 大数据视频预警--曲线面积图
export default function DataTit() {
  const initInfo = { count: 0, waitCount: 0, todayCount: 0 }
  const [data, setData] = useState(initInfo)
  function to_init_data() {
    dailyIndexCorrectIndex({}).then(res => {
      console.log("总数据")
      console.log(res)
      setData(res.data)
    })
  }
  useEffect(() => {
    to_init_data()
  }, [])
  return (
    <>
      <div className="tit-cont">
        <div style={{ display: 'flex' }}>
          <div className="tit-cont-top">视频预警</div>
          <div className="tit-right">
            <div className="right-one">30日内</div>
          </div>
        </div>
        <div className="tit-cont-btm">
          <div className="cont-btm-ct">
            <div className={["ct-tag", "grey-bg"].join(' ')}>
              <Image
                width={20}
                src={Zs}
                preview={false}
              />
            </div>
            <div className="btm-ct-ft">
              <div className="btm-ct-ft-top">总纠偏数</div>
              <div className="btm-ct-ft-btm">{data.count}</div>
            </div>
          </div>
          <div className="cont-btm-ct">
            <div className={["ct-tag", "yellow-bg"].join(' ')}>
              <IconFont type="icondaichuli" style={{ color: '#FF9F24', fontSize: '20px' }} />
            </div>
            <div className="btm-ct-ft">
              <div className="btm-ct-ft-top">待处理数</div>
              <div className="btm-ct-ft-btm">{data.waitCount}</div>
            </div>
          </div>
          <div className="cont-btm-ct">
            <div className={["ct-tag", "green-bg"].join(' ')}>
              <IconFont type="iconxinzengyugengxinhuopindangan" style={{ color: '#72D7DD', fontSize: '20px' }} />
            </div>
            <div className="btm-ct-ft">
              <div className="btm-ct-ft-top">今日新增</div>
              <div className="btm-ct-ft-btm">{data.todayCount}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}