import React, { useState } from "react"
import {Row, Col, Breadcrumb, Space, DatePicker} from "antd"
import "./index.less"
import ZgCard from "../../../components/zg-card"
import RiskRanking from "./components/risk-ranking"
import RiskBe from "./components/risk-be"
import ScoreCurve from "./components/score-curve"
import RiskScore from "./components/risk-score"
import RiskTrend from "./components/risk-trend"
import Evaluationanaly from "./components/evaluationanaly"
import CompletionCatering from "./components/completioncatering"
import EarlyAnalysis from "./components/earlyanalysis"
import dayjs from "dayjs"

// 大数据可视化--风险指数区域
export default function RiskArea(props: any) {
  const idxs: any = -1
  const [idx, setIdx] = useState(idxs)
  let startDate: any = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
  let endDate: any = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
  const [sdate, setSdate] = useState(startDate)
  const [edate, setEdate] = useState(endDate)
  const [canteenNumber, setCanteenNumber] = useState('')
  const [score, setScore] = useState(80)
  const { RangePicker } = DatePicker

  // 返回食堂编号
  function getCanteenNumber(number: any, score: any) {
    setCanteenNumber(number)
    setScore(score)
  }
  //昨日 七日  30日切换
  function getIdx(val: any) {
    if (val !== idx) {
      setIdx(val)
    }
    if (val === -1) {
      let startDate: any = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      let endDate: any = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      setSdate(startDate)
      setEdate(endDate)
    }
    if (val === 0) {
      let endDate: any = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      let startDate: any = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      setSdate(startDate)
      setEdate(endDate)
    }
    if (val === 1) {
      let endDate: any = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      let startDate: any = dayjs().subtract(30, 'day').format('YYYY-MM-DD')
      setSdate(startDate)
      setEdate(endDate)
    }
  }

  function handDate(dates: any, dateStrings: any) {
    setSdate(dateStrings[0])
    setEdate(dateStrings[1])
  }

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>大数据分析</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/analysis/risk-area">风险指数</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className='risk-any-wrap'>
        <Row gutter={12}>
          <Col span={24}>
            <div className="base-info-wrap1">
              <ZgCard>
                <div className="head-wrap">
                  <div className="title">风险指数单日</div>
                  <div className="line1">
                    <div className="line-left">时间：</div>
                    <div className="line-content">
                      <div
                        className={["bott", -1 === idx ? "active" : ""].join(
                          " "
                        )}
                        onClick={() => getIdx(-1)}
                      >
                        昨日
                      </div>
                      <div
                        className={["bott", 0 === idx ? "active" : ""].join(
                          " "
                        )}
                        onClick={() => getIdx(0)}
                      >
                        七日内
                      </div>
                      <div
                        className={["bott", 1 === idx ? "active" : ""].join(
                          " "
                        )}
                        onClick={() => getIdx(1)}
                      >
                        30日内
                      </div>
                      <div
                          className={["bott", 2 === idx ? "active" : ""].join(
                              " "
                          )}
                          onClick={() => getIdx(2)}
                      >
                        <Space
                            direction="vertical"
                            size={12}
                            style={{ marginRight: "15px" }}
                        >
                          <RangePicker onChange={handDate} />
                        </Space>
                      </div>
                    </div>
                  </div>
                </div>
              </ZgCard>
            </div>
          </Col>
        </Row>
        <Row gutter={12} className='risk-any-content'>
          <Col span={6} style={{ height: '100%' }}>
            <div className='wrap-right'>
              <RiskRanking dateIdx={idx} getCanteenNumber={getCanteenNumber} sdate={sdate} edate={edate}></RiskRanking>
            </div>
          </Col>
          <Col span={18} style={{ height: '100%', overflow: 'auto' }}>
            <Row gutter={12}>
              <Col span={8}>
                {idx === -1 ? <Evaluationanaly canteenNumber={canteenNumber} score={score} sdate={sdate} edate={edate}></Evaluationanaly> :
                  <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ marginBottom: 14, borderRadius: 5, flex: 1, background: "white" }}>
                      <RiskScore score={score} canteenNumber={canteenNumber} sdate={sdate} edate={edate}></RiskScore>
                    </div>
                    <div><RiskBe canteenNumber={canteenNumber} sdate={sdate} edate={edate}></RiskBe></div>
                  </div>
                }
              </Col>
              <Col span={16}>
                <div>{idx === -1 ? <CompletionCatering canteenNumber={canteenNumber} sdate={sdate} edate={edate}></CompletionCatering> : <ScoreCurve canteenNumber={canteenNumber} sdate={sdate} edate={edate}></ScoreCurve>}</div>
                <div>{idx === -1 ? <EarlyAnalysis canteenNumber={canteenNumber} sdate={sdate} edate={edate}></EarlyAnalysis> : <RiskTrend canteenNumber={canteenNumber} sdate={sdate} edate={edate}></RiskTrend>}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}
