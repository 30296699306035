import Index from '../layout/index'
import List from '../pages/canteen/canteen-list/list'
import Info from '../pages/canteen/canteen-info/info'
import canteenInfoIndex from '../pages/canteen/canteen-info/index'
import Outbound from '../pages/canteen/outbound-record/list'
import Store from '../pages/canteen/store-record/list'
import WorkLog from '../pages/canteen/work-log/list'
import Morning from '../pages/canteen/morning/list'
import Sample from '../pages/canteen/sample/list'
import Tableware from '../pages/canteen/tableware/list'
import Quarentine from '../pages/canteen/quarentine/list'
import CanteenCorrection from '../pages/canteen/correction/list'
import Clean from '../pages/canteen/clean/list'
import Addictive from '../pages/canteen/addictive/list'
import Safe from '../pages/canteen/safe/list'
import Environment from '../pages/canteen/environment/list'
import Waste from '../pages/canteen/waste/list'
import Accompany from '../pages/canteen/accompany/list'
import Leader from '../pages/canteen/leader/list'
import Worker from '../pages/canteen/worker/list'
import DayMenu from '../pages/canteen/day-menu/list'
import Recommend from '../pages/meal/recommend'
import RecommendInfo from '../pages/meal/recommend-info'
import Dish from '../pages/meal/dish'
import Platform from '../pages/platform/index'
import certificatesList from '../pages/warningManage/certificates/list'
import canteen_ertificateList from '../pages/warningManage/canteen_ertificate/list'
import equipmentIOTList from '../pages/equipment-iot/list'
import Material from '../pages/warningManage/materials/material'
import LotEarly from '../pages/warningManage/lotEarly/list'
import DataAnalysis from '../pages/analysis/index/data-analysis'
import RiskArea from '../pages/analysis/risk-area/index'
import Correction from '../pages/warningManage/correction/list'
import Warning from '../pages/analysis/comprehensive/comprehensive-warning'
import IntelligenceRectify from '../pages/analysis/intelligence-rectify/index'
import EarlyWarning from '../pages/canteen/earlywarning/list'
import RawMaterial from '../pages/canteen/rawmaterial/list'
import Document from '../pages/canteen/document/list'
import FoodDocument from '../pages/canteen/foodDocument/list'
import SmartVideo from '../pages/smart-video/index'
import InteractiveIndex from '../pages/interactive/list'

import SuggestionList from '../pages/interactive/components/suggestion'
import SuggestionDetail from '../pages/interactive/components/suggestionDetail'
import PotluckUser from '../pages/potluck-user/list'
import PotluckUserDetail from '../pages/potluck-user/detail'

import Supplier from '../pages/canteen/supplier/list'
import RiskSingle from '../pages/analysis/risk-single/index'
import SupplierAdmin from '../pages/supplier/list'

import Information from '../pages/information/list'
import InformationIndex from '../pages/information/components/information'
import AddInformation from '../pages/information/components/addInformation'
import DetailInformation from '../pages/information/components/detailInformation'

import InspectionList from '../pages/inspection-check/inspection/list'
import Supervision from '../pages/inspection-check/supervision/list'
import InspectionAdd from '../pages/inspection-check/inspection/add'
import InspectionDetail from '../pages/inspection-check/inspection/detail'
import SelfCheckList from '../pages/inspection-check/self-check/list'
import SelfCheckDetail from '../pages/inspection-check/self-check/detail'
import SuperviseList from '../pages/inspection-check/supervise/list'
import SuperviseAdd from '../pages/inspection-check/supervise/add'
import SupervisionDetail from '../pages/inspection-check/supervision/detail'

import SupervisionDetail1 from '../pages/inspection-check/supervision/detail1'
import SuperviseDetail from '../pages/inspection-check/supervise/detail'
import BoardIndexArea from '../pages/board/indexArea'
import BoardIndexCity from '../pages/board/indexCity'
import NetworkIndex from '../pages/analysis/network-analyze/index'
import AppraiseIndex from '../pages/analysis/appraise-analyze/index'

// 商户管理
import MerchantEnter from '../pages/merchant/enter/index'
// import enterDetail from '../pages/merchant/enter/index'
import MerchantMessage from '../pages/merchant/message/index'
import messageDetail from '../pages/merchant/message/detail'
import MerchantOrder from '../pages/merchant/order/index'

// 懒加载
// import Lazyloading from '../utils/loadable';
// const Index =  Lazyloading(() => import('../layout/index'))
// const List =  Lazyloading(() => import('../pages/canteen/canteen-list/list'))
// const Info =  Lazyloading(() => import('../pages/canteen/canteen-info/info'))
// const canteenInfoIndex =  Lazyloading(() => import('../pages/canteen/canteen-info/index'))
// const Outbound =  Lazyloading(() => import('../pages/canteen/outbound-record/list'))
// const Store =  Lazyloading(() => import('../pages/canteen/store-record/list'))
// const WorkLog =  Lazyloading(() => import('../pages/canteen/work-log/list'))
// const Morning =  Lazyloading(() => import('../pages/canteen/morning/list'))
// const Sample =  Lazyloading(() => import('../pages/canteen/sample/list'))
// const Tableware =  Lazyloading(() => import('../pages/canteen/tableware/list'))
// const Quarentine =  Lazyloading(() => import('../pages/canteen/quarentine/list'))
// const CanteenCorrection =  Lazyloading(() => import('../pages/canteen/correction/list'))
// const Clean =  Lazyloading(() => import('../pages/canteen/clean/list'))
// const Addictive =  Lazyloading(() => import('../pages/canteen/addictive/list'))
// const Safe =  Lazyloading(() => import('../pages/canteen/safe/list'))
// const Environment =  Lazyloading(() => import('../pages/canteen/environment/list'))
// const Waste =  Lazyloading(() => import('../pages/canteen/waste/list'))
// const Accompany =  Lazyloading(() => import('../pages/canteen/accompany/list'))
// const Leader =  Lazyloading(() => import('../pages/canteen/leader/list'))
// const Worker =  Lazyloading(() => import('../pages/canteen/worker/list'))
// const DayMenu =  Lazyloading(() => import('../pages/canteen/day-menu/list'))
// const Recommend =  Lazyloading(() => import('../pages/meal/recommend'))
// const RecommendInfo =  Lazyloading(() => import('../pages/meal/recommend-info'))
// const Dish =  Lazyloading(() => import('../pages/meal/dish'))
// const Platform =  Lazyloading(() => import('../pages/platform/index'))
// const certificatesList =  Lazyloading(() => import('../pages/warningManage/certificates/list'))
// const equipmentIOTList =  Lazyloading(() => import('../pages/equipment-iot/list'))
// const Material =  Lazyloading(() => import('../pages/warningManage/materials/material'))
// const LotEarly =  Lazyloading(() => import('../pages/warningManage/lotEarly/list'))
// const DataAnalysis =  Lazyloading(() => import('../pages/analysis/index/data-analysis'))
// const RiskArea =  Lazyloading(() => import('../pages/analysis/risk-area/index'))
// const Correction =  Lazyloading(() => import('../pages/warningManage/correction/list'))
// const Warning =  Lazyloading(() => import('../pages/analysis/comprehensive/comprehensive-warning'))
// const IntelligenceRectify =  Lazyloading(() => import('../pages/analysis/intelligence-rectify/index'))
// const EarlyWarning =  Lazyloading(() => import('../pages/canteen/earlywarning/list'))
// const RawMaterial =  Lazyloading(() => import('../pages/canteen/rawmaterial/list'))
// const Document =  Lazyloading(() => import('../pages/canteen/document/list'))
// const SmartVideo =  Lazyloading(() => import('../pages/smart-video/index'))
// const Supplier =  Lazyloading(() => import('../pages/canteen/supplier/list'))
// const RiskSingle =  Lazyloading(() => import('../pages/analysis/risk-single/index'))

let routers = [
  {
    component: Index,
    path: '/',
    redirect: '/canteen/list',
    children: [
      {
        component: Platform,
        path: '/Platform/index'
      },
      {
        component: List,
        path: '/canteen/list'
      },

      {
        component: Info,
        path: '/canteen/info',
        redirect: '/canteen/info/index',
        children: [
          {
            component: Supplier,
            path: '/canteen/info/supplier',
            meta: {
              title: '供应商列表'
            }
          },
          {
            component: Document,
            path: '/canteen/info/document',
            meta: {
              title: '健康证预警'
            }
          },
          {
            component: FoodDocument,
            path: '/canteen/info/foodDocument',
            meta: {
              title: '餐饮证件预警'
            }
          },
          {
            component: RawMaterial,
            path: '/canteen/info/rawmaterial',
            meta: {
              title: '原料预警'
            }
          },
          {
            component: EarlyWarning,
            path: '/canteen/info/earlywarning',
            meta: {
              title: '物联预警'
            }
          },
          {
            component: CanteenCorrection,
            path: '/canteen/info/correction',
            meta: {
              title: '视频预警'
            }
          },
          {
            component: canteenInfoIndex,
            path: '/canteen/info/index'
          },
          {
            component: Outbound,
            path: '/canteen/info/outbound',
            meta: {
              title: '出库台账'
            }
          },
          {
            component: Store,
            path: '/canteen/info/store',
            meta: {
              title: '入库台账'
            }
          },
          {
            component: WorkLog,
            path: '/canteen/info/workLog',
            meta: {
              isShow: true,
              title: '工作日志'
            }
          },
          {
            component: Morning,
            path: '/canteen/info/morning',
            meta: {
              isShow: true,
              title: '晨检记录'
            }
          },
          {
            component: Sample,
            path: '/canteen/info/sample',
            meta: {
              isShow: true,
              title: '食品留样'
            }
          },
          {
            component: Tableware,
            path: '/canteen/info/tableware',
            meta: {
              isShow: true,
              title: '餐具消毒'
            }
          },
          {
            component: Quarentine,
            path: '/canteen/info/quarentine',
            meta: {
              isShow: true,
              title: '检疫检测'
            }
          },
          {
            component: Clean,
            path: '/canteen/info/clean',
            meta: {
              isShow: true,
              title: '食堂清洁'
            }
          },
          {
            component: Addictive,
            path: '/canteen/info/addictive',
            meta: {
              isShow: true,
              title: '添加剂使用'
            }
          },
          {
            component: Safe,
            path: '/canteen/info/safe',
            meta: {
              isShow: true,
              title: '安全自查'
            }
          },
          {
            component: Environment,
            path: '/canteen/info/environment',
            meta: {
              isShow: true,
              title: '环境消毒'
            }
          },
          {
            component: Waste,
            path: '/canteen/info/waste',
            meta: {
              isShow: true,
              title: '废弃物处理'
            }
          },
          {
            component: Accompany,
            path: '/canteen/info/accompany',
            meta: {
              isShow: true,
              title: '食堂陪餐'
            }
          },
          {
            component: Leader,
            path: '/canteen/info/leader',
            meta: {
              isShow: true,
              title: '领导视察'
            }
          },
          {
            component: Worker,
            path: '/canteen/info/worker',
            meta: {
              isShow: true,
              title: '从业人员'
            }
          },
          {
            component: DayMenu,
            path: '/canteen/info/dayMenu',
            meta: {
              isShow: true,
              title: '每日菜谱'
            }
          }
        ]
      },
      {
        component: Recommend,
        path: '/meal/recommend/list',
        meta: {
          title: '菜谱推荐'
        }
      },
      {
        component: RecommendInfo,
        path: '/meal/recommend/info',
        meta: {
          title: '菜谱详情'
        }
      },
      {
        component: Dish,
        path: '/meal/dish'
      },
      {
        component: InteractiveIndex,
        path: '/interactive/list'
      },
      {
        component: SuggestionList,
        path: '/suggestion/list'
      },
      {
        component: SuggestionDetail,
        path: '/suggestion/detail'
      },
      {
        component: PotluckUser,
        path: '/PotluckUser/list'
      },
      {
        component: PotluckUserDetail,
        path: '/PotluckUser/detail'
      },
      {
        component: SmartVideo,
        path: '/smartVideo/list'
      },
      {
        component: SupplierAdmin,
        path: '/supplier/list'
      },
      {
        component: Information,
        path: '/information/list/index'
      },
      {
        component: InformationIndex,
        path: '/information/list/index'
      },
      {
        component: AddInformation,
        path: '/information/list/add'
      },
      {
        component: DetailInformation,
        path: '/information/list/detail'
      },
      {
        component: LotEarly,
        path: '/lotEarly/list'
      },
      {
        component: Material,
        path: '/materials/material'
      },
      {
        component: certificatesList,
        path: '/certificates/list'
      },
      {
        component: canteen_ertificateList,
        path: '/canteen_ertificate/list'
      },

      {
        component: equipmentIOTList,
        path: '/equipment-iot/list'
      },
      {
        component: Correction,
        path: '/correction/list'
      },
      {
        component: DataAnalysis,
        path: '/analysis/data-analysis'
      },
      {
        component: RiskArea,
        path: '/analysis/risk-area'
      },
      {
        component: Warning,
        path: '/analysis/comprehensive-warning'
      },
      {
        component: RiskSingle,
        path: '/analysis/risk-single'
      },
      {
        component: IntelligenceRectify,
        path: '/analysis/intelligence-rectify'
      },
      {
        component: NetworkIndex,
        path: '/analysis/networkIndex'
      },
      {
        component: AppraiseIndex,
        path: '/analysis/appraiseIndex'
      },
      {
        component: InspectionList,
        path: '/inspection/list'
      },
      {
        component: Supervision,
        path: '/supervision/list'
      },
      {
        component: SupervisionDetail,
        path: '/supervision/detail'
      },
      {
        component: SupervisionDetail1,
        path: '/supervision/detail1'
      },
      {
        component: InspectionAdd,
        path: '/inspection/add'
      },
      {
        component: InspectionDetail,
        path: '/inspection/detail'
      },
      {
        component: SelfCheckList,
        path: '/self-check/list'
      },
      {
        component: SelfCheckDetail,
        path: '/self-check/detail'
      },
      {
        component: SuperviseList,
        path: '/supervise/list'
      },
      {
        component: SuperviseAdd,
        path: '/supervise/add'
      },
      {
        component: SuperviseDetail,
        path: '/supervise/detail'
      },
      {
        component: BoardIndexCity,
        path: '/board/IndexCity'
      },
      {
        component: BoardIndexArea,
        path: '/board/IndexArea'
      },
      // 商家信息
      {
        component: MerchantEnter,
        path: '/merchant/enterIndex'
      },
      {
        component: MerchantOrder,
        path: '/merchant/orderIndex'
      },
      {
        component: MerchantMessage,
        path: '/merchant/messageIndex'
      },
      {
        component: messageDetail,
        path: '/merchant/messageDetail'
      }
      // {
      //   component: messageDetail,
      //   path: '/merchant/message/Detail'
      //   // title: '商户详情'
      // }
    ]
  }
]
export default routers
