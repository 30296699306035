import React, { useState, useEffect, useCallback } from "react"
import {
  Table,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Button,
  Input,
  Select,
  message,
} from "antd"
import ShowModal from "../../../components/show-modal"
// import ZgInfoItem from '../../../components/zg-info-item'
import {
  getCanteenList,
  addTask,
  getDevicesList,
} from "../../../apis/warningManage/canteen_ertificate/list"
import { mineCanteens } from "../../../apis/warningManage/certificates/list"
import moment from "moment"
// 导出预警组件
import ZgExport from '../../../components/zg-export'
import ZgCascader from '../../../components/zg-cascader'

export default function Certificates() {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [dataList, setDataList] = useState([])
  const [canteenName, setCanteenName] = useState()
  const [sdate, setSdate] = useState()
  const [edate, setEdate] = useState()
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区
  const [schoolName, setSchoolName] = useState([])
  const options = schoolName.map((e: any) => (
    <Option key={e.canteen_number} value={e.canteen_name}>
      {e.canteen_name}
    </Option>
  ))
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) => `${index + 1}`,
      width: "6%",
    },

    {
      title: "餐饮名称",
      dataIndex: "canteen_name",
      key: "canteen_name",
    },
    // type_name
    {
      title: "证件类型",
      dataIndex: "warn_type",
      render: (warn_type: any) =>
        warn_type === 1 ? (
          <span>食品经营许可证</span>
        ) : warn_type === 2 ? (
          <span>食品安全责任险</span>
        ) : (
          ""
        ),
    },
    // {
    //   title: '姓名',
    //   dataIndex: 'first_liable',
    //   key: 'first_liable',
    // },

    {
      title: "过期时间",
      dataIndex: "warn_type",
      key: "warn_type",
      render: (warn_type: any, row: any) =>
        warn_type === 1 ? (
          <span>{row.license_date.substring(0, 10)}</span>
        ) : warn_type === 2 ? (
          <span>{row.insurance_date.substring(0, 10)}</span>
        ) : (
          ""
        ),
    },
    {
      title: "过期天数",
      dataIndex: "license_date",
      render: (license_date: any, row: any) => {
        // 引用moment组件
        let now = moment().locale("zh-cn").format("YYYY-MM-DD")
        if (row.warn_type === 1) {
          return (
            <>
              <span>过期</span>
              {moment(now, "YYYY-MM-DD").diff(license_date, "day")}
              <span>天</span>
            </>
          )
        }
        if (row.warn_type === 2) {
          return (
            <>
              <span>过期</span>
              {moment(now, "YYYY-MM-DD").diff(row.insurance_date, "day")}
              <span>天</span>
            </>
          )
        }
      },
    },
    {
      title: "风险等级",
      dataIndex: "level",
      render: (level: any) =>
        level === 1 ? (
          <div
            style={{
              background: "red",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅰ级</span>
          </div>
        ) : level === 2 ? (
          <div
            style={{
              background: "#ff9900",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅱ级</span>
          </div>
        ) : level === 3 ? (
          <div
            style={{
              background: "#3399ff",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅲ级</span>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "操作",
      dataIndex: "warn_type",
      key: "warn_type",
      render: (warn_type: any, row: any) => (
        <Button
          className="btn"
          type="primary"
          onClick={() => checkDetail(row)}
          ghost={true}
        >
          {" "}
          生成整改单{" "}
        </Button>
      ),
    },
    // {
    //   title: '状态',
    //   dataIndex: 'handle_state',
    //   key: 'handle_state',
    //   render: (state: any) => (
    //     <>
    //       {<span key={state}>
    //         {state === 0 ? <div style={{ color: "red" }}>待处理</div> : state == 1 ? <div>已处理</div> : "无"}
    //       </span>}
    //     </>
    //   )
    // },
    // {
    //   title: '处理时间',
    //   dataIndex: 'handle_date',
    //   key: 'handle_date',
    // },
    // {
    //   title: '处理人',
    //   dataIndex: 'handle_user_name',
    //   key: 'handle_user_name',
    // }
  ]
  const [str, setStr] = useState()
  const showModal = () => {
    mineCanteens({ name: "" }).then((res) => {
      if (res.status === 200) {
        let str: any = (
          <ShowModal
            isShow={true}
            data={res.data}
            closeModal={closeModal}
          ></ShowModal>
        )
        setStr(str)
      }
    })
  }
  const closeModal = () => {
    let str: any = (
      <ShowModal isShow={false} data={[]} closeModal={closeModal}></ShowModal>
    )
    setStr(str)
  }

  // 生成整改单
  function checkDetail(val: any) {
    console.log("=======>", val)

    let data = {
      canteen_number: val.canteen_number,
      title: val.warn_desc,
      type: 2,
      risk_type: "餐饮证件预警",
      is_auto: 1,
      inspection_time: val.created_at,
      problem_describe: val.warn_desc,
    }
    addTask(data).then((res) => {
      console.log(res)
      const key = "updatable"
      message.loading({ content: "正在生成整改单...", key })
      setTimeout(() => {
        message.success({ content: "生成完成", key, duration: 2 })
      }, 1000)
    })
  }

  function handDate(dates: any, dateStrings: any) {
    setSdate(dateStrings[0])
    setEdate(dateStrings[1])
    setCurrentPage(1)
  }

  function handName(val: any) {
    setCanteenName(val)
    setCurrentPage(1)
  }

  function changePage(val: number) {
    setCurrentPage(val)
  }

  function searchList() {
    setCurrentPage(1)
  }
  //下拉框数据
  useEffect(() => {
    getDevicesList({}).then((res) => {
      setSchoolName(res.data)
    })
  }, [])

  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  // function to_get_list() {
  //   getCertList({ page: currentPage, canteen_name: canteenName, state: state, sdate: sdate, edate: edate }).then((res) => {
  //     setTotalCount(res.data.total)
  //     setDataList(res.data.data)
  //     setPerPage(res.data.per_page)
  //   })
  // }
  const to_get_list = useCallback(() => {
    return getCanteenList({
      sdate: sdate,
      edate: edate,
      page: currentPage,
      name: canteenName,
      city: cityName,
      district: districtName,
    }).then(
      (res) => {
        setTotalCount(res.data.total)
        setDataList(res.data.data)
        setPerPage(res.data.per_page)
      }
    )
  }, [currentPage, canteenName, sdate, edate, cityName, districtName])

  useEffect(() => {
    to_get_list()
  }, [to_get_list]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>证件管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/canteen_ertificate/list">餐饮证件预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container">
        <div className="zg-container-content">
          <Row style={{ marginBottom: "32px", fontSize: "20px" }}>
            <Col span={4}>餐饮证件预警</Col>
          </Row>
          <Row>
            <Col span={24} className="gutter-row" style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center'
            }}>
              <div className="down">
                <span>预警时间：</span>
                <Space
                  direction="vertical"
                  size={12}
                  style={{ marginRight: "15px" }}
                >
                  <RangePicker onChange={handDate} />
                </Space>
              </div>
              <div className="down">
                <span>餐饮名称：</span>
                {/* <Input
                placeholder="请输入"
                onChange={(e) => handName(e)}
                style={{ width: 140, marginRight: "15px" }}
              /> */}
                <Select
                  showSearch
                  placeholder="请输入食堂名称"
                  style={{ marginRight: "20px", width: "135px" }}
                  onChange={handName}
                  allowClear
                  dropdownClassName="dropdown-style"
                  dropdownMatchSelectWidth={200}
                >
                  <Option value="">全部</Option>
                  {options}
                </Select>
              </div>
              <div className="down">
                {/* 所属区域查询功能 */}
                <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              </div>
              {/* <span>状态选择：</span>
              <Select placeholder="状态选择" style={{ width: 140, marginRight: '15px' }} onChange={handState} allowClear>
                <Option value="2">全部</Option>
                <Option value="0">未处理</Option>
                <Option value="1">已处理</Option>
              </Select> */}
              <Button className="down" type="primary" onClick={searchList}>
                查询
              </Button>

            </Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col span={24} className="gutter-row" >
              {/* 导出 */}
              <ZgExport></ZgExport>
              <Button
                className="down btn"
                ghost={true}
                style={{ marginLeft: "15px", float: "right" }}
                type="primary"
                onClick={showModal}
              >
                一键生成整改单
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataList}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              ></Table>
            </Col>
          </Row>
        </div>
      </div>
      {str}
    </>
  )
}
