import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Image } from 'antd';
import ZgInfoItem from '../../../components/zg-info-item';
import noImg from '../../../assets/images/noData.png'
import { dishInfo } from '../../../apis/meal/dish'
import { isBlank } from '../../../utils/is_blank'
import IconFont from "components/icon-font";
import '../less/detail.less'
export default function Detail(props: any) {
  let info = {
    name: '',
    type_id: 1,
    cost_price: '',
    pics: '',
    pic_host: '',
    nutrition: { carbohydrate: '', protein: '', hot: '', fat: '', sodium: '' },
    dishes_type: { name: '' },
    burden_master: '',
    burden: '',
    formula: '',
    specifications: '',
    tag1: [],
    tag2: []
  }
  const [detailInfo, setDetailInfo] = useState(info)
  // console.log(props.id)
  let to_get_info = useCallback(() => {
    return dishInfo({ id: props.id }).then(res => {
      console.log("res", res.data)
      setDetailInfo(res.data)
    })
  }, [props.id])
  useEffect(() => {
    to_get_info()
  }, [to_get_info])
  return (
    <div className="detail-wrap">
      <Row gutter={[12, 12]}>
        <Col span={6}><ZgInfoItem title="菜品名称" content={detailInfo.name}></ZgInfoItem></Col>
        <Col span={6}><ZgInfoItem title="菜品类别" content={detailInfo.dishes_type.name}></ZgInfoItem></Col>
        <Col span={6}><ZgInfoItem title="菜品价格" content={detailInfo.cost_price + '元'}></ZgInfoItem></Col>
        <Col span={6}><ZgInfoItem title="规格（g/份）" content={detailInfo.specifications}></ZgInfoItem></Col>
      </Row>
      {/* 菜品标签 */}
      <div className="line" style={{ display: (detailInfo.tag2 || detailInfo.tag1 ? 'flex' : 'none') }}></div>
      <div className="title-level-second" style={{ display: (detailInfo.tag2 || detailInfo.tag1 ? 'flex' : 'none') }}>菜品标签</div>
      <div className="detail_info" style={{ display: (detailInfo.tag2 || detailInfo.tag1 ? 'flex' : 'none') }}>
        {
          detailInfo.tag2 ? detailInfo.tag2.map(function (val) {
            return <div className="detail_tag" key={val}>
              <IconFont type="iconabnormal_fill" style={{ color: "#FF8400", fontSize: "16px", marginRight: '5px' }} />
              {val}
            </div>
          }) : ''
        }
        {
          detailInfo.tag1 ? detailInfo.tag1.map(function (val) {
            return <div className="detail_tag" key={val}>
              <IconFont type="icontuijian1" style={{ color: "#04C26F", fontSize: "16px", marginRight: '5px' }} />
              {val}
            </div>
          }) : ''
        }
      </div>

      <div className="line"></div>
      <div className="title-level-second">食材明细</div>
      <ZgInfoItem title="主料" content={detailInfo.burden_master ? detailInfo.burden_master : "暂无"}></ZgInfoItem>
      <ZgInfoItem title="辅料" content={detailInfo.burden ? detailInfo.burden : "暂无"}></ZgInfoItem>
      <div className="line"></div>
      <div className="title-level-second">食材做法</div>
      <div className="desc-wrap">
        {isBlank(detailInfo.formula) ? '暂无' : detailInfo.formula}
      </div>
      <div className="line"></div>
      <div className="title-level-second">营养分析</div>
      <Row gutter={[12, 12]}>
        <Col span={4.8}><ZgInfoItem title="蛋白质" content={detailInfo.nutrition.protein === '' ? '--' : detailInfo.nutrition.protein + "g/100g"}></ZgInfoItem></Col>
        <Col span={4.8}><ZgInfoItem title="脂肪" content={detailInfo.nutrition.fat === '' ? '--' : detailInfo.nutrition.fat + "g/100g"}></ZgInfoItem></Col>
        <Col span={4.8}><ZgInfoItem title="碳水化合物" content={detailInfo.nutrition.carbohydrate === '' ? '--' : detailInfo.nutrition.carbohydrate + "g/100g"}></ZgInfoItem></Col>
        <Col span={4.8}><ZgInfoItem title="热量" content={detailInfo.nutrition.hot === '' ? '--' : detailInfo.nutrition.hot + "千卡/100g"}></ZgInfoItem></Col>
        <Col span={4.8}><ZgInfoItem title="钠" content={detailInfo.nutrition.sodium === '' ? '--' : detailInfo.nutrition.sodium + "(mg)"}></ZgInfoItem></Col>
      </Row>
      <div className="line"></div>
      <div className="title-level-second">菜品图片</div>
      <div className="img-wrap">
        <Image className="img" style={{ display: (detailInfo.pics === '' ? 'none' : 'inline-block') }} src={detailInfo.pic_host + detailInfo.pics} />
        <Image className="img" preview={false} style={{ display: (detailInfo.pics === '' ? 'inline-block' : 'none') }} src={noImg} />
      </div>
    </div>
  )
}