import React, { useState, useEffect } from "react";
import Title from "../title";
import { Modal } from "antd";
import { ArrayLngLat, Map, Markers } from "react-amap";
import { getMapList } from "../../../../apis/analysis/index";
import { getMapCount, getSupplierCount } from "../../../../apis/board";
import $storeLocal from "storejs";
import { getRemoteToken } from "../../../../apis/canteen-info/list";
import Pop from "../pop";

import GetTheme from "../../../../utils/getTheme";

export default function Jianguanzongkuang(props: any) {
  let styles = GetTheme();
  // 是否显示地图
  const [showMap, setShowMap] = useState(false);
  const stacData = [
    { name: "餐饮总数", value: 0 },
    { name: "供应商总数", value: 0 },
    { name: "物联在线设备", value: 0 },
    { name: "从业人员", value: 0 },
  ];
  const [stacList, setStacList] = useState(stacData);
  const [markers, setMarkers] = useState([]);
  // 地图中心点
  const [mapCenter, setMapCenter] = useState<ArrayLngLat>([
    112.144146, 32.042426,
  ]); // 默认中心点

  //监管总况dom渲染
  const stacEle = stacList.map((item: any) => (
    <div className={styles.stacItem} key={item.name}>
      <div className={styles.desc}>{item.name}</div>
      <div className={styles.divBlock + " " + styles.recBlock}>
        <div className={styles.yellowColor}>{item.value}</div>
      </div>
    </div>
  ));
  const [showPop, setPop] = useState(false);
  const [canteenNumber, setNumber] = useState("");
  //关闭弹窗
  function closePop() {
    setPop(false);
  }

  let to_get_map = () => {
    let data = {};
    getMapList(data).then((res) => {
      let arr: any = [];
      res.data.forEach((item: any, key: any) => {
        arr.push({
          position: {
            longitude: Number(item.map_x),
            latitude: Number(item.map_y),
          },
          // title: item.canteen_number,
          // label: {
          //   content:
          //     "<div class='info' style='color:black'>" +
          //     item.canteen_name +
          //     "</div>", //设置文本标注内容
          //   direction: "right", //设置文本标注方位
          // },
          title: item.canteen_name,
          extData: {
            canteen_number: item.canteen_number, // 食堂编号
          },
        });
      });
      setMarkers(arr);
      // 设置地图中心点
      setMapCenter([
        arr[0]["position"]["longitude"],
        arr[0]["position"]["latitude"],
      ]);
      setShowMap(true);
    });
  };

  //点击地图食堂名字
  function mapCheckDetail(info: any, marker: any) {
    console.log("marker", marker.getExtData());
    let number = marker.getExtData().extData.canteen_number;
    let host = "https://proxy.deyicy.com/" + number;
    getRemoteToken({ number: number }).then((res) => {
      $storeLocal.set("canteenRemoteInfo", {
        host: host,
        number: number,
        token: res.data.token,
      });
      setNumber(number);
      setPop(true);
    });
  }

  //获取各类型食堂统计数目
  const get_canteen_count = () => {
    getMapCount({}).then((res: any) => {
      // console.log("物联在线设备", res)
      let ls: any = [
        { name: "餐饮总数", value: res.data.canteen_count },
        { name: "供应商总数", value: 0 },
        { name: "物联在线设备", value: res.data.device_online_amount },
        { name: "从业人员", value: res.data.workers_count },
      ];
      getSupplierCount({}).then((res: any) => {
        ls[1].value = res.data.count;
        setStacList(ls);
      });
    });
  };
  useEffect(() => {
    get_canteen_count();
    to_get_map();
  }, []);
  return (
    <>
      <div className={styles.center}>
        <div className={styles.box + " " + styles.box1}>
          <Title title="监管总况"></Title>
          <div className={styles.stacList}>{stacEle}</div>
          <div
            style={{
              width: "100%",
              height: 415,
            }}
          >
            {showMap === true ? (
              <Map
                amapkey="b970bbf41025f93ec47892d34a6d8ab0"
                center={mapCenter}
                zoom={12}
                mapStyle="amap://styles/d3333ca0695e800a6a45d66397185933"
              >
                <Markers
                  markers={markers}
                  events={{
                    click: (info: any, marker: any) =>
                      mapCheckDetail(info, marker),
                  }}
                />
              </Map>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Modal
        bodyStyle={{ padding: 0 }}
        onCancel={closePop}
        closable={false}
        footer={null}
        visible={showPop}
        width={1100}
      >
        <Pop close={closePop} number={canteenNumber}></Pop>
      </Modal>
    </>
  );
}
