import React, { useState, useCallback, useEffect } from "react";
import styles1 from "../../indexArea.module.css";
import Title from "../title";
import { Select } from "antd";
import echarts from "echarts";
import { getVideoCount, getHighError } from "../../../../apis/board";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Shipinyujing(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  const [correctCount, setCorrectCount] = useState(0);
  const [videoDeviceCount, setVideoCount] = useState(0);

  function check_more(path: any) {
    history.push(path);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [iDateType, setIDateType] = useState(1);
  const [videoDateType, setVideoDateType] = useState(1);
  function videoDateChange(type: any) {
    setVideoDateType(type);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function iDateChange(type: any) {
    setIDateType(type);
  }
  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }
  // 渲染视频直播图表
  const renderVideoChart = (data: any) => {
    let chartData: any = [];
    data.forEach((item: any, index: any) => {
      if (index < 4) {
        chartData.push({ value: item.count, name: item.correct_type });
      }
    });
    var ele: any = document.getElementById("videoChart2");
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        orient: "horizontal",
        bottom: 10,
        left: "center",
        textStyle: { color: "#fff" },
      },
      color: ["#1EB38B", "#3582FE", "#A682E6", "#F29961"],
      series: [
        {
          name: "纠偏类型占比",
          type: "pie",
          radius: ["20%", "60%"],
          center: ["50%", "30%"],
          avoidLabelOverlap: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                fontSize: 12,
                formatter: "{d}%",
                position: "inner",
              },
              labelLine: {
                show: true,
              },
            },
          },
          data: chartData,
        },
      ],
    });
  };
  //摄像头数量
  const videoCountData = useCallback(() => {
    return getVideoCount({
      sdate: formatDate(videoDateType)[0],
      edate: formatDate(videoDateType)[1],
    }).then((res: any) => {
      setVideoCount(res.data.total);
    });
  }, [videoDateType]);
  //高频纠偏
  const highError = useCallback(() => {
    return getHighError({
      sdate: formatDate(videoDateType)[0],
      edate: formatDate(videoDateType)[1],
    }).then((res: any) => {
      renderVideoChart(res.data.info);
      setCorrectCount(res.data.count);
    });
  }, [videoDateType]);
  useEffect(() => {
    highError();
    videoCountData();
  }, [highError, videoCountData]);
  return (
    <div className={styles1.shipinyujing}>
      <div className={styles.box + " " + styles1.videoWarn}>
        <div className={styles.flexRow + " " + styles.titleLine}>
          <div className={styles.flexRow + " " + styles.checkLine}>
            <Title title="视频预警分析"></Title>
            <span
              onClick={() => check_more("/correction/list")}
              className={styles.checkMore + " " + styles.yellowColor}
            >
              查看
            </span>
          </div>
          <Select
            onChange={videoDateChange}
            className={"board-select " + styles.select}
            key={timeList[0].type}
            defaultValue={timeList[0].type}
          >
            {timeList.map((item: any) => (
              <Option key={item.type} value={item.type}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.flexRow}>
          <div
            className={
              styles.divBlock +
              " " +
              styles1.recBlockCenter +
              " " +
              styles.recBlock1
            }
          >
            摄像头数量
            <span className={styles1.yellowColor}>{videoDeviceCount}</span>
          </div>
          <div className={styles.divBlock + " " + styles1.recBlockCenter}>
            视频纠偏预警
            <span className={styles1.yellowColor}>{correctCount}</span>
          </div>
        </div>
        <div
          className={
            styles.flexRow + " " + styles.dotTitle + " " + styles.purpleColor
          }
        >
          <span className={styles.dotBg}>
            <span className={styles.dot}></span>
          </span>
          纠偏类型占比
        </div>
        <div className={styles1.videoChartWrap}>
          <div
            className={styles.videoChart + " " + styles1.videoChartHo}
            id="videoChart2"
          ></div>
        </div>
      </div>
    </div>
  );
}
