import request from '../../utils/request'
import fileRequest from '../../utils/fileRequest'
/**
 * 获取视频列表
 */
export function getVideoList(data: any) {
  return request({
    url: '/app/video/config/index',
    method: 'get',
    params: data
  })
}
/**
 * 提交违规行为
 */
export function commitError(data: any) {
  return request({
    url: '/app/video/correct/save',
    method: 'post',
    data
  })
}
/**
 * 上传图片
 */
export function uploadImg(data: any) {
  return fileRequest({
    url: '/app/file-manage/upfile',
    method: 'post',
    data
  })
}
/**
 * 获取食堂配置信息
 */
export function getTypeConfig(data: any) {
  return request({
    url: '/app/config/info',
    method: 'get',
    params: data
  })
}

/**
 * 生成整改单
 */
export function addTask(data: any) {
  return request({
    url: '/app/inspection-tasks/add',
    method: 'post',
    data
  })
}

/**
 * 获取萤石accessToken
 */
export function getYsAccessToken(data: any) {
  return request({
    url: '/api/ezviz/token',
    method: 'get',
    params: data
  })
}
