import React, { useEffect, useState } from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import { Row, Col } from "antd";
import { warningIndex } from "../../../../apis/intelligence-rectify/intelligence-rectify"

export default function DocumentWarning() {
  const initInfo = {
    cert_expire_count: 0,
    cert_expire_new_add_count: 0,
    cert_expire_notHandle_count: 0
  }
  const [data, setData] = useState(initInfo)
  function to_init_data() {
    warningIndex({}).then(res => {
      console.log("综合预警")
      console.log(res)
      setData(res.data)
    })
  }
  useEffect(() => {
    to_init_data()
  }, [])
  return (
    <>
      <div className="stac-info-wrap">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="证件预警数据"></ZgTitle>
          </ZgRow>
          <Row>
            <Col span={8} style={{ textAlign: "center" }}>
              <div>
                <div className="fontSize">累计预警数</div>
                <div className="fontDigital">{data.cert_expire_count}</div>
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <div className="borders">
                <div className="fontSize">已处理</div>
                <div className="fontDigital">{data.cert_expire_notHandle_count}</div>
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <div>
                <div className="fontSize">今日新增</div>
                <div className="fontDigital">{data.cert_expire_new_add_count}</div>
              </div>
            </Col>
          </Row>
        </ZgCard>
      </div>
    </>
  );
}
