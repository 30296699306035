import React from 'react'
import ZgRow from './zg-row'
export default function ZgInfoItem(props: any) {
  const { title } = props
  const { content } = props
  return (
    <>
      <ZgRow align={"center"} justify={"flex-start"} >
        <span style={{ color: '#778CA2', marginRight: '15px' }}>{title}</span>
        <span style={{ color: '#252631' }}>{content}</span>
      </ZgRow>
    </>
  )
}