import request from '../../../utils/request'

/**
 * 获取食堂列表
 */
export function getCorrectionList(data: any) {
  return request({
    url: '/app/video/correct/index',
    method: 'get',
    params: data
  })
}
/**
 * 获取食堂名称列表
 */
export function getCanteenList(data: any) {
  return request({
    url: '/app/canteen/all',
    method: 'get',
    params: data
  })
}
/**
* 生成整改单
*/
export function addTask(data: any) {
  return request({
    url: "/app/inspection-tasks/add",
    method: "post",
    data,
  })
}

/**
* 纠偏类型
*/
export function getCorrectType(data: any) {
  return request({
    url: "/api/canteen/video/correct/type",
    method: 'get',
    params: data
  })
}



