import React, { useEffect, useState, useCallback } from "react"
import { Table, Breadcrumb, Row, Col, Modal, Button, Select } from "antd"
import ZgSearch from "../../../components/zg-search"
import ZgCascader from "../../../components/zg-cascader"
// import ZgCatering from "../../../components/zg-catering"
import "../../../style/button.less"
import { getCanteenList, getCanteenType, getExportIndex } from "../../../apis/canteen-info/list"
import Detail from "../canteen-detail/detail"

export default function CanteenList() {
  const { Option } = Select
  const [showDetail, setShowDetail] = useState(false) //详情弹窗
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [canteenName, setCanteenName] = useState("") //食堂名称
  const [canteenType, setCanteenType] = useState("") //食堂名称
  const [checkId, setCheckId] = useState("") //查看详情id
  const [canteentype, setCanteenTypes] = useState<any[]>([])

  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区

  let columns = [
    {
      title: "序号",
      dataIndex: "id",
      render: (text: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
    },
    {
      title: "餐饮名称",
      dataIndex: "canteen_name",
    },
    {
      title: "餐饮类型",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) =>
        `${text === "school" ? "学校/幼儿园食堂"
          : text === "office" ? "机关/企业食堂"
            : text === "enterprise" ? "中央厨房"
              : text === "sociology" ? "大中型餐饮"
                : text === "cookshop" ? "小餐饮"
                  : "社会餐饮"}`,
    },
    {
      title: "所属城市",
      dataIndex: "city",
    },
    {
      title: "联系人",
      dataIndex: "first_liable",
    },
    {
      title: "手机号码",
      dataIndex: "canteen_phone",
    },
    {
      title: "从业人员",
      dataIndex: "workers_number",
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <Button
          className="btn"
          type="primary"
          onClick={() => checkDetail(record.canteen_number)}
          ghost={true}
        >
          查看详情
        </Button>
      ),
    },
  ]
  const [canteenList, setCanteenList] = useState([])
  // 获取食堂名称
  let getCanteenName = (data: any) => {
    setCanteenName(data)
  }
  // 设置查看的食堂id
  let to_set_id = (data: any) => {
    setCheckId(data)
  }
  // 查看详情
  function checkDetail(data: any) {
    to_set_id(data)
    // console.log(checkId)
    return setShowDetail(true)
  }
  // 关闭弹窗
  function to_close() {
    return setShowDetail(false)
  }
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  // 餐饮类型查询
  const onTypeChange = (value: any) => {
    // console.log("类型", value)
    setCanteenType(value)
  }

  // 导出功能
  const showExport = () => {
    console.log('导出功能')
    getExportIndex({ name: canteenName, type: canteenType, city: cityName, district: districtName }).then((res) => {
      console.log(res.data)
      const a = document.createElement('a');
      const csvData = new Blob([res.data]);
      let file_name = '餐饮单位信息表.xlsx'
      document.body.appendChild(a);
      a.style.display = "none"
      let url = window.URL.createObjectURL(csvData);
      a.href = url;
      a.download = file_name;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

    })
  }

  // 获取食堂列表
  const to_get_list = useCallback(() => {
    return getCanteenList({ page: currentPage, name: canteenName, type: canteenType, city: cityName, district: districtName }).then(
      (res) => {
        setTotalCount(res.data.total)
        setCanteenList(res.data.data)
        setPerPage(res.data.per_page)
      }
    )
  }, [currentPage, canteenName, canteenType, cityName, districtName])


  function changePage(val: number) {
    setCurrentPage(val)
  }
  // 餐饮类型： 数据列表
  const CanteenType_Options = Object.keys(canteentype).map((key: any) => (
    <Option key={key} value={key}>
      {canteentype[key]}
    </Option>
  ))

  //渲染下拉框数据
  useEffect(() => {
    getCanteenType({}).then((res) => {
      setCanteenTypes(res.data)
    })
  }, [])

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>餐饮管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/canteen/list">餐饮列表</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container">
        <div className="zg-container-content">
          <Row style={{ marginBottom: "32px", fontSize: "20px" }}>
            <Col span={4}>餐饮列表</Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col className="gutter-row">
              <ZgSearch
                getInputValue={getCanteenName}
                placeholder="请输入餐饮名称"
              ></ZgSearch>
            </Col>
            <Col className="gutter-row">
              <div style={{ marginLeft: '10px' }}>
                <span>餐饮类型：</span>
                <Select
                  style={{ width: "200px" }}
                  placeholder="请选择餐饮类型"
                  onChange={onTypeChange}
                  allowClear
                >
                  {CanteenType_Options}
                </Select>
              </div>
            </Col>
            <Col className="gutter-row">
              <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
            </Col>
            <Button
              style={{ marginLeft: "15px", float: "right" }}
              type="primary"
              onClick={showExport}
            >
              导出
            </Button>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={canteenList}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              ></Table>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        visible={showDetail}
        title="餐饮详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
    </>
  )
}
