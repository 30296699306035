import { message } from "antd";
import axios from "axios";
var baseURL = 'https://3c-supplier.deyicy.com'
/** 创建axios实例 */
const service = axios.create({
  timeout: 15000, // 请求超时时间
  baseURL: baseURL, // api的base_url
  headers: { 'content-type': 'application/json' }

});
/** request拦截器 */
service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    // 遇到错误或者超时
    Promise.reject(error);
  }
);
/**
 *  respone 拦截器
 */
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response;
    if (res.status !== 200) {
      message.error(res.message);
    } else {
      return response;
    }
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = '/login'
    }
    return Promise.reject(error);
  }
);
export default service;