import React, { useCallback, useState, useEffect } from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import { Row, Col } from "antd";
import IconFont from "components/icon-font";
import { safeIndexDayInfo } from "../../../../apis/intelligence-rectify/intelligence-rectify"

export default function CompletionCatering(props: any) {
  const info = {
    safety_checks_count: 0,
    samples_count: 0,  //留样次数
    disinfections_count: 0,//餐具消毒次数
    morning_check_count: 0,
    clean_checks_count: 0,
    waste_disposals_count: 0,
    quarantine_inspection_count: 0,
    environ_disinfections_count: 0,
    accompany_dinner_count: 0,
    additive_count: 0,
  }
  const [data, setData] = useState(info)
  const get_data = useCallback(
    () => {
      return safeIndexDayInfo({ canteen_number: props.canteenNumber, date: props.sdate }).then(res => {
        if (res.status === 200) {
          setData(res.data)
        } else {
          console.warn("safeIndexDayInfo 请求出错 ===>", res)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate],
  )
  useEffect(() => {
    if (props.canteenNumber !== '') {
      get_data()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_data])
  return (
    <div style={{ marginBottom: 12, height: 380 }}>
      <ZgCard className="complete-card-box">
        <ZgRow justify="space-between" align="flex-start">
          <ZgTitle title="餐饮工作完成情况"></ZgTitle>
        </ZgRow>
        <Row gutter={[12, 12]} wrap>

          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconcanyin1" style={{ color: "#C6CFF6", fontSize: "16px" }} />
            </div>
            <div className="longford">食品留样</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.samples_count}</span>/3）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconxiaodu" style={{ color: "#C6CFF6", fontSize: "16px" }} />
            </div>
            <div className="longford">餐具消毒</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.disinfections_count}</span>/3）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconchenjian" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">人员晨检</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.morning_check_count}</span>人）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconanquan" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">安全自查</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.safety_checks_count}</span>次）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconqingjie" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">食堂清洁</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.clean_checks_count}</span>次）</div>
          </Col>

          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconfeiqiwuchuzhi" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">废弃物处置</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.waste_disposals_count}</span>次）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconjianyanjianyi" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">检疫检测</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.quarantine_inspection_count}</span>次）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconhuanjing" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">场所消毒</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.environ_disinfections_count}</span>次）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="icontianjiaji" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">添加剂使用</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.additive_count}</span>次）</div>
          </Col>
          <Col className="flexist">
            <div className="ring">
              <IconFont type="iconlingdao" style={{ color: "#5473E8", fontSize: "16px" }} />
            </div>
            <div className="longford">食堂陪餐</div>
            <div style={{ fontSize: '14px' }}>（<span style={{ color: "#EA5752" }}>{data.accompany_dinner_count}</span>次）</div>
          </Col>
        </Row>

      </ZgCard>
    </div>
  );
}
