import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button } from 'antd'
import ZgRow from '../../../components/zg-row'
import moment from 'moment'
import { getInRecord } from '../../../apis/canteen-info/proxy-info'
import { ColumnProps } from 'antd/lib/table'
const { RangePicker } = DatePicker

export default function StoreList() {
  const [startdate, setStartdate] = useState(moment(Date()).subtract(1, 'month'))
  const [enddate, setEnddate] = useState(moment(Date()))
  const [dataList, setList] = useState([]) //列表数据
  const [currentPage, setCurrentPage] = useState(1) //设置当前页数
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数

  function time_change(date: any) {
    if (date) {
      setStartdate(date[0])
      setEnddate(date[1])
      setCurrentPage(1)
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    const sdate = moment(startdate).format('YYYY-MM-DD')
    const edate = moment(enddate).format('YYYY-MM-DD')
    return getInRecord({ page: currentPage, page_size: 15, sdate: sdate, edate: edate }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, startdate, enddate])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let columns: ColumnProps<any>[] = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${index + 1 + (currentPage - 1) * perPage}`
    },
    {
      title: '入库时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: any, row: any) => {
        if (row.order && row.order.supply_time) {
          return row.order.supply_time
        }
        return created_at
      }
    },
    {
      title: '商品名称',
      dataIndex: 'supplier_goods_name',
      key: 'supplier_goods_name'
    },
    {
      title: '数量',
      dataIndex: 'amount',
      render: (amount: any, row: any) => {
        amount = parseFloat(amount)
        return `${amount}${row.goods.unit_name}`
      }
    },
    // {
    //   title: '总价（元）',
    //   dataIndex: 'total_price',
    //   key: 'total_price',
    // },
    {
      title: '生产日期',
      key: 'production_date',
      render: (record: any) => <div>{record.goods.production_date}</div>
    },
    {
      title: '保质期',
      key: 'shelf_life',
      render: (record: any) => <div>{record.goods.goods_basic.shelf_life}天</div>
    },
    {
      title: '供应商',
      render: (record: any) => <div>{record.order.supplier.name}</div>
    },
    {
      title: '入库人',
      key: 'confirm_user_name',
      render: (record: any) => <div>{record.order.confirm_user_name}</div>
    }
  ]
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">入库台账</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>入库日期：</span>
            <RangePicker onChange={time_change} defaultValue={[startdate, enddate]}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>
              查询
            </Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => columns.id + columns.goods_id}
              pagination={{
                // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage
              }}
            ></Table>
          </Col>
        </Row>
      </div>
    </>
  )
}
