import React, { useEffect, useState, useCallback } from "react"
import { Tabs, Table, Row, Col, Button, Modal } from "antd"
import ZgSearch from "../../../components/zg-search"
import ZgRow from "../../../components/zg-row"
import Detail from "./detail"
import Detail1 from "./detail1"
import {
  getStacSuppliers,
  getStacSuppliers1,
} from "../../../apis/canteen-info/proxy-info"
import { useHistory } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"

export default function SupplierList(props: any) {
  const [showDetail, setShowDetail] = useState(false)
  const [supplierList, setSupplier] = useState([])
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [supplierName, setSupplierName] = useState("") //食堂名称
  const [checkId, setCheckId] = useState("") //查看详情id

  const [showDetail1, setShowDetail1] = useState(false)
  const [supplierList1, setSupplier1] = useState([])
  const [currentPage1, setCurrentPage1] = useState(1) //当前页码
  const [totalCount1, setTotalCount1] = useState(1) //总数
  const [perPage1, setPerPage1] = useState(15) //每页条数
  const [supplierName1, setSupplierName1] = useState("") //食堂名称
  const [checkId1, setCheckId1] = useState("") //查看详情id
  let history = useHistory()
  const { TabPane } = Tabs

  //获取供应商
  let to_get_supplier = useCallback(() => {
    return getStacSuppliers({
      page: currentPage,
      name: supplierName,
      page_size: 15,
      state: 1,
      number: props.number,
    }).then((res) => {
      console.log("xxxx", res)
      setSupplier(res.data.data)
      setTotalCount(res.data.total)
      setPerPage(res.data.per_page)
    })
  }, [props.number, currentPage, supplierName])

  //获取供应商
  let to_get_supplier1 = useCallback(() => {
    return getStacSuppliers1({
      page: currentPage1,
      name: supplierName1,
      page_size: 15,
      state: 1,
      number: props.number,
    }).then((res) => {
      console.log("xxxx111111", res)
      setSupplier1(res.data.data)
      setTotalCount1(res.data.total)
      setPerPage1(res.data.per_page)
    })
  }, [props.number, currentPage1, supplierName1])

  useEffect(() => {
    to_get_supplier()
    to_get_supplier1()
  }, [to_get_supplier, to_get_supplier1])

  // 获取供应商名称
  let getSupplierName = (data: any) => {
    setCurrentPage(1)
    setSupplierName(data)
  }

  // 设置查看的食堂id
  let to_set_id = (data: any) => {
    setCheckId(data)
  }

  function checkDetail(data: any) {
    to_set_id(data)
    // console.log(checkId)
    return setShowDetail(true)
  }

  function to_close() {
    return setShowDetail(false)
  }

  function changePage(val: number) {
    setCurrentPage(val)
  }

  let columns = [
    {
      title: "序号",
      dataIndex: "idx",
      key: "idx",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "供应商名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "注册类型",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => `${text === 0 ? "公司" : "个人"}`,
    },
    {
      title: "法人姓名",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "签约时间",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <Button
          className="btn"
          type="primary"
          onClick={() => checkDetail(record.number)}
          ghost={true}
        >
          查看详情
        </Button>
      ),
    },
  ]
  // 获取供应商名称
  let getSupplierName1 = (data: any) => {
    setCurrentPage1(1)
    setSupplierName1(data)
  }

  // 设置查看的食堂id
  let to_set_id1 = (data: any) => {
    setCheckId1(data)
  }

  function checkDetail1(data: any) {
    to_set_id1(data)
    // console.log(checkId)
    return setShowDetail1(true)
  }
  function to_close1() {
    return setShowDetail1(false)
  }
  function changePage1(val: number) {
    setCurrentPage1(val)
  }
  let columns1 = [
    {
      title: "序号",
      dataIndex: "idx",
      key: "idx",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "供应商名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "注册类型",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => `${text === 0 ? "公司" : "个人"}`,
    },
    {
      title: "法人姓名",
      dataIndex: "contact",
      key: "contact1",
    },
    {
      title: "签约时间",
      dataIndex: "created_at",
      key: "created_at1",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone1",
    },
    {
      title: "操作",
      key: "action1",
      render: (record: any) => (
        <Button
          className="btn"
          type="primary"
          onClick={() => checkDetail1(record.number)}
          ghost={true}
        >
          查看详情
        </Button>
      ),
    },
  ]

  function Onback() {
    history.go(-1)
  }

  return (
    <>
      <div className="zg-container-content">
        <div onClick={Onback}>
          <ArrowLeftOutlined />
        </div>
        <div className="title-level-first">供应商列表</div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={<span>线上供应商</span>} key="1">
            <Row style={{ marginBottom: "24px" }}>
              <ZgRow>
                <ZgSearch
                  getInputValue={getSupplierName}
                  placeholder="请输入供应商名称"
                ></ZgSearch>
              </ZgRow>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={supplierList}
                  rowKey={(columns) => columns.id}
                  pagination={{
                    // 分页
                    current: currentPage,
                    total: totalCount,
                    pageSize: perPage,
                    onChange: changePage,
                  }}
                ></Table>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={<span>线下供应商</span>} key="2">
            <Row style={{ marginBottom: "24px" }}>
              <ZgRow>
                <ZgSearch
                  getInputValue={getSupplierName1}
                  placeholder="请输入供应商名称"
                ></ZgSearch>
              </ZgRow>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  columns={columns1}
                  dataSource={supplierList1}
                  rowKey={(columns1) => columns1.id}
                  pagination={{
                    // 分页
                    current: currentPage1,
                    total: totalCount1,
                    pageSize: perPage1,
                    onChange: changePage1,
                  }}
                ></Table>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        visible={showDetail}
        title="供应商详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
      <Modal
        visible={showDetail1}
        title="供应商详情"
        onCancel={to_close1}
        footer={null}
        width="900px"
      >
        <Detail1 id={checkId1}></Detail1>
      </Modal>
    </>
  )
}
