import React, { useState, useCallback, useEffect } from "react"
import {
  Breadcrumb,
  Row,
  Col,
  Button,
  Image,
  Modal,
  Input,
  message,
} from "antd"
import { useHistory } from "react-router-dom"
import "../../../style/inspection.less"
import {
  superviseDetail,
  resultSuperviseTask,
} from "../../../apis/inspection-check/inspection"
import $storeLocal from "storejs"
import dayjs from "dayjs"

export default function SuperviseDetail(props: any) {
  let history = useHistory()
  let UserInfo = $storeLocal.get("UserBaseInfo")

  const [id] = useState(props.location.state)
  const [detail, setDetail] = useState({
    state: 1,
    title: "",
    unit_name: "",
    explain: "",
    manager_user_id: "",
    supervise_time: "",
    user_name: "",
    result: [
      {
        result: "",
        result_user_id: "",
        result_time: "",
        updated_at: "",
        user: { id: "", realname: "" },
      },
    ],
    imgs1: [""],
    user: { id: "", realname: "" },
  })

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [result, setResult] = useState("")

  function resultText(val: any) {
    setResult(val.target.defaultValue)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  function handleOk() {
    let rqs: any = {
      id: id,
      result: result,
    }

    resultSuperviseTask(rqs)
      .then((res: any) => {
        if (res.status === 200) {
          message.info("回复成功")
          history.go(-1)
          setIsModalVisible(false)
        }
      })
      .catch((e) => {
        message.warn(e.response.data.message)
      })
  }

  function handleCancel() {
    setIsModalVisible(false)
  }

  function to_url() {
    history.push({
      pathname: "/supervise/list",
    })
  }

  const get_detail = useCallback(() => {
    return superviseDetail({ id: id }).then((res) => {
      if (res.data.length > 0) {
        let arr = res.data[0].problem_images.split(",")
        res.data[0].imgs1 = arr
        setDetail(res.data[0])
      }
    })
  }, [id])

  useEffect(() => {
    get_detail()
  }, [get_detail])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管巡检</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/supervise/list">监管督办</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row
            style={{
              fontSize: "20px",
              paddingBottom: "24px",
              borderBottom: "1px solid #E8EBF9",
            }}
          >
            <Col span={22}>督办任务详情</Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Button
                type="default"
                onClick={to_url}
                style={{
                  border: "1px solid #409EFF",
                  borderRadius: 0,
                  color: "#409EFF",
                }}
              >
                返回
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            {detail.state === 1 ? (
              <Col
                style={{
                  border: "1px solid #F5DAB1",
                  backgroundColor: "#FDF6EC",
                  color: "#E6A23C",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：
                <span className="">待回复</span>
              </Col>
            ) : (
              <Col
                style={{
                  border: "1px solid #C2E7B0",
                  backgroundColor: "#F0F9EB",
                  color: "#67C23A",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：<span className="green-color">已回复</span>
              </Col>
            )}
          </Row>
          <div className="title first-title">基本信息</div>
          <div className="info-line info-flex">
            <div className="info-item">主题：{detail.title}</div>

            <div className="info-item">
              区域单位：
              {detail.unit_name}
            </div>
          </div>
          <div className="info-line info-flex">
            <div className="info-item">
              督办人员：
              {detail.user_name}
            </div>
            <div className="info-item">
              督办时间：
              <span className="info-item">
                {dayjs(detail.supervise_time).format("YYYY-MM-DD HH:mm")}
              </span>
            </div>
          </div>
          {/**<div className="info-line">
            <div className="info-item">巡检单</div>
            <Image.PreviewGroup>
              {detail.imgs1.map((item: any, index: any) => (
                <Image
                  key={index}
                  className="detail-img"
                  src={item.image_url}
                  style={{
                    display:
                      item.image_url === "" || item.image_url == null
                        ? "none"
                        : "",
                  }}
                />
              ))}
            </Image.PreviewGroup>
                </div>*/}
          <div className="info-line">任务说明：</div>
          <div style={{ marginTop: "10px", marginLeft: "20px" }}>
            <div className="info-item">
              {detail.explain === "" ? "暂无任务说明。" : detail.explain}
            </div>
            <Image.PreviewGroup>
              {detail.imgs1.map((item: any, index: any) => (
                <Image
                  key={index}
                  className="detail-img"
                  src={item}
                  style={{
                    display: item === "" || item == null ? "none" : "",
                  }}
                />
              ))}
            </Image.PreviewGroup>
          </div>
          <div
            style={{
              display: detail.state === 2 ? "block" : "none",
            }}
          >
            <div className="title">回复说明</div>
            <div className="info-line">
              <div className="info-item" style={{ width: "auto" }}>
                <span>回复人：</span>
                <span>
                  {detail.result.length > 0
                    ? detail.result[0].user.realname
                    : ""}
                </span>
                <span style={{ marginLeft: "10vw" }}>回复时间：</span>
                <span>
                  {detail.result.length > 0 ? detail.result[0].updated_at : ""}
                </span>
              </div>
              <div className="info-item">
                <span>回复情况说明：</span>
                <span>
                  {detail.result.length > 0 ? detail.result[0].result : ""}
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              display:
                detail.state === 1 &&
                  detail.result.length > 0 &&
                  detail.result[0].result_user_id === UserInfo.id
                  ? "block"
                  : "none",
            }}
          >
            <Button type="primary" onClick={showModal}>
              回复
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title="回复"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="回复"
      >
        {/* <div style={{ textAlign: "center", paddingBottom: "15px" }}>
          督办任务是否执行并下发？
        </div> */}
        <div style={{}}>
          <Input.TextArea
            onChange={resultText}
            placeholder="请输入处理结果"
          ></Input.TextArea>
        </div>
      </Modal>
    </>
  )
}
