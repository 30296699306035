import React from 'react';
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import IconFont from 'components/icon-font';

export default function EarlyAnalysis() {
  return (
    <>
      <div className="stac-info-wrap">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="预警分析数据"></ZgTitle>
          </ZgRow>
          <div>
            <div className="correctionflex ">
              <div className="orrectionfize">视频预警</div>
              <div className="addfize">昨日新增 <IconFont type="iconshangsheng" style={{ color: "#4CCCD4", fontSize: "16px" }} /><span>256</span></div>
              <div className="addfize">待处理<span>256</span></div>
            </div>

            <div className="correctionflex">
              <div className="orrectionfize">视频预警</div>
              <div className="addfize">昨日新增 <IconFont type="iconshangsheng" style={{ color: "#4CCCD4", fontSize: "16px" }} /><span>256</span></div>
              <div className="addfize">待处理<span>256</span></div>
            </div>

            <div className="correctionflex">
              <div className="orrectionfize">视频预警</div>
              <div className="addfize">昨日新增 <IconFont type="iconshangsheng" style={{ color: "#4CCCD4", fontSize: "16px" }} /><span>256</span></div>
              <div className="addfize">待处理<span>256</span></div>
            </div>

            <div className="correctionflex">
              <div className="orrectionfize">视频预警</div>
              <div className="addfize">昨日新增 <IconFont type="iconshangsheng" style={{ color: "#4CCCD4", fontSize: "16px" }} /><span>256</span></div>
              <div className="addfize">待处理<span>256</span></div>
            </div>
          </div>
        </ZgCard>
      </div>
    </>
  );
}