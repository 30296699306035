import React from 'react'
import '../less/dish-item.less'
import { Image } from 'antd'
export default function DishItem(props: any) {
  return (
    <>
      <div className="dish-item" style={{ cursor: 'pointer'}}>
        <div className="item-img">
            <Image preview={false} height={120} src={props.dishInfo.pic_host + props.dishInfo.pics}></Image>
        </div>
        <div className="item-cont">
          <div className="item-cont-top">{props.dishInfo.name}</div>
          <div className="item-cont-btm">
            <div className="btm-one">￥{props.dishInfo.cost_price}</div>
            <div className="btm-two">{props.dishInfo.dishes_type.name}</div>
          </div>
        </div>
      </div>
    </>
  )
};