import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button, Select, Input, Modal, message, Checkbox } from 'antd'
import ZgRow from '../../components/zg-row'
import { uploadImg, commitError, addTask, getYsAccessToken } from '../../apis/smart-video/index'
import $storeLocal from 'storejs'
import './style.less'
import IconFont from '../../components/icon-font'
import Videojs from 'video.js'
// 添加hls插件，以保证播放m3u8格式的视频
import 'videojs-contrib-hls'
// 导入videojs 的样式
import 'video.js/dist/video-js.css'
import VideojsZhcnLanguage from 'video.js/dist/lang/zh-CN.json'
import lrz from 'lrz'
import moment from 'moment'

// ys sdk
import EZUIKit from 'ezuikit-js'

Videojs.addLanguage('zh-CN', VideojsZhcnLanguage) //设置中文
const { Option } = Select
const { TextArea } = Input

const ysPlayerMap: Map<string, any> = new Map() // 存储萤石播放器实例

export default function VideoItem(props: any) {
  // console.log('video props===>', props)
  const cvTempRef = useRef<any>()
  const cvRef = useRef<any>()
  // const videoRef = useRef<any>();
  const [cacheImgObj, setCacheImg] = useState({})
  const [videoInfo, setVideoInfo] = useState({ high_url: '', area: '', canteen: { canteen_name: '' } })
  const [originImg, setOriginImg] = useState('')
  const [downImg, setDownImg] = useState('')
  const [showDetail, setShowDetail] = useState(false)
  const [typeList, setTypeList] = useState([])
  const [curType, setCurType] = useState('')
  let [isMouseDown, setMouseDown] = useState(false)
  const [sheet, setSheet] = useState(false)
  let [X, setX] = useState(0)
  let [X1, setX1] = useState(0)
  let [Y, setY] = useState(0)
  let [Y1, setY1] = useState(0)

  const videoBox = useRef<any>()

  // video容器

  // 播放器
  // const [videoPlayer, setVideoPlayer] = useState<any>(null)

  // 初始化video
  function initVideo(url: any) {
    console.log('video url ===>', url)
    ysPlayerMap.forEach(tmp => {
      if (tmp) tmp.stop()
    })

    // 翻源码发现通过Videojs.getPlayer(id)可以获取此id的实例
    // 解决了之前复杂的videojs重建问题
    // 太依赖于网上搜索不行（垃圾百度），还是要多翻源码，复杂的甚至感觉无法解决的问题说不定都可以很简单的处理掉
    const videoPlayer = Videojs.getPlayer(props.vid)
    if (videoPlayer) {
      videoPlayer.dispose() // 销毁实例后重建（解决当前页切换食堂时问题）
    }
    console.log('videoPlayer===>', videoPlayer)

    // 构造video标签
    const videoEl = `<video id="` + props.vid + `" muted controls  style="width:100%;height:100%" class="video-js" />`
    console.log('===> el props_id', props.vid)
    videoBox.current.innerHTML = videoEl
    const player = Videojs(props.vid, {
      controls: true,
      preload: 'auto',
      fluid: false // 关闭自适应容器
    })

    // url解码
    url = decodeURIComponent(url)

    player.src({ src: url })
    player.play()
  }

  // 使用萤石组件播放
  async function initYsVideo(opt: any) {
    let vid = props.vid
    const videoPlayer = Videojs.getPlayer(vid)
    if (videoPlayer) videoPlayer.dispose()

    if (ysPlayerMap && ysPlayerMap.get(vid)) {
      ysPlayerMap.get(vid)?.stop()
      videoBox.current.innerHTML = ''
    }

    // 获取accessToken
    const tokenRes = await getYsAccessToken({ phone: opt.phone })
    console.log('===> getYsAccessToken', tokenRes)

    // 创建DOM
    let elId = props.vid + moment.now()
    const ysVideoEl = `<div id="` + elId + `"></div>`
    videoBox.current.innerHTML = ysVideoEl

    // 获取容器宽高
    let w = videoBox.current.clientWidth
    let h = videoBox.current.clientHeight - 50

    // 播放器初始化
    let ysPlayerTmp = new EZUIKit.EZUIKitPlayer({
      id: elId, // 视频容器ID
      accessToken: tokenRes.data.data.accessToken,
      url: opt.live_high_url,
      hd: true, // 高清
      width: w,
      height: h,
      // template: 'pcLive', // simple-极简版; standard-标准版; security - 安防版(预览回放);voice-语音版; theme-可配置主题
      // template: 'simple',
      // footer: ['talk', 'broadcast', 'hd', 'fullScreen'],
      themeData: {
        header: {
          color: '#1890ff',
          activeColor: '#FFFFFF',
          backgroundColor: '#000000',
          btnList: [
            {
              iconId: 'deviceID',
              part: 'left',
              defaultActive: 0,
              memo: '顶部设备名称',
              isrender: 1
            },
            {
              iconId: 'deviceName',
              part: 'left',
              defaultActive: 0,
              memo: '顶部设备ID',
              isrender: 1
            }
          ]
        },
        footer: {
          color: '#FFFFFF',
          activeColor: '#1890FF',
          backgroundColor: '#00000021',
          btnList: [
            {
              iconId: 'play',
              part: 'left',
              defaultActive: 1,
              memo: '播放',
              isrender: 1
            },
            {
              iconId: 'capturePicture',
              part: 'left',
              defaultActive: 0,
              memo: '截屏按钮',
              isrender: 1
            },
            {
              iconId: 'sound',
              part: 'left',
              defaultActive: 0,
              memo: '声音按钮',
              isrender: 1
            },
            {
              iconId: 'pantile',
              part: 'left',
              defaultActive: 0,
              memo: '云台控制按钮',
              isrender: 1
            },

            {
              iconId: 'talk',
              part: 'left',
              defaultActive: 0,
              memo: '对讲按钮',
              isrender: 1
            },
            {
              iconId: 'hd',
              part: 'right',
              defaultActive: 0,
              memo: '清晰度切换按钮',
              isrender: 1
            },
            {
              iconId: 'webExpend',
              part: 'right',
              defaultActive: 0,
              memo: '网页全屏按钮',
              isrender: 1
            },
            {
              iconId: 'expend',
              part: 'right',
              defaultActive: 0,
              memo: '全局全屏按钮',
              isrender: 1
            }
          ]
        }
      },
      plugin: ['talk'], // 加载插件，talk-对讲
      capturePictureCallBack: (r: any) => {
        console.log('===> 截图回调', r)
      }
    })

    ysPlayerMap.set(vid, ysPlayerTmp) // 写入map
    console.log('===> ys player', ysPlayerMap)
  }

  // 播放监控
  useEffect(() => {
    console.log('======> 切换了')

    let lsTemp = $storeLocal.get('correctConfigInfo')
    let ls: any = []
    for (let i in lsTemp) {
      ls.push(lsTemp[i])
    }
    setTypeList(ls)
    setVideoInfo(props.videoInfo)

    console.log('===> props.videoInfo', props.videoInfo)

    // 当开启对讲时，并且视频源是萤石平台时，调用萤石官方组件进行播放
    // doc：https://open.ys7.com/help/31
    const info = props.videoInfo
    if (info.is_talk == 1 && info.platform == 1) {
      console.log('===> call ys sdk')
      initYsVideo(info)
      return
    }

    // 调用默认播放
    if (props.videoInfo.high_url) {
      // 播放
      initVideo(props.videoInfo.high_url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.videoInfo])

  //截取当前帧的图片
  useEffect(() => {
    if (showDetail) {
      cutPicture()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetail])

  // 萤石抓图
  async function ysCutPic() {
    let ysTmp = ysPlayerMap.get(props.vid)
    if (ysTmp) {
      ysTmp.play().then(() => {})

      // ysPlayer.capturePicture('img') // 下载
      ysTmp.capturePicture('img', (data: any) => {
        console.log('===> img.data', data)
        const img = new Image()
        img.src = data.base64
        img.id = 'tmp-ys-img0001'
        document.body.appendChild(img)

        setTimeout(() => {
          cvRef.current.height = 900
          cvRef.current.width = 1560
          var ctx = cvRef.current.getContext('2d')
          ctx.drawImage(img, 0, 0, 1560, 900)

          setOriginImg(cvRef.current.toDataURL('image/jpeg'))
          setDownImg(originImg)
          let imgObj = new Image()
          imgObj.addEventListener('load', () => {
            setCacheImg(imgObj)
          })
          imgObj.src = cvRef.current.toDataURL('image/jpeg')

          img.remove()
        }) //等待渲染完成
      })
    }
  }

  // 截图
  function cutPicture() {
    const info = props.videoInfo
    console.log('====> props.videoInfo.cutPicture', info, props.vid)
    if (info.platform == 1 && info.is_talk == 1) {
      // 萤石sdk截图
      ysCutPic()
      return
    }

    cvRef.current.height = 900
    cvRef.current.width = 1560
    var ctx = cvRef.current.getContext('2d')
    var videoRef = document.getElementById(props.vid)

    ctx.drawImage(videoRef?.children[0], 0, 0, 1560, 900)
    setOriginImg(cvRef.current.toDataURL('image/jpeg'))
    setDownImg(originImg)
    let imgObj = new Image()
    imgObj.addEventListener('load', () => {
      setCacheImg(imgObj)
    })
    imgObj.src = cvRef.current.toDataURL('image/jpeg')
    // console.log(cvRef.current.toDataURL('image/jpeg'))
  }

  function checkDetail(e: any) {
    e.stopPropagation()

    if (ysPlayerMap.get(props.vid)) {
      setShowDetail(true)
      return
    }

    if (props.videoInfo.high_url === '') {
      message.error('请选择监控区域')
    } else {
      setShowDetail(true)
    }
  }

  //给canvas注册mousedown mousemove 事件
  function mouseDownAction(e: any) {
    setMouseDown(true)
    setX(e.nativeEvent.offsetX * 2)
    setX1(e.nativeEvent.offsetX * 2)
    setY(e.nativeEvent.offsetY * 2)
    setY1(e.nativeEvent.offsetY * 2)
  }
  function mouseMoveAction(e: any) {
    if (isMouseDown) {
      if (X1 !== e.nativeEvent.offsetX || Y1 !== e.nativeEvent.offsetY) {
        setX1(e.nativeEvent.offsetX * 2)
        setY1(e.nativeEvent.offsetY * 2)
        let wwidth = X1 - X
        let wheigth = Y1 - Y
        let tempCtx = cvTempRef.current.getContext('2d')
        cvTempRef.current.width = 1560
        cvTempRef.current.height = 900 // 设置宽高
        // 清除临时层指定区域的所有像素
        tempCtx.clearRect(0, 0, 1560, 900)
        let ctx: any = cvRef.current.getContext('2d')
        if (cacheImgObj) {
          try {
            ctx.drawImage(cacheImgObj, 0, 0, 1560, 900)
          } catch (error) {}
        }
        drawLine(X, Y, wwidth, wheigth)
      }
    }
  }
  function mouseUpAction() {
    setMouseDown(false)
    let wwidth = X1 - X
    let wheigth = Y1 - Y
    drawLine(X, Y, wwidth, wheigth)
    setDownImg(cvRef.current.toDataURL('image/jpeg'))
  }
  function drawLine(bx: any, by: any, ex: any, ey: any) {
    var ctx = cvRef.current.getContext('2d')
    ctx.lineWidth = 4
    ctx.strokeStyle = 'red'
    ctx.strokeRect(bx, by, ex, ey) //绘制矩形
  }
  //清空canvas 上所有的涂鸦信息
  function removeEditCanvas() {
    let ctx = cvRef.current.getContext('2d')
    ctx.clearRect(0, 0, 1560, 900)
    if (cacheImgObj) {
      try {
        ctx.drawImage(cacheImgObj, 0, 0, 1560, 900)
      } catch (error) {}
    }
    setDownImg(originImg)
  }
  function get_type(data: any) {
    setCurType(data)
  }
  function to_close() {
    setCacheImg({})
    setOriginImg('')
    setShowDetail(false)
  }

  //提交纠偏数据
  function to_commit() {
    let cmtInfo = {
      canteen_number: props.videoInfo.canteen_number,
      area: props.videoInfo.area,
      desr: '',
      pic: '',
      correct_type: curType
    }

    if (!curType) {
      message.error('请选择纠偏类型')
      return false
    }
    if (downImg === '') {
      message.error('请框选出违规重点')
      return false
    }

    // 控制生成整改单功能
    if (sheet) {
      let curDate = moment().format('YYYY-MM-DD HH:mm:ss')
      let data = {
        canteen_number: cmtInfo.canteen_number,
        title: cmtInfo.area + curType,
        type: 2,
        risk_type: '视频预警',
        is_auto: 1,
        inspection_time: curDate,
        problem_describe: cmtInfo.area + curType
      }
      console.log('=====', data)
      addTask(data).then(res => {
        console.log(res)
        // const key = 'updatable';
        // message.loading({ content: '正在生成整改单...', key });
        // setTimeout(() => {
        //   message.success({ content: '生成完成', key, duration: 2 });
        // }, 1000);
      })
    }
    let arr: any = downImg.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    let upImg = new File([u8arr], 'img.jpg', { type: mime })
    lrz(upImg, {
      width: 1000,
      quality: 0.9 // 自定义使用压缩方式
    }).then((fileRst: any) => {
      let formData = new FormData()
      formData.append('file', fileRst.file)
      uploadImg(formData).then(res => {
        cmtInfo.pic = res.data.path
        commitError(cmtInfo).then(res => {
          setShowDetail(false)
          message.success('提交成功')
        })
      })
    })
  }
  //
  function onCheck(val: any) {
    console.log('=====>', val.target.checked)
    setSheet(val.target.checked)
  }

  useEffect(() => {
    return componentWillUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 卸载组件时
  function componentWillUnmount() {
    // 组件销毁时你要执行的代码
    const videoPlayer = Videojs.getPlayer(props.vid)
    if (videoPlayer) videoPlayer.dispose()

    // 销毁萤石播放实例
    ysPlayerMap.forEach(tmp => {
      if (tmp) tmp.stop()
    })
  }

  return (
    <div className={props.className}>
      <div className="video-item">
        <ZgRow justify="space-between" className="title-wrap">
          <ZgRow justify="flex-start" className="left">
            <IconFont type="iconshexiangtou" className="icon" />
            <span className="desc">{videoInfo.area}</span>
          </ZgRow>
          {/* 多窗口展示时关闭抓拍功能 */}
          {props.noZhuatu != '1' ? (
            <ZgRow justify="flex-end" className="right" onClick={checkDetail}>
              <span className="desc">抓拍取证</span>
              <IconFont type="iconshoudongjiupian" className="icon" />
            </ZgRow>
          ) : (
            <></>
          )}
        </ZgRow>
        <div id="video-info-conid" className="video-info" ref={videoBox}></div>
      </div>
      <Modal visible={showDetail} title="抓拍取证" onCancel={to_close} footer={null} width="1090px">
        <Row gutter={24} className="commit-wrap">
          <Col span={18}>
            <div className="desc">纠偏图像（可直接在图像上画框标记出重点）</div>
            <div className="img-wrap">
              <canvas style={{ display: 'none' }} ref={cvTempRef}></canvas>
              <canvas
                ref={cvRef}
                className="preview_canvas"
                onMouseDown={mouseDownAction}
                onMouseMove={mouseMoveAction}
                onMouseUp={mouseUpAction}
              ></canvas>
            </div>
          </Col>
          <Col span={6}>
            <div className="title-level-second">餐饮单位</div>
            <div className="no-edit">{videoInfo.canteen.canteen_name}</div>
            <div className="title-level-second">证据类型</div>
            <Select className="select-wrap" placeholder="请选择纠偏类型" onChange={get_type}>
              {typeList.map((item: any, idx: any) => (
                <Option key={idx} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <div className="title-level-second">备注</div>
            <TextArea placeholder="请输入备注" autoSize={{ minRows: 6, maxRows: 10 }} />
            <Checkbox onChange={onCheck}>是否生成整改单</Checkbox>
            <ZgRow className="btn-wrap">
              <Button className="btn btn1" onClick={removeEditCanvas}>
                取消编辑
              </Button>
              <Button className="btn" type="primary" onClick={to_commit}>
                证据保存
              </Button>
            </ZgRow>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
