import React, { useCallback, useEffect, useState } from "react"
import {
  Table,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Button,
  message,
} from "antd"
import {
  getDevices,
  getDevicesList,
  addTask,
} from "../../../apis/warningManage/lotEarly/list"
import ZgCascader from '../../../components/zg-cascader'
import moment from "moment"
import ShowModal from "components/show-modal"
import { mineCanteens } from "apis/warningManage/certificates/list"
// 导出预警组件
import ZgExport from '../../../components/zg-export'

export default function LotEarly() {

  const { Option } = Select
  const { RangePicker } = DatePicker
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [correctionList, setCorrectionList] = useState([])
  const [startdate, setStartdate] = useState(
    moment(Date()).subtract(1, "month")
  )
  const [enddate, setEnddate] = useState(moment(Date()))
  const [schoolName, setSchoolName] = useState([])
  const [canteen_number, setCanteen_number] = useState("")

  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区
  const [is_read, setIs_read] = useState()
  const [actionType, setActionType] = useState("") // 报警类型
  const [level, setLevel] = useState<number>() // 风险等级查询
  const options = schoolName.map((e: any) => (
    <Option key={e.canteen_number} value={e.canteen_name}>
      {e.canteen_name}
    </Option>
  ))
  const [str, setStr] = useState()
  const showModal = () => {
    mineCanteens({ name: "" }).then((res) => {
      if (res.status === 200) {
        let str: any = (
          <ShowModal
            isShow={true}
            data={res.data}
            closeModal={closeModal}
          ></ShowModal>
        )
        setStr(str)
      }
    })
  }
  const closeModal = () => {
    let str: any = (
      <ShowModal isShow={false} data={[]} closeModal={closeModal}></ShowModal>
    )
    setStr(str)
  }
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      render: (id: any, record: any, index: number) => {
        // `${index + 1 + (currentPage - 1) * perPage}`,
        return `${index + 1}`
      },
      width: "6%",
    },
    {
      title: "预警时间",
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
    },
    {
      title: "餐饮名称",
      dataIndex: "canteen",
      render: (canteen: { canteen_name: any }) => canteen.canteen_name,
      width: "16%",
    },
    {
      title: "设备信息",
      dataIndex: "content",
      key: "content",
      width: "13%",
    },
    {
      title: "报警提醒",
      dataIndex: "title",
      key: "title",
      width: "8%",
    },
    {
      title: "风险等级",
      dataIndex: "warn_level",
      render: (warn_level: any) =>
        warn_level === 1 ? (
          <div
            style={{
              background: "red",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅰ级</span>
          </div>
        ) : warn_level === 2 ? (
          <div
            style={{
              background: "#ff9900",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅱ级</span>
          </div>
        ) : warn_level === 3 ? (
          <div
            style={{
              background: "#3399ff",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅲ级</span>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "状态",
      dataIndex: "is_read",
      key: "is_read",
      render: (is_read: any) =>
        is_read === 1 ? (
          <div style={{ color: "red" }}>待处理</div>
        ) : (
          <div>已处理</div>
        ),
      width: 70,
    },
    {
      title: "处理时间",
      dataIndex: "handle_date",
      key: "handle_date",
      width: "12%",
    },

    {
      title: "处理人",
      dataIndex: "handle_user_name",
      key: "handle_user_name",
    },
    {
      title: "操作",
      dataIndex: "is_read",
      render: (is_read: any, row: any) =>
        is_read === 1 ? (
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail(row)}
            ghost={true}
          >
            {" "}
            生成整改单{" "}
          </Button>
        ) : (
          ""
        ),
    },
  ]
  function handState(val: any) {
    setIs_read(val)
    setCurrentPage(1)
  }

  function setName(val: any, key: any) {
    let numberId = ""
    if (val) {
      setCanteen_number(key.key)
    } else {
      setCanteen_number(numberId)
    }
    setCurrentPage(1)
  }
  // 生成整改单
  function checkDetail(val: any) {
    console.log("=======>", val)
    let data = {
      canteen_number: val.canteen_number,
      title: val.content + val.title,
      type: 2,
      risk_type: "物联预警",
      is_auto: 1,
      inspection_time: val.created_at,
      problem_describe: val.content + val.title,
    }
    addTask(data).then((res) => {
      console.log(res)
      const key = "updatable"
      message.loading({ content: "正在生成整改单...", key })
      setTimeout(() => {
        message.success({ content: "生成完成", key, duration: 2 })
      }, 1000)
    })
  }


  // 报警类型
  const onCorrect = (data: string) => {
    setActionType(data)
  }

  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }

  // 风险等级
  const onRisk = (id: number) => {
    console.log("风险等级", id)
    setLevel(id)
  }


  //下拉框数据
  useEffect(() => {
    getDevicesList({}).then((res) => {
      setSchoolName(res.data)
    })
  }, [])

  //时间查询
  function go_Data(dates: any, dateString: any) {
    setStartdate(dateString[0])
    setEnddate(dateString[1])
    setCurrentPage(1)
  }

  //列表数据
  const to_get_list = useCallback(() => {
    const sdate = moment(startdate).format("YYYY-MM-DD")
    const edate = moment(enddate).format("YYYY-MM-DD")

    return getDevices({
      page: currentPage,
      sdate,
      edate,
      canteen_number: canteen_number,
      is_read: is_read,
      city: cityName,
      title: actionType,
      warn_level: level,
      district: districtName,
    }).then((res) => {
      setTotalCount(res.data.total)
      setCorrectionList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [canteen_number, currentPage, enddate, is_read, startdate, cityName, actionType, level, districtName])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>预警管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/lotEarly/list">物联预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container">
        <div className="zg-container-content">
          <Row style={{ marginBottom: "32px", fontSize: "20px" }}>
            <Col span={4}>物联预警</Col>
          </Row>
          <Row>
            <Col span={24} className="gutter-row" style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center'
            }}>
              <div className="down">
                <span>预警时间：</span>
                <Space
                  direction="vertical"
                  size={12}
                  style={{ marginRight: "15px" }}
                >
                  <RangePicker
                    onChange={go_Data}
                    defaultValue={[moment(startdate), moment(enddate)]}
                  />
                </Space>
              </div>
              <div className="down">
                <span>餐饮名称：</span>
                <Select
                  showSearch
                  placeholder="请输入食堂名称"
                  style={{ marginRight: "20px", width: "135px" }}
                  onChange={setName}
                  dropdownMatchSelectWidth={200}
                  allowClear
                >
                  <Option value="">全部</Option>
                  {options}
                </Select>
              </div>
              <div className="down">
                <span>状态选择：</span>
                <Select
                  placeholder="状态选择"
                  style={{ marginRight: "20px", width: "135px" }}
                  onChange={handState}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="1">待处理</Option>
                  <Option value="2">已处理</Option>
                </Select>
              </div>
              <div className="down">
                <span>风险等级：</span>
                <Select
                  placeholder="全部"
                  style={{ width: "150px", marginRight: "20px" }}
                  onChange={onRisk}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="1">Ⅰ级</Option>
                  <Option value="2">Ⅱ级</Option>
                  <Option value="3">Ⅲ级</Option>
                </Select>
              </div>
              <div className="down">
                <span>报警类型：</span>
                <Select
                  placeholder="全部"
                  style={{ width: "150px", marginRight: "20px" }}
                  onChange={onCorrect}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="设备离线">设备离线</Option>
                  <Option value="湿度过高">湿度过高</Option>
                  <Option value="温度过高">温度过高</Option>
                  <Option value="温度过低">温度过低</Option>
                </Select>
              </div>
              <div className="down">
                <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              </div>
              <Button className="down" type="primary">查询</Button>

            </Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col span={24} className="gutter-row"  >
              <ZgExport></ZgExport>
              <Button
                className="down btn"
                ghost={true}
                style={{ marginLeft: "15px", float: "right" }}
                type="primary"
                onClick={showModal}
              >
                一键生成整改单
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                dataSource={correctionList}
                columns={columns}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {str}
    </>
  )
}
