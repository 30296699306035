import request from "../../utils/request"

/**
 * app/data-statistics/warning/index/today-analysis-data-count
 * 平台总览---今日预警数据统计 v1.1.6
 * @param data
 */
export function getTodayCount(data: any) {
  return request({
    url: "/app/data-statistics/warning/index/today-analysis-data-count",
    method: "get",
    params: data,
  })
}

/**
 * app/canteen/statistics
 * 热度统计数据
 * @param data
 */
export function getStatistics(data: any) {
  return request({
    url: "/app/canteen/statistics",
    method: "get",
    params: data,
  })
}

/**
 * app/canteen/maplist?state=1
 * 热度统计地图数据
 * @param data
 */
export function getMapList(data: any) {
  return request({
    url: "/app/canteen/maplist?state=1",
    method: "get",
    params: data,
  })
}

/**
 * app/statistics/correct-bytype-ex
 * 高频事件分析扩展(含总数统计),平台总览---视频预警分析 v1.1.6
 * @param data
 */
export function getInsight(data: any) {
  return request({
    url: "/app/statistics/correct-bytype-ex",
    method: "get",
    params: data,
  })
}

/**
 * app/index-day/index-between
 * 大数据分析 餐饮安全指数排行榜 安全指数分析 TOP5 按天区间时间段 v1.6.6
 * @param data
 */
export function getSafety(data: any) {
  return request({
    url: "/app/index-day/index-between",
    method: "get",
    params: data,
  })
}

/**
 * app/video/correct/index
 * 纠偏列表
 * @param data
 */
export function getInspection(data: any) {
  return request({
    url: "/app/video/correct/index",
    method: "get",
    params: data,
  })
}

/**
 * app​/data-statistics​/canteen​/index​/work-analysis-index
 * 厨房工作完成指标分析(不传时间,默认昨天) v1.1.6
 * @param data
 */
export function getWork(data: any) {
  return request({
    url: "/app/data-statistics/canteen/index/work-analysis-index",
    method: "get",
    params: data,
  })
}
