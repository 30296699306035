import React, { Component } from 'react'
export interface rowProps { // 接收属性
  align: string;
  justify: string;
  children: any;
  className: string;
  style: any
}
class zgColumn extends Component<rowProps, {}> {
  static defaultProps = {
    align: 'center',
    justify: 'center',
    children: '',
    className: '',
    style: ''
  }
  public constructor(props: rowProps) {
    super(props)
  }
  public render() {
    return (
      <>
        <div className={this.props.className} style={{ display: 'flex', ...this.props.style, flexDirection: 'column', alignItems: this.props.align, justifyContent: this.props.justify }}>
          {this.props.children}
        </div>
      </>
    )
  }
};

export default zgColumn;