import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Select } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getCerttList } from '../../../apis/canteen-info/warn'
import { useHistory } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function CorrectList() {
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [state, setSelState] = useState(2)//状态选择
  let history = useHistory()
  let numberTemp = history.location.search.split('=')[1]
  const [number] = useState(numberTemp)//食堂number
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数

  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getCerttList({ page: currentPage, sdate: timeList[0], edate: timeList[1], canteen_number: number, state: state }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList, number, state])

  function change_state(data: any) {
    setSelState(data)
  }
  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '预警时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '增加类型',
      dataIndex: 'warn_type',
      key: 'warn_type',
      // warn_type
      render: (warn_type: any) => (
        warn_type === 2 ? '食品安全责任险' : '食品安全许可证'
      )
    },
    // {
    //   title: '姓名',
    //   dataIndex: 'name',
    //   key: 'name',
    // },
    // {
    //   title: '岗位',
    //   dataIndex: 'amount',
    //   key: 'amount',
    // },
    {
      title: '过期时间',
      dataIndex: 'warn_type',
      key: 'warn_type',
      render: (warn_type: any, row: any) => (
        // insurance_date  保险
        // license_date 执照
        warn_type === 2 ? <span>{row.insurance_date}</span> : <span>{row.license_date}</span>
      )
    }, {
      title: '过期天数',
      dataIndex: 'warn_type',
      render: (warn_type: any, row: any) => {
        let handle_date = moment().format("YYYY-MM-DD");
        // insurance_date  保险
        // license_date 执照
        if (warn_type === 2) return <span>过期{moment(handle_date).diff(row.insurance_date, 'day')} 天</span>
        return (<><span>过期</span>{moment(handle_date).diff(row.license_date, 'day')}<span>天</span></>)
      }
    },
    // {
    //   title: '处理状态',
    //   dataIndex: 'handle_state',
    //   render: (text: any) => (
    //     <div style={{ color: (text === 0 ? "red" : "#999") }}>{text === 0 ? "待处理" : "已处理"}</div>
    //   ),
    // }, {
    //   title: '处理时间',
    //   dataIndex: 'handle_date',
    //   key: 'handle_date',
    // }, {
    //   title: '处理人',
    //   dataIndex: 'handle_user_name',
    //   key: 'handle_user_name',
    // }
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">餐饮证件预警</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>预警时间：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <span style={{ marginLeft: '16px' }}>处理状态：</span>
            <Select onChange={change_state} defaultValue="2" style={{ width: 120 }}>
              <Option value="2">全部</Option>
              <Option value="0">待处理</Option>
              <Option value="1">已处理</Option>
            </Select>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => columns.id}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >
            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}