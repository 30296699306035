import React, { useState, useImperativeHandle } from 'react'
import { Image, Modal } from 'antd'
import AirICon from '../assets/images/airICon.png'
import Bx from '../assets/images/bx.png'
import Ck from '../assets/images/ck.png'
import { DoubleRightOutlined } from '@ant-design/icons'
import IotFridgeDaily from './iot-fridge-daily'
import IotStorageDaily from './iot-storage-daily'
import IotAirDaily from './iot-air-daily'
import '../style/zg-iot.less'
import { isBlank } from '../utils/is_blank'
import { thingsInternetGroupIndex } from '../apis/iot/equipment-iot'

export default function ZgIOT(props: any) {
  const [isFridgeVisible, setIsFridgeVisible] = useState(false)
  const [isStorageVisible, setIsStorageVisible] = useState(false)
  const [isAirVisible, setIsAirVisible] = useState(false)
  const [lineState, setLineState] = useState('-1')
  const [tbId, setTbId] = useState(0)
  const [fridgeInfo, setFridgeInfo] = useState({})
  const [deviceData1, setDeviceData1] = useState([])
  const [deviceData2, setDeviceData2] = useState([])
  const [deviceData3, setDeviceData3] = useState([])

  // const [allDeviceData, setAllDeviceData] = useState([])
  let deviceDataA: any = []
  let deviceDataB: any = []
  let deviceDataC: any = []

  // let allDeviceDataALL: any = []
  function onLineClick(val: string) {
    setLineState(val)
  }

  function tbClick(val: number) {
    setTbId(val)
  }

  useImperativeHandle(props.cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeVal(val: any) {
      setLineState('-1')
      setTbId(0)
      thingsInternetGroupIndex({}).then(res => {
        if (res.status === 200) {
          res.data.forEach((item: any) => {
            if (item.state === 0) {
              item.online_state = 2
            }
            if (item.map_type === '1') {
              if (!isBlank(item.devicesgroup) && item.devicesgroup.length > 0) {
                if (item.type_child_number !== 'GT0006') {
                  item.errorList = []
                  item.devicesgroup.forEach((em: any) => {
                    if (!isBlank(em.cacheInfo.temperature)) {
                      if (em.cacheInfo.temperature > em.value_range_max) {
                        em.wdState = 0
                        em.warning = '温度过高'
                        item.errorList.push({
                          warning: '温度过高',
                          wdState: 0,
                          position: em.position
                        })
                      } else if (em.cacheInfo.temperature < em.value_range_small) {
                        em.wdState = 0
                        em.warning = '温度过低'
                        item.errorList.push({
                          warning: '温度过低',
                          wdState: 0,
                          position: em.position
                        })
                      }
                    }
                  })
                }
              }
              deviceDataA.push(item)
            } else if (item.map_type === '2') {
              if (!isBlank(item.devicesgroup) && item.devicesgroup.length > 0) {
                item.errorList = []
                item.devicesgroup.forEach((em: any) => {
                  if (!isBlank(em.cacheInfo.temperature)) {
                    if (em.cacheInfo.temperature > em.value_range_max) {
                      em.wdState = 0
                      em.wdWarning = '温度过高'
                      item.errorList.push({
                        wdWarning: '温度过高',
                        wdState: 0
                      })
                    } else if (em.cacheInfo.temperature < em.value_range_small) {
                      em.wdState = 0
                      em.wdWarning = '温度过低'
                      item.errorList.push({
                        wdWarning: '温度过低',
                        wdState: 0
                      })
                    }
                  }
                  if (!isBlank(em.cacheInfo.humidity)) {
                    if (em.cacheInfo.humidity > em.value_humidity_max) {
                      em.sdState = 0
                      em.sdWarning = '湿度过高'
                      item.errorList.push({
                        sdWarning: '湿度过高',
                        sdState: 0
                      })
                    } else if (em.cacheInfo.humidity < em.value_humidity_small) {
                      em.sdState = 0
                      em.sdWarning = '湿度过低'
                      item.errorList.push({
                        sdWarning: '湿度过低',
                        sdState: 0
                      })
                    }
                  }
                })
              }
              deviceDataB.push(item)
            } else if (item.map_type === '3') {
              deviceDataC.push(item)
            }
            // allDeviceDataALL.push(item)
          })
          setDeviceData1(deviceDataA)
          setDeviceData2(deviceDataB)
          setDeviceData3(deviceDataC)
          // setAllDeviceData(allDeviceDataALL)
        }
      })
    }
  }))

  const showFridgeModal = (val: any) => {
    setFridgeInfo(val)
    setIsFridgeVisible(true)
  }

  const handleFridgeCancel = () => {
    setIsFridgeVisible(false)
  }

  const showStorageModal = (val: any) => {
    setFridgeInfo(val)
    setIsStorageVisible(true)
  }

  const handleStorageCancel = () => {
    setIsStorageVisible(false)
  }

  const showAirModal = (val: any) => {
    setFridgeInfo(val)
    setIsAirVisible(true)
  }

  const handleAirCancel = () => {
    setIsAirVisible(false)
  }

  let emptyShow = null
  if (deviceData1.length === 0 && deviceData2.length === 0 && deviceData3.length === 0) {
    emptyShow = <div className="emptyShow">暂无数据</div>
  }

  return (
    <>
      <div className="tit-row">
        <div className="first-tit">在线状态</div>
        <div className={lineState === '-1' ? 'ck-active' : 'default-active'} onClick={() => onLineClick('-1')}>
          全部
        </div>
        <div className={lineState === '1' ? 'ck-active' : 'default-active'} onClick={() => onLineClick('1')}>
          在线
        </div>
        <div className={lineState === '0' ? 'ck-active' : 'default-active'} onClick={() => onLineClick('0')}>
          离线
        </div>
        <div className={lineState === '2' ? 'ck-active' : 'default-active'} onClick={() => onLineClick('2')}>
          停用
        </div>
      </div>
      <div className="tit-row">
        <div className="first-tit">物联类型</div>
        <div className={tbId === 0 ? 'ck-active' : 'default-active'} onClick={() => tbClick(0)}>
          全部
        </div>
        <div className={tbId === 1 ? 'ck-active' : 'default-active'} onClick={() => tbClick(1)}>
          设备监测类
        </div>
        <div className={tbId === 2 ? 'ck-active' : 'default-active'} onClick={() => tbClick(2)}>
          环境监测类
        </div>
        <div className={tbId === 3 ? 'ck-active' : 'default-active'} onClick={() => tbClick(3)}>
          空气质量监测类
        </div>
        <div className={tbId === 4 ? 'ck-active' : 'default-active'} onClick={() => tbClick(4)}>
          其他
        </div>
      </div>
      <div className="wrap-cont">
        {/* 冰箱 */}
        {deviceData1.map((item: any, index) => {
          if ((tbId === 0 || tbId === 1) && (lineState === '-1' || lineState === item.online_state)) {
            return (
              <div className="iot-cont" onClick={() => showFridgeModal(item)} key={index}>
                <div
                  className={[
                    'iot-state',
                    item.state === '0' ? 'grey-bg' : item.online_state === '1' ? 'green-bg' : 'grey-bg',
                    item.state === '0' ? 'black-color' : item.online_state === '1' ? 'white-color' : 'black-color'
                  ].join(' ')}
                >
                  {item.state === '0' ? '停用' : item.online_state === '1' ? '在线' : '离线'}
                </div>
                <div className="iot-name">
                  <div className="iot-img">
                    <Image width={44} height={42} preview={false} src={Bx} />
                  </div>
                  <div className="name-adress">
                    <div className="ft-name">{item.name}</div>
                    <div className="ft-adr">{item.room}</div>
                    <div className="adr-row">
                      {!!item.errorList &&
                      item.errorList.length > 0 &&
                      item.online_state === '1' &&
                      item.type_child_number !== 'GT0006' ? (
                        item.errorList.map((em: any, idx: any) => {
                          return (
                            <div className="adr-item" key={idx}>
                              {!!em.position && item.state === '1' && em.warning ? (
                                <div className="adr-tag">{em.position}</div>
                              ) : (
                                <div className="exp-info"></div>
                              )}
                              {em.warning && item.state === '1' ? (
                                <div className="exp-info">{em.warning}</div>
                              ) : (
                                <div className="exp-info"></div>
                              )}
                            </div>
                          )
                        })
                      ) : (
                        <div className="exp-info"></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="iotdown">
                  {!!item.devicesgroup && item.devicesgroup.length > 0 && item.online_state === '1' ? (
                    item.type_child_number !== 'GT0006' ? (
                      item.devicesgroup.map((itm: any) => {
                        return (
                          <div className="down-row" key={itm.number}>
                            {!!itm.position && item.state === '1' ? <div className="adr-tag">{itm.position}</div> : ''}
                            <div className="nm">温度</div>
                            <div className={['nm-num', itm.wdState === 0 ? 'red-color' : 'green-color'].join(' ')}>
                              {item.state === '0' ? '---' : itm.cacheInfo.temperature}
                              °C
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className="down-row">
                        <div className="sdg-tag">责任人:</div>
                        <div className="nm">{item.person_name}</div>
                      </div>
                    )
                  ) : (
                    <div className="down-row">
                      <div className="nm">{item.type_child_number !== 'GT0006' ? '温度' : '责任人'}</div>
                      <div className={['nm-num', 'grey-color'].join(' ')}>
                        {item.type_child_number !== 'GT0006' ? '---°C' : '---'}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          } else {
            return <></>
          }
        })}

        {/* 仓库 */}
        {deviceData2.map((item: any, index) => {
          console.log('deviceData2 item ==>', item)

          if ((tbId === 0 || tbId === 2) && (lineState === '-1' || lineState === item.online_state)) {
            return (
              <div className="iot-cont" onClick={() => showStorageModal(item)} key={index}>
                <div
                  className={[
                    'iot-state',
                    item.state === '0' ? 'grey-bg' : item.online_state === '1' ? 'green-bg' : 'grey-bg',
                    item.state === '0' ? 'black-color' : item.online_state === '1' ? 'white-color' : 'black-color'
                  ].join(' ')}
                >
                  {item.state === '0' ? '停用' : item.online_state === '1' ? '在线' : '离线'}
                </div>
                <div className="iot-name">
                  <div className="iot-img">
                    <Image width={44} height={42} preview={false} src={Ck} />
                  </div>
                  <div className="name-adress">
                    <div className="ft-name">{item.name}</div>
                    <div className="ft-adr">{item.room}</div>
                    <div className="adr-row">
                      {!!item.errorList && item.errorList.length > 0 && item.online_state === '1'
                        ? item.errorList.map((em: any, idx: any) => {
                            console.log('环境类检测设备错误信息', em)
                            return (
                              <div className="adr-item" key={idx}>
                                {em.wdWarning ? <div className="exp-info">{em.wdWarning}</div> : ''}
                                {em.sdWarning ? <div className="exp-info">{em.sdWarning}</div> : ''}
                              </div>
                            )
                          })
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="iotdown">
                  <div className="down-row">
                    <div className="nm">温度</div>
                    <div
                      className={[
                        'nm-num',
                        item.online_state !== '1'
                          ? 'grey-color'
                          : item.devicesgroup[0].wdState === 0
                          ? 'red-color'
                          : 'green-color'
                      ].join(' ')}
                    >
                      {item.state === '0' || item.online_state !== '1'
                        ? '---'
                        : !!item.devicesgroup && item.devicesgroup.length > 0
                        ? item.devicesgroup[0].cacheInfo.temperature
                        : '---'}
                      °C
                    </div>
                  </div>
                  <div className="down-row">
                    <div className="nm">湿度</div>
                    <div
                      className={[
                        'nm-num',
                        item.online_state !== '1'
                          ? 'grey-color'
                          : item.devicesgroup[0].sdState === 0
                          ? 'red-color'
                          : 'green-color'
                      ].join(' ')}
                    >
                      {item.state === '0' || item.online_state !== '1'
                        ? '---'
                        : !!item.devicesgroup && item.devicesgroup.length > 0
                        ? item.devicesgroup[0].cacheInfo.humidity
                        : '---'}
                      %
                    </div>
                  </div>
                </div>
              </div>
            )
          } else {
            return <></>
          }
        })}
        {/* 空气质量检测仪 */}
        {deviceData3.map((item: any, index) => {
          if ((tbId === 0 || tbId === 3) && (lineState === '-1' || lineState === item.online_state)) {
            return (
              <div className="iot-cont" onClick={() => showAirModal(item)} key={index}>
                <div
                  className={[
                    'iot-state',
                    item.state === '0' ? 'grey-bg' : item.online_state === '1' ? 'green-bg' : 'grey-bg',
                    item.state === '0' ? 'black-color' : item.online_state === '1' ? 'white-color' : 'black-color'
                  ].join(' ')}
                >
                  {item.state === '0' ? '停用' : item.online_state === '1' ? '在线' : '离线'}
                </div>
                <div className="iot-name">
                  <div className="iot-img">
                    <Image width={44} height={42} preview={false} src={AirICon} />
                  </div>
                  <div className="name-adress">
                    <div className="ft-name">{item.name}</div>
                    <div className="ft-adr">{item.room}</div>
                    <div className="adr-row"></div>
                  </div>
                </div>
                <div className="iotdown">
                  <div className="down-row">
                    <div className="nm">TVOC</div>
                    {item.devicesgroup[0].cacheGasInfo ? (
                      <div
                        className={[
                          'nm-pip',
                          item.state === '0' || item.online_state !== '1'
                            ? 'grey-color'
                            : item.devicesgroup[0].cacheGasInfo.tvocArr.lable === '标准'
                            ? 'green-color'
                            : item.devicesgroup[0].cacheGasInfo.tvocArr.lable === '轻度污染'
                            ? 'orange-color'
                            : 'red-color'
                        ].join(' ')}
                      >
                        {item.state === '0' || item.online_state !== '1'
                          ? '---'
                          : !!item.devicesgroup
                          ? item.devicesgroup[0].cacheGasInfo.tvocArr.lable
                          : '---'}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="down-row">
                    <div className="nm">PM2.5</div>
                    {item.devicesgroup[0].cacheGasInfo ? (
                      <div
                        className={[
                          'nm-pip',
                          item.state === '0' || item.online_state !== '1'
                            ? 'grey-color'
                            : item.devicesgroup[0].cacheGasInfo.pm25Arr.lable === '优'
                            ? 'green-color'
                            : item.devicesgroup[0].cacheGasInfo.pm25Arr.lable === '良'
                            ? 'blue-color'
                            : item.devicesgroup[0].cacheGasInfo.pm25Arr.lable === '轻度'
                            ? 'yellow-color'
                            : item.devicesgroup[0].cacheGasInfo.pm25Arr.lable === '中度'
                            ? 'orange-color'
                            : item.devicesgroup[0].cacheGasInfo.pm25Arr.lable === '重度'
                            ? 'lightRed-color'
                            : 'red-color'
                        ].join(' ')}
                      >
                        {item.state === '0' || item.online_state !== '1'
                          ? '---'
                          : !!item.devicesgroup
                          ? item.devicesgroup[0].cacheGasInfo.pm25Arr.lable
                          : '---'}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="air-more">
                      <span className="air-ft">更多</span>
                      <DoubleRightOutlined />
                    </div>
                  </div>
                </div>
              </div>
            )
          } else {
            return <></>
          }
        })}
      </div>
      {emptyShow}

      <Modal footer={null} visible={isFridgeVisible} width={900} onCancel={handleFridgeCancel}>
        <IotFridgeDaily fridgeInfo={fridgeInfo}></IotFridgeDaily>
      </Modal>
      <Modal footer={null} visible={isStorageVisible} width={900} onCancel={handleStorageCancel}>
        <IotStorageDaily fridgeInfo={fridgeInfo}></IotStorageDaily>
      </Modal>
      <Modal footer={null} visible={isAirVisible} width={900} onCancel={handleAirCancel}>
        <IotAirDaily fridgeInfo={fridgeInfo}></IotAirDaily>
      </Modal>
    </>
  )
}
