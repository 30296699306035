import React, { useState, useEffect, useCallback } from "react"
import ZgCard from "../../../../components/zg-card"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import { ArrayLngLat, Map, Markers } from "react-amap"

import { getStatistics, getMapList } from "../../../../apis/analysis/index"
import { getSuppliersStatistics } from "../../../../apis/analysis/suppliers"
import { afeterDate, getCurDate } from "../../../../utils/formatDate"

const randomPosition = () => ({
  longitude: 100 + Math.random() * 20,
  latitude: 30 + Math.random() * 20,
})

const randomMarker = (len: number) =>
  Array(len)
    .fill(true)
    .map((e, idx) => ({
      position: randomPosition(),
    }))

// 大数据分析首页--全市餐饮热度统计
export default function DataHeat() {
  // const [markers] = useState(randomMarker(10))
  const [markers, setMarkers] = useState(randomMarker(10))

  const [dataList, setDataList] = useState({
    total: 0,
    workers: 0,
    devices: 0,
    correct: 0,
  })

  const [suppliers, setSuppliers] = useState({
    customers: 0,//签约供应商
    audited: 0, //已审核的供应商
    wait: 0,//待审核供应商
    reg: 0 //注册供应商
  });

  // 地图中心点
  const [mapCenter, setMapCenter] = useState<ArrayLngLat>([112.144146, 32.042426]) // 默认中心点

  let to_get_suppliers = useCallback(() => {
    let data = {}
    return getSuppliersStatistics(data).then((res) => {
      // customers //签约供应商数量 
      setSuppliers(res.data)
    })
  }, [])

  let to_get_map = useCallback(() => {
    let data = {}
    return getMapList(data).then((res) => {
      let arr: any = []
      res.data.forEach((item: any, key: any) => {
        // // 跳出循环
        // if (key > 10) {
        //   return
        // }
        arr.push({
          position: {
            longitude: Number(item.map_x),
            latitude: Number(item.map_y),
          },
          title: item.canteen_name,
          label: {
            // offset: new AMap.Pixel(20, 20), //设置文本标注偏移量
            content: "<div class='info'>" + item.canteen_name + "</div>", //设置文本标注内容
            direction: "right", //设置文本标注方位
          },
        })
      })
      setMarkers(arr)

      // 设置地图中心点
      console.log("markers ===>", arr)
      setMapCenter([arr[0]['position']['longitude'], arr[0]['position']['latitude']])
    })
  }, [])

  let to_get_info = useCallback(() => {
    let sdate = afeterDate(-30)
    let edate = getCurDate()
    let data = { sdate: sdate, edate: edate, page_size: 5 }
    return getStatistics(data).then((res) => {
      // "total": "食堂总数",
      // "workers": "从业人员",
      // "devices": "在线设备",
      // "correct": "纠偏报警"
      setDataList(res.data)
    })
  }, [])


  // let to_get_supp = useCallback(() => {
  //   let sdate = afeterDate(-30)
  //   let edate = getCurDate()
  //   let data = { sdate: sdate, edate: edate, page_size: 5 }
  //   return get_Statistics(data).then((res) => {
  //     // "total": "食堂总数",
  //     // "workers": "从业人员",
  //     // "devices": "在线设备",
  //     // "correct": "纠偏报警"
  //     console.log(res.data)
  //     // setDataList(res.data)
  //   })
  // }, [])


  // marker.setLabel({
  //   offset: new AMap.Pixel(20, 20), //设置文本标注偏移量
  //   content: "<div class='info'>我是 marker 的 label 标签</div>", //设置文本标注内容
  //   direction: "right", //设置文本标注方位
  // })

  useEffect(() => {
    to_get_info()
    to_get_map()
    to_get_suppliers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to_get_info, to_get_map])

  return (
    <>
      <div className="stac-info-wrap1">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="餐饮单位分布"></ZgTitle>
            {/* <IconFont type="iconicon-test3" className="icon" /> */}
          </ZgRow>
          <div className="heat-wrap">
            <div
              className="map"
              style={{
                width: "100%",
                height: 320,
                // paddingLeft: 50,
                // paddingRight: 50,
              }}
            >
              <Map
                amapkey='b970bbf41025f93ec47892d34a6d8ab0'
                plugins={["ToolBar"]}
                center={mapCenter}
                zoom={8}
                mapStyle="amap://styles/normal"
              >
                <Markers markers={markers} />
              </Map>
            </div>
            <ZgRow justify="space-between" className="row-data">
              <div className="data">
                <div className="sum">{dataList.total}</div>
                <div className="allsum">餐饮总数</div>
              </div>

              <div className="data">
                <div className="sum">{dataList.workers}</div>
                <div className="allsum">从业人员</div>
              </div>

              <div className="data">
                <div className="sum">{dataList.devices}</div>
                <div className="allsum">在线设备</div>
              </div>

              <div className="data">
                <div className="sum">{suppliers.customers}</div>
                <div className="allsum">供应商</div>
              </div>
            </ZgRow>
          </div>
        </ZgCard>
      </div>
    </>
  )
}
