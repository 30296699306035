import request from "utils/request"
import proxy from "../../utils/proxy"
import supplierRequest from "../../utils/supplierRequest"
/**
 * 获取食堂统计信息
 */
export function getStacRecord(data: any) {
  //巡检记录
  return proxy({
    url: "/api/data-index/day-info",
    method: "get",
    params: data,
  })
}
export function getStacSuppliers(data: any) {
  //食堂端供应商
  return proxy({
    url: "/admin/invoicing/supplier/list",
    method: "get",
    params: data,
  })
}
export function getStacSuppliers1(data: any) {
  //食堂端供应商
  return proxy({
    url: "/api/supplier/offline/business-list",
    method: "get",
    params: data,
  })
}
export function getAllSuppliers(data: any) {
  //总监管端供应商
  return supplierRequest({
    url: "/api/suppliers/lists",
    method: "get",
    params: data,
  })
}

// 签约供应商
// 调用总平台接口
export function getSignSupplierList(data: any) {
  return request({
    url: "/app/suppliers/list",
    method: "get",
    params: data,
  })
}

export function getStacSupplierInfo(data: any) {
  //供应商详情
  return supplierRequest({
    url: "/api/suppliers/info",
    method: "get",
    params: data,
  })
}

export function getStacSupplierInfo1(data: any) {
  //供应商详情
  return proxy({
    url: "/api/supplier/offline/business-info",
    method: "get",
    params: data,
  })
}

export function getStacMember(data: any) {
  //五员制
  return proxy({
    // url: "/admin/user/five_member", // 旧
    url: "/admin/five-member/list", // 新五员
    method: "get",
    params: data,
  })
}
export function getStacWorker(data: any) {
  //从业人员
  return proxy({
    url: "/admin/user/list",
    method: "get",
    params: data,
  })
}
export function getStacJob(data: any) {
  //职务list
  return proxy({
    url: "/admin/job/all",
    method: "get",
    params: data,
  })
}
export function getWorkerInfo(data: any) {
  //从业人员详情
  return proxy({
    url: "/admin/user/info",
    method: "get",
    params: data,
  })
}
export function getDayMenu(data: any) {
  //每日菜谱
  return proxy({
    url: "/admin/food-menus/list",
    method: "get",
    params: data,
  })
}
export function getMenuDetail(data: any) {
  //获取菜谱详情
  return proxy({
    url: "/admin/dishes/info",
    method: "get",
    params: data,
  })
}
export function getOutRecord(data: any) {
  //获取出库台账
  return proxy({
    url: "/admin/invoicing/outbound-order/month_record",
    method: "get",
    params: data,
  })
}
export function getGoodsInfo(data: any) {
  //获取原料详情
  return proxy({
    url: "/admin/invoicing/goods/info",
    method: "get",
    params: data,
  })
}
export function geSourceRecord(data: any) {
  //获取原料溯源
  return proxy({
    url: "/admin/invoicing/goods/source",
    method: "get",
    params: data,
  })
}
export function getInRecord(data: any) {
  //获取入库台账
  return proxy({
    url: "/admin/invoicing/supplier-order/month_record",
    method: "get",
    params: data,
  })
}

/**
 * 食堂安全指数评估
 * @param data
 * @returns 文字描述
 */
export function getSafeIndexDayInfo(data: any) {
  return request({
    url: "/app/safe-index-day/infos",
    method: "get",
    params: data,
  })
}
