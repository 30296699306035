/* eslint-disable array-callback-return */
import React, { useState, useCallback, useEffect } from "react"
import { Breadcrumb, Row, Col, Button, Image, Radio, Table, Modal } from "antd"
import { useHistory } from "react-router-dom"
import "../../../style/inspection.less"
import {
  taskDetail1,
  warningList,
} from "../../../apis/inspection-check/inspection"
export default function InspectionDetail1(props: any) {
  let history = useHistory()
  const [id] = useState(props.location.state[0])
  const [canteen_number] = useState(props.location.state[1])
  const [detail, setDetail] = useState({
    canteen_number: "",
    id: 0,
    state: 1,
    title: "",
    canteensmanage: { realname: "" },
    canteens: { canteen_name: "" },
    problem_describe: "",
    inspection_time: "",
    created_at: "",
    risk_type: "",
    reply: [
      {
        content: "",
        reply_user_name: "",
        reply_user_role: "",
        reply_images: [],
        updated_at: "",
      },
    ],
    imgs1: [{ image_url: "" }],
    imgs2: [{ image_url: "" }],
    risk_types: [{ risk_type: "", lcount: 0 }],
  })
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [rType, setRType] = useState() //
  const [itId, setItId] = useState(0) //
  const [dataList, setDataList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [riskTypes, setRiskTypes] = useState([
    {
      risk_type: "",
      lcount: 0,
    },
  ])

  const [columns, setColumns] = useState([
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "预警时间",
      dataIndex: "warning_time",
    },
    {
      title: "区域",
      dataIndex: "area",
    },
    {
      title: "类型",
      dataIndex: "correct_type",
    },
    {
      title: "纠偏图像",
      dataIndex: "pic",
      render: (pic: any, record: any) =>
        pic !== "" && pic !== undefined && pic !== null ? (
          <Image key={pic} className="detail-img" src={record.http_url + pic} />
        ) : (
          ""
        ),
    },
    {
      title: "风险等级",
      dataIndex: "level",
      render: (level: any) =>
        level === 1 ? (
          <div
            style={{
              background: "red",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅰ级</span>
          </div>
        ) : level === 2 ? (
          <div
            style={{
              background: "#ff9900",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅱ级</span>
          </div>
        ) : level === 3 ? (
          <div
            style={{
              background: "#3399ff",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅲ级</span>
          </div>
        ) : (
          ""
        ),
    },
  ])
  function changePage(val: number) {
    setCurrentPage(val)
  }
  function to_url() {
    history.push({
      pathname: "/supervision/list",
    })
  }
  function setTableColumn(val: any) {
    let columns1: any = []
    if (val === "原料预警") {
      columns1 = [
        {
          title: "序号",
          dataIndex: "id",
          //将数据排数
          render: (id: any, record: any, index: number) => `${index + 1}`,
        },
        {
          title: "预警时间",
          dataIndex: "warning_time",
        },
        {
          title: "原料名称",
          dataIndex: "area",
        },
        {
          title: "过期时间",
          dataIndex: "correct_type",
        },
        {
          title: "风险等级",
          dataIndex: "level",
          render: (level: any) =>
            level === 1 ? (
              <div
                style={{
                  background: "red",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅰ级</span>
              </div>
            ) : level === 2 ? (
              <div
                style={{
                  background: "#ff9900",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅱ级</span>
              </div>
            ) : level === 3 ? (
              <div
                style={{
                  background: "#3399ff",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅲ级</span>
              </div>
            ) : (
              ""
            ),
        },
      ]
    } else if (val === "健康证预警" || val === "餐饮证件预警") {
      columns1 = [
        {
          title: "序号",
          dataIndex: "id",
          //将数据排数
          render: (id: any, record: any, index: number) => `${index + 1}`,
        },
        {
          title: "预警时间",
          dataIndex: "warning_time",
        },
        {
          title: "证件类型",
          dataIndex: "area",
        },
        {
          title: "过期时间",
          dataIndex: "correct_type",
        },
        {
          title: "风险等级",
          dataIndex: "level",
          render: (level: any) =>
            level === 1 ? (
              <div
                style={{
                  background: "red",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅰ级</span>
              </div>
            ) : level === 2 ? (
              <div
                style={{
                  background: "#ff9900",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅱ级</span>
              </div>
            ) : level === 3 ? (
              <div
                style={{
                  background: "#3399ff",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅲ级</span>
              </div>
            ) : (
              ""
            ),
        },
      ]
    } else if (val === "物联预警") {
      columns1 = [
        {
          title: "序号",
          dataIndex: "id",
          //将数据排数
          render: (id: any, record: any, index: number) => `${index + 1}`,
        },
        {
          title: "预警时间",
          dataIndex: "warning_time",
        },
        {
          title: "设备信息",
          dataIndex: "area",
        },
        {
          title: "报警提醒",
          dataIndex: "correct_type",
        },
        {
          title: "风险等级",
          dataIndex: "level",
          render: (level: any) =>
            level === 1 ? (
              <div
                style={{
                  background: "red",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅰ级</span>
              </div>
            ) : level === 2 ? (
              <div
                style={{
                  background: "#ff9900",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅱ级</span>
              </div>
            ) : level === 3 ? (
              <div
                style={{
                  background: "#3399ff",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅲ级</span>
              </div>
            ) : (
              ""
            ),
        },
      ]
    } else {
      columns1 = [
        {
          title: "序号",
          dataIndex: "id",
          //将数据排数
          render: (id: any, record: any, index: number) => `${index + 1}`,
        },
        {
          title: "预警时间",
          dataIndex: "warning_time",
        },
        {
          title: "区域",
          dataIndex: "area",
        },
        {
          title: "类型",
          dataIndex: "correct_type",
        },
        {
          title: "纠偏图像",
          dataIndex: "pic",
          render: (pic: any, record: any) =>
            pic !== "" && pic !== undefined && pic !== null ? (
              <Image
                key={pic}
                className="detail-img"
                src={record.http_url + pic}
              />
            ) : (
              ""
            ),
        },
        {
          title: "风险等级",
          dataIndex: "level",
          render: (level: any) =>
            level === 1 ? (
              <div
                style={{
                  background: "red",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅰ级</span>
              </div>
            ) : level === 2 ? (
              <div
                style={{
                  background: "#ff9900",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅱ级</span>
              </div>
            ) : level === 3 ? (
              <div
                style={{
                  background: "#3399ff",
                  width: "33px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "white" }}>Ⅲ级</span>
              </div>
            ) : (
              ""
            ),
        },
      ]
    }
    setColumns(columns1)
  }
  function onChange(val: any) {
    setRType(val.target.value)
    setItId(detail.id)
    setTableColumn(val.target.value)
  }
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const getWarning = useCallback(() => {
    warningList({
      inspection_task_id: itId <= 0 ? id : itId,
      canteen_number: canteen_number,
      risk_type: rType,
      page: currentPage,
    }).then((res) => {
      setDataList(res.data.data)
      setTotalCount(res.data.total)
      setPerPage(res.data.per_page)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itId, canteen_number, rType])

  const get_detail = useCallback(() => {
    return taskDetail1({ id: id }).then((res) => {
      setDetail(res.data)
      let risks: any = [
        {
          name: "视频预警",
          count: 0,
        },
        {
          name: "原料预警",
          count: 0,
        },
        {
          name: "健康证预警",
          count: 0,
        },
        {
          name: "餐饮证件预警",
          count: 0,
        },
        {
          name: "物联预警",
          count: 0,
        },
      ]
      res.data.risk_types.forEach((val: any, index: any) => {
        risks.forEach((el: any) => {
          if (val.risk_type === el.name) {
            el.count = val.lcount
          }
        })
      })
      let rtT: any = ""
      risks.forEach((v: any) => {
        if (v.count > 0) {
          if (rtT === "" || rtT === null || rtT === undefined) rtT = v.name
        }
      })
      setRiskTypes(risks)
      setRType(rtT)
      setItId(res.data.id)
      setTableColumn(rtT)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    get_detail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getWarning()
  }, [getWarning])
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管巡查</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/inspection/list">风险监管</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row
            style={{
              fontSize: "20px",
              paddingBottom: "24px",
              borderBottom: "1px solid #E8EBF9",
            }}
          >
            <Col span={18}>风险监管详情</Col>
            <Col style={{ textAlign: "right" }} span={6}>
              <Button
                type="primary"
                onClick={showModal}
                style={{
                  borderRadius: 0,
                  marginLeft: "15px",
                  float: "right",
                  display: detail.state === 1 ? "none" : "block",
                }}
              >
                查看回复
              </Button>
              <Button
                type="default"
                onClick={to_url}
                style={{
                  border: "1px solid #409EFF",
                  borderRadius: 0,
                  color: "#409EFF",
                  float: "right",
                }}
              >
                返回
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            {detail.state === 1 ? (
              <Col
                style={{
                  border: "1px solid #F5DAB1",
                  backgroundColor: "#FDF6EC",
                  color: "#E6A23C",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：
                <span className="">待回复</span>
              </Col>
            ) : (
              <Col
                style={{
                  border: "1px solid #C2E7B0",
                  backgroundColor: "#F0F9EB",
                  color: "#67C23A",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：<span className="green-color">已回复</span>
              </Col>
            )}
          </Row>
          <div className="title first-title">基本信息</div>

          <div className="info-line info-flex">
            <div className="info-item">
              巡检单位：{detail.canteens.canteen_name}
            </div>
            <div className="info-item">巡检主题：{detail.title}</div>
          </div>
          <div className="info-line info-flex">
            <div className="info-item">
              巡检人员：{detail.canteensmanage.realname}
            </div>
            <div className="info-item">巡检时间：{detail.created_at}</div>
          </div>
          <div className="title">预警数据</div>
          <div className="info-line info-flex">
            <div className="info-item" style={{ width: "100vw" }}>
              任务说明：
              <span
                dangerouslySetInnerHTML={{ __html: detail.problem_describe }}
              />
            </div>
          </div>
          <div className="info-line info-flex">
            <div className="info-item" style={{ width: "100vw" }}>
              <Radio.Group
                value={rType}
                onChange={onChange}
                style={{ marginBottom: 16 }}
              >
                {riskTypes.map((val: any, index: any) => {
                  if (val.count > 0) {
                    return (
                      <Radio.Button value={val.name} key={index}>
                        {val.name}
                        <span style={{ padding: "0px 10px" }}>{val.count}</span>
                      </Radio.Button>
                    )
                  }
                })}
              </Radio.Group>
            </div>
          </div>
          <div className="info-line info-flex">
            <div className="info-item" style={{ width: "100vw" }}>
              <Table
                style={{ width: "98%" }}
                columns={columns}
                scroll={{ y: 600 }}
                dataSource={dataList}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              ></Table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="回复说明"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <div className="info-line">
              <div className="info-item">
                <span>回复人员：</span>
                <span>
                  {detail.reply.length > 0
                    ? detail.reply[0].reply_user_name
                    : ""}
                </span>
              </div>
              <div className="info-item">
                <span>回复时间：</span>
                <span>
                  {detail.reply.length > 0 ? detail.reply[0].updated_at : ""}
                </span>
              </div>
              <div className="info-item">
                <span>回复说明：</span>
                <span>
                  {detail.reply.length > 0 ? detail.reply[0].content : ""}
                </span>
              </div>
              <Image.PreviewGroup>
                {detail.reply.length > 0
                  ? detail.reply[0].reply_images.map(
                      (item: any, index: any) => (
                        <Image key={index} className="detail-img" src={item} />
                      )
                    )
                  : ""}
              </Image.PreviewGroup>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
