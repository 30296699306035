import React from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import { Row, Col } from "antd";
import IconFont from "components/icon-font";

export default function CompletionCatering() {
  return (
    <>
      <div className="stac-info-wrap">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="餐饮工作完成情况"></ZgTitle>
          </ZgRow>
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Row gutter={[12, 12]} style={{marginLeft:'50px'}}>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconrizhi" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">管理日志</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconcanyin1" style={{ color: "#C6CFF6", fontSize: "16px" }}/>
                </div>
                <div className="longford">食品留样</div>
                <div style={{fontSize:'14px'}}>（<span style={{color:"#EA5752"}}>2</span>次）</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconxiaodu" style={{ color: "#C6CFF6", fontSize: "16px" }}/>
                </div>
                <div className="longford">餐具消毒</div>
                <div style={{fontSize:'14px'}}>（<span style={{color:"#EA5752"}}>2</span>次）</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconchenjian" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">人员晨检</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconanquan" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">安全自查</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconqingjie" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">食堂清洁</div>
              </Col>

              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconfeiqiwuchuzhi" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">废弃物处置</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconjianyanjianyi" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">检疫检测</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconhuanjing" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">场所消毒</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="icontianjiaji" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">添加剂使用</div>
              </Col>
              <Col span={4} className="flexist">
                <div className="ring">
                  <IconFont type="iconlingdao" style={{ color: "#5473E8", fontSize: "16px" }}/>
                </div>
                <div className="longford">食堂陪餐</div>
              </Col>
            </Row>
          </div>
        </ZgCard>
      </div>
    </>
  );
}
