import React from 'react'
import '../style/iot-fridge-daily.less'
import Ck from '../assets/images/ck.png'
import { Table, Image } from 'antd'

export default function IotStorage(props: any) {
  const columns = [
    {
      title: '设备名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '温度',
      dataIndex: 'cacheInfo',
      key: 'cacheInfo',
      render: (text: any, record: any) => (
        <>
          {
            <div>
              {
                props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ?
                  <div><span className={[!!record.warning ? 'red-color' : 'green-color'].join(' ')}>{record.cacheInfo.temperature}℃</span><span>（最低{record.value_range_small}℃，最高{record.value_range_max}℃）</span></div>
                  : <div>-----</div>
              }
              {
                props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ? <div className="red-color">{record.warning}</div> : ''
              }
            </div>
          }
        </>
      )
    },
    {
      title: '湿度',
      dataIndex: 'cacheInfo',
      key: 'cacheInfo',
      render: (text: any, record: any) => (
        <>
          {
            <div>
              {
                props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ?
                  <div><span className={[!!record.warning ? 'red-color' : 'green-color'].join(' ')}>{record.cacheInfo.humidity}%</span><span>（最低{record.value_humidity_small}%，最高{record.value_humidity_max}%）</span></div>
                  : <div>-----</div>
              }
              {
                props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ? <div className="red-color">{record.warning}</div> : ''
              }
            </div>
          }
        </>
      )
    }
  ]

  return (
    <>
      <div className="fridge-daily">
        <div className="fridge-tit">
          <div className="tit-img">
            <Image
              width={44}
              height={42}
              preview={false}
              src={Ck}
            />
          </div>
          <div className="tit-cont">
            <div><span className="tit-ft-top1">{props.fridgeInfo.name}</span><span className="tit-ft-top2">（{props.fridgeInfo.number}）</span></div>
            <div className="tit-ft-down">{props.fridgeInfo.room}</div>
          </div>
          <div className={props.fridgeInfo.state === '0' || props.fridgeInfo.online_state === '0' ? 'tit-lx-state' : 'tit-zx-state'}>{props.fridgeInfo.state === '0' ? '停用' : props.fridgeInfo.online_state === '1' ? '在线' : '离线'}</div>
        </div>
        <div className="fridge-table">
          <Table
            columns={columns}
            dataSource={props.fridgeInfo.devicesgroup}
            rowKey="id" pagination={false} />
        </div>
        <div className="foot-time">数据最后上传时间：{props.fridgeInfo['devicesgroup'][0].last_time}</div>
      </div>
    </ >
  )
}