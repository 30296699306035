import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Modal, Image } from 'antd'
import ZgRow from '../../../components/zg-row'
import Detail from './detail'
import moment from 'moment'
import '../../../style/button.less'
import { getOutRecord } from '../../../apis/canteen-info/proxy-info'
import { noimgBase64 } from 'utils/common'
const { RangePicker } = DatePicker

export default function StoreList() {
  const [showDetail, setShowDetail] = useState(false)
  const [startdate, setStartdate] = useState(moment(Date()).subtract(1, 'month'))
  const [enddate, setEnddate] = useState(moment(Date()))
  const [dataList, setList] = useState([]) //列表数据
  const [currentPage, setCurrentPage] = useState(1) //设置当前页数
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [checkId, setCheckId] = useState('') //查看详情id

  function time_change(date: any) {
    if (date) {
      setStartdate(date[0])
      setEnddate(date[1])
      setCurrentPage(1)
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    const sdate = moment(startdate).format('YYYY-MM-DD')
    const edate = moment(enddate).format('YYYY-MM-DD')
    return getOutRecord({ page: currentPage, page_size: 15, sdate: sdate, edate: edate }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, startdate, enddate])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let to_set_id = (data: any) => {
    setCheckId(data)
  }

  function checkDetail(data: any) {
    to_set_id(data)
    return setShowDetail(true)
  }

  function to_close() {
    return setShowDetail(false)
  }

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${index + 1 + (currentPage - 1) * perPage}`,
      width: '6%'
    },
    {
      title: '出库时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '11%'
    },
    {
      title: '商品名称',
      dataIndex: 'goods_name',
      key: 'goods_name',
      width: '10%'
    },
    {
      title: '数量',
      render: (record: any) => <div>{parseFloat(record.amount) + record.goods.unit_name}</div>,
      width: '6%'
    },
    // {
    //   title: '总价（元）',
    //   render: (record: any) => <div>{(parseFloat(record.amount) * record.goods_price).toFixed(2)}元</div>,
    //   width: '10%'
    // },
    {
      title: '生产日期',
      render: (record: any) => <div>{record.goods.production_date}</div>,
      width: '10%'
    },
    {
      title: '保质期',
      render: (record: any) => <div>{record.goods.goods_basic.shelf_life}</div>,
      width: '7%'
    },
    {
      title: '供应商',
      render: (record: any) => <div>{record.goods.supplier === '' ? '--' : record.goods.supplier.name}</div>,
      width: '10%'
    },
    {
      title: '出库人',
      render: (record: any) => <div>{record.order.user_name}</div>,
      width: '8%'
    },
    {
      title: '质检报告',
      render: (record: any) => {
        const picStr = record.goods.info.inspection_report_pic_appends as string
        let picArr = new Array<string>()
        if (picStr) {
          picArr = picStr.split(',')
        }
        if (picArr.length > 0) {
          return (
            <div>
              {picArr.map((v, i) => {
                return (
                  <Image
                    style={{ width: '50px', height: '50px ', marginRight: '1px' }}
                    src={v}
                    fallback={noimgBase64}
                  />
                )
              })}
            </div>
          )
        } else {
          return (
            <div>
              <Image style={{ width: '50px', height: '50px ' }} src={noimgBase64} />
            </div>
          )
        }
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (record: any) => (
        <Button className="btn" type="primary" onClick={() => checkDetail(record.goods_number)} ghost={true}>
          查看详情
        </Button>
      )
    }
  ]

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">出库台账</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>出库日期：</span>
            <RangePicker onChange={time_change} defaultValue={[startdate, enddate]}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }}>
              查询
            </Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => columns.id}
              pagination={{
                // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage
              }}
            ></Table>
          </Col>
        </Row>
      </div>
      <Modal visible={showDetail} title="查看详情" onCancel={to_close} footer={null} width="900px">
        <Detail number={checkId}></Detail>
      </Modal>
    </>
  )
}
