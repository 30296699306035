import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Modal } from 'antd';
import ZgCard from '../../../components/zg-card';
import ZgRow from '../../../components/zg-row';
import CanteenDetail from '../canteen-detail/detail'
import { getCanteenInfo } from '../../../apis/canteen-info/list'

export default function CanteenList(props: any) {
  const [showDetail, setShowDetail] = useState(false)
  const [checkId, setCheckId] = useState('')
  let info = {
    id: '',
    number: '',
    canteen_name: '',
    canteen_number: '',
    type: '',
    customers_number: '',
    area: '',
    province: '',
    city: '',
    district: '',
    address: '',
    level: '',
    workers_number: '',
    first_liable: '',
    canteen_phone: ''
  }
  const [detailInfo, setDetailInfo] = useState(info)

  const to_get_info = () => {
    if (!props.number) return

    getCanteenInfo({ number: props.number }).then(res => {
      setDetailInfo(res.data)
    })
  }

  useEffect(() => {
    to_get_info()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.number])

  function checkDetail(data: any) {
    setCheckId(data)
    return setShowDetail(true)
  }

  function to_close() {
    return setShowDetail(false)
  }

  return (
    <div className="base-info-wrap">
      <ZgCard>
        <ZgRow justify="space-between">
          <div className="title-level-first">基本信息</div>
          <Button onClick={() => checkDetail(detailInfo.canteen_number)}>查看详情</Button>
        </ZgRow>
        <Row gutter={24} className="row-line">
          <Col span={8} className="card-wrap">
              <span className="label">餐饮名称</span>
              <div>
                <ZgRow className="name" justify="flex-start"><span>{detailInfo.canteen_name}</span><span className="flag">{detailInfo.level}</span></ZgRow>
                <div><span className="desc-tag1">{
                  detailInfo.type === "school" ? "学校/幼儿园食堂"
                    : detailInfo.type === "office" ? "机关/企业食堂"
                      : detailInfo.type === "enterprise" ? "中央厨房"
                        : detailInfo.type === "sociology" ? "大中型餐饮"
                          : detailInfo.type === "cookshop" ? "小餐饮"
                            : "社会餐饮"
                }</span><span className="desc-tag2">{detailInfo.workers_number}人</span></div>
              </div>
          </Col>
          <Col span={4} className="card-wrap">
            <div className="label">负责人</div>
            <div className="name">{detailInfo.first_liable}</div>
          </Col>
          <Col span={5} className="card-wrap">
            <div className="label">联系电话</div>
            <div className="name">{detailInfo.canteen_phone}</div>
          </Col>
          <Col span={7} className="card-wrap">
            <div className="label">地址</div>
            <div className="name">{detailInfo.address}</div>
          </Col>
        </Row>
      </ZgCard>
      <Modal
        visible={showDetail}
        title="餐饮详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <CanteenDetail id={checkId}></CanteenDetail>
      </Modal>
    </div>
  )
}