import React, { useState, useEffect, useCallback } from "react"
import ZgCard from "../../../../components/zg-card"
import ZgRow from "../../../../components/zg-row"
import ReactEcharts from "echarts-for-react"

import { getInsight } from "../../../../apis/analysis/index"
import { afeterDate, getCurDate } from "../../../../utils/formatDate"
import { useHistory } from "react-router-dom"
// 大数据分析首页--30日内视频预警分析
export default function DataInsight() {
   let history = useHistory()
  const [dataList, setDataList] = useState([])
  let to_get_info = useCallback(() => {
    let sdate = afeterDate(-30)
    let edate = getCurDate()
    let data = { sdate: sdate, edate: edate }
    return getInsight(data).then((res) => {
      let arr: any = []
      if (res.data.info.length > 3) {
        res.data.info.forEach((item: any, key: any) => {
          // 跳出循环
          if (key > 3) {
            return
          }
          // 插入数据
          arr.push({ value: item.count, name: item.correct_type })
        })
      } else {
        res.data.info.forEach((item: any) => {
          // 插入数据
          arr.push({ value: item.count, name: item.correct_type })
        })
      }
      let totalScore = arr.reduce((pre: any, arr1: any) => {
        return parseInt(pre) + parseInt(arr1.value)
      }, 0)

      arr.forEach((item: any) => {
        let bl = (item.value / totalScore) * 100
        // item.value = Math.floor(bl) //取整
        item.value = bl.toFixed(0) //四舍五入
      })
      setDataList(arr)
    })
  }, [])
  
 

function gotojiuPianList() {
    history.push("/correction/list")
}



  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  const echartsOption = {
    legend: {
      orient: "vertical",
      bottom: "0%",
      left: "left",
      itemGap: 20,
      textStyle: {
        padding: [0, 0, 0, 12],
      },
    },
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: "分类",
        type: "pie",
        radius: "55%",
        center: ["50%", "30%"],
        data: dataList,
        //roseType: "radius",
        label: {
          color: "#A4ABC9",
          formatter: "{c}%",
        },
        labelLine: {
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
        animationType: "scale",
        animationEasing: "elasticOut",
        animationDelay: function (idx: any) {
          return Math.random() * 200
        },
      },
    ],
  }

  return (
    <>
      <div className="stac-info-wrap1">
        <ZgCard>
          {/* <ZgTitle title="30日内视频预警分析"></ZgTitle> */}
          <ZgRow justify="space-between" className="title-level-second">
            <ZgRow>
              <span className="vertical-line"></span>
              <span>30日内视频预警分析</span>
            </ZgRow>
            <span style={{ textAlign: "right" }}><span onClick={gotojiuPianList}>进入</span></span>
          </ZgRow>

          <div className="">
            <ReactEcharts
              option={echartsOption}
              theme="clear"
              style={{ height: 420 }}
            ></ReactEcharts>
          </div>
        </ZgCard>
      </div>
    </>
  )
}
