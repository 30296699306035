import React, { useEffect, useState, useCallback } from 'react'
import styles from './index.module.css'
import ZgRow from "../../../components/zg-row";
import echarts from 'echarts'
import { baseInfo, dayInfo, riskAna } from '../../../apis/analysis/appraise'
export default function Right(props: any) {
  const [legendList, setLegendList] = useState([''])
  const [legendList1, setLegendList1] = useState([''])
  //获取基本信息
  let infoTemp = { name: '', unit_count: 0, workers_count: 0, supplier_count: 0 }
  const [info, setInfo] = useState(infoTemp)
  const get_info = useCallback(
    () => {
      return baseInfo({ area_id: props.id }).then(res => {
        setInfo(res.data)
      })
    },
    [props.id],
  )
  // 日常管理图表
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sumCanteen, setSumCanteen] = useState(0)
  let carr = [{ name: '---', value: 0 }]
  const [goodCanteen, setGoodCanteen] = useState(carr)
  const [badCanteen, setBadCanteen] = useState(carr)
  const get_day = useCallback(
    () => {
      return dayInfo({ area_id: props.id, sdate: props.sdate, edate: props.edate }).then(res => {
        let data: any = [
          { name: '优秀', value: res.data[0].level_3 },
          { name: '良好', value: res.data[0].level_2 },
          { name: '一般', value: res.data[0].level_1 },
          { name: '未公示菜谱', value: res.data[0].level_0 },
        ]
        setSumCanteen(res.data[0].count)
        renderDayStacChart(res.data[0].count, data)

        let gl: any = []
        res.data[0].up_list.forEach((item: any) => {
          gl.push({ name: item.canteen_name, value: parseFloat(item.avg) === 0 ? '---' : parseFloat(item.avg).toFixed(1) })
        })
        setGoodCanteen(gl)
        let bl: any = []
        res.data[0].low_list.forEach((item: any) => {
          bl.push({ name: item.canteen_name, value: parseFloat(item.avg) === 0 ? '---' : parseFloat(item.avg).toFixed(1) })
        })
        setBadCanteen(bl)
      })
    },
    [props],
  )
  const renderDayStacChart = (sum: any, data: any) => {
    let chartData: any = []
    let ls: any = []
    data.forEach((item: any) => {
      ls.push({ name: item.name, value: item.value })
      chartData.push({ value: item.value, name: item.name })
    })
    setLegendList(ls)
    var ele: any = document.getElementById('dayStacChart')
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      color: ['#1EB38B', '#3582FE', '#DC8320', '#A5A5A5'],
      title: {
        text: "共" + sum + "家",
        left: "center",
        top: "40%",
        textStyle: {
          color: "#fff",
          fontSize: 14,
          align: "center"
        }
      },
      graphic: {
        type: 'text',
        left: 'center',
        top: '52%',
        style: {
          text: '餐饮单位',
          textAlign: 'center',
          fill: '#fff',
          fontSize: 12
        }
      },
      series: [
        {
          name: '日常管理分析',
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'inner'
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inner'

              },
              labelLine: {
                show: false,
              }
            }
          },
          data: chartData
        }
      ]
    });
  }
  // 风险预警图表
  let corTemp: any = [{ name: '---', value: '0%' }]
  let overTemp: any = []
  const [correctLs, setCorrect] = useState(corTemp)
  const [overLs, setOver] = useState(overTemp)
  const get_risk = useCallback(() => {
    return riskAna({ area_id: props.id, sdate: props.sdate, edate: props.edate }).then((res: any) => {
      let dataRisk: any = [
        { name: '一类风险', value: res.data.level1 },
        { name: '二类风险', value: res.data.level2 },
        { name: '三类风险', value: res.data.level3 }
      ]
      renderRiskStacChart(dataRisk)
      let ls: any = []
      res.data.video_correct.forEach((item: any, index: any) => {
        if (res.data.video_correct.length > 5) {
          if (index < 5) {
            ls.push({ name: item.correct_type, value: item.count })
          }
        } else {
          ls.push({ name: item.correct_type, value: item.count })
        }
      })
      setCorrect(ls)
      let overLs = [res.data.canteen_cert_1, res.data.canteen_cert_2, res.data.goods_count, res.data.health_cert]
      setOver(overLs)
    })
  },
    [props],
  )
  const renderRiskStacChart = (data: any) => {
    let chartData: any = []
    let ls: any = []
    data.forEach((item: any) => {
      ls.push({ name: item.name, value: item.value })
      chartData.push({ value: item.value, name: item.name })
    })
    setLegendList1(ls)
    var ele: any = document.getElementById('riskStacChart')
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      color: ['#E84D4F', '#DC8320', '#3582FE'],
      series: [
        {
          name: '风险等级',
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'inner'
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'inner',
                formatter: '{d}%'
              },
              labelLine: {
                show: false,
              }
            }
          },
          data: chartData
        }
      ]
    });
  }
  const overEle = (<div>
    <ZgRow align="center" justify="space-between" className={styles.stacLine}>
      <div className={styles.stacName}><span>原料过期</span></div>
      <div className={styles.stacValue}>{overLs[2]}</div>
    </ZgRow>
    <ZgRow align="center" justify="space-between" className={styles.stacLine}>
      <div className={styles.stacName}><span>健康证过期</span></div>
      <div className={styles.stacValue}>{overLs[3]}</div>
    </ZgRow>
    <ZgRow align="center" justify="space-between" className={styles.stacLine}>
      <div className={styles.stacName}><span>食品责任险过期</span></div>
      <div className={styles.stacValue}>{overLs[1]}</div>
    </ZgRow>
    <ZgRow align="center" justify="space-between" className={styles.stacLine}>
      <div className={styles.stacName}><span>食品经营许可证过期</span></div>
      <div className={styles.stacValue}>{overLs[0]}</div>
    </ZgRow>
  </div>)
  useEffect(() => {
    get_day()
    get_risk()
  }, [get_day, get_risk])
  useEffect(() => {
    get_info()
  }, [get_info])
  return (
    <div className={styles.rightBox}>
      <ZgRow justify="space-between" className={styles.rightTitleLine}>
        <div className={styles.rightTitle}>{props.curArea}</div>
        {/* <div className={styles.exportBtn}>导出</div> */}
      </ZgRow>
      <div className={styles.blockTitle}>基本信息</div>
      <ZgRow justify="space-between" className={styles.colorBg + ' ' + styles.baseInfo}>
        <div className={styles.baseLeft}>
          <ZgRow justify="space-between" className={styles.baseItem}>
            <div className={styles.rightLabel}>位置全称：</div>
            <div className={styles.rightValue}>{info.name}</div>
          </ZgRow>
          <ZgRow justify="space-between" className={styles.baseItem}>
            <div className={styles.rightLabel}>总区域供应商：</div>
            <div className={styles.rightValue}>{info.supplier_count}家</div>
          </ZgRow>
        </div>
        <div className={styles.baseRight}>
          <ZgRow justify="space-between" className={styles.baseItem}>
            <div className={styles.rightLabel}>管辖餐饮单位：</div>
            <div className={styles.rightValue}>{info.unit_count}家</div>
          </ZgRow>
          <ZgRow justify="space-between" className={styles.baseItem}>
            <div className={styles.rightLabel}>从业人员总数：</div>
            <div className={styles.rightValue}>{info.workers_count}人</div>
          </ZgRow>
        </div>
      </ZgRow>
      <div className={styles.blockTitle}>餐饮单位日常管理情况分析</div>
      <div className={styles.colorBg + ' ' + styles.dayStac}>
        <ZgRow align='center' justify="center">
          <div className={styles.dayStacChart}>
            <div id="dayStacChart" style={{ width: '100%', height: '100%' }}></div>
          </div>
          <div className={styles.legendWrap}>
            {
              legendList.map((item: any, index: any) => (
                <div className={styles.flexRow + ' ' + styles.legendItem} key={index}>
                  <span className={styles.legendSpan + ' ' + (index === 0 ? styles.greenBg : index === 1 ? styles.blueBg : index === 2 ? styles.yellowBg : styles.greyBg)}></span>
                  <span className={styles.legendText}>{item.name}</span>
                  <span className={styles.legendNumber}>{item.value}</span>
                </div>
              ))
            }
          </div>
        </ZgRow>
        <ZgRow align="start" justify="space-between">
          <div className={styles.stacItem}>
            <div className={styles.stacTitle}>优秀餐饮单位TOP5</div>
            {
              goodCanteen.map((item: any, index: any) => (
                <ZgRow key={index} align="center" justify="space-between" className={styles.stacLine}>
                  <div className={styles.stacName}><span className={styles.stacIndex}>{index + 1}</span><span>{item.name}</span></div>
                  <div className={styles.stacValue}>{item.value}</div>
                </ZgRow>
              ))
            }
          </div>
          <div className={styles.stacItem}>
            <div className={styles.stacTitle}>较差餐饮单位TOP5</div>
            {
              badCanteen.map((item: any, index: any) => (
                <ZgRow key={index} align="center" justify="space-between" className={styles.stacLine + ' ' + styles.stacLineGrey}>
                  <div className={styles.stacName}><span className={styles.stacIndex}>{index + 1}</span><span>{item.name}</span></div>
                  <div className={styles.stacValue}>{item.value}</div>
                </ZgRow>
              ))
            }
          </div>
        </ZgRow>
      </div>
      <div className={styles.blockTitle + ' ' + styles.blockTitle3}>风险预警情况分析</div>
      <div className={styles.colorBg + ' ' + styles.dayStac}>
        <ZgRow align='center' justify="center">
          <div className={styles.dayStacChart}>
            <div id="riskStacChart" style={{ width: '100%', height: '100%' }}></div>
          </div>
          <div className={styles.legendWrap}>
            {
              legendList1.map((item: any, index: any) => (
                <div className={styles.flexRow + ' ' + styles.legendItem} key={index}>
                  <span className={styles.legendSpan + ' ' + (index === 0 ? styles.redBg : index === 1 ? styles.yellowBg : index === 2 ? styles.blueBg : styles.greyBg)}></span>
                  <span className={styles.legendText}>{item.name}</span>
                  <span className={styles.legendNumber}>{item.value}</span>
                </div>
              ))
            }
          </div>
        </ZgRow>
        <ZgRow align="start" justify="space-between">
          <div className={styles.stacItem}>
            <div className={styles.stacTitle}>视频预警</div>
            {
              correctLs.length > 0 ? correctLs.map((item: any, index: any) => (
                <ZgRow align="center" justify="space-between" className={styles.stacLine} key={index}>
                  <div className={styles.stacName}><span>{item.name}</span></div>
                  <div className={styles.stacValue}>{item.value}</div>
                </ZgRow>
              )) : <div style={{ color: '#A8A5FF', padding: 20, textAlign: 'center' }}>暂无数据</div>
            }
          </div>
          <div className={styles.stacItem}>
            <div className={styles.stacTitle}>过期预警</div>
            {overLs[0] === 0 && overLs[1] === 0 && overLs[2] === 0 && overLs[3] === 0 ? <div style={{ color: '#A8A5FF', padding: 20, textAlign: 'center' }}>暂无数据</div> : overEle}
          </div>
        </ZgRow>
      </div>
    </div>
  )
}