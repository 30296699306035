import React from 'react'
import { Col, Row } from 'antd';
import ZgRow from '../../../components/zg-row';
import ZgInfoItem from '../../../components/zg-info-item';
import './list.less'
export default function Detail(props: any) {
  return (
    <div className="detail-wrap">
      <Row gutter={12}>
        <Col span={8}><ZgInfoItem title="填报人" content="小明"></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="检查结果" content="合格"></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="填报时间" content="2020.12.12 12:12"></ZgInfoItem></Col>
      </Row>
      <div className="line"></div>
      <div className="title-level-second">检查项目</div>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="晨检人数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="晨检状况&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="原料采购情况" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="原料储备情况" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="食品加工状况" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="食品留样状况" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="食品用具消毒" content="1"></ZgInfoItem>
      </ZgRow>
      <ZgRow justify="flex-start" className="check-line">
        <ZgInfoItem title="场所清洁状况" content="1"></ZgInfoItem>
      </ZgRow>
    </div>
  )
}