import proxy from '../../utils/proxy'
/**
 * 获取食堂晨检
 */
export function getMorning(data: any) {
  return proxy({
    url: '/admin/record/morningchecks/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取留样记录
 */
export function getSample(data: any) {
  return proxy({
    url: '/admin/record/samples/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取留样详情
 */
export function getSampleInfo(data: any) {
  return proxy({
    url: '/admin/record/samples/info',
    method: 'get',
    params: data
  })
}
/**
 * 获取餐具消毒记录
 */
export function getTableList(data: any) {
  return proxy({
    url: '/admin/record/tableware-disinfections/index',
    method: 'get',
    params: data
  })
}
/**
 * 获取检疫检测记录
 */
export function getQuaList(data: any) {
  return proxy({
    url: '/admin/record/quarantine-inspections/list',//1:理化 2:微生物
    method: 'get',
    params: data
  })
}
/**
 * 获取食堂清洁记录
 */
export function getCleanList(data: any) {
  return proxy({
    url: '/admin/record/cleanchecks/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取食堂添加剂使用记录
 */
export function getAddictiveList(data: any) {
  return proxy({
    url: '/admin/record/additiveuses/list',
    method: 'get',
    params: data
  })
}

/**
 * 获取食堂安全自查
 */
export function getSafetyList(data: any) {
  return proxy({
    url: '/admin/record/safetychecks/list',
    method: 'get',
    params: data
  })
}

/**
 * 获取食堂环境消毒
 */
export function getEnvironList(data: any) {
  return proxy({
    url: '/admin/record/environdisinfections/list',
    method: 'get',
    params: data
  })
}


/**
 * 获取食堂环境消毒
 */
export function getWasteList(data: any) {
  return proxy({
    url: '/admin/record/wastedisposals/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取食堂陪餐列表
 */
export function getAccomList(data: any) {
  return proxy({
    url: '/admin/record/accompanydinner/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取食堂领导视察
 */
export function getLeaderList(data: any) {
  return proxy({
    url: '/admin/record/leaderinspect/list',
    method: 'get',
    params: data
  })
}
