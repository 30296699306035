import React, { useEffect, useState } from 'react'
import '../../../../style/data-cake.less'
import ZgTitle from '../../../../components/zg-title'
import ReactEcharts from 'echarts-for-react'
import { afeterDate, getCurDate } from "../../../../utils/formatDate"
import { correctBytypeEx } from "../../../../apis/intelligence-rectify/intelligence-rectify"

// 大数据视频预警--饼图
export default function DataCake() {
  const dataInit = [{ value: 0, name: "" }]
  const [data, setData] = useState(dataInit)
  const echartsOption = {
    legend: {
      type: 'scroll',
      orient: 'vertical',
      bottom: '0%',
      left: 'left',
      top: '64%',
      itemGap: 20,
      textStyle: {
        padding: [0, 0, 0, 12]
      },
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: '访问来源',
        type: 'pie',
        radius: '55%',
        center: ['50%', '32%'],
        data: data.sort(function (a, b) { return a.value - b.value; }),
        roseType: 'radius',
        label: {
          color: '#A4ABC9',
          formatter: '{d}%'
        },
        labelLine: {
          smooth: 0.2,
          length: 10,
          length2: 20
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx: any) {
          return Math.random() * 200;
        }
      }
    ]
  }

  function to_init_data(val1: any, val2: any) {
    correctBytypeEx({ sadte: val1, edate: val2 }).then(res => {
      console.log("高频")
      console.log(res)
      let dataList: any = []
      let other = 0
      res.data.info.forEach((item: any, index: any) => {
        if (index < 4) {
          dataList.push({ value: item.count, name: item.correct_type })
        } else {
          other += item.count
        }
      })
      dataList.push({ value: other, name: '其他' })
      setData(dataList)
    })
  }

  useEffect(() => {
    to_init_data(afeterDate(-30), getCurDate())
  }, [])
  return (
    <>
      <div className="cake-cont">
        <div className="cont-tit">
          <ZgTitle title="高频事件分析"></ZgTitle>
          <div className="tit-right">
            <div className="right-one">30日内</div>
          </div>
        </div>
        <div className="cake-main">
          <ReactEcharts option={echartsOption} theme="clear" style={{ height: '100%' }}></ReactEcharts>
        </div>
      </div>
    </>
  )
}