import React, { useCallback, useState, useEffect } from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import ZgColumn from "../../../../components/zg-column";
import { safeIndexDayInfo } from "../../../../apis/intelligence-rectify/intelligence-rectify"

import ReactEcharts from "echarts-for-react";
import { forwardRef } from "react";

function EvalActionably(props: any, ref: any) {
  const [yjScore, setYjScore] = useState(0)
  const [okScore, setOkScore] = useState(0)
  const [info, setInfo] = useState<any>({
    analyze: '--',
    score: 80,
    goods_expired_score: 0,
    health_certificate_expired_score: 0,
    correct_score: 0

  })

  const option = {
    series: [
      {
        name: "分值",
        type: "pie",
        hoverOffset: 1,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        color: ["#5473E8", "#E9ECF7"],
        radius: ["95%", "98%"],
        data: [
          {
            value: info.score,
            name: "n",
          },
          {
            value: 100 - info.score,
            name: "v",
          },
        ],
      },
    ],
  }

  const get_data = useCallback(
    () => {
      return safeIndexDayInfo({ canteen_number: props.canteenNumber, date: props.sdate }).then(res => {
        if (res.status === 200) {
          setInfo(res.data)
          setYjScore(parseFloat(res.data.goods_expired_score) + parseFloat(res.data.health_certificate_expired_score) + parseFloat(res.data.correct_score))
          setOkScore(parseFloat((parseFloat(res.data.score) - (parseFloat(res.data.goods_expired_score) + parseFloat(res.data.health_certificate_expired_score) + parseFloat(res.data.correct_score)) - 80).toFixed(2)))
        } else {
          console.warn("safeIndexDayInfo 请求出错 ===>", res)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate],
  )
  useEffect(() => {
    if (props.canteenNumber !== '') {
      get_data()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_data])
  return (
    <div style={{ backgroundColor: '#ffffff', height: '100%', borderRadius: 5 }}>
      <ZgCard>
        <ZgRow justify="space-between" align="flex-start">
          <ZgTitle title="评估分析报告"></ZgTitle>
        </ZgRow>
        <div>
          <div className="card">
            <div className="chart-wrap">
              <ReactEcharts option={option} style={{ height: 219 }} />
              <ZgColumn className="desc-wrap">
                <div className="chart-number">
                  {info.score}
                  <span className="unit">分</span>
                </div>
                <div className="chart-desc">评分</div>
              </ZgColumn>
            </div>
          </div>
          <div className="tinyflex">
            <div className="text_cen">
              <div className="points">80</div>
              <div className="dones">基础分</div>
            </div>
            <div className="text_cen">
              <div className="seventeen">{okScore}</div>
              <div className="dones">工作完成</div>
            </div>
            <div className="text_cen">
              <div className="two_color">{yjScore}</div>
              <div className="dones">风险预警</div>
            </div>
          </div>
          <div>
            <div className="analysis">
              <span className="statusquo">
                现状分析：
                <span className="overall">
                  {info.analyze}
                </span>
              </span>
            </div>
          </div>
        </div>
      </ZgCard>
    </div>
  );
}
export default forwardRef(EvalActionably)