import request from '../../utils/request'

export function getCanteenPartyList(data: any) {
  return request({
    url: '/app/canteen-party/list',
    method: 'get',
    params: data
  })
}


export function getCanteenPartyInfo(data: any) {
  return request({
    url: 'app/canteen-party/info',
    method: 'get',
    params: data
  })
}
