import React from 'react'
import { Image } from 'antd';
import '../style/zg-image.less'
export default function ZgImage(props: any) {
  return (
    <>
      <div className="zg-img">
        <div className="img-hg">
          <Image width={'100%'} height={144} preview={false}
            src={props.src}
          />
        </div>
        <div className="zg-img-cont">
          <div className="img-cont-tit">{props.title}</div>
          <div className="img-cont-btm">
            <span className="base-msg">{props.name}</span>
            <span className="base-msg">{props.time}</span>
          </div>
        </div>
      </div>
    </ >
  )
}