import React, { useEffect, useState } from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import ZgColumn from "../../../../components/zg-column";

import ReactEcharts from "echarts-for-react";

export default function EvalActionably() {
  const [score, setScore] = useState(0);
  const option = {
    series: [
      {
        name: "访问",
        type: "pie",
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        color: ["#5473E8", "#E9ECF7"],
        radius: ["95%", "98%"],
        data: [
          {
            value: score,
            name: "n",
          },
          {
            value: 100 - score,
            name: "v",
          },
        ],
      },
    ],
  };
  useEffect(() => {
    setScore(90);
    // console.log(props.score)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [90]);
  return (
    <>
      <div style={{backgroundColor:'#ffffff',paddingBottom:'30%'}}>
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="评估分析报告"></ZgTitle>
          </ZgRow>
          <div>
            <div className="card">
              <div className="chart-wrap">
                <ReactEcharts option={option} style={{ height: 219 }} />
                <ZgColumn className="desc-wrap">
                  <div className="chart-number">
                    {score}
                    <span className="unit">分</span>
                  </div>
                  <div className="chart-desc">良好</div>
                </ZgColumn>
              </div>
            </div>
            <div className="tinyflex">
              <div className="text_cen">
                <div className="points">80</div>
                <div className="dones">基础分</div>
              </div>
              <div className="text_cen">
                <div className="seventeen">+17</div>
                <div className="dones">工作完成</div>
              </div>
              <div className="text_cen">
                <div className="two_color">-2</div>
                <div className="dones">风险预警</div>
              </div>
            </div>
            <div>
              <div className="analysis">
                <span className="statusquo">
                  现状分析：
                  <span className="overall">
                    总体运营状况优秀。保持的很好，按时提交记录，保持评估结果的同时稳步提高！
                  </span>
                </span>
              </div>
              <div className="analysis">
                <span className="statusquo">
                  现状分析：
                  <span className="overall">
                    总体运营状况优秀。保持的很好，按时提交记录，保持评估结果的同时稳步提高！
                  </span>
                </span>
              </div>
            </div>
          </div>
        </ZgCard>
      </div>
    </>
  );
}
