import React, { useState, useEffect, useCallback } from "react";
import styles1 from "../../indexArea.module.css";
import Title from "../title";
import { Progress, Select } from "antd";
import { getCertStac } from "../../../../apis/board";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Guoqiyujing(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  function check_more(path: any) {
    history.push(path);
  }
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  const [certDateType, setCertDateType] = useState(1);
  const squareData = [
    { name: "原料过期", value: 0, sum: 0, percent: 0 },
    { name: "从业人员健康证", value: 0, sum: 0, percent: 0 },
    { name: "餐饮证件", value: 0, sum: 0, percent: 0 },
  ];
  const [squareList, setCertList] = useState(squareData);
  //证件预警数据
  const certProgressData = useCallback(() => {
    return getCertStac({
      sdate: formatDate(certDateType)[0],
      edate: formatDate(certDateType)[1],
    }).then((res: any) => {
      let csum = res.data.health_count;
      let cwait = res.data.health_wait_count;
      let cpercent = csum === 0 ? 0 : ((cwait / csum) * 100).toFixed(1);
      let t1sum = res.data.total_count1 + res.data.total_count2;
      let t1wait = res.data.total_wait_count1 + res.data.total_wait_count2;
      let t1percent = t1sum === 0 ? 0 : ((t1wait / t1sum) * 100).toFixed(1);
      let sum = res.data.goods_expire_count;
      let wait = res.data.goods_expire_wait_count;
      let percent = sum === 0 ? 0 : ((wait / sum) * 100).toFixed(1);
      let ls: any = [
        { name: "原料过期", value: wait, sum: sum, percent: percent },
        { name: "从业人员健康证", value: cwait, sum: csum, percent: cpercent },
        { name: "餐饮证件", value: t1wait, sum: t1sum, percent: t1percent },
      ];
      setCertList(ls);
    });
  }, [certDateType]);
  function cerDateChange(type: any) {
    setCertDateType(type);
  }
  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }

  //过期预警数据分析dom渲染
  const squareEle = squareList.map((item: any, index: any) => (
    <div
      className={styles.certItem}
      key={item.name}
      onClick={
        index === 0
          ? () => check_more("/materials/material")
          : index === 1
          ? () => check_more("/certificates/list")
          : () => check_more("/canteen_ertificate/list")
      }
    >
      <div className={styles.certName + " " + styles.purpleColor}>
        {item.name}
      </div>
      <div className={styles.circleBox}>
        <Progress
          trailColor="#bbb"
          strokeColor="#D42D2F"
          className={styles.certProgress}
          strokeWidth={4}
          width={50}
          type="circle"
          percent={item.percent}
        />
        <div className={styles.circleBg}>{item.value}</div>
      </div>
      <div className={styles.certWait}>
        <span className={styles.certLabel}>待处理</span>
        <span className={styles.value + " " + styles.redColor}>
          {item.value}
        </span>
      </div>
      <div className={styles.certAll}>
        <span className={styles.certLabel}>总预警</span>
        <span className={styles.value}>{item.sum}</span>
      </div>
    </div>
  ));
  useEffect(() => {
    certProgressData();
  }, [certProgressData]);
  return (
    <div className={styles1.rightSideFlex}>
      <div className={styles.rightSide}>
        <div className={styles.box + " " + styles.box1}>
          <div className={styles.flexRow + " " + styles.titleLine}>
            <Title title="过期预警"></Title>
            <Select
              onChange={cerDateChange}
              className={"board-select " + styles.select}
              key={timeList[0].type}
              defaultValue={timeList[0].type}
            >
              {timeList.map((item: any) => (
                <Option key={item.type} value={item.type}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.flexRow + " " + styles.certList}>
            {squareEle}
          </div>
        </div>
      </div>
    </div>
  );
}
