import React from "react"
import "./data.less"
import { Row, Col } from "antd"
import DataHead from "./components/data-head"
import DataToday from "./components/data-today"
import DataInspection from "./components/data-inspection"
import DataSafety from "./components/data-safety"
import DataWork from "./components/data-work"
import DataHeat from "./components/data-heat"
import DataInsight from "./components/data-insight"

// 大数据可视化首页
export default function DataAnalysis(props: any) {
  return (
    <>
      <div className="zg-container-y">
        <Row gutter={12}>
          <Col span={24}>
            <DataHead></DataHead>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={5}>
            <DataToday></DataToday>
          </Col>
          <Col span={13}>
            <DataHeat></DataHeat>
          </Col>
          <Col span={6}>
            <DataInsight></DataInsight>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={5}>
            <DataSafety></DataSafety>
          </Col>
          <Col span={13}>
            <DataInspection></DataInspection>
          </Col>
          <Col span={6}>
            <DataWork></DataWork>
          </Col>
        </Row>
      </div>
    </>
  )
}
