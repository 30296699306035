import React, { useState, useEffect } from 'react'
import './login.less'
import { Form, Input, Button, Checkbox, Row, Col, message, Image } from 'antd'
import { useHistory } from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import bgImg from '../../assets/images/loginBg.jpg'
import tb from '../../assets/images/tb.png'
import ZgRow from '../../components/zg-row'
import { login, userInfo, getCaptcha, userGradeInfo } from '../../apis/login'
import $storeLocal from 'storejs'
import { noimgBase64 } from 'utils/common'

export default function Login() {
  const [captchaData, setCaptchaData] = useState({ img: '', key: '' })
  let history = useHistory()
  let loginInfo = {
    username: '',
    password: '',
    remember: true
  }
  let un = ''
  let ps = ''
  let storeInfo = $storeLocal.get('userLoginData')
  if (storeInfo && storeInfo.username) {
    un = storeInfo.username
  }
  if (storeInfo && storeInfo.password) {
    ps = storeInfo.password
  }
  const [username] = useState(un)
  const [password] = useState(ps)
  const onFinish = (values: any) => {
    loginInfo = values
    login({
      username: values.username,
      password: values.password,
      captcha: values.captcha,
      key: captchaData.key
    })
      .then((res: any) => {
        $storeLocal.set('customPCToken', res.data.token)
        getUserInfo()
      })
      .catch(e => {
        message.error(e.response.data.message)
        refreshCaptcha()
      })
  }

  function refreshCaptcha() {
    getCaptcha({}).then((res: any) => {
      setCaptchaData(res.data)
    })
  }

  function getUserInfo() {
    userInfo({}).then((res: any) => {
      $storeLocal.set('UserBaseInfo', res.data)
      let storeLogin = {}
      if (loginInfo.remember) {
        storeLogin = {
          username: loginInfo.username,
          password: loginInfo.password
        }
      } else {
        storeLogin = {
          username: loginInfo.username
        }
      }
      $storeLocal.set('userLoginData', storeLogin)
      userGradeInfo({}).then((res: any) => {
        $storeLocal.set('customPCGrade', res.data.grade)
        // 教育端和监管端角色区分 //1：监管端   5：教育端
        // $storeLocal.set("customPCType", "5");
        $storeLocal.set('customPCType', res.data.type)

        // 获取登录人的管理区域
        if (res.data.grade === 'city') {
          history.push('/board/indexCity')
        } else if (res.data.grade === 'district') {
          history.push('/board/indexArea')
        } else {
          history.push('/analysis/data-analysis')
        }
      })
    })
  }

  useEffect(() => {
    refreshCaptcha()
  }, [])

  return (
    <div className="login-container">
      <img alt="" className="img" src={bgImg} />
      <Row className="login-wrap">
        <Col className="left" span={12}>
          <ZgRow className="title">Welcome</ZgRow>
          <ZgRow className="desc-line">
            <img alt="" className="logo" src={tb} />
            <span>互联网+明厨亮灶智慧监管平台</span>
          </ZgRow>
          <ZgRow className="footer">湖北致格意诚科技有限公司</ZgRow>
        </Col>
        <Col className="right" span={12}>
          <ZgRow className="title">欢迎登录</ZgRow>
          <div className="title-line">
            <span className="act-line"></span>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
              username: username,
              password: password
            }}
            onFinish={onFinish}
          >
            <Form.Item name="username" rules={[{ required: true, message: '请输入账号!' }]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入账号" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item name="captcha" rules={[{ required: true, message: '请输入验证码!' }]}>
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="验证码"
                  style={{
                    borderLeft: '0px',
                    borderTop: '0px',
                    borderRight: '0px',
                    borderRadius: '0px',
                    boxShadow: 'none'
                  }}
                />

                <Image
                  alt="验证码"
                  src={captchaData.img}
                  height={40}
                  width={120}
                  onClick={refreshCaptcha}
                  preview={false}
                  fallback={noimgBase64}
                ></Image>
              </div>
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>记住密码</Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                登录
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
