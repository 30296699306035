import React, { useState, useEffect, useCallback } from "react";
import Title from "../title";
import { Select } from "antd";
import { cityQuarantList } from "../../../../apis/board";
import dayjs from "dayjs";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Jianyijiance(props: any) {
  let styles = GetTheme();
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  const [dateType, setDateType] = useState(1);

  function dateChange(type: any) {
    setDateType(type);
  }

  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }
  const quarantTemp = [
    {
      name: "",
      count: "---",
      rate: "0%",
    },
  ];
  const [quarantList, setQuarantList] = useState(quarantTemp);
  //检疫检测列表
  const quarantListData = useCallback(() => {
    return cityQuarantList({
      sdate: formatDate(dateType)[0],
      edate: formatDate(dateType)[1],
    }).then((res: any) => {
      let ls: any = [];
      res.data.forEach((item: any) => {
        ls.push({
          name: item.unit_name,
          count: item.count,
          rate: item.score + "%",
        });
      });
      setQuarantList(ls);
    });
  }, [dateType]);

  //无数据提示
  const noEle = (
    <div className={styles.noWrap}>
      <span className={styles.purpleColor}>暂无数据</span>
    </div>
  );
  //检疫检测内容块
  const quarantContent = quarantList.map((item: any, index: any) => (
    <div className={styles.flexRow + " " + styles.quarantLine} key={index}>
      <span className={styles.quarantItem}>
        <span
          className={
            styles.quarantIndex +
            " " +
            (index === 0
              ? styles.greenBackground
              : index === 1
              ? styles.blueBackground
              : index === 2
              ? styles.yellowBackground
              : styles.purpleBackground)
          }
        >
          {index + 1}
        </span>
        {item.name}
      </span>
      <span className={styles.quarantItem2}>{item.count}</span>
      <span className={styles.quarantItem3}>{item.rate}</span>
    </div>
  ));
  useEffect(() => {
    quarantListData();
  }, [quarantListData]);
  return (
    <>
      <div>
        <div className={styles.leftSide}>
          <div className={styles.box + " " + styles.box3}>
            <div className={styles.flexRow + " " + styles.titleLine}>
              <div className={styles.flexRow + " " + styles.checkLine}>
                <Title title="检测合格率排行榜"></Title>
              </div>
              <Select
                onChange={dateChange}
                className={"board-select " + styles.select}
                key={timeList[0].type}
                defaultValue={timeList[0].type}
              >
                {timeList.map((item: any) => (
                  <Option key={item.type} value={item.type}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={styles.flexRow}>
              <span className={styles.quarantTitle}>单位名称</span>
              <span className={styles.quarantTitle2}>检测数量</span>
              <span className={styles.quarantTitle3}>合格率</span>
            </div>
            <div className={styles.quarantList}>
              {quarantList.length > 0 ? quarantContent : noEle}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
