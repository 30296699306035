import request from '../../utils/request'

export function indexBetween(data: any) {
  return request({
    url: '/app/index-day/index-between',
    method: 'get',
    params: data
  })
}

export function canteenRankingList(data: any) {
  return request({
    url: '/app/video/correct/canteen-ranking-list',
    method: 'get',
    params: data
  })
}

export function dailyIndex(data: any) {
  return request({
    url: '/app/data-statistics/daily-index/distribute',
    method: 'get',
    params: data
  })
}

// 风险预警趋势
export function dailyIndexTrend(data: any) {
  return request({
    url: '/app/data-statistics/daily-index/index/trend',
    method: 'get',
    params: data
  })
}

// 视频预警统计数
export function dailyIndexCorrectIndex(data: any) {
  return request({
    url: '/app/data-statistics/daily-index/correct/index',
    method: 'get',
    params: data
  })
}

// 高频事件分析
export function correctBytypeEx(data: any) {
  return request({
    url: '/app/statistics/correct-bytype-ex',
    method: 'get',
    params: data
  })
}

// 综合预警统计
export function warningIndex(data: any) {
  return request({
    url: '/app/data-statistics/warning/index/index',
    method: 'get',
    params: data
  })
}

// 评估详情（单日）
export function safeIndexDayInfo(data: any) {
  return request({
    url: '/app/safe-index-day/info',
    method: 'get',
    params: data
  })
}

// 评估详情（多日）
export function safeIndexDaysInfo(data: any) {
  return request({
    url: '/app/safe-index-day/infos',
    method: 'get',
    params: data
  })
}

// 次数
export function indexDayInfoBetween(data: any) {
  return request({
    url: '/app/index-day/info-between',
    method: 'get',
    params: data
  })
}


// // 次数
// export function indexDayInfoBetween(data: any) {
//   return request({
//     url: '/app/safe-index-day/info',
//     method: 'get',
//     params: data
//   })
// }


// 预警分析数据
export function yesterdayAnalysisDataCount(data: any) {
  return request({
    url: '/app/data-statistics/warning/index/yesterday-analysis-data-count',
    method: 'get',
    params: data
  })
}
