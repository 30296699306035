import React, { useState, useEffect, useCallback } from 'react'
import { Col, Row } from 'antd';
import ZgRow from '../../../components/zg-row';
import './list.less'
import { getSampleInfo } from '../../../apis/canteen-info/proxy-checks'
export default function Detail(props: any) {
  let info = {
    meal_type: 0,
    infos: [{
      dishes_name: '',
      image_appends: ''
    }],
    created_at: '',
    user_name: '',
    no: '',
    handle_name: '',
    handle_descr: ''
  }
  const [detailInfo, setDetailInfo] = useState<any>(info)
  let to_get_info = useCallback(() => {
    return getSampleInfo({ id: props.id }).then(res => {
      setDetailInfo(res.data)
    })
  }, [props.id])
  useEffect(() => {
    to_get_info()
  }, [to_get_info])
  return (
    <div className="detail-wrap">
      <ZgRow justify="space-between" className="title-line">
        <span>{detailInfo.meal_type === 0 ? '早餐' : detailInfo.meal_type === 1 ? '中餐' : detailInfo.meal_type === 2 ? '晚餐' : '其他'}</span>
        <span>留样人：{detailInfo.user_name}&nbsp;&nbsp;&nbsp;&nbsp; {detailInfo.created_at}</span>
      </ZgRow>
      <Row gutter={[12, 12]}>
        {
          detailInfo.infos.map((item: any) => (
            <Col span={8} className="" key={'info_' + item.id}>
              <div className="name-line img-block">{item.no > 9 ? item.no : '0' + item.no} {item.dishes_name}</div>
            </Col>
          ))
        }
      </Row>

      {
        detailInfo.handle_id ? (
          <div className="handle-info">
            于 {detailInfo.handle_date} 由 {detailInfo.handle_name} 进行了处理。
          </div>
        ) : ''
      }
    </div>
  )
}