import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Image, Button } from 'antd';
import ZgInfoItem from '../../../components/zg-info-item';
// import noImg from '../../../assets/images/noData.png'
import { getWorkerInfo } from '../../../apis/canteen-info/proxy-info'
import ZgRow from '../../../components/zg-row';
import ZgColumn from '../../../components/zg-column';
import noDataImg from '../../../assets/images/noData.png'

import './list.less'
export default function Detail(props: any) {
  let info = {
    name: '',
    gender: 1,
    entry_date: '',
    phone: '',
    id_card: '',
    avatar_appends: '',
    certificate: { expire_date: '', pics_appends: '' },
    contract_img_appends: '',
    accident_insurance_appends: '',
    induction_agreement_appends: '',
    responsibility_appends: ''
  }
  const [detailInfo, setDetailInfo] = useState(info)
  // console.log(props.id)
  let to_get_info = useCallback(() => {
    return getWorkerInfo({ id: props.id }).then(res => {
      if (res.data.certificates.length > 0) {
        res.data.certificate = res.data.certificates[0]
      } else {
        res.data.certificate = { expire_date: '', pics_appends: '' }
      }
      setDetailInfo(res.data)
    })
  }, [props.id])
  useEffect(() => {
    to_get_info()
  }, [to_get_info])
  return (
    <div className="detail-wrap">
      <Row gutter={[12, 12]}>
        <Col span={8}><ZgInfoItem title="姓名" content={detailInfo.name}></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="性别" content={detailInfo.gender === 1 ? "男" : "女"}></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="入职时间" content={detailInfo.entry_date}></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="联系方式" content={detailInfo.phone}></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="身份证号" content={detailInfo.id_card === "" ? '无' : detailInfo.id_card}></ZgInfoItem></Col>
      </Row>
      <div className="img-wrap1">
        <ZgInfoItem title="本人照片"></ZgInfoItem>
        <ZgRow justify="flex-start" className="card-info">
          {/* <img style={{ display: (detailInfo.avatar_appends === '' ? 'none' : 'block') }} className="img" src={detailInfo.avatar_appends} alt="" /> */}
          <div style={{ display: (detailInfo.avatar_appends === '' ? 'none' : 'block') }}>
            <Image className="img" src={detailInfo.avatar_appends} />
          </div>
          <ZgColumn className="none-wrap" style={{ display: (detailInfo.avatar_appends === '' ? 'flex' : 'none') }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Image className="no-img" preview={false} src={noDataImg}></Image>
              暂无图片
            </div>
          </ZgColumn>
        </ZgRow>
      </div>
      {/* <div className="img-wrap">
        <Image style={{ display: (detailInfo.avatar_appends === '' ? 'none' : 'inline-block') }} src={detailInfo.avatar_appends} />
        <Image preview={false} style={{ display: (detailInfo.avatar_appends === '' ? 'inline-block' : 'none') }} src={noImg} />
        <div className="desc">本人照片</div>
      </div> */}
      <div className="line"></div>
      <div className="title-level-second">健康证</div>
      <div className="img-wrap1">
        <ZgRow justify="flex-start" className="card-info">
          {/* <img style={{ display: (detailInfo.certificate.pics_appends === '' ? 'none' : 'block') }} className="img" src={detailInfo.certificate.pics_appends} alt="" /> */}
          <div style={{ display: (detailInfo.certificate.pics_appends === '' ? 'none' : 'block') }}>
            <Image style={{ display: (detailInfo.certificate.pics_appends === '' ? 'none' : 'block') }} className="img" src={detailInfo.certificate.pics_appends} />
          </div>
          <ZgColumn className="none-wrap" style={{ display: (detailInfo.certificate.pics_appends === '' ? 'flex' : 'none') }}>
            <Image style={{ justifyContent: 'center', alignItems: 'center', display: (detailInfo.certificate.pics_appends === '' ? 'flex' : 'none') }} className="no-img" preview={false} src={noDataImg}></Image>
            暂无图片
          </ZgColumn>
        </ZgRow>
        {/* <Image className="img" style={{ display: (detailInfo.certificate.pics_appends === '' ? 'none' : 'inline-block') }} src={detailInfo.certificate.pics_appends} />
        <Image className="img" preview={false} style={{ display: (detailInfo.certificate.pics_appends === '' ? 'inline-block' : 'none') }} src={noImg} /> */}
        <div style={{ display: (detailInfo.certificate.expire_date === '' ? 'none' : 'block') }} >健康证有效期：{detailInfo.certificate.expire_date}</div>
      </div>
      <div className="title-level-second">相关证件</div>
      <Row gutter={12}>
        <Col span={6}>
          <span className="label">入职合同</span>
          <span className="img-list-wrap">
            <Button className="btn" type="primary" ghost={true}>查看</Button>
            <div className="img-list">
              {/* <div style={{ display: (detailInfo.induction_agreement_appends === "" ? "none" : 'block') }}>
                <Image.PreviewGroup >
                  <Image
                    width={200}
                    src={detailInfo.induction_agreement_appends}
                  />
                </Image.PreviewGroup>
              </div>
              <div style={{ display: (detailInfo.induction_agreement_appends === "" ? "block" : 'none') }}>
                <ZgColumn className="none-wrap" style={{ display: (detailInfo.certificate.pics_appends === '' ? 'flex' : 'none') }}>
                  <Image style={{ display: (detailInfo.certificate.pics_appends === '' ? 'flex' : 'none') }} className="no-img" preview={false} src={noDataImg}></Image>
                  暂无图片
                </ZgColumn>
              </div> */}

              <div style={{ display: (detailInfo.induction_agreement_appends === "" ? "none" : 'block') }}>
                <Image.PreviewGroup >
                  <Image
                    width={200}
                    src={detailInfo.induction_agreement_appends}
                  />
                </Image.PreviewGroup>
              </div>
              <div style={{ display: (detailInfo.induction_agreement_appends === "" ? "block" : 'none') }}>
                <Image.PreviewGroup >
                  <Image width={200} style={{ display: (detailInfo.induction_agreement_appends === '' ? 'flex' : 'none') }} className="no-img" src={noDataImg}></Image>
                </Image.PreviewGroup>
              </div>
            </div>
          </span>
        </Col>
        <Col span={6}>
          <span className="label">意外保险</span>
          <span className="img-list-wrap">
            <Button className="btn" type="primary" ghost={true}>查看</Button>
            <div className="img-list">
              {/* <Image.PreviewGroup>
                <Image
                  width={200}
                  src={detailInfo.accident_insurance_appends}
                />
              </Image.PreviewGroup> */}


              <div style={{ display: (detailInfo.accident_insurance_appends === "" ? "none" : 'block') }}>
                <Image.PreviewGroup >
                  <Image
                    width={200}
                    src={detailInfo.accident_insurance_appends}
                  />
                </Image.PreviewGroup>
              </div>
              <div style={{ display: (detailInfo.accident_insurance_appends === "" ? "block" : 'none') }}>
                <Image.PreviewGroup >
                  <Image style={{ display: (detailInfo.certificate.pics_appends === '' ? 'flex' : 'none') }} className="no-img" src={noDataImg}></Image>
                </Image.PreviewGroup>
              </div>
            </div>

          </span>
        </Col>
        <Col span={6}>
          <span className="label">食品安全承诺书</span>
          <span className="img-list-wrap">
            <Button className="btn" type="primary" ghost={true}>查看</Button>
            <div className="img-list">
              {/* <Image.PreviewGroup>
                <Image
                  width={200}
                  src={detailInfo.responsibility_appends}
                />
              </Image.PreviewGroup> */}

              <div style={{ display: (detailInfo.responsibility_appends === "" ? "none" : 'block') }}>
                <Image.PreviewGroup >
                  <Image
                    width={200}
                    src={detailInfo.responsibility_appends}
                  />
                </Image.PreviewGroup>
              </div>
              <div style={{ display: (detailInfo.responsibility_appends === "" ? "block" : 'none') }}>
                <Image.PreviewGroup >
                  <Image width={200} style={{ display: (detailInfo.responsibility_appends === '' ? 'flex' : 'none') }} className="no-img" src={noDataImg}></Image>
                </Image.PreviewGroup>
              </div>

            </div>
          </span>
        </Col>
        <Col span={6}>
          <span className="label">劳动合同</span>
          <span className="img-list-wrap">
            <Button className="btn" type="primary" ghost={true}>查看</Button>
            <div className="img-list">
              {/* <Image.PreviewGroup>
                <Image
                  width={200}
                  src={detailInfo.contract_img_appends}
                />
              </Image.PreviewGroup> */}

              <div style={{ display: (detailInfo.contract_img_appends === "" ? "none" : 'block') }}>
                <Image.PreviewGroup >
                  <Image
                    width={200}
                    src={detailInfo.contract_img_appends}
                  />
                </Image.PreviewGroup>
              </div>
              <div style={{ display: (detailInfo.contract_img_appends === "" ? "block" : 'none') }}>
                <Image.PreviewGroup >
                  <Image width={200} style={{ display: (detailInfo.contract_img_appends === '' ? 'flex' : 'none') }} className="no-img" src={noDataImg}></Image>
                </Image.PreviewGroup>
              </div>


            </div>
          </span>
        </Col>
      </Row>
    </div >
  )
}