import React from 'react'
import { Breadcrumb, Row, Col } from 'antd';
import { useHistory } from "react-router-dom";
import CanteenList from './canteenList'
import { renderRoutes } from 'react-router-config'
// import {useAsyncMemo} from 'use-async-memo'

export default function CanteenInfo(props: any) {
  let history = useHistory()
  let path = history.location.pathname
  let number = history.location.search.split('=')[1]
  let title = ''
  let isShow = false

  props.route.children.forEach((item: any) => {
    if (item.path === path && item.meta) {
      title = item.meta.title
      isShow = true
    }
  })

  // 返回餐饮基本信息页
  const backUp = () => {
    props.history.push('/canteen/info/index?number=' + number)
  }

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item >
          <span style={{ cursor: 'default' }}>餐饮管理</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          <span style={{ cursor: 'pointer' }} onClick={backUp}>餐饮信息查询</span>
        </Breadcrumb.Item>
        {isShow ? <Breadcrumb.Item >
          <span style={{ cursor: 'default' }}>{title}</span>
        </Breadcrumb.Item> : ''}
      </Breadcrumb>

      <Row gutter={12} className="zg-container" style={{ marginLeft: 0, marginRight: 0, padding: '20px 10px' }}>
        <Col span={5} xxl={4} style={{ height: '100%' }}>
          <CanteenList number={number}></CanteenList>
        </Col>
        <Col span={19} xxl={20} style={{ height: '100%', boxSizing: 'border-box', overflow: 'auto' }}>
          {renderRoutes(props.route.children)}
        </Col>
      </Row>
    </>
  )
};