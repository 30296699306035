import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import routers from './router'
import { renderRoutes } from 'react-router-config'
import Login from '../pages/login/login'
import $storeLocal from "storejs"
class SetRoute extends Component<{}, {}> {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/' exact render={() => (<Redirect to={$storeLocal.get("customPCGrade") === 'city' ? '/board/indexCity' : $storeLocal.get("customPCGrade") === 'district' ? '/board/indexArea' : '/analysis/data-analysis'} />)} />
          <Route path='/login' component={Login}></Route>
          {renderRoutes(routers)}
        </Switch>
      </Router>
    )
  }
}
export default SetRoute