import React from "react";
import "./single.less";
import { Row, Col, Breadcrumb } from "antd";
import FoodSafety from "./component/foodsafety";
import EvalActionably from "./component/evaluationanaly";
import CompletionCatering from "./component/completioncatering";
import EarlyAnalysis from "./component/earlyanalysis";

// 大数据综合预警
export default function RiskSingle() {
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>大数据分析</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/analysis/comprehensive-warning">风险指数</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          padding: "12px 12px 12px 20px",
          overflowY: "auto",
          height: "94%",
        }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <div className="heads" style={{ backgroundColor: "#fff" }}>
              <span>风险指数单日</span>
              <div className="compared">
                <span>时间：</span>
                <span
                  className="spans"
                  style={{ backgroundColor: "#5473E8", color: "#ffffff" }}
                >
                  昨日
                </span>
                <span className="spans">7日内</span>
                <span className="spans">30日内</span>
                <span className="spans">全部</span>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: '12px' }}>
          <Row gutter={[12, 12]}>
            <Col span={4}>
              <FoodSafety></FoodSafety>
            </Col>
            <Col span={6}>
              <EvalActionably></EvalActionably>
            </Col>
            <Col span={14}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <CompletionCatering></CompletionCatering>
                </Col>
                <Col span={24}>
                  <EarlyAnalysis></EarlyAnalysis>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
