import { Breadcrumb, Button, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import '../less/detail.less'
import { contentsInfo } from '../../../apis/information/information'
import { useCallback, useEffect, useState } from "react";


export default function DetailInformation(props: any) {

  let info = {
    title: '',// 标题
    descr: '',//摘要
    content: '',
  }

  const history = useHistory()
  const [id] = useState(props.location.state)
  const [detail, setDetail] = useState(info)
  function backUp() {
    history.goBack()
  }

  const get_detail = useCallback(() => {
    return contentsInfo({ id: id }).then((res) => {
      console.log("sjikj ", res.data)
      setDetail(res.data)
    })
  }, [id])
  useEffect(() => {
    get_detail()
  }, [get_detail])


  return (
    <>
      <Breadcrumb className="zg-bread-nav wrpa">
        <Breadcrumb.Item>
          <span onClick={backUp}>新闻资讯</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          文章详情
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row
            style={{
              fontSize: "20px",
              paddingBottom: "24px",
              borderBottom: "1px solid #E8EBF9",
            }}
          >
            <Col span={22}>文章详情</Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Button
                onClick={backUp}
                type="default"
                style={{
                  border: "1px solid #409EFF",
                  borderRadius: 0,
                  color: "#409EFF",
                }}
              >
                返回
              </Button>
            </Col>
          </Row>
          <Row style={{ padding: "20px 100px" }}>
            <Col span={24} >
              <div className="title_wrpa">湖北疫情</div>
              <div className="summary" style={{ background: 'rgba(199, 199, 199, 0.27)' }}>
                <div className="size">摘要：</div>
                <div className="descr">{detail.descr}</div>
              </div>
              <div className="content">
                <div className="main-wrap" dangerouslySetInnerHTML={{ __html: detail.content }}></div>
                123132
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

// function get_detail() {
//   throw new Error("Function not implemented.");
// }
