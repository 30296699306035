import request from "../utils/request"
/**
 * 获取餐饮单位各个类型数目统计
 */
export function getTypeCanteenCount(data: any) {
  return request({
    url: "/app/canteen/group-type-count",
    method: "get",
    params: data,
  })
}
/**
 * 获取综合安全指数分析排行
 */
export function getAllRank(data: any) {
  return request({
    url: "/app/area/index-day/area-ranking",
    method: "get",
    params: data,
  })
}
/**
 * 获取市级下各区的指数排行
 */
export function getAreaScoreRank(data: any) {
  return request({
    url: "app/area/index-day/index-between",
    method: "get",
    params: data,
  })
}
/**
 * 获取餐饮单位安全指数排行
 */
export function getScoreRank(data: any) {
  return request({
    url: "/app/index-day/index-between",
    method: "get",
    params: data,
  })
}
/**
 * 获取地图上概况统计
 */
export function getMapCount(data: any) {
  return request({
    url: "/app/statistics/canteen-count",
    method: "get",
    params: data,
  })
}
/**
 * 获取供应商数量
 */
export function getSupplierCount(data: any) {
  return request({
    url: "/app/suppliers/count",
    method: "get",
    params: data,
  })
}

/**
 * 获取原料预警统计数据
 */
export function getMaterailStac(data: any) {
  return request({
    url: "/app/data-statistics/warning/index/index",
    method: "get",
    params: data,
  })
}
/**
 * 获取原料预警趋势数据
 */
export function getMaterailTrend(data: any) {
  return request({
    url: "/app/data-statistics/warning/goods/list",
    method: "get",
    params: data,
  })
}
/**
 * 获取视频设备总数
 */
export function getVideoCount(data: any) {
  return request({
    url: "/app/video/config/index",
    method: "get",
    params: data,
  })
}
/**
 * 获取视频高频错误
 */
export function getHighError(data: any) {
  return request({
    url: "/app/statistics/correct-bytype-ex",
    method: "get",
    params: data,
  })
}
/**
 * 获取视频纠偏占比
 */
export function getCorrectStac(data: any) {
  return request({
    url: "/app/data-statistics/daily-index/correct/index",
    method: "get",
    params: data,
  })
}
/**
 * 获取视频纠偏列表数据
 */
export function getCorrectList(data: any) {
  return request({
    url: "/app/video/correct/index",
    method: "get",
    params: data,
  })
}
/**
 * 获取食堂基本信息
 */
export function getCanteenInfo(data: any) {
  return request({
    url: "/app/canteen/info",
    method: "get",
    params: data,
  })
}
/**
 * 获取市级下面的区
 */
export function getAreaList(data: any) {
  return request({
    url: "/app/area/list",
    method: "get",
    params: data,
  })
}
/**
 * 获取市级下风险等级统计
 */
export function getRiskStac(data: any) {
  return request({
    url: "/app/area/warning/index/level-ranking",
    method: "get",
    params: data,
  })
}
/**
 * 获取证件预警统计
 */
export function getCertStac(data: any) {
  return request({
    url: "/app/data-statistics/cert/index/date-between-count",
    method: "get",
    params: data,
  })
}
/**
 * 获取物联统计
 */
export function getIotWarnStac(data: any) {
  return request({
    url: "/app/data-statistics/iot-device/group-state-count",
    method: "get",
    params: data,
  })
}

/**
 * 导出预警管理内的信息
 */
export function getExportIndex(data: any) {
  return request({
    url: "/app/warning/export/index",
    // method: "get",
    params: data,
    method: 'get',
    responseType: 'blob',
    // ...opt
  })
}

// 获取区级的检疫检测数据
export function areaQuarantList(data: any) {
  return request({
    url: "/app/quarantine-inspection/index/top-list",
    method: "get",
    params: data,
  })
}
// 获取市级的检疫检测数据
export function cityQuarantList(data: any) {
  return request({
    url: "/app/area/quarantine-inspection/area-ranking",
    method: "get",
    params: data,
  })
}