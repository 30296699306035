import { Col, Row, Modal } from 'antd';
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import ZgCard from '../../../components/zg-card';
import ZgRow from '../../../components/zg-row';
import ZgTitle from '../../../components/zg-title';
import IconFont from '../../../components/icon-font';
import Member from '../fiveMember/fiveMember'
import { getStacRecord, getStacSuppliers, getStacMember } from '../../../apis/canteen-info/proxy-info'

export default function StacInfo(props: any) {
  let recordInfoTemp = {
    workers_number: 0,
    correct_not_count: 0,
    goods_expired_not_count: 0,
    iot_warning_not_handle_count: 0,
    health_certificate_expired_not_count: 0,
    canteen_cert_expired_count: 0,
    accompany_dinner_count: 0,
    additive_count: 0,
    clean_checks_count: 0,
    disinfections_count: 0,
    environ_disinfections_count: 0,
    leader_inspect_count: 0,
    log_count: 0,
    morning_check_finish_day: 0,
    quarantine_inspection_count: 0,
    safety_checks_count: 0,
    samples_count: 0,
    waste_disposals_count: 0
  }

  let linkTemp = [{
    title: '今日晨检',
    count: 0,
    link: '/canteen/info/morning'
  }, {
    title: '今日留样',
    count: 0,
    link: '/canteen/info/sample'
  }, {
    title: '餐具消毒',
    count: 0,
    link: '/canteen/info/tableware'
  }, {
    title: '检疫检测',
    count: 0,
    link: '/canteen/info/quarentine'
  }, {
    title: '食堂清洁',
    count: 1,
    link: '/canteen/info/clean'
  }, {
    title: '添加剂使用',
    count: 0,
    link: '/canteen/info/addictive'
  }, {
    title: '安全自查',
    count: 0,
    link: '/canteen/info/safe'
  }, {
    title: '环境消毒',
    count: 0,
    link: '/canteen/info/environment'
  }, {
    title: '废弃物处理',
    count: 0,
    link: '/canteen/info/waste'
  }, {
    title: '食堂陪餐',
    count: 0,
    link: '/canteen/info/accompany'
  }, {
    title: '领导视察',
    count: 0,
    link: '/canteen/info/leader'
  }]

  const [memberList, setMemberList] = useState([])
  const [checkList, setCheckList] = useState(linkTemp)
  const [showMember, setMember] = useState(false)
  const [supplierNumber, setSupplier] = useState(0)
  const [recordInfo, setRecordInfo] = useState(recordInfoTemp)

  //获取五员制信息数据
  const to_get_member = () => {
    return getStacMember({ number: props.number, page_size: 999 }).then(res => {
      console.log("=======> 五员",res.data.data)
      setMemberList(res.data.data)
    })
  }

  //获取供应商的个数
  const to_get_supplier = () => {
    getStacSuppliers({ page_size: 1, state: 1, number: props.number }).then(res => {
      setSupplier(res.data.total)
    })
  }

  // 获取食堂日常检查数据
  const to_get_info = () => {
    // let date = moment().subtract(1, 'days').format('YYYY-MM-DD')
    let date = moment().format('YYYY-MM-DD') // 取今日
    return getStacRecord({ date: date, number: props.number }).then(res => {
      props.set_score(parseFloat(res.data.score)) //给父级传递分数值
      // console.log(props.set_score)
      setRecordInfo(res.data)
      //日常巡检跳转路由
      let linkTemp2 = [{
        title: '今日晨检',
        count: res.data.morning_check_finish_day,
        link: '/canteen/info/morning?number=' + props.number
      }, {
        title: '今日留样',
        count: res.data.samples_count,
        link: '/canteen/info/sample?number=' + props.number
      }, {
        title: '餐具消毒',
        count: res.data.disinfections_count,
        link: '/canteen/info/tableware?number=' + props.number
      }, {
        title: '检疫检测',
        count: res.data.quarantine_inspection_count,
        link: '/canteen/info/quarentine?number=' + props.number
      }, {
        title: '食堂清洁',
        count: res.data.clean_checks_count,
        link: '/canteen/info/clean?number=' + props.number
      }, {
        title: '添加剂使用',
        count: res.data.additive_count,
        link: '/canteen/info/addictive?number=' + props.number
      }, {
        title: '安全自查',
        count: res.data.safety_checks_count,
        link: '/canteen/info/safe?number=' + props.number
      }, {
        title: '环境消毒',
        count: res.data.environ_disinfections_count,
        link: '/canteen/info/environment?number=' + props.number
      }, {
        title: '废弃物处理',
        count: res.data.waste_disposals_count,
        link: '/canteen/info/waste?number=' + props.number
      }, {
        title: '食堂陪餐',
        count: res.data.accompany_dinner_count,
        link: '/canteen/info/accompany?number=' + props.number
      }, {
        title: '领导视察',
        count: recordInfo.leader_inspect_count,
        link: '/canteen/info/leader?number=' + props.number
      }]
      setCheckList(linkTemp2)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    if (!props.number) return

    to_get_info()
    to_get_supplier()
    to_get_member()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.number])

  function checkMember() {
    return setMember(true)
  }

  function to_close() {
    return setMember(false)
  }

  let history = useHistory()
  function to_outbound() {
    history.push('/canteen/info/outbound?number=' + props.number)
  }

  function to_store() {
    history.push('/canteen/info/store?number=' + props.number)
  }

  function to_check(link: any) {
    history.push(link)
  }

  return (
    <>
      <ZgCard className="stac-info-wrap">
        <ZgTitle title="基础信息"></ZgTitle>
        <Row gutter={12} className="row-line">
          <Col span={6} className="card-wrap" onClick={() => to_check('/canteen/info/supplier?number=' + props.number)}>
            <ZgRow justify="space-between" className="line1">
              <IconFont type="icongongyingshang1" className="icon" />
              <div>
                <div className="number">{supplierNumber}</div>
                <div className="desc">签约供应商数</div>
              </div>
            </ZgRow>
            <ZgRow justify="space-between" className="line2">
              <span>查看更多</span>
            </ZgRow>
          </Col>
          <Col span={6} className="card-wrap" onClick={() => to_check('/canteen/info/worker?number=' + props.number)}>
            <ZgRow justify="space-between" className="line1">
              <IconFont type="iconzhigongguanli" className="icon" />
              <div>
                <div className="number">{recordInfo.workers_number}</div>
                <div className="desc">从业人员总数</div>
              </div>
            </ZgRow>
            <ZgRow justify="space-between" className="line2">
              <span>查看更多</span>
            </ZgRow>
          </Col>
          <Col span={6} className="card-wrap" onClick={checkMember}>
            <ZgRow justify="space-between" className="line1">
              <IconFont type="iconrenyuan" className="icon" />
              <div>
                <div className="number">五员制</div>
              </div>
            </ZgRow>
            <ZgRow justify="space-between" className="line2">
              <span>查看更多</span>
            </ZgRow>
          </Col>
          <Col span={6} className="card-wrap" onClick={() => to_check('/canteen/info/dayMenu?number=' + props.number)}>
            <ZgRow justify="space-between" className="line1">
              <IconFont type="iconwendang" className="icon" />
              <div>
                <div className="number">今日菜谱</div>
              </div>
            </ZgRow>
            <ZgRow justify="space-between" className="line2">
              <span>查看更多</span>
            </ZgRow>
          </Col>
        </Row>
        <ZgTitle title="日常巡检"></ZgTitle>
        <Row gutter={[12, 12]} className="row-line">
          {
            checkList.map((item, key) => (
              <Col key={key} span={6} xxl={4}>
                <ZgRow onClick={() => to_check(item.link)} justify="space-between" className="cell-item">
                  <span className="text">{item.title}</span>
                  <span className={["tag", item.count > 0 ? "blue" : "yellow"].join(' ')}>{item.count > 0 ? item.count : '无'}</span>
                </ZgRow>
              </Col>
            ))
          }
        </Row>
        <ZgTitle title="采购溯源"></ZgTitle>
        <Row gutter={12} className="row-line">
          <Col span={6} xxl={4}>
            <ZgRow justify="flex-start" className="cell-item" onClick={to_store}>
              <IconFont type="iconruku" className="icon" />
              <span className="text" style={{ marginLeft: '10px' }}>入库台账</span>
            </ZgRow>
          </Col>
          <Col span={6} xxl={4}>
            <ZgRow justify="flex-start" className="cell-item" onClick={to_outbound}>
              <IconFont type="iconchuku1" className="icon" />
              <span className="text" style={{ marginLeft: '10px' }}>出库台账</span>
            </ZgRow>
          </Col>
        </Row>
        <ZgTitle title="预警信息"></ZgTitle>
        <Row gutter={12} className="row-line row-line-warn">
          <Col onClick={() => to_check('/canteen/info/correction?number=' + props.number)}>
            <ZgRow justify="space-between" className="cell-item">
              <ZgRow className="icon-wrap"><IconFont type="iconshipin" style={{ color: '#5372E3' }} className="icon" /></ZgRow>
              <span className="text">视频预警</span>
              {/* <span className="tag">{recordInfo.correct_not_count}</span> */}
            </ZgRow>
          </Col>
          <Col onClick={() => to_check('/canteen/info/earlywarning?number=' + props.number)}>
            <ZgRow justify="space-between" className="cell-item">
              <ZgRow className="icon-wrap icon-wrap-green"><IconFont style={{ color: '#4CCCD4' }} type="iconwulianwang1" className="icon" /></ZgRow>
              <span className="text">物联预警</span>
              {/* <span className="tag">{recordInfo.iot_warning_not_handle_count}</span> */}
            </ZgRow>
          </Col>
          <Col onClick={() => to_check('/canteen/info/rawmaterial?number=' + props.number)}>
            <ZgRow justify="space-between" className="cell-item">
              <ZgRow className="icon-wrap icon-wrap-yellow"><IconFont style={{ color: '#FF9F24' }} type="iconyuanliaoxuqiu" className="icon" /></ZgRow>
              <span className="text">原料预警</span>
              <span className="tag">{recordInfo.goods_expired_not_count}</span>
            </ZgRow>
          </Col>
          <Col onClick={() => to_check('/canteen/info/document?number=' + props.number)} >
            <ZgRow justify="space-between" className="cell-item">
              <ZgRow className="icon-wrap"><IconFont type="iconzhengjian" style={{ color: '#5372E3' }} className="icon" /></ZgRow>
              <span className="text">健康证预警</span>
              <span className="tag">{recordInfo.health_certificate_expired_not_count}</span>
            </ZgRow>
          </Col>
          <Col onClick={() => to_check('/canteen/info/foodDocument?number=' + props.number)} >
            <ZgRow justify="space-between" className="cell-item">
              <ZgRow className="icon-wrap"><IconFont type="iconzhengjian" style={{ color: '#5372E3' }} className="icon" /></ZgRow>
              <span className="text">餐饮证件预警</span>
              <span className="tag">{recordInfo.canteen_cert_expired_count ? recordInfo.canteen_cert_expired_count : 0}</span>
            </ZgRow>
          </Col>
        </Row>
      </ZgCard>
      <Modal
        visible={showMember}
        title="五员制"
        onCancel={to_close}
        footer={null}
        width="400px"
      >
        <Member memberList={memberList}></Member>
      </Modal>
    </ >
  )
}