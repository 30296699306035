import { Button, Col, DatePicker, Input, Modal, Row, Select, Space, message, Table, Image, Tag } from "antd";
import { useCallback, useEffect, useRef, useState } from 'react';
import '../less/table.less'
import StateModal from './stateModal';
import MD5 from '../../../../utils/md5'

import { useHistory } from "react-router-dom"
import { getBusinessList, EditStatus } from '../../../../apis/merchant/massage'
import styles from '../less/index.module.less'

const { RangePicker } = DatePicker
const { Option } = Select
export default function TableData(props: any) {
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  const [showDetail, setShowDetail] = useState(false) //详情弹窗
  const [titleModal, setTitleModal] = useState('') //详情弹窗 title 
  const [val, setVal] = useState({}) // 操作页的数据
  const [businessList, setBusinessList] = useState([])

  /**
  *  当前时间戳（10位）
  */
  const timestamp = () => {
    let outcome = Math.round(new Date().getTime() / 1000).toString();
    return Number(outcome)
  }
  /**
   * 开始结束时间
   */
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  // 商户类型—趣味烧烤2,便捷快餐3,商务酒店7,田园农庄6,
  const [type, setType] = useState('')
  // 商户名 不填为空字符串
  const [searchName, setSearchName] = useState('')
  //	0为禁用1为启用
  const [status, setStatus] = useState('')
  // 量化分级 ABCD
  const [level, setLevel] = useState('')


  // 子组件 -- 
  const stateRef = useRef({
    vals: {
      status: '',
      mer_id: ''
    },
    textArea: ''
  });

  let history = useHistory()

  const get_businessList = useCallback(async () => {
    // signcode 32位 MD5加密
    let sign = MD5('1046' + timestamp() + 'E80AB7149', 32)

    let data = {
      start_time: startTime,
      end_time: endTime,
      type: type,
      name: searchName,
      status: status,
      level: level,
      time: timestamp(),
      app_id: 1046,
      signcode: sign,
      page: currentPage,
      limit: perPage
    }
    const res = await getBusinessList(data)
    if (res.status === 200) {
      setTotalCount(res.data.data.count)
      setBusinessList(res.data.data.list)
      setPerPage(15)
    }

  }, [currentPage, perPage, level, status, searchName, type, startTime, endTime])

  const columns = [
    {
      title: '基本信息',
      dataIndex: 'mer_id',
      width: '25%',
      render: (text: any, record: any, index: any) => (
        <>
          <div key={index}>
            <div className='title-name'>{record.name}</div>
            <div className='title-basic'>
              <div className='title-img'>
                <Image
                  width={60}
                  height={55}
                  src={record.headpic}
                />
              </div>
              <div className='title-info'>
                <div>联系人：{record.account}</div>
                <div>电话：{record.phone} </div>
                <div>地址：{record.address}</div>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      title: '量化分级',
      dataIndex: 'level',
      key: 'level',
      render: (level: string) => (
        <>
          <Tag color={level == 'A' ? '#43CF7C' : level == 'B' ? '#2A82E4' : level == 'C' ? '#FF8D1A' : level == 'D' ? '#FF5733' : '#fff'} key={level} >
            {level} 级
          </Tag >
        </>
      ),
    },
    {
      title: '商户类型',
      dataIndex: 'cat_name',
      key: 'cat_name',
    },
    // {
    //   title: '餐饮监管',
    //   dataIndex: 'idx',
    //   key: 'idx',
    // },
    {
      title: '商户状态',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <>{status == '0' ? <div style={{ color: 'red' }}>已禁用</div> : <div>已启用</div>}</>
      )
    },
    {
      title: '操作',
      dataIndex: 'record',
      key: 'record',
      render: (text: any, record: any, index: any) => (
        <div>
          <Button className="btn" type="primary" style={{ marginRight: '15px' }} onClick={() => checkDetail(record)} ghost={true}>商户详情</Button>
          <Button className="btn" type="primary" onClick={() => showState(record)} ghost={true}>{record.status == '1' ? '禁用' : '启用'}</Button>
        </div>
      )
    },
  ]
  // 点击分页
  const changePage = (val: number) => {
    setCurrentPage(val)
  }
  //商户详情
  const checkDetail = (record: any) => {
    console.log("商户详情", record.mer_id);
    history.push('/merchant/messageDetail?mer_id=' + record.mer_id)
  }

  // 显示操作页
  const showState = (val: any) => {
    console.log(val.status);
    setVal(val);
    if (val.status == '1') setTitleModal('禁用商户')
    if (val.status == '0') setTitleModal('启用商户')
    setShowDetail(true)
  }

  // 关闭操作页
  const to_close = () => {
    setShowDetail(false)
  }

  // Modal 确认按钮
  const handleOk = async () => {
    let user: any = localStorage.getItem('UserBaseInfo')
    // signcode 32位 MD5加密
    let sign = MD5('1046' + timestamp() + 'E80AB7149', 32)
    console.log(stateRef.current);

    let data = {
      time: timestamp(),
      app_id: 1046,
      admin_id: JSON.parse(user).id,
      operationname: JSON.parse(user).username,
      content: stateRef.current.textArea,
      signcode: sign,
      status: stateRef.current.vals.status == '0' ? '1' : '0',
      mer_id: stateRef.current.vals.mer_id
    }
    console.log("data,", data);
    const res = await EditStatus(data)
    if (res.data.code == 200) {
      setShowDetail(false);
      stateRef.current.textArea = ''
      get_businessList()
    }
    else {
      message.warning(res.data.message);

    }
  };
  // Modal 取消按钮
  // const handleCancel = () => {
  //   setShowDetail(false);
  // };

  useEffect(() => {
    get_businessList()
  }, [get_businessList])

  // 入驻日期
  const handDate = (dates: any, dateString: any) => {
    console.log("入驻日期", dates, dateString);
    setStartTime(dateString[0])
    setEndTime(dateString[1])
    setCurrentPage(1)
  }

  // 点击查询功能 
  const search = () => {
    setCurrentPage(1)
  }

  // 商户类型 查询
  const onTenantCorrect = (value: any) => {
    // console.log("商户类型", value);
    setType(value)
    setCurrentPage(1)
  }
  // 商户状态 查询
  const onStateCorrect = (value: any) => {
    // console.log("商户状态", value);
    setStatus(value)
    setCurrentPage(1)
  }

  // 量化分级 查询
  const onGradeCorrect = (value: any) => {
    // console.log("量化分级", value);
    setLevel(value)
    setCurrentPage(1)
  }

  // 输入名称 查询
  const onSearch = (datas: any) => {
    // console.log("输入名称", datas.target.value);
    setSearchName(datas.target.value)
    setCurrentPage(1)
  }

  return (
    <>
      <div className={styles.tableData} style={{ height: '11.5vh', padding: '0 20px 0 20px' }}>
        <div className={styles.tableData_content} style={{ display: 'grid', alignItems: 'center' }}>
          {/* 头部查询功能 */}
          <Row gutter={[10, 8]}>
            <Col className="gutter-row" span={6}>
              <div className="down" style={{ width: "100%" }}>
                <span style={{ width: "20%", marginRight: "20px" }}>商户类型</span>
                <Select
                  placeholder="全部"
                  onChange={onTenantCorrect}
                  style={{ width: "65%" }}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="2">趣味烧烤</Option>
                  <Option value="3">便捷快餐</Option>
                  <Option value="7">商务酒店</Option>
                  <Option value="6">田园农庄</Option>
                </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="down">
                <span style={{ width: "20%", marginRight: "20px" }}>入驻日期</span>
                <Space
                  direction="vertical"
                  size={12}
                  style={{ width: "65%" }}
                >
                  <RangePicker onChange={handDate} style={{ width: '100%' }} />
                </Space>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="down" style={{ width: "100%" }}>
                <span style={{ width: "20%", marginRight: "20px" }}>商户状态</span>
                <Select
                  placeholder="全部"
                  onChange={onStateCorrect}
                  style={{ width: "65%" }}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="0">禁用</Option>
                  <Option value="1">启用</Option>
                </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={6}></Col>
            <Col className="gutter-row" span={6}>
              <div className="down" style={{ width: "100%" }}>
                <span style={{ width: "20%", marginRight: "20px" }}>量化分级</span>
                <Select
                  placeholder="全部"
                  onChange={onGradeCorrect}
                  style={{ width: "65%" }}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="A">A</Option>
                  <Option value="B">B</Option>
                  <Option value="C">C</Option>
                  <Option value="D">D</Option>
                </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="down" style={{ width: "100%" }}>
                <span style={{ width: "20%", marginRight: "20px" }}>商户名称</span>
                <Input placeholder="请输入" style={{ width: '65%' }} onChange={onSearch} />
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <Button className="down" type="primary" onClick={search}  >
                查询
              </Button>
            </Col>
            <Col className="gutter-row" span={6}></Col>
          </Row>
          {/* <Query></Query> */}
        </div>
      </div>


      <div className={styles.tableData} style={{ height: 'calc(88.5vh - 162px)' }}>
        <div className={styles.tableData_content}  >
          {/* 数据表格 */}

          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={businessList}
                rowKey={(record) => record.mer_id}
                scroll={{ y: 'calc(88.5vh - 332px)' }}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              ></Table>
            </Col>
          </Row>
          {/* <TableData></TableData> */}
        </div>
      </div>

      <Modal
        visible={showDetail}
        title={titleModal}
        onCancel={to_close}
        onOk={handleOk}
      >
        <StateModal val={val} onRef={stateRef}></StateModal>
      </Modal>
    </>
  )
}