import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Image } from 'antd'
import ZgRow from '../../../components/zg-row'
import ZgColumn from '../../../components/zg-column'
import ZgInfoItem from '../../../components/zg-info-item'
import { getCanteenInfo } from '../../../apis/canteen-info/list'
import noDataImg from '../../../assets/images/noData.png'
import './detail.less'

export default function CanteenList(props: any) {
  let info = {
    canteen_name: '',
    canteen_number: '',
    type: '',
    unit_name: '',
    customers_number: '',
    area: '',
    province: '',
    city: '',
    district: '',
    address: '',
    trusteeship_name: '',
    engage_type: '',
    level: '',
    workers_number: '',
    first_liable: '',
    specific_liable: '',
    canteen_phone: '',
    http_url_license_image_appends: '',
    http_url_insurance_image_appends: '',
    jh_licence_code: '',
    license_date: '',
    insurance_date: '',
    insurance_number: '',
    is_forever: '0',
    business_licence_number: '',
    business_license_date: '',
    http_url_business_license_image_appends: ''
  }

  const [detailInfo, setDetailInfo] = useState(info)

  // console.log(props.id)
  let to_get_info = useCallback(() => {
    return getCanteenInfo({ number: props.id }).then(res => {
      setDetailInfo(res.data)
    })
  }, [props.id])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  return (
    <div className="detial-wrap">
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <ZgInfoItem title="餐饮名称" content={detailInfo.canteen_name} />
        </Col>
        <Col span={8}>
          <ZgInfoItem title="餐饮编号" content={detailInfo.canteen_number} />
        </Col>
        <Col span={8}>
          <ZgInfoItem
            title="餐饮类型"
            content={
              detailInfo.type === 'school'
                ? '学校/幼儿园食堂'
                : detailInfo.type === 'office'
                ? '机关/企业食堂'
                : detailInfo.type === 'enterprise'
                ? '中央厨房'
                : detailInfo.type === 'sociology'
                ? '大中型餐饮'
                : detailInfo.type === 'cookshop'
                ? '小餐饮'
                : '社会餐饮'
            }
          />
        </Col>
        <Col span={8}>
          <ZgInfoItem title="单位名称" content={detailInfo.unit_name} />
        </Col>
        <Col span={8}>
          <ZgInfoItem title="就餐人数" content={detailInfo.customers_number + '人'} />
        </Col>
        <Col span={8}>
          <ZgInfoItem title="食堂面积" content={detailInfo.area + 'm²'} />
        </Col>
        <Col span={8}>
          <ZgInfoItem
            title="省市区&nbsp;&nbsp;&nbsp;&nbsp;"
            content={detailInfo.province + detailInfo.city + detailInfo.district}
          />
        </Col>
        <Col span={8}>
          <ZgInfoItem title="详细地址" content={detailInfo.address} />
        </Col>
      </Row>
      <div className="line"></div>
      <div className="title-level-second">托管详情</div>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <ZgInfoItem title="托管公司&nbsp;&nbsp;&nbsp;&nbsp;" content={detailInfo.trusteeship_name} />
        </Col>
        <Col span={6}>
          <ZgInfoItem title="经营类型&nbsp;&nbsp;&nbsp;&nbsp;" content={detailInfo.engage_type} />
        </Col>
        <Col span={6}>
          <ZgInfoItem title="量化分级" content={detailInfo.level} />
        </Col>
        <Col span={4}>
          <ZgInfoItem title="从业人员" content={detailInfo.workers_number + '人'} />
        </Col>
        <Col span={8}>
          <ZgInfoItem title="第一责任人" content={detailInfo.first_liable} />
        </Col>
        <Col span={6}>
          <ZgInfoItem title="具体责任人" content={detailInfo.specific_liable} />
        </Col>
        <Col span={6}>
          <ZgInfoItem title="联系电话" content={detailInfo.canteen_phone} />
        </Col>
      </Row>
      <div className="line" />
      <ZgRow justify="flex-start" className="card-info">
        {detailInfo.business_licence_number ? (
          <div className="left-item" style={{ display: detailInfo.business_licence_number === '' ? 'none' : 'block' }}>
            <div className="title-level-second">营业执照</div>
            <Image
              style={{ display: detailInfo.http_url_business_license_image_appends === '' ? 'none' : 'block' }}
              className="img"
              src={detailInfo.http_url_business_license_image_appends}
              alt=""
            />
            <ZgColumn
              className="none-wrap"
              style={{ display: detailInfo.http_url_business_license_image_appends === '' ? 'flex' : 'none' }}
            >
              <Image className="no-img" preview={false} src={noDataImg} />
              暂无图片
            </ZgColumn>
            <div className="desc">证件编号： {detailInfo.business_licence_number}</div>
            {detailInfo.is_forever === '0' ? (
              <div className="desc">证件有效期至： {detailInfo.business_license_date}</div>
            ) : (
              <div className="desc">证件有效期至： 永久有效</div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="left-item">
          <div className="title-level-second">食品经营许可证</div>
          <Image
            style={{ display: detailInfo.http_url_license_image_appends === '' ? 'none' : 'block' }}
            className="img"
            src={detailInfo.http_url_license_image_appends}
            alt=""
          />
          <ZgColumn
            className="none-wrap"
            style={{ display: detailInfo.http_url_license_image_appends === '' ? 'flex' : 'none' }}
          >
            <Image className="no-img" preview={false} src={noDataImg} />
            暂无图片
          </ZgColumn>
          <div className="desc">证件编号： {detailInfo.jh_licence_code || '暂无'}</div>
          <div className="desc">证件有效期至： {detailInfo.license_date.substring(0, 10)}</div>
        </div>
        <div className="right-item">
          <div className="title-level-second">食品安全责任险</div>
          <Image
            style={{ display: detailInfo.http_url_insurance_image_appends === '' ? 'none' : 'block' }}
            className="img"
            src={detailInfo.http_url_insurance_image_appends}
            alt=""
          />
          <ZgColumn
            className="none-wrap"
            style={{ display: detailInfo.http_url_insurance_image_appends === '' ? 'flex' : 'none' }}
          >
            <Image className="no-img" preview={false} src={noDataImg} />
            暂无图片
          </ZgColumn>
          <div className="desc">保险单号 {detailInfo.insurance_number}</div>
          <div className="desc">证件有效期至： {detailInfo.insurance_date.substring(0, 10)}</div>
        </div>
      </ZgRow>
    </div>
  )
}
