import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Image } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getLeaderList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;

export default function LeaderList() {
  const [timeList, setTimeList] = useState('')//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数

  function time_change(data: any) {
    let nu = "";
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList(nu)
    }
  }
  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getLeaderList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let columns = [
    {
      title: "序号",
      dataIndex: "idx",
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    // {
    //   title: "视察日期",
    //   dataIndex: "inspect_date",
    //   key: "inspect_date",
    // },
    {
      title: "视察日期",
      dataIndex: "created_at",
      key: "created_at",
      width:'12%'
    },
    {
      title: "视察类型",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "视察人",
      dataIndex: "leader",
      key: "leader",
    },
    {
      title: "视察人职务",
      dataIndex: "leader_post",
      key: "leader_post",
    },
    {
      title: "陪查人",
      dataIndex: "companions",
      key: "companions",
    },
    {
      title: "视察图片",
      dataIndex: "pics_appends",
      render: (pics_appends: any) => (
        pics_appends !== "" ? <Image src={pics_appends.split(",")[0]} alt="" style={{ width: '48px', height: '42px' }} /> : "无"
      ),
    },
    {
      title: "视察结果",
      dataIndex: "result",
      key: "result",
    },
    {
      title: "评价",
      dataIndex: "evaluate",
      key: "evaluate",
      width:'14%'
    },
    {
      title: "记录人",
      dataIndex: "record_user_name",
      key: "record_user_name",
    }
  ]

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">领导视察</div>
        <Row style={{ marginBottom: "24px" }}>
          <ZgRow>
            <span>视察日期：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: "16px" }}>
              查询
            </Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}
