import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getWasteList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;

export default function StoreList() {
  const [timeList, setTimeList] = useState('')//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数

  function time_change(data: any) {
    let nu = "";
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList(nu)
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getWasteList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let columns = [
    {
      title: '序号',
      dataIndex: 'id',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '处理时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '废弃物数量',
      // dataList
      render: (text: any, dataList: any, index: number) => {
        const unit = dataList.unit ? dataList.unit : '桶'
        return dataList.amount + unit
      }
    },
    {
      title: '存放地点',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '发放人',
      dataIndex: 'record_username',
      key: 'record_username',
    },
    {
      title: '接收单位',
      dataIndex: 'handle_company',
      key: 'handle_company',
    },
    {
      title: '接收人',
      dataIndex: 'handle_username',
      key: 'handle_username',
    },
    {
      title: '接受资质',
      dataIndex: 'handle_qualified',
      key: 'handle_qualified',

    }, {
      title: '处置方式',
      dataIndex: 'handle_mode',
      key: 'handle_mode',
    }, {
      title: '处置场地',
      dataIndex: 'handle_address',
      key: 'handle_address',

    }
  ];

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">废弃物处理</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>处理时间 :</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}