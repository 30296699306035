import React, { useCallback, useState, useEffect } from 'react'
import ZgTitle from "../../../../components/zg-title"
import ZgCard from "../../../../components/zg-card"
import ZgRow from "../../../../components/zg-row"
import IconFont from 'components/icon-font'
import { yesterdayAnalysisDataCount } from "../../../../apis/intelligence-rectify/intelligence-rectify"

export default function EarlyAnalysis(props: any) {
  const [data, setData] = useState([])
  const get_data = useCallback(
    () => {
      let dataList: any = []
      return yesterdayAnalysisDataCount({ canteen_number: props.canteenNumber, sdate: props.sdate, edate: props.edate }).then(res => {
        if (res.status === 200) {
          dataList.push({ name: '视频预警', yNum: res.data.video_correct_count, noNum: res.data.video_correct_waitCount })
          dataList.push({ name: '物联预警', yNum: res.data.iot_count, noNum: res.data.iot_wait_count })
          dataList.push({ name: '原料预警', yNum: res.data.goods_count, noNum: res.data.goods_wait_count })
          dataList.push({ name: '健康证预警', yNum: res.data.cert_count, noNum: res.data.cert_wait_count })
          setData(dataList)
        } else {
          console.warn("safeIndexDayInfo 请求出错 ===>", res)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate],
  )
  useEffect(() => {
    if (props.canteenNumber !== '') {
      get_data()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_data])
  return (
    <ZgCard className="early-any-wrap">
      <ZgRow justify="space-between" align="flex-start">
        <ZgTitle title="预警分析数据"></ZgTitle>
      </ZgRow>
      <div className='early-any-box'>
        {
          data.map((item: any, index: any) => {
            return <div className="correctionflex" key={index}>
              {/* <div className="orrectionfize">{item.name}</div>
                  <div style={{display:"flex"}}>
                    <div className="addfize">昨日新增 <IconFont type="iconshangsheng" style={{ color: "#4CCCD4", fontSize: "16px" }} /><div>{item.yNum}</div></div>
                    <div className="addfize">待处理<div>{item.noNum}</div></div>
                  </div> */}

              <div style={{ width: '100%', display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="orrectionfize">{item.name}</div>
                <div className="addfize">昨日新增 <IconFont type="iconshangsheng" style={{ color: "#4CCCD4", fontSize: "16px" }} /><span>{item.yNum}</span></div>
                <div className="addfize">待处理<span>{item.noNum}</span></div>
              </div>
            </div>
          })
        }
      </div>
    </ZgCard>
  );
}