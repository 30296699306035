import React, { useState } from 'react'
import ZgRow from './zg-row';
import '../style/zgTab.less'
export default function ZgTab(props: any) {
  const [activeIdx, setIdx] = useState(0)
  function to_set(idx: any) {
    props.onClick(idx)
    return setIdx(idx)
  }
  let itemList = props.list.map((item: any, idx: any) => (
    <span onClick={() => to_set(idx)} className={["item", idx === activeIdx ? "active" : ""].join(' ')} key={idx}>{item}</span>
  ))
  return (
    <>
      <ZgRow justify='flex-start'>
        {itemList}
      </ZgRow>
    </>
  )
}