import React, { useState, useCallback, useEffect } from "react"
import moment from "moment"
import {
  Breadcrumb,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Upload,
  Input,
  Form,
  Modal,
  message,
} from "antd"
import { PlusOutlined } from "@ant-design/icons"
import "../../../style/inspection.less"
import { uploadImg } from "../../../apis/smart-video/index"
import {
  addSuperviseTask,
  superviseCanteenList,
} from "../../../apis/inspection-check/inspection"
import lrz from "lrz"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import $storeLocal from "storejs"

export default function SuperviseAdd() {
  let history = useHistory()

  const { TextArea } = Input
  const { Option } = Select
  const [previewVisible, setPreviewVis] = useState(false)
  const [previewImage, setPreviewImg] = useState("")
  const [fileList1, setFileList1] = useState<Array<any>>([])
  const [img1, setImg1] = useState<Array<any>>([])
  const [canteen_list, setCanteen] = useState<Array<any>>([])
  const [previewTitle, setPreviewTitle] = useState("")
  const [unitName, setUnitName] = useState([])

  const [taskId, setTaskId] = useState([])
  let userInfo = $storeLocal.get("UserBaseInfo")

  const realname =
    userInfo.realname == null || userInfo.realname === ""
      ? userInfo.username
      : userInfo.realname

  //提交数据
  function to_submit(values: any) {
    let time = dayjs(values.time).format("YYYY-MM-DD HH:mm")

    let arr1 = img1.map((item: any) => item.url)
    unitName.forEach((item, index) => {
      if (item === undefined || item === null || item === "") {
        message.error("缺少单位名称")
        return false
      }
    })

    let rqs: any = {
      user_name: values.realname,
      unit_name: unitName,
      title: values.title,
      tasks_id: taskId,
      explain: values.desc,
      problem_images: arr1.join(","),
      supervise_time: time,
    }

    addSuperviseTask(rqs).then((res: any) => {
      if (res.status === 200) {
        message.info("提交成功")
        history.go(-1)
      }
    })
  }
  function handleCancel() {
    setPreviewVis(false)
  }

  function handlePreview(file: any) {
    let im: any = file.url || file.preview
    setPreviewImg(im)
    setPreviewVis(true)
    setPreviewTitle("预览图片")
  }
  // 删除图片
  function to_remove1(file: any) {
    const idx: any = fileList1.indexOf(file)
    const newFileList = fileList1.slice()
    newFileList.splice(idx, 1)
    setFileList1(newFileList)

    img1.forEach((element, index) => {
      if (file.url === element.url) {
        img1.splice(index)
      }
    })
    setImg1(img1)
  }
  let files: any = []
  // 上传之前控制
  function beforeUpload1(file: any) {
    const isLt2M = file.size / 1024 / 1000 < 1
    let upfile: any = file.file
    if (!isLt2M) {
      lrz(file.file, { quality: 0.8 }).then((rst: any) => {
        // 处理成功会执行
        upfile = rst
      })
    }
    let formData = new FormData()
    formData.append("file", upfile)
    uploadImg(formData).then((res: any) => {
      let data: any = {
        url: res.data.absolute_path,
        imgUrl: res.data.path,
        uid: file.file.uid,
        status: "done",
        name: file.file.name,
      }

      let data1 = []
      data1.push(data)
      files = [...data1, ...files]
      if (fileList1.length > 0) {
        setFileList1([...files, ...fileList1])
        setImg1([...files, ...fileList1])
      } else {
        setFileList1(files)
        setImg1(files)
      }
    })
  }
  function to_cancel() {
    history.go(-1)
  }
  function chose_canteen(val: any, item: any) {
    let names: any = []
    item.forEach((element: any) => {
      names.push(element.name)
    })

    setTaskId(val)
    setUnitName(names)
  }

  function get_time(value: any) {
    console.log("onOk: ", value)
  }
  function onBlur() { }

  function onFocus() { }

  function onSearch(val: any) { }
  //获取食堂列表
  const get_canteen = useCallback(() => {
    return superviseCanteenList({}).then((res) => {
      setCanteen(res.data)
    })
  }, [])
  useEffect(() => {
    get_canteen()
  }, [get_canteen])
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  )
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管巡检</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/supervise/list">监管督办</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row style={{ fontSize: "20px", marginBottom: "10px" }}>
            <Col span={4}>新增监管督办</Col>
          </Row>
          <div className="title title1">基本信息</div>
          <Form onFinish={to_submit}>
            <Row gutter={90}>
              <Col span={8}>
                <Form.Item
                  name="title"
                  label="主题"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={90}>
              <Col span={8}>
                <Form.Item
                  name="unit_name"
                  label="区域单位"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="children"
                    placeholder="请选择"
                    onChange={chose_canteen}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    allowClear
                    showSearch
                  >
                    {canteen_list.map((item: any) => (
                      <Option
                        value={item.id}
                        key={item.id}
                        name={item.unit_name}
                      >
                        {item.unit_name}({item.realname})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={90}>
              <Col span={8}>
                <Form.Item
                  name="realname"
                  label="督办人员"
                  initialValue={realname}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="time"
                  label="督办时间"
                  rules={[{ required: true }]}
                  initialValue={moment(new Date(), "YYYY-MM-DD HH:mm")}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ format: "HH:mm" }}
                    onOk={get_time}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="inspectionimg"
              label="督办任务"
              labelCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Upload
                listType="picture-card"
                action="2"
                fileList={fileList1}
                customRequest={beforeUpload1}
                onRemove={to_remove1}
                onPreview={handlePreview}
                multiple
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            {/* <div className="title">巡检任务</div> */}
            <div className="tip tip1">任务说明：</div>
            <Row gutter={90}>
              <Col span={16}>
                <Form.Item name="desc">
                  <TextArea placeholder="请输入" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            {/* <div className="tip">图片：</div>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              action="2"
              fileList={fileList2}
              customRequest={beforeUpload2}
              onRemove={to_remove2}
              multiple
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>
            </Upload> */}
            <Form.Item className="btn-list">
              <Button type="primary" htmlType="submit" className="btn1">
                提交任务
              </Button>
              <Button
                htmlType="button"
                onClick={to_cancel}
                className="cancel-btn"
              >
                取消
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  )
}
