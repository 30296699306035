import React from 'react'
import { Col, Row } from 'antd';
import ZgCard from '../../../components/zg-card';
import ZgRow from '../../../components/zg-row';
import IconFont from '../../../components/icon-font';
import { useHistory } from 'react-router-dom';
export default function Video(props: any) {
  let history = useHistory()

  // 前往视频页
  function to_video() {
    history.push('/smartVideo/list', {
      number: props.number
    })
  }

  // 前往设备页
  function to_device() {
    history.push('/equipment-iot/list', {
      number: props.number
    })
  }

  return (
    <div className="video-wrap">
      <ZgCard>
        <div className="title-level-first">明厨亮灶</div>
        <Row gutter={12} className="row-line">
          <Col span={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ZgRow onClick={to_video} justify="flex-start" className="cell-item" style={{ marginRight: '10px' }}>
              <IconFont className="icon" type="iconshexiangtou" />
              <span className="text">实时监控</span>
            </ZgRow>
          </Col>
          <Col span={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ZgRow onClick={to_device} justify="flex-start" className="cell-item">
              <IconFont className="icon" type="iconwulianwang" />
              <span className="text">物联监测</span>
            </ZgRow>
          </Col>
        </Row>
      </ZgCard>
    </div>
  )
}