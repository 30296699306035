import React, { useEffect, useState, useCallback } from 'react'
import ZgTitle from "../../../components/zg-title";
import ZgRow from "../../../components/zg-row";
import styles from './index.module.css'
import echarts from 'echarts'
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { deviceWarnType } from '../../../apis/analysis/warn'
import { warnTrend } from '../../../apis/analysis/warn'
import { useHistory } from "react-router-dom"
import 'swiper/swiper.min.css';
SwiperCore.use([Autoplay]);
export default function RightSide(props: any) {
  let history = useHistory()
  // 渲染图表
  const renderChart = (data: any) => {
    let labels: any = []
    let counts: any = []
    data.forEach((item: any, index: any) => {
      labels.push(item.name)
      counts.push(item.value)
    })
    var ele: any = document.getElementById('warnType')
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        bottom: 0,
        left: 'center',
        textStyle: { color: '#000' },
        formatter: function (name) {
          var index = 0;
          var clientlabels = labels;
          var clientcounts = counts;
          clientlabels.forEach(function (value: any, i: any) {
            if (value === name) {
              index = i;
            }
          });
          return name + " " + clientcounts[index];
        }
      },
      color: ['#1eb38b', '#3582fe', '#dc8320', '#a5a5a5'],
      series: [
        {
          name: '异常设备预警类型',
          type: 'pie',
          radius: ['20%', '60%'],
          center: ['50%', '35%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'inner'
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'inner',
                formatter: '{d}%'
              },
              labelLine: {
                show: false,
              }
            }
          },
          data: data
        }
      ]
    });
  }
  let list: any = [{ type: '---', state: 0, name: '---' }]
  const [warnList, setWarnList] = useState(list)
  const warnEle = warnList.map((item: any, index: any) => (
    <SwiperSlide className={styles.warnItem} key={index}>
      <div className={styles.warnLeft}>
        <span className={styles.warnType}>{item.type}</span>
        <span className={styles.warnName}>{item.name}</span>
      </div>
      <div className={styles.warnRight}>
        {item.state === 1 ?
          <span className={styles.warnColor}>待处理</span> :
          item.state === 2 ? <span>已处理</span> :
            <span>---</span>}
      </div>
    </SwiperSlide>
  ))
  const to_more = () => {
    history.push('/lotEarly/list')
  }
  const get_type = useCallback(() => {
    return deviceWarnType({ area_id: props.id }).then((res: any) => {
      let data: any = [
        { name: '温度过高', value: res.data.high_amount === '' ? 0 : res.data.high_amount },
        { name: '设备离线', value: res.data.offline_amount === '' ? 0 : res.data.offline_amount },
        { name: '温度过低', value: res.data.low_amount === '' ? 0 : res.data.low_amount },
        { name: '湿度过高', value: res.data.hum_high_amount === '' ? 0 : res.data.hum_high_amount }
      ]
      renderChart(data)
    })
  }, [props.id])
  //异常预警动态
  const get_list = useCallback(() => {
    return warnTrend({ area_id: props.id }).then((res: any) => {
      let ls: any = []
      res.data.dynamic.forEach((item: any) => {
        ls.push({ type: item.title, state: (item.handle_date ? 2 : 1), name: item.canteen.canteen_name })
      })
      setWarnList(ls)
    })
  }, [props.id])
  useEffect(() => {
    get_type()
    get_list()
  }, [get_type, get_list])
  return (
    <div className={styles.rightSide}>
      <div className={styles.box}>
        <ZgTitle title="异常设备预警类型"></ZgTitle>
        <div className={styles.rightChart}>
          <div id="warnType" style={{ width: '100%', height: '100%' }}></div>
        </div>
      </div>
      <div className={styles.box + ' ' + styles.warnList}>
        <ZgRow justify="space-between" align="center">
          <ZgTitle title="异常预警动态"></ZgTitle>
          <span style={{ color: '#999', marginBottom: 20, cursor: 'pointer' }} onClick={to_more}>查看更多</span>
        </ZgRow>
        <Swiper
          initialSlide={0}   //初始化时的索引位置
          speed={1000} //页面切换速度，slider自动滑动开始到结束的时间（单位ms）
          loop={true} //是否形成环路
          spaceBetween={0} //页面直接的间距
          slidesPerView={12} //设置slider容器能够同时显示的slides数量(carousel模式)。
          direction='vertical'
          style={{ height: 416 }}
          //自动滚屏
          autoplay={{
            delay: 2000, //自动滚屏速度
            disableOnInteraction: false,  //false: 鼠标操作屏幕后继续自动滚屏
          }}
        >
          {warnEle}
        </Swiper>
      </div>
    </div>
  )
}