import React, { useEffect, useCallback } from 'react'
import ZgTitle from "../../../components/zg-title";
import styles from './index.module.css'
import ZgRow from "../../../components/zg-row";
import echarts from 'echarts'
import { warnTrend, deviceWarnType } from '../../../apis/analysis/warn'
export default function Center(props: any) {
  //物联预警总数
  const IOTWarnStac = useCallback(() => {
    return deviceWarnType({ area_id: props.id }).then((res: any) => {
      let ls1: any = [{ name: '在线', value: res.data.online_amount }, { name: '离线', value: res.data.offline_amount }]
      let ls2: any = [{ name: '正常', value: res.data.normal_amount }, { name: '异常', value: res.data.abnormal_amount }]
      renderDeviceChart1(ls1)
      renderDeviceChart2(ls2)
    })
  }, [props.id])
  // 渲染物联图表1
  const renderDeviceChart1 = (data: any) => {
    let labels: any = []
    let counts: any = []
    let chartData: any = []
    data.forEach((item: any, index: any) => {
      labels.push(item.name)
      counts.push(item.value)
      chartData.push({ value: item.value, name: item.name })
    })
    var ele: any = document.getElementById('deviceChart1')
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        bottom: 0,
        left: 'center',
        textStyle: { color: '#000' },
        formatter: function (name) {
          var index = 0;
          var clientlabels = ['在线', '离线', '停用'];
          var clientcounts = counts;
          clientlabels.forEach(function (value, i) {
            if (value === name) {
              index = i;
            }
          });
          return name + "  " + clientcounts[index];
        }
      },
      color: ['#1EB38B', '#E3C60B', '#8F8F8F'],
      series: [
        {
          name: '设备占比状态',
          type: 'pie',
          center: ['50%', '35%'],
          radius: '60%',
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'inner'
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inner'

              },
              labelLine: {
                show: false,
              }
            }
          },
          data: chartData
        }
      ]
    });
  }
  // 渲染物联图表2
  const renderDeviceChart2 = (data: any) => {
    let labels: any = []
    let counts: any = []
    let chartData: any = []
    data.forEach((item: any, index: any) => {
      labels.push(item.name)
      counts.push(item.value)
      chartData.push({ value: item.value, name: item.name })
    })
    var ele: any = document.getElementById('deviceChart2')
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        bottom: 0,
        left: 'center',
        textStyle: { color: '#000' },
        formatter: function (name) {
          var index = 0;
          var clientlabels = labels;
          var clientcounts = counts;
          clientlabels.forEach(function (value: any, i: any) {
            if (value === name) {
              index = i;
            }
          });
          return name + "  " + clientcounts[index];
        }
      },
      color: ['#1EB38B', '#E84D4F'],
      series: [
        {
          name: '设备正常异常占比',
          type: 'pie',
          radius: '60%',
          center: ['50%', '35%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'inner'
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inner'

              },
              labelLine: {
                show: false,
              }
            }
          },
          data: chartData
        }
      ]
    });
  }
  //趋势图
  const renderTrend = (data: any) => {
    let dates: any = []
    let datas: any = []
    data.forEach((item: any, index: any) => {
      let str: any = item.date.substr(5)
      dates.push(str + '时')
      datas.push(item.value)
    })
    var ele: any = document.getElementById('trendChart')
    var myChart = echarts.init(ele);
    let getLinearColor = (color1: any, color2: any): any => {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: color1 },
        { offset: 1, color: color2 }
      ])
    }
    // 绘制图表
    myChart.setOption({
      xAxis: {
        type: 'category',
        data: dates
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: datas,
        type: 'line',
        smooth: true,
        showSymbol: true,
        symbol: 'diamond',
        itemStyle: {
          color: '#51a4ff'
        },
        lineStyle: {
          width: 0
        },
        areaStyle: {
          color: getLinearColor('#0079ff', 'white')
        }
      }]
    });
  }
  const get_trend = useCallback(() => {
    return warnTrend({ area_id: props.id }).then((res: any) => {
      let trends: any = []
      res.data.list.forEach((item: any) => {
        trends.push({ value: item.count, date: item.hours })
      })
      renderTrend(trends)
    })
  }, [props.id])
  useEffect(() => {
    IOTWarnStac()
    get_trend()
  }, [get_trend, IOTWarnStac])
  return (
    <div className={styles.centerBox}>
      <ZgRow justify="space-between">
        <div className={styles.box + ' ' + styles.deviceChartList}>
          <ZgTitle title="设备在线离线占比"></ZgTitle>
          <div className={styles.deviceChart1}>
            <div id="deviceChart1" style={{ width: '100%', height: '100%' }}></div>
          </div>
        </div>
        <div className={styles.box + ' ' + styles.deviceChartList}>
          <ZgTitle title="设备正常异常占比"></ZgTitle>
          <div className={styles.deviceChart2}>
            <div id="deviceChart2" style={{ width: '100%', height: '100%' }}></div>
          </div>
        </div>
      </ZgRow>
      <div className={styles.box + ' ' + styles.trendBox}>
        <ZgRow justify="flex-start">
          <ZgTitle title="24小时内设备异常预警趋势图"></ZgTitle>
          <span style={{ marginBottom: 20, marginLeft: 5, color: '#999' }}>(时间区间内新增预警数量)</span>
        </ZgRow>
        <div className={styles.trendChart}>
          <div id="trendChart" style={{ width: '100%', height: '100%' }}></div>
        </div>
      </div>
    </div>
  )
}