import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button , Image} from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getEnvironList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;

  export default function EnviList() {
    const [timeList, setTimeList] = useState('')//时间数组
    const [dataList, setList] = useState([])//列表数据
    const [currentPage, setCurrentPage] = useState(1)//设置当前页数
    const [totalCount, setTotalCount] = useState(0)  //总数
    const [perPage, setPerPage] = useState(15)  //每页条数
    function time_change(data: any) {
      let nu = "";
      if (data) {
        let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
        setTimeList(ls)
      }else{
        setTimeList(nu)
      }
    }
    // 获取数据列表
    const to_get_list = useCallback(() => {
      return getEnvironList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
        setTotalCount(res.data.total)
        setList(res.data.data)
        setPerPage(res.data.per_page)
      })
    }, [currentPage, timeList])
    function changePage(val: number) {
      setCurrentPage(val)
    }
    // hook函数
    useEffect(() => {
      to_get_list()
    }, [to_get_list])


  let columns = [
    {
      title: '序号',
      dataIndex: 'id',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '消毒时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width:'15%'
    },
    {
      title: '操作人',
      dataIndex: 'handle_name',
      key: 'handle_name',
    },
    {
      title: '消毒区域',
      dataIndex: 'room',
      key: 'room',
      width:'20%'
    },
    {
      title: '消毒方式',
      dataIndex: 'type',
      key: 'type',
      width:'20%'
    },
    {
      title: '消毒时长',
      dataIndex: 'operation_time',
      render: (text: any) => (
        <div>{text}分钟</div>
      )
    },
    {
      title: '消毒照片',
      dataIndex: 'pics_appends',
      render: (pics_appends:any) => (
        pics_appends!=="" ? <Image src={pics_appends.split(",")[0]} alt="" style={{width:'48px',height:'42px'}}/>: "无"
      ),
      width: '10%'
    }
  ];
 
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">环境消毒</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>消毒时间 :</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }}>搜索</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
          <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}