import React, { useState } from 'react'
import ZgRow from '../../../components/zg-row';
import '../../../style/zgTab.less'
export default function ZgTab(props: any) {
  const [activeIdx, setIdx] = useState(0)
  function to_set(id: any) {
    props.onClick(id)
    return setIdx(id)
  }
  let itemList = props.list.map((item: any) => (
    <span onClick={() => to_set(item.id)} className={["item", item.id === activeIdx ? "active" : ""].join(' ')} key={item.id}>{item.name}</span>
  ))
  return (
    <>
      <ZgRow justify='flex-start'>
        {itemList}
      </ZgRow>
    </>
  )
}