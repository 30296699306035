import React, { useState, useEffect, useCallback } from 'react'
import { Col, Row, Image } from 'antd';
import ZgRow from '../../../components/zg-row';
import ZgInfoItem from '../../../components/zg-info-item';
import productImg from '../../../assets/images/noData.png'
import { getGoodsInfo, geSourceRecord } from '../../../apis/canteen-info/proxy-info'
import ZgColumn from '../../../components/zg-column';
import './list.less'
import IconFont from 'components/icon-font';
import { noimgBase64 } from 'utils/common';

// 显示图片
function ShowPics(props: any) {
  const info = props.row.info
  const picStr = info.inspection_report_pic_appends as string
  let picArr = new Array<string>()
  if (picStr) {
    picArr = picStr.split(',')
  }
  if (picArr.length > 0) {
    return <div>{
      picArr.map((v, i) => {
        return <Image src={v} style={{ marginRight: '5px' }} fallback={noimgBase64} />
      })
    }</div>
  } else {
    return <Image src={noimgBase64} />
  }
}

export default function Detail(props: any) {
  let info = {
    name: '',
    thumbs_appends: '',
    goods_basic: {
      type: { name: '' },
      shelf_life: ''
    },
    production_date: '',
    supplier: {
      name: '',
      phone: '',
    },
    info: { inspection_report_pic_appends: '' },
    manufacturer: '', // 生产厂家
  }

  let sourceTemp = [{
    type: 1,
    date: '2020-10-08 10:23:12',
    content: '',
    title: '',
    username: ''
  }]

  const [detailInfo, setDetailInfo] = useState(info)
  const [sourceInfo, setSourceInfo] = useState(sourceTemp)

  let to_get_info = useCallback(() => {
    return getGoodsInfo({ number: props.number }).then(res => {
      // res.data.thumbs_appends.split(',')[0]
      setDetailInfo(res.data)
    })
  }, [props.number])

  let to_get_source = useCallback(() => {
    return geSourceRecord({ number: props.number }).then(res => {
      setSourceInfo(res.data)
    })
  }, [props.number])

  useEffect(() => {
    to_get_info()
    to_get_source()
  }, [to_get_info, to_get_source])

  return (
    <div className="detail-wrap">
      <Row gutter={24}>
        <Col span={4}>
          <ZgRow justify="flex-start" className="card-info1">
            <div className="card-info2" style={{ display: (detailInfo.thumbs_appends === "" ? 'none' : 'block') }}>
              <Image className="imgs" src={detailInfo.thumbs_appends.split(',')[0]} />
            </div>
            <ZgColumn className="none-wrap" style={{ display: (detailInfo.thumbs_appends === "" ? 'flex' : 'none') }}>
              <img className="no-img" src={productImg} alt=""/>
              <div style={{ marginBottom: '5px' }}>暂无图片</div>
            </ZgColumn></ZgRow>
          {/* thumbs_appends */}
        </Col>
        <Col span={20}>
          <Row gutter={[12, 12]}>
            <Col span={12}><ZgInfoItem title="商品名称&nbsp;&nbsp;&nbsp;&nbsp;" content={detailInfo.name === "" ? "无" : detailInfo.name}></ZgInfoItem></Col>
            <Col span={12}><ZgInfoItem title="商品类型" content={detailInfo.goods_basic.type.name === "" ? "无" : detailInfo.goods_basic.type.name}></ZgInfoItem></Col>
            <Col span={12}><ZgInfoItem title="生产日期&nbsp;&nbsp;&nbsp;&nbsp;" content={detailInfo.production_date === "" ? "无" : detailInfo.production_date}></ZgInfoItem></Col>
            <Col span={12}><ZgInfoItem title="保质期&nbsp;&nbsp;&nbsp;&nbsp;" content={detailInfo.goods_basic.shelf_life === "" ? "无" : detailInfo.goods_basic.shelf_life}></ZgInfoItem></Col>
            <Col span={12}><ZgInfoItem title="供应商名称" content={detailInfo.supplier ? detailInfo.supplier.name : "无"}></ZgInfoItem></Col>
            <Col span={12}><ZgInfoItem title="生产厂家" content={detailInfo.manufacturer ? detailInfo.manufacturer : "无"}></ZgInfoItem></Col>
            <Col span={12}><ZgInfoItem title="联系方式" content={detailInfo.supplier ? detailInfo.supplier.phone : "无"}></ZgInfoItem></Col>
            <Col span={24}>
              <ZgInfoItem title="质检报告&nbsp;&nbsp;&nbsp;&nbsp;" content={detailInfo.info.inspection_report_pic_appends === "" ? "无" : ""}></ZgInfoItem>
              {/* //质检报告
              <Image className="report-img" src={detailInfo.info.inspection_report_pic_appends} /> */}
              <ZgRow justify="flex-start" className="card-info1">
                <div className="ant-image1" style={{ display: (detailInfo.info.inspection_report_pic_appends === "" ? 'none' : 'block') }}>
                  {/* <Image src={detailInfo.info.inspection_report_pic_appends.split(',')[0]} /> */}
                  <ShowPics row={detailInfo} />
                </div>
              </ZgRow>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="line"></div>
      <div className="title-level-second">原料溯源</div>
      <div>
        {
          sourceInfo.map((item: any, idx: any) => (
            <ZgRow key={idx} align="flex-start" className="source-line">
              <div className="time-wrap">
                <div className="date">{item.date.substr(5, 5)}</div>
                <div className="time">{item.date.substr(11, 5)}</div>
              </div>
              <ZgRow className="icon-wrap out-icon-wrap">
                {/* 1 => '采购下单', 2 => '配送', 3 => '验收入库', 4 => '添加入库', 5 => '领用出库', 6 => '销毁出库' */}
                <IconFont style={{ color: '#fff', fontSize: '20px' }} type={item.type === 1 ? 'iconxiadan' : item.type === 2 ? 'iconpeisong' : item.type === 3 ? 'iconruku' : item.type === 4 ? 'iconruku' : item.type === 5 ? 'iconchuku1' : 'iconchuku1'} />
              </ZgRow>
              <div className="desc-wrap">
                <div className="title">{item.title}</div>
                <div className="desc">操作人：{item.username}</div>
                <div className="desc">{item.content}</div>
              </div>
            </ZgRow>
          ))
        }
      </div>
    </div>
  )
}