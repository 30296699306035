import React, { useState, useEffect, useCallback } from "react";
import styles1 from "../../indexArea.module.css";
import Title from "../title";
import { Select, Image } from "antd";
import { getCorrectList } from "../../../../apis/board";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

import GetTheme from "../../../../utils/getTheme";

SwiperCore.use([Autoplay]);
// import ReactScroll from 'react-seamless-scroll'
// import Swiper from 'swiper'
// import 'swiper/swiper.min.css'
const { Option } = Select;
export default function Jiupianshuju(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  function check_more(path: any) {
    history.push(path);
  }
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  const [cDateType, setCDateType] = useState(1);
  const correctData = [
    {
      img: "",
      name: "",
      desc: "",
      state: "",
    },
  ];
  const [correctList, setCorrectList] = useState(correctData);
  function cDateChange(type: any) {
    setCDateType(type);
  }
  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }
  //视频纠偏列表
  const correctListData = useCallback(() => {
    return getCorrectList({
      page_size: 30,
      sdate: formatDate(cDateType)[0],
      edate: formatDate(cDateType)[1],
    }).then((res: any) => {
      let ls: any = [];
      res.data.data.forEach((item: any) => {
        ls.push({
          img: item.http_url_image_appends,
          name: item.canteen.canteen_name,
          desc: item.correct_type,
          state: item.state,
        });
      });
      setCorrectList(ls);
      mySwiper();
    });
  }, [cDateType]);
  // 纠偏数据
  const correctEle = correctList.map((item: any, index: any) => (
    <SwiperSlide
      className={styles.flexRow + " " + styles1.correctItem}
      key={index}
    >
      <div className={styles.flexRow + " " + styles1.correctLeft}>
        <Image
          src={item.img}
          className={styles1.correctImg}
          alt=""
          placeholder
        />
        <div className={styles1.correctInfo}>
          <div className={styles1.correctName}>{item.name}</div>
          <div className={styles.purpleColor + " " + styles1.correctDesc}>
            {item.desc}
          </div>
        </div>
      </div>
      <div>
        {item.state === 1 ? (
          <span className={styles.redColor}>待处理</span>
        ) : (
          <span>已处理</span>
        )}
      </div>
    </SwiperSlide>
  ));
  const mySwiper = () => {
    // const swiperObj = new Swiper('#board-correct-box', {
    //   slidesPerView: 7,
    //   spaceBetween: 24,
    //   loop: true,
    //   direction: 'vertical',
    //   autoplay: {
    //     delay: 2000, //2秒切换一次
    //     disableOnInteraction: false,
    //   },
    //   observer: true,//修改swiper自己或子元素时，自动初始化swiper    重要
    //   observeParents: true,//修改swiper的父元素时，自动初始化swiper  重要
    // })
  };
  useEffect(() => {
    correctListData();
  }, [correctListData]);
  return (
    <div className={styles1.rightSideFlex}>
      <div className={styles.rightSide}>
        <div className={styles.box + " " + styles.box2}>
          <div className={styles.flexRow + " " + styles.titleLine}>
            <div className={styles.flexRow + " " + styles.checkLine}>
              <Title title="实时纠偏数据"></Title>
              <span
                onClick={() => check_more("/correction/list")}
                className={styles.checkMore + " " + styles.yellowColor}
              >
                查看
              </span>
            </div>
            <Select
              onChange={cDateChange}
              className={"board-select " + styles.select}
              key={timeList[0].type}
              defaultValue={timeList[0].type}
            >
              {timeList.map((item: any) => (
                <Option key={item.type} value={item.type}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <Swiper
            initialSlide={0} //初始化时的索引位置
            speed={1000} //页面切换速度，slider自动滑动开始到结束的时间（单位ms）
            loop={true} //是否形成环路
            spaceBetween={24} //页面直接的间距
            slidesPerView={3} //设置slider容器能够同时显示的slides数量(carousel模式)。
            direction="vertical"
            style={{ height: 200 }}
            //自动滚屏
            autoplay={{
              delay: 2000, //自动滚屏速度
              disableOnInteraction: false, //false: 鼠标操作屏幕后继续自动滚屏
            }}
          >
            {correctEle}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
