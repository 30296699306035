import React, { useState, useEffect, useCallback } from "react";
import styles1 from "../../indexArea.module.css";
import Title from "../title";
import Pop from "../pop";
import { Modal, Select } from "antd";
import { getScoreRank } from "../../../../apis/board";
import dayjs from "dayjs";
import $storeLocal from "storejs";
import { getRemoteToken } from "../../../../apis/canteen-info/list";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Anquanzhishu(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  const [curCanteen, setCanteen] = useState(-1);
  const [showPop, setPop] = useState(false);
  const rankData = [
    {
      canteen_number: "",
      name: "---",
      score: "---",
      rate: "0",
      trend: "up",
    },
  ];
  const [rankList, setRankList] = useState(rankData);
  const [rankListType, setRankListType] = useState("desc");
  function get_sort(val: any) {
    setRankListType(val);
  }

  const [canteenNumber, setNumber] = useState("");
  function getCanten(idx: any, item: any) {
    setCanteen(idx);
    let host = "https://proxy.deyicy.com/" + item.canteen_number;
    getRemoteToken({ number: item.canteen_number }).then((res) => {
      $storeLocal.set("canteenRemoteInfo", {
        host: host,
        number: item.canteen_number,
        token: res.data.token,
      });
      setNumber(item.canteen_number);
      setPop(true);
    });
  }
  //关闭弹窗
  function closePop() {
    setPop(false);
  }
  function check_more(path: any) {
    history.push(path);
  }
  //餐饮单位安全指数分析排行dom渲染
  const rankEle = rankList.map((item: any, index: any) => (
    <div
      className={
        styles.rankItem +
        " " +
        styles1.rankItem +
        " " +
        (index === curCanteen ? styles1.active : "")
      }
      key={index}
      onClick={() => getCanten(index, item)}
    >
      <div className={styles.flexRow + " " + styles.progressLine}>
        <span
          className={
            styles.rankIndex +
            " " +
            (index === 0
              ? styles.greenBackground
              : index === 1
                ? styles.blueBackground
                : index === 2
                  ? styles.yellowBackground
                  : styles.purpleBackground)
          }
        >
          {index + 1}
        </span>
        <div className={styles.lineCenter}>
          <div className={styles.rankName}>{item.name}</div>
          <div className={styles.progressDesc}>
            较前日
            {item.trend !== "" ? (
              item.trend === "up" ? (
                <span className={styles.rankArrow + " " + styles.greenColor}>
                  ↑
                </span>
              ) : (
                <span className={styles.rankArrow + " " + styles.yellowColor}>
                  ↓
                </span>
              )
            ) : (
              <span></span>
            )}
            {item.rate}
          </div>
        </div>
        <span className={styles.progressValue}>
          {item.score === 0 ? "---" : item.score}
        </span>
      </div>
    </div>
  ));
  //无数据提示
  const noEle = (
    <div className={styles.noWrap}>
      <span className={styles.purpleColor}>暂无数据</span>
    </div>
  );
  //获取食堂指数排行
  const get_score_rank = useCallback(() => {
    let sdate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    let edate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    getScoreRank({ sdate: sdate, edate: edate, sort_type: rankListType }).then(
      (res: any) => {
        let ranks: any = [];
        res.data.data.forEach((item: any) => {
          let rate: any =
            parseFloat(item.now_score) - parseFloat(item.before_score);
          if (
            item.before_score === null ||
            parseFloat(item.before_score) === 0
          ) {
            rate = "---";
          }
          if (rate === 0) {
            rate = "无变化";
          }
          let sitem = {
            canteen_number: item.canteen_number,
            name: item.canteen_name,
            score: item.now_score,
            rate: "",
            trend: "",
          };
          if (typeof rate == "number") {
            if (rate > 0) {
              sitem.trend = "up";
              sitem.rate = rate.toFixed(1);
            } else {
              sitem.trend = "down";
              sitem.rate = Math.abs(rate).toFixed(1);
            }
          } else {
            sitem.rate = rate;
          }

          ranks.push(sitem);
        });
        setRankList(ranks);
      }
    );
  }, [rankListType]);
  useEffect(() => {
    get_score_rank();
  }, [get_score_rank]);
  return (
    <>
      <div>
        <div className={styles.leftSide}>
          <div className={styles.box + " " + styles1.canteenBox}>
            <div className={styles.flexRow + " " + styles.titleLine}>
              <div className={styles.flexRow + " " + styles.checkLine}>
                <Title title="安全指数排行(昨日)"></Title>
                <span
                  onClick={() => check_more("/analysis/risk-area")}
                  className={styles.checkMore + " " + styles.yellowColor}
                >
                  查看
                </span>
              </div>
              <Select
                onChange={get_sort}
                className={"board-select " + styles.select}
                defaultValue="desc"
              >
                <Option value="desc">从高到低</Option>
                <Option value="asc">从低到高</Option>
              </Select>
            </div>
            <div className={styles1.rankList}>
              {rankList.length > 0 ? rankEle : noEle}
            </div>
          </div>
        </div>
      </div>
      <Modal
        bodyStyle={{ padding: 0 }}
        onCancel={closePop}
        closable={false}
        footer={null}
        visible={showPop}
        width={1100}
      >
        <Pop close={closePop} number={canteenNumber}></Pop>
      </Modal>
    </>
  );
}
