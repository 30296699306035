import React, { useEffect, useState } from 'react'
import '../../../../style/data-ranking.less'
import ZgTitle from '../../../../components/zg-title'
import IconFont from '../../../../components/icon-font'
import { afeterDate, getCurDate } from '../../../../utils/formatDate'
import { indexBetween, canteenRankingList } from '../../../../apis/intelligence-rectify/intelligence-rectify'

// 大数据视频预警--饼图
export default function DataRanking() {
  const [dataList, setDataList] = useState([])
  function to_init_data(val1: any, val2: any) {
    canteenRankingList({ sdate: val1, edate: val2, page_size: 50 }).then(res => {
      console.log("data-list ===>", res.data)
      setDataList(res.data.data)
    })
  }

  useEffect(() => {
    to_init_data(afeterDate(-30), getCurDate())
  }, [])
  return (
    <>
      <div className="rank-cont">
        <div style={{ display: 'flex' }}>
          <ZgTitle title="餐饮纠偏排行榜"></ZgTitle>
          <div className="tit-right">
            <div className="right-one">30日内</div>
          </div>
        </div>
        <div className="rank-list">
          {dataList.map((item: any, index) => {
            return (
              <div className="list-item" key={index}>
                <div className="list-row-left">
                  <div
                    className={[
                      'list-left-tag',
                      index + 1 === 1
                        ? 'blue-bg'
                        : index + 1 === 2
                          ? 'green-bg'
                          : index + 1 === 3
                            ? 'yellow-bg'
                            : 'grey-bg'
                    ].join(' ')}
                  >
                    {index + 1}
                  </div>
                  <div className="list-left-cln">
                    <div className="list-left-ft1">{item.canteen_name}</div>
                    {/*<div className="list-left-ft2">*/}
                    {/*  较前日*/}
                    {/*  {parseFloat(item.now_score) > parseFloat(item.before_score) ? (*/}
                    {/*    <span>*/}
                    {/*      <IconFont type="iconshangsheng" style={{ color: '#72D7DD', fontSize: '16px' }} />*/}
                    {/*    </span>*/}
                    {/*  ) : (*/}
                    {/*    <span>*/}
                    {/*      <IconFont type="iconxiajiang" style={{ color: '#EA5752', fontSize: '16px' }} />*/}
                    {/*    </span>*/}
                    {/*  )}*/}
                    {/*  {(*/}
                    {/*    parseFloat(item.now_score ? item.now_score : '0') -*/}
                    {/*    parseFloat(item.before_score ? item.before_score : '0')*/}
                    {/*  )*/}
                    {/*    .toFixed(2)*/}
                    {/*    .replace('-', '')}*/}
                    {/*  %*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="list-row-right">{item.total}</div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
