import React from "react";
import "./warning.less";
import { Row, Col, Breadcrumb } from "antd";
import MaterialWarning from "./component/material-warning"
import DocumentData from "./component/document-data"
import RiskTrend from "./component/risk-trend"
import DocumentTrends from "./component/document-trends"
import DocumentRanking from "./component/document-ranking"
import RawMaterial from "./component/raw-material"

// 大数据综合预警
export default function Warning() {
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>大数据分析</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/analysis/comprehensive-warning">综合预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: '12px 12px 12px 20px', overflowY: 'auto', height: '94%' }} >
        <Row gutter={12} >
          <Col span={24} >
            <div className="heads" style={{ backgroundColor: '#fff' }}>综合预警</div>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <MaterialWarning></MaterialWarning>
          </Col>
          <Col span={12}>
            <DocumentData></DocumentData>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <RiskTrend></RiskTrend>
          </Col>
          <Col span={12}>
            <DocumentTrends></DocumentTrends>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <RawMaterial></RawMaterial>
          </Col>
          <Col span={12}>
            <DocumentRanking></DocumentRanking>
          </Col>
        </Row>
      </div>
    </>
  );
}
