import { Breadcrumb, Col, DatePicker, Input, Row, Select, Space } from "antd";
import styles from './less/index.module.less'
import TableData from './component/table'

export default function MessageIndex(props: any) {
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>商户管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>商户信息表</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Row style={{ fontSize: "18px", margin: "10px 0 10px 20px" }}>
          <Col span={4}>商户信息表</Col>
        </Row>
      </div>
      <TableData />
    </>
  )
}