import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getAddictiveList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;
export default function AddictiveList() {
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }
  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getAddictiveList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])
  function changePage(val: number) {
    setCurrentPage(val)
  }
  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])
  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '使用时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '记录人',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: '原料名称',
      dataIndex: 'food_name',
      key: 'food_name',
    },
    {
      title: '原料用量(kg)',
      dataIndex: 'food_amount',
      key: 'food_amount',
    },
    {
      title: '添加剂名称',
      dataIndex: 'additive_name',
      key: 'additive_name',
    }, {
      title: '添加剂用量(g)',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">添加剂使用</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>使用日期：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}