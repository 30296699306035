import React, { useState, useCallback, useEffect } from "react"
import ZgCard from "../../../../components/zg-card"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import IconFont from "../../../../components/icon-font"

import { getTodayCount } from "../../../../apis/analysis/index"
// import { afeterDate, getCurDate } from "../../../../utils/formatDate"

// 大数据分析首页--今日预警数据统计
export default function DataToday() {
  let info = {
    video_correct_waitCount: "", //纠偏预警
    video_correct_count: "", //纠偏总数
    iot_wait_count: "", // 物联预警
    iot_count: "", // 物联总数
    goods_wait_count: "", // 原料预警
    goods_count: "", // 原料总数
    cert_wait_count: "", // 证件预警
    cert_count: "", // 证件总数
  }

  const [detailInfo, setDetailInfo] = useState(info)

  // console.log(props.id)
  let to_get_info = useCallback(() => {
    let data = {}
    return getTodayCount(data).then((res) => {
      setDetailInfo(res.data)
    })
    // console.log(detailInfo)
  }, [])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  return (
    <>
      <div className="stac-info-wrap1">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="今日预警数据统计"></ZgTitle>
            {/* <IconFont type="iconicon-test3" className="icon" /> */}
          </ZgRow>
          <div className="today-wrap">
            <ZgRow justify="flex-start" className="today-item">
              <div className="icon-wrap">
                <IconFont type="icongongyingshang" className="icon" />
              </div>
              <div className="info">
                <div className="tit">视频预警待处理</div>
                <div className="data">
                  <span className="red">
                    {detailInfo.video_correct_waitCount}
                  </span>
                  /<span>{detailInfo.video_correct_count}</span>
                </div>
              </div>
            </ZgRow>

            <ZgRow justify="flex-start" className="today-item">
              <div className="icon-wrap">
                <IconFont type="icongongyingshang" className="icon" />
              </div>
              <div className="info">
                <div className="tit">原料预警待处理</div>
                <div className="data">
                  <span className="red">{detailInfo.goods_wait_count}</span>/
                  <span>{detailInfo.goods_count}</span>
                </div>
              </div>
            </ZgRow>

            <ZgRow justify="flex-start" className="today-item">
              <div className="icon-wrap">
                <IconFont type="icongongyingshang" className="icon" />
              </div>
              <div className="info">
                <div className="tit">物联预警待处理</div>
                <div className="data">
                  <span className="red">{detailInfo.iot_wait_count}</span>/
                  <span>{detailInfo.iot_count}</span>
                </div>
              </div>
            </ZgRow>

            <ZgRow justify="flex-start" className="today-item">
              <div className="icon-wrap">
                <IconFont type="icongongyingshang" className="icon" />
              </div>
              <div className="info">
                <div className="tit">证件预警待处理</div>
                <div className="data">
                  <span className="red">{detailInfo.cert_wait_count}</span>/
                  <span>{detailInfo.cert_wait_count}</span>
                </div>
              </div>
            </ZgRow>
          </div>
        </ZgCard>
      </div>
    </>
  )
}
