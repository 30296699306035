import React, { useState, useEffect, useCallback } from "react"
import {
  Table,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Button,
  message,
} from "antd"
// import ZgInfoItem from '../../../components/zg-info-item'
import {
  getCertList,
  addTask,
  mineCanteens,
  getDevicesList
} from "../../../apis/warningManage/certificates/list"
import moment from "moment"
import { ColumnProps } from "antd/lib/table"
import ShowModal from "components/show-modal"
// 导出预警组件
import ZgExport from '../../../components/zg-export'
import ZgCascader from '../../../components/zg-cascader'

export default function Certificates() {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [dataList, setDataList] = useState([])
  const [schoolName, setSchoolName] = useState([])
  const [canteenName, setCanteenName] = useState()
  const [state, setState] = useState()
  const [sdate, setSdate] = useState()
  const [edate, setEdate] = useState()
  const [str, setStr] = useState()
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区
  const options = schoolName.map((e: any) => (
    <Option key={e.canteen_number} value={e.canteen_name}>
      {e.canteen_name}
    </Option>
  ))
  const columns: ColumnProps<any>[] = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) => `${index + 1}`,
      width: "60px",
      fixed: "left",
    },

    {
      title: "餐饮名称",
      dataIndex: "canteen",
      render: (canteen: { canteen_name: any }) => `${canteen.canteen_name}`,
      width: "12%",
    },
    // type_name
    {
      title: "证件类型",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "岗位",
      dataIndex: "job",
      key: "job",
      render: (job: string) => (
        <>
          {job.split(",").map((tag: string) => {
            return <span key={tag}>{tag.toUpperCase()}</span>
          })}
        </>
      ),
    },
    {
      title: "过期时间",
      dataIndex: "expire_date",
      key: "expire_date",
    },
    {
      title: "过期天数",
      dataIndex: "expire_date",
      render: (expire_date: any, row: any) => {
        if (row.handle_state === 1) return "--"
        // 引用moment组件
        let handle_date = row.handle_date
        if (!handle_date) handle_date = moment().format("YYYY-MM-DD")
        return (
          <>
            <span>过期</span>
            {moment(handle_date, "YYYY-MM-DD").diff(expire_date, "day")}
            <span>天</span>
          </>
        )
      },
    },
    {
      title: "风险等级",
      dataIndex: "level",
      render: (level: any) =>
        level === 1 ? (
          <div
            style={{
              background: "red",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅰ级</span>
          </div>
        ) : level === 2 ? (
          <div
            style={{
              background: "#ff9900",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅱ级</span>
          </div>
        ) : level === 3 ? (
          <div
            style={{
              background: "#3399ff",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅲ级</span>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "状态",
      dataIndex: "handle_state",
      key: "handle_state",
      render: (state: any) => (
        <>
          {
            <span key={state}>
              {state === 0 ? (
                <div style={{ color: "red" }}>待处理</div>
              ) : state === 1 ? (
                <div>已处理</div>
              ) : (
                "无"
              )}
            </span>
          }
        </>
      ),
    },
    {
      title: "处理时间",
      dataIndex: "handle_date",
      key: "handle_date",
      width: "12%",
    },
    {
      title: "处理人",
      dataIndex: "handle_user_name",
      key: "handle_user_name",
    },
    {
      title: "操作",
      dataIndex: "handle_state",
      key: "handle_state",
      fixed: "right",
      width: "120px",
      render: (state: any, row: any) => (
        <>
          {
            <span key={state}>
              {state === 0 ? (
                <Button
                  className="btn"
                  type="primary"
                  onClick={() => checkDetail(row)}
                  ghost={true}
                >
                  {" "}
                  生成整改单{" "}
                </Button>
              ) : (
                ""
              )}
            </span>
          }
        </>
      ),
    },
  ]

  function handState(val: any) {
    setState(val)
    setCurrentPage(1)
  }

  function handDate(dates: any, dateStrings: any) {
    setSdate(dateStrings[0])
    setEdate(dateStrings[1])
    setCurrentPage(1)
  }

  // function handName(val: any) {
  //   setCanteenName(val.target.value)
  //   setCurrentPage(1)
  // }

  function changePage(val: number) {
    setCurrentPage(val)
  }
  //下拉框数据
  useEffect(() => {
    getDevicesList({}).then((res) => {
      setSchoolName(res.data)
    })
  }, [])
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  // 生成整改单
  function checkDetail(val: any) {
    console.log("=======>", val)
    let data = {
      canteen_number: val.canteen_number,
      title: val.name + val.type_name + "已过期",
      type: 2,
      risk_type: "健康证预警",
      is_auto: 1,
      inspection_time: val.created_at,
      problem_describe: val.warn_desc,
    }
    addTask(data).then((res) => {
      const key = "updatable"
      message.loading({ content: "正在生成整改单...", key })
      setTimeout(() => {
        message.success({ content: "生成完成", key, duration: 2 })
      }, 1000)
    })
  }

  const showModal = () => {
    mineCanteens({ name: "" }).then((res) => {
      if (res.status === 200) {
        let str: any = (
          <ShowModal
            isShow={true}
            data={res.data}
            closeModal={closeModal}
          ></ShowModal>
        )
        setStr(str)
      }
    })
  }
  const closeModal = () => {
    let str: any = (
      <ShowModal isShow={false} data={[]} closeModal={closeModal}></ShowModal>
    )
    setStr(str)
  }

  function searchList() {
    setCurrentPage(1)
  }

  function setName(val: any, key: any) {
    console.log(val, key)
    setCanteenName(val)
    setCurrentPage(1)
    // let numberId = ""
    // if (val) {
    //   setCanteen_number(key.key)
    // } else {
    //   setCanteen_number(numberId)
    // }
    // setCurrentPage(1)
  }

  const to_get_list = useCallback(() => {
    return getCertList({
      page: currentPage,
      canteen_name: canteenName,
      state: state,
      sdate: sdate,
      edate: edate,
      city: cityName,
      district: districtName,
    }).then((res) => {
      setTotalCount(res.data.total)
      setDataList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, canteenName, state, sdate, edate, cityName, districtName])

  useEffect(() => {
    to_get_list()
  }, [to_get_list]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>健康证预警</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/canteen/list">健康证预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container">
        <div className="zg-container-content">
          <Row style={{ marginBottom: "32px", fontSize: "20px" }}>
            <Col span={4}>健康证预警</Col>
          </Row>
          <Row>
            <Col span={24} className="gutter-row" style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center'
            }} >
              <div className="down">
                <span>预警时间：</span>
                <Space
                  direction="vertical"
                  size={12}
                  style={{ marginRight: "15px" }}
                >
                  <RangePicker onChange={handDate} />
                </Space>
              </div>
              <div className="down">
                <span>餐饮名称：</span>
                {/* <Input
                placeholder="请输入"
                onChange={(e) => handName(e)}
                style={{ width: 140, marginRight: "15px" }}
              /> */}
                <Select
                  showSearch
                  placeholder="请输入食堂名称"
                  style={{ marginRight: "20px", width: "135px" }}
                  onChange={setName}
                  allowClear
                  dropdownMatchSelectWidth={200}
                >
                  <Option value="">全部</Option>
                  {options}
                </Select>
              </div>
              <div className="down">
                <span>状态选择：</span>
                <Select
                  placeholder="状态选择"
                  style={{ width: 140, marginRight: "15px" }}
                  onChange={handState}
                  allowClear
                >
                  <Option value="2">全部</Option>
                  <Option value="0">未处理</Option>
                  <Option value="1">已处理</Option>
                </Select>
              </div>
              <div className="down">
                {/* 所属区域查询功能 */}
                <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              </div>
              <Button className="down" type="primary" onClick={searchList}>
                查询
              </Button>

            </Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col span={24} className="gutter-row">
              {/* 导出 */}
              <ZgExport ></ZgExport>
              <Button
                className="down btn"
                ghost={true}
                style={{ marginLeft: "15px", float: "right" }}
                type="primary"
                onClick={showModal}
              >
                一键生成整改单
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataList}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              ></Table>
            </Col>
          </Row>
        </div>
      </div>
      {str}
    </>
  )
}
