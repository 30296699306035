import React from 'react'
import '../style/iot-fridge-daily.less'
import AirICon from '../assets/images/airICon.png'
import { Table, Image } from 'antd'

export default function IotAir(props: any) {
  const columns = [
    {
      title: 'TVOC',
      dataIndex: 'cacheGasInfo',
      key: 'cacheGasInfo',
      render: (text: any, record: any) => (
        <div>
          <div className={record.cacheGasInfo.tvocArr.lable === '标准' ? 'green-color' : record.cacheGasInfo.tvocArr.lable === '轻度污染' ? 'orange-color' : record.cacheGasInfo.tvocArr.lable === '重度污染' ? 'lightRed-color' : 'red-color'}>{props.fridgeInfo.state === '0' ? '---' : record.cacheGasInfo.tvoc}MG/㎡</div>
          <div className={["table-flag", "white-color", record.cacheGasInfo.tvocArr.lable === '标准' ? 'green-bg' : record.cacheGasInfo.tvocArr.lable === '轻度污染' ? 'orange-bg' : record.cacheGasInfo.tvocArr.lable === '重度污染' ? 'lightRed-bg' : 'red-bg'].join(' ')}>{props.fridgeInfo.state === '0' || !record.cacheGasInfo.tvocArr ? '---' : record.cacheGasInfo.tvocArr.lable}</div>
        </div>
      )
    },
    {
      title: '温度',
      dataIndex: 'cacheGasInfo',
      key: 'cacheGasInfo',
      render: (text: any, record: any) => (
        <div>
          <div className={record.cacheGasInfo.temArr.lable === '寒冷' || record.cacheGasInfo.temArr.lable === '炎热' ? 'orange-color' : record.cacheGasInfo.temArr.lable === '冷' || record.cacheGasInfo.temArr.lable === '微热' ? 'yellow-color' : record.cacheGasInfo.temArr.lable === '舒适' ? 'green-color' : 'red-color'}>{props.fridgeInfo.state === '0' ? '---' : record.cacheGasInfo.temperature}MG/㎡</div>
          <div className={["table-flag", "white-color", record.cacheGasInfo.temArr.lable === '寒冷' || record.cacheGasInfo.temArr.lable === '炎热' ? 'orange-bg' : record.cacheGasInfo.temArr.lable === '冷' || record.cacheGasInfo.temArr.lable === '微热' ? 'yellow-bg' : record.cacheGasInfo.temArr.lable === '舒适' ? 'green-bg' : 'red-bg'].join(' ')}>{props.fridgeInfo.state === '0' || !record.cacheGasInfo.temArr ? '---' : record.cacheGasInfo.temArr.lable}</div>
        </div>
      )
    },
    {
      title: '湿度',
      dataIndex: 'cacheGasInfo',
      key: 'cacheGasInfo',
      render: (text: any, record: any) => (
        <div>
          <div className={record.cacheGasInfo.humArr.lable === '干燥' || record.cacheGasInfo.humArr.lable === '温度大' ? 'orange-color' : record.cacheGasInfo.humArr.lable === '理想' ? 'green-color' : 'red-color'}>{props.fridgeInfo.state === '0' ? '---' : record.cacheGasInfo.humidity}MG/㎡</div>
          <div className={["table-flag", "white-color", record.cacheGasInfo.humArr.lable === '干燥' || record.cacheGasInfo.humArr.lable === '温度大' ? 'orange-bg' : record.cacheGasInfo.humArr.lable === '理想' ? 'green-bg' : 'red-bg'].join(' ')}>{props.fridgeInfo.state === '0' || !record.cacheGasInfo.humArr ? '---' : record.cacheGasInfo.humArr.lable}</div>
        </div>
      )
    },
    {
      title: 'PM2.5',
      dataIndex: 'cacheGasInfo',
      key: 'cacheGasInfo',
      render: (text: any, record: any) => (
        <div>
          <div className={record.cacheGasInfo.pm25Arr.lable === '优' ? 'green-color' : record.cacheGasInfo.pm25Arr.lable === '良' ? 'blue-color' : record.cacheGasInfo.pm25Arr.lable === '轻度' ? 'yellow-color' : record.cacheGasInfo.pm25Arr.lable === '中度' ? 'orange-color' : record.cacheGasInfo.pm25Arr.lable === '重度' ? 'lightRed-color' : record.cacheGasInfo.pm25Arr.lable === '严重' ? 'yz-color' : 'red-color'}>{props.fridgeInfo.state === '0' ? '---' : record.cacheGasInfo.pm25}MG/㎡</div>
          <div className={["table-flag", "white-color", record.cacheGasInfo.pm25Arr.lable === '优' ? 'green-bg' : record.cacheGasInfo.pm25Arr.lable === '良' ? 'blue-bg' : record.cacheGasInfo.pm25Arr.lable === '轻度' ? 'yellow-bg' : record.cacheGasInfo.pm25Arr.lable === '中度' ? 'orange-bg' : record.cacheGasInfo.pm25Arr.lable === '重度' ? 'lightRed-bg' : record.cacheGasInfo.pm25Arr.lable === '严重' ? 'yz-bg' : 'red-bg'].join(' ')}>{props.fridgeInfo.state === '0' || !record.cacheGasInfo.pm25Arr ? '---' : record.cacheGasInfo.pm25Arr.lable}</div>
        </div>
      )
    },
    {
      title: '二氧化碳',
      dataIndex: 'cacheGasInfo',
      key: 'cacheGasInfo',
      render: (text: any, record: any) => (
        <div>
          <div className={record.cacheGasInfo.co2Arr.lable === '空气清新' ? 'green-color' : record.cacheGasInfo.co2Arr.lable === '空气浑浊' ? 'orange-color' : record.cacheGasInfo.co2Arr.lable === '严重污染' ? 'lightRed-color' : record.cacheGasInfo.co2Arr.lable === '重度污染' ? 'yz-color' : 'red-color'}>{props.fridgeInfo.state === '0' ? '---' : record.cacheGasInfo.co2}MG/㎡</div>
          <div className={["table-flag", "white-color", record.cacheGasInfo.co2Arr.lable === '空气清新' ? 'green-bg' : record.cacheGasInfo.co2Arr.lable === '空气浑浊' ? 'orange-bg' : record.cacheGasInfo.co2Arr.lable === '严重污染' ? 'lightRed-bg' : record.cacheGasInfo.co2Arr.lable === '重度污染' ? 'yz-bg' : 'red-bg'].join(' ')}>{props.fridgeInfo.state === '0' || !record.cacheGasInfo.co2Arr ? '---' : record.cacheGasInfo.co2Arr.lable}</div>
        </div>
      )
    }
  ]
  return (
    <>
      <div className="fridge-daily">
        <div className="fridge-tit">
          <div className="tit-img">
            <Image
              width={44}
              height={42}
              preview={false}
              src={AirICon}
            />
          </div>
          <div className="tit-cont">
            <div><span className="tit-ft-top1">{props.fridgeInfo.name}</span><span className="tit-ft-top2">（{props.fridgeInfo.number}）</span></div>
            <div className="tit-ft-down">{props.fridgeInfo.room}</div>
          </div>
          <div className={props.fridgeInfo.state === '0' || props.fridgeInfo.online_state === '0' ? 'tit-lx-state' : 'tit-zx-state'}>{props.fridgeInfo.state === '0' ? '停用' : props.fridgeInfo.online_state === '1' ? '在线' : '离线'}</div>
        </div>
        <div className="fridge-table">
          <Table columns={columns}
            dataSource={props.fridgeInfo.devicesgroup}
            rowKey="id" pagination={false} />
        </div>
        <div className="foot-time">数据最后上传时间：{props.fridgeInfo['devicesgroup'][0].last_time}</div>
      </div>
    </ >
  )
}