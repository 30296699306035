import request from '../../utils/request'
/**
 * 获取纠偏列表
 */
export function getCorrectList(data: any) {
  return request({
    url: '/app/video/correct/index',
    method: 'get',
    params: data
  })
}
/**
 * 获取原料预警列表
 */
export function getMatList(data: any) {
  return request({
    url: '/app/warning/goods/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取证件预警列表
 */
export function getCertList(data: any) {
  return request({
    url: '/app/warning/cert/pc-list',
    method: 'get',
    params: data
  })
}

/**
 * 获取餐饮证件预警列表
 */
export function getCerttList(data: any) {
  return request({
    url: '/app/warning/canteen/cert',
    method: 'get',
    params: data
  })
}
/**
 * 获取物联预警列表
 */
export function getWarnList(data: any) {
  return request({
    url: '/app/warning/canteen/devices-list',
    method: 'get',
    params: data
  })
}