import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getTableList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;
export default function MorningList() {
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }
  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getTableList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])
  function changePage(val: number) {
    setCurrentPage(val)
  }
  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])
  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '消毒时间',
      dataIndex: 'created_at',
      width:'12%'
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (text: any) => (
        <div>{text === 1 ? '早餐' : text === 2 ? '中餐' : text === 3 ? '晚餐' : '其他'}</div>
      )
    }, {
      title: '餐具名称',
      render: (record: any) => (
        <div>
          {
            record.tableware_list.map((item: any, idx: any) => (
              <span key={'tableware_list_' + item.id}>{item.name + (idx !== (record.tableware_list.length - 1) ? '、' : '')}</span>
            ))
          }
        </div>
      )
    }, {
      title: '餐具数量（件）',
      dataIndex: 'amount',
    }, {
      title: '消毒温度（℃）',
      key: 'max_temperature',
      render: (item: any) => {
        let str = item.max_temperature
        if (str === 0) return <div>--</div>
        if (str !== 0) return <div>{str}</div>
      }
    }, {
      title: '消毒时长（分）',
      render: (item: any) => {
        let str = item.last_time
        if (str === 0) str = '--'
        return <div>{str}</div>
      }
    }, {
      title: '消毒状态',
      render: (item: any) => {
        // 状态，qualified：合格，unqualified：不合格,unit_exception 设备异常，in_progress:进行中，manual_qualified:手动提交（合格）,manual_unqualified:手动提交（不合格），cancel：取消消毒
        let str = '--'
        if (item.state === 'in_progress') {
          str = '进行中'
        }
        if (item.state === 'qualified') {
          str = '合格'
        }
        if (item.state === 'unit_exception') {
          str = '不合格'
        }
        if (item.state === 'manual_qualified') {
          str = '手动提交（合格）'
        }
        if (item.state === 'manual_unqualified') {
          str = '手动提交（不合格）'
        }
        if (item.state === 'cancel') {
          str = '取消消毒'
        }

        return (
          <div>{str}</div>
        )
      },
      width:'10%'
    }, {
      title: '消毒柜负责人',
      dataIndex: 'user_name',
    }
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">餐具消毒</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>消毒日期：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey='id'
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >
            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}