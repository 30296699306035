import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Select, Modal, Image, Space } from 'antd';
import ZgRow from '../../../components/zg-row';
import Detail from './detail'
import '../../../style/button.less'
import { getStacWorker, getStacJob } from '../../../apis/canteen-info/proxy-info'
const { Option } = Select
export default function WorkerList() {
  const { RangePicker } = DatePicker
  const [showDetail, setShowDetail] = useState(false)
  // const [timeList, setTimeList] = useState('')//
  const [sdate, setSdate] = useState()
  const [edate, setEdate] = useState()
  const [jobList, setJobList] = useState([])//部门数据
  const [workerList, setWorkerList] = useState([]) //从业人员数据
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  // const [canteenName, setCanteenName] = useState('')  //食堂名称
  const [checkId, setCheckId] = useState('')  //查看详情id
  const [job_id, setJobId] = useState('') //设置职务id
  let to_set_id = (data: any) => {
    setCheckId(data)
  }
  function checkDetail(data: any) {
    to_set_id(data)
    return setShowDetail(true)
  }
  function to_close() {
    return setShowDetail(false)
  }

  function time_change(datas: any, dateStrings: any) {
    setSdate(dateStrings[0])
    setEdate(dateStrings[1])
    setCurrentPage(1)
  }
  // function time_change(data: any) {
  //   // console.log(moment(data).format('YYYY-MM-DD'))
  //   if (data) {
  //     setTimeList(moment(data).format('YYYY-MM-DD'))
  //   }
  // }
  // 获取从业人员列表
  const to_get_list = useCallback(() => {
    return getStacWorker({ page: currentPage, job_id: job_id, sdate: sdate, edate: edate, is_staff: 1 }).then(res => {
      setTotalCount(res.data.total)
      setWorkerList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, job_id, sdate, edate])
  // currentPage设置为1
  function searchList() {
    setCurrentPage(1)
  }

  function change_job(data: any) {
    setJobId(data)
    setCurrentPage(1)
  }
  function changePage(val: number) {
    setCurrentPage(val)
  }
  useEffect(() => {
    getStacJob({}).then(res => {
      setJobList(res.data)
    })
  }, [])
  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])
  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      render: (gender: any) => (
        <div>{gender === 1 ? "男" : gender === 2 ? "女" : ""}</div>
      )
    }, {
      title: '职务',
      dataIndex: 'jobs',
      render: (text: any) => (
        <div>{text[0].name}</div>
      )
    }, {
      title: '入职时间',
      dataIndex: 'entry_date',
      key: 'entry_date',
    }, {
      title: '健康证',
      dataIndex: 'certificates',
      render: (text: any) => (
        // eslint-disable-next-line array-callback-return
        text.map((item: any, idx: any) => {
          if (item.pics_appends && idx === 0) {
            return <Image key={item.id} style={{ width: '45px', height: '45px ' }} src={item.pics_appends} alt="" />
          }
        })
      ),
    }, {
      title: '健康证有效截止期',
      dataIndex: 'certificates',
      render: (text: any) => (
        // eslint-disable-next-line array-callback-return
        text.map((item: any, idx: any) => {
          if (item.pics_appends && idx === 0) {
            return item.expire_date
          }
        })
      ),
    }, {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
    }, {
      title: '操作',
      key: 'action',
      render: (record: any) => (
        <Button className="btn" type="primary" onClick={() => checkDetail(record.id)} ghost={true}>查看详情</Button>
      )
    }
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">从业人员</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>入职日期：</span>
            <Space direction="vertical" size={12} style={{ marginRight: '15px' }}>
              <RangePicker onChange={time_change} />
            </Space>

            {/* <DatePicker onChange={time_change}></DatePicker> */}
            <span style={{ marginLeft: '16px' }}>职务：</span>
            <Select defaultValue="全部" style={{ width: 120 }} onChange={change_job}>
              <Option value="">全部</Option>
              {
                jobList.map((item: any) => (

                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))
              }
            </Select>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={() => searchList()}>搜索</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={workerList}
              rowKey={columns => columns.id}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
      <Modal
        visible={showDetail}
        title="从业人员详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
    </>
  )
}