import request from '../../utils/request'

export function dishTypes(data: any) {
  return request({
    url: '/app/dishes/types',
    method: 'get',
    params: data
  })
}

export function dishList(data: any) {
  return request({
    url: '/app/dishes/list',
    method: 'get',
    params: data
  })
}

export function dishInfo(data: any) {
  return request({
    url: '/app/dishes/info',
    method: 'get',
    params: data
  })
}