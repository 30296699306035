import React, { useCallback, useEffect, useState } from "react"
import {
  Table,
  Select,
  DatePicker,
  Space,
  Breadcrumb,
  Row,
  Col,
  Button,
  message,
} from "antd"
import {
  getMaterial,
  getCanteenList,
  addTask,
} from "../../../apis/warningManage/materials/list"
import moment from "moment"
import { ColumnProps } from "antd/lib/table"
import ShowModal from "components/show-modal"
import { mineCanteens } from "apis/warningManage/certificates/list"
import ZgCascader from '../../../components/zg-cascader'
import ZgExport from '../../../components/zg-export'

export default function Material(props: any) {
  const { RangePicker } = DatePicker
  const { Option } = Select

  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [correctionList, setCorrectionList] = useState([]) //列表数据
  const [state, setState] = useState(3) //状态
  const [startdate, setStartdate] = useState() //开始时间
  const [enddate, setEnddate] = useState() //结束时间
  const [shoolid, setshoolId] = useState("") //学校id
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区
  const [shoolname, setShoolName] = useState([]) //食堂名称

  //遍历出食堂名称
  const options = shoolname.map((e: any) => (
    <Option key={e.canteen_number} value={e.canteen_name}>
      {e.canteen_name}
    </Option>
  ))
  const [str, setStr] = useState()
  const showModal = () => {
    mineCanteens({ name: "" }).then((res) => {
      if (res.status === 200) {
        let str: any = (
          <ShowModal
            isShow={true}
            data={res.data}
            closeModal={closeModal}
          ></ShowModal>
        )
        setStr(str)
      }
    })
  }
  const closeModal = () => {
    let str: any = (
      <ShowModal isShow={false} data={[]} closeModal={closeModal}></ShowModal>
    )
    setStr(str)
  }
  let columns: ColumnProps<any>[] = [
    {
      title: "序号",
      dataIndex: "id",
      render: (id: any, record: any, index: number) => `${index + 1}`,
      width: "60px",
      fixed: "left",
    },
    {
      title: "预警时间",
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
    },
    {
      title: "餐饮单位名称",
      dataIndex: "canteen",
      render: (canteen: { canteen_name: any }) => `${canteen.canteen_name}`,
      width: "10%",
    },
    {
      title: "原料名称",
      dataIndex: "name",
      key: "name",
      width: '8%'
    },
    {
      title: "原料数量",
      dataIndex: "amount",
      key: "amount",
      // unit_name
      render: (amount: any, row: any) => {
        return (
          <>
            <div>
              {amount}
              {row.unit_name}
            </div>
          </>
        )
      },
    },
    {
      title: "风险等级",
      dataIndex: "level",
      render: (level: any) =>
        level === 1 ? (
          <div
            style={{
              background: "red",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅰ级</span>
          </div>
        ) : level === 2 ? (
          <div
            style={{
              background: "#ff9900",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅱ级</span>
          </div>
        ) : level === 3 ? (
          <div
            style={{
              background: "#3399ff",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅲ级</span>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "过期时间",
      dataIndex: "expiration_date",
      key: "expiration_date",
      width: "90px",
    },
    {
      title: "过期天数",
      dataIndex: "expiration_date",
      render: (expire_date: any, row: any) => {
        // 引用moment组件
        let handle_date = row.handle_date
        if (Math.round(row.remain_amount) === 0) return "--" // 已处理，无处理时间时不展示过期天数
        if (!handle_date) handle_date = moment().format("YYYY-MM-DD")
        return (
          <>
            <span>过期</span>
            {moment(handle_date, "YYYY-MM-DD").diff(expire_date, "day")}
            <span>天</span>
          </>
        )
      },
      width: "75px",
    },
    {
      title: "状态",
      dataIndex: "remain_amount",
      render: (remain_amount: any) =>
        Math.round(remain_amount) === 0 ? (
          <span>已处理</span>
        ) : (
          <span style={{ color: "red" }}>待处理</span>
        ),
      width: "80px",
    },
    {
      title: "处理时间",
      dataIndex: "handle_date",
      render: (handle_date: any) => <span>{handle_date}</span>,
      width: "10%",
    },
    {
      title: "处理人",
      dataIndex: "handle_user_name",
      render: (handle_user_name: any) => <span>{handle_user_name}</span>,
      width: "90px",
    },
    {
      title: "操作",
      dataIndex: "remain_amount",
      fixed: "right",
      width: "120px",
      render: (remain_amount: any, row: any) =>
        Math.round(remain_amount) === 0 ? (
          ""
        ) : (
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail(row)}
            ghost={true}
          >
            {" "}
            生成整改单{" "}
          </Button>
        ),
    },
  ]

  useEffect(() => {
    getCanteenList({}).then((res) => {
      setShoolName(res.data)
    })
  }, [])
  // 生成整改单
  function checkDetail(val: any) {
    console.log("=======>", val)
    let data = {
      canteen_number: val.canteen_number,
      title: val.name + "已过期",
      type: 2,
      risk_type: "原料预警",
      is_auto: 1,
      inspection_time: val.created_at,
      problem_describe: val.warn_desc,
    }
    addTask(data).then((res) => {
      const key = "updatable"
      message.loading({ content: "正在生成整改单...", key })
      setTimeout(() => {
        message.success({ content: "生成完成", key, duration: 2 })
      }, 1000)
    })
  }
  //状态
  function handState(val: any) {
    if (val) {
      setState(val)
    } else {
      setState(3)
    }
    setCurrentPage(1)
  }

  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  //下拉框查询
  function setName(val: any, key: any) {
    let numberID = ""
    if (val) {
      setshoolId(key.key)
    } else {
      setshoolId(numberID)
    }
    setCurrentPage(1)
  }

  //获取时间
  function go_Data(dates: any, dateString: any) {
    setStartdate(dateString[0])
    setEnddate(dateString[1])
    setCurrentPage(1)
  }

  //列表展示
  const to_get_list = useCallback(() => {
    return getMaterial({
      page: currentPage,
      state: state,
      sdate: startdate,
      edate: enddate,
      canteen_number: shoolid,
      city: cityName,
      district: districtName,
    }).then((res) => {
      setTotalCount(res.data.total)
      setCorrectionList(res.data.data)
      setPerPage(res.data.per_page)
      console.log(res.data.data)
    })
  }, [currentPage, enddate, shoolid, startdate, state, cityName, districtName])

  function changePage(val: number) {
    setCurrentPage(val)
  }
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  function search() {
    setCurrentPage(1)
    to_get_list()
  }

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>预警管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/materials/material">原料预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container">
        <div className="zg-container-content">
          <Row style={{ marginBottom: "32px", fontSize: "20px" }}>
            <Col span={4}>原料预警</Col>
          </Row>
          <Row>
            <Col span={24} className="gutter-row" style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center'
            }}>
              <div className="down">
                <span>预警时间：</span>
                <Space
                  direction="vertical"
                  size={12}
                  style={{ marginRight: "15px" }}
                >
                  <RangePicker onChange={go_Data} />
                </Space>
              </div>
              <div className="down">
                <span>餐饮单位名称：</span>
                <Select
                  showSearch
                  placeholder="请输入食堂名称"
                  style={{ width: 140, marginRight: "15px" }}
                  onChange={setName}
                  allowClear
                  dropdownMatchSelectWidth={200}
                >
                  <Option value="">全部</Option>
                  {options}
                </Select>
              </div>
              <div className="down">
                <span>状态选择：</span>
                <Select
                  placeholder="状态选择"
                  style={{ width: 140, marginRight: "15px" }}
                  onChange={handState}
                  allowClear
                >
                  <Option value="3">全部</Option>
                  <Option value="1">已处理</Option>
                  <Option value="0">待处理</Option>
                </Select>
              </div>
              <div className="down">
                <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              </div>
              <Button type="primary" onClick={search} className="down">
                查询
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col span={24} className="gutter-row" >
              <ZgExport></ZgExport>
              <Button
                style={{ marginLeft: "15px", float: "right" }}
                type="primary"
                ghost={true}
                onClick={showModal}
                className="down btn"
              >
                一键生成整改单
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={correctionList}
                rowKey={(columns) => columns.id}
                pagination={{
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {str}
    </>
  )
}
