import React, { useState, useEffect } from "react";
import Title from "../title";
import { getTypeCanteenCount } from "../../../../apis/board";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../../utils/getTheme";

export default function Canyindanwei(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  // 餐饮单位总数
  const squareData = [
    { name: "中央厨房", value: 0 },
    { name: "学校/幼儿园食堂", value: 0 },
    { name: "机关/企业食堂", value: 0 },
    { name: "大中型/小型餐饮", value: 0 },
  ];

  const [squareList, setSquareList] = useState(squareData);
  //餐饮单位风险等级dom渲染
  const squareEle = squareList.map((item: any) => (
    <div className={styles.divBlock + " " + styles.squareBlock} key={item.name}>
      <div className={styles.yellowColor}>{item.value}</div>
      <div className={styles.desc}>{item.name}</div>
    </div>
  ));
  //获取各类型食堂统计数目
  const get_canteen_count = () => {
    getTypeCanteenCount({}).then((res: any) => {
      let ls: any = [
        { name: "中央厨房", value: 0 },
        { name: "学校/幼儿园食堂", value: 0 },
        { name: "机关/企业食堂", value: 0 },
        { name: "大中型/小型餐饮", value: 0 },
      ];
      res.data.forEach((item: any) => {
        if (item.type === "enterprise") {
          ls[0].value += item.count;
        } else if (item.type === "school") {
          ls[1].value += item.count;
        } else if (item.type === "office") {
          ls[2].value += item.count;
        } else {
          ls[3].value += item.count;
        }
      });

      setSquareList(ls);
    });
  };

  function check_more(path: any) {
    history.push(path);
  }
  useEffect(() => {
    get_canteen_count();
  }, []);
  return (
    <>
      <div>
        <div className={styles.leftSide}>
          <div className={styles.box + " " + styles.box1}>
            <div className={styles.flexRow + " " + styles.titleLine}>
              <div className={styles.flexRow + " " + styles.checkLine}>
                <Title title="餐饮单位"></Title>
                <span
                  onClick={() => check_more("/canteen/list")}
                  className={styles.checkMore + " " + styles.yellowColor}
                >
                  查看
                </span>
              </div>
            </div>
            <div className={styles.divBlock + " " + styles.recBlock}>
              地区管理餐饮单位总数：
              <span className={styles.yellowColor}>
                {squareList[0].value +
                  squareList[1].value +
                  squareList[2].value +
                  squareList[3].value}
              </span>
            </div>
            <div className={styles.squareList}>{squareEle}</div>
          </div>
        </div>
      </div>
    </>
  );
}
