import React from "react"

export default function ZgProgress(props: any) {
  // let percentageNum = props.percentageNum * 100
  //这个支持css样式响应式的
  let leftPercentage = (100 - props.percentageNum) * -1
  //不支持样式响应式,可以写死
  // let leftPercentage = (1-this.props.percentageNum)*(-450);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "10px",
          background: "#dedede",
          position: "relative",
          margin: "auto 0",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "10px",
            background: "#1AAAA8",
            position: "absolute",
            left: `${leftPercentage}%`,
          }}
        ></div>
      </div>
    </>
  )
}
