import React, { useCallback, useState, useEffect } from "react"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import ZgColumn from "../../../../components/zg-column"
import "../index.less"

import ReactEcharts from "echarts-for-react"

import { safeIndexDaysInfo } from '../../../../apis/intelligence-rectify/intelligence-rectify'

// 风险指数区域--评估分析报告
function RiskTrend(props: any, ref: any) {
  const [info, setInfo] = useState<any>({
    info_desc: '--',
    info: { score: 0 }
  })

  const option = {
    series: [
      {
        name: "分值",
        type: "pie",
        // hoverAnimation: false,
        hoverOffset: 1,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        color: ["#5473E8", "#E9ECF7"],
        radius: ["86%", "90%"],
        data: [
          {
            value: info.info.score,
            name: "n",
          },
          {
            value: 100 - info.info.score,
            name: "v",
          },
        ],
      },
    ],
  }
  const get_data = useCallback(
    () => {
      return safeIndexDaysInfo({ canteen_number: props.canteenNumber, sdate: props.sdate, edate: props.edate }).then(res => {
        if (res.status === 200) {
          setInfo(res.data)
        } else {
          console.warn("safeIndexDayInfo 请求出错 ===>", res)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate, props.edate],
  )
  useEffect(() => {
    get_data()
  }, [get_data])
  return (
    <div>
      <div className="work-wrap">
        <ZgRow justify="space-between" align="flex-start">
          <ZgTitle title="评估分析报告"></ZgTitle>
        </ZgRow>
        <div className="card mult-score-card">
          <div className="chart-wrap">
            <ReactEcharts option={option} style={{ height: 220 }} />
            <ZgColumn className="desc-wrap">
              <div className="chart-number">
                {info.info.score}
                <span className="unit">分</span>
              </div>
              <div className="chart-desc">评分</div>
            </ZgColumn>
          </div>
          <div className="desc">
            <span>现状分析：</span>
            {info.info_desc}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RiskTrend
