import React, { useState, useEffect, useCallback } from "react";
import Title from "../title";
import { Progress, Select } from "antd";
import { getAllRank } from "../../../../apis/board";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Zongheanquan(props: any) {
  let styles = GetTheme();
  // 综合安全指数分析
  const allScoreTemp = [
    {
      name: "",
      score: "---",
      rate: "0",
      trend: "up",
    },
  ];
  const [rankListType, setRankListType] = useState("desc");
  function get_sort(val: any) {
    setRankListType(val);
  }
  const [allRank, setAllRank] = useState(allScoreTemp);

  //综合安全指数分析排行dom渲染
  const areaScoreEle = allRank.map((item: any, index: any) => (
    <div className={styles.progressItem} key={index}>
      <div className={styles.flexRow + " " + styles.progressLine}>
        <span className={styles.progressName}>{item.name}</span>
        <div className={styles.lineCenter}>
          <Progress
            strokeColor={
              item.score >= 95
                ? "#1EB38B"
                : item.score >= 93 && item.score < 95
                  ? "#3a8fff"
                  : "#E78419"
            }
            trailColor="#1D1B57"
            className={styles.progress}
            percent={item.score}
            showInfo={false}
          />
          <div className={styles.progressDesc}>
            较前日
            {item.trend !== "" ? (
              item.trend === "up" ? (
                <span className={styles.rankArrow + " " + styles.greenColor}>
                  ↑
                </span>
              ) : (
                <span className={styles.rankArrow + " " + styles.yellowColor}>
                  ↓
                </span>
              )
            ) : (
              <span></span>
            )}
            {item.rate}
          </div>
        </div>
        <span className={styles.progressValue}>
          {item.score === 0 ? "---" : item.score}
        </span>
      </div>
    </div>
  ));
  //无数据提示
  // const noEle = <div className={styles.noWrap}><span className={styles.purpleColor}>暂无数据</span></div>

  // 获取综合安全指数
  const get_all_rank = useCallback(() => {
    getAllRank({ sort_type: rankListType }).then((res: any) => {
      if (res.data instanceof Array) {
        let ranks: any = [];
        res.data.forEach((item: any) => {
          let rate: any = "";
          if (item.before_score === null || item.before_score === 0) {
            rate = "---";
          } else {
            rate = parseFloat(item.now_score) - parseFloat(item.before_score);
          }
          if (rate === 0) {
            rate = "无变化";
          }
          let sitem = {
            name: item.unit_name,
            score: item.now_score,
            rate: "",
            trend: "",
          };
          if (typeof rate == "number") {
            if (rate > 0) {
              sitem.trend = "up";
              sitem.rate = rate.toFixed(1);
            } else {
              sitem.trend = "down";
              sitem.rate = Math.abs(rate).toFixed(1);
            }
          } else {
            sitem.rate = rate;
          }

          ranks.push(sitem);
        });
        setAllRank(ranks);
      }
    });
  }, [rankListType]);

  useEffect(() => {
    get_all_rank();
  }, [get_all_rank]);
  return (
    <>
      <div>
        <div className={styles.leftSide}>
          <div className={styles.box + " " + styles.box2}>
            <div className={styles.flexRow + " " + styles.titleLine}>
              <div className={styles.flexRow + " " + styles.checkLine}>
                <Title title="综合安全指数分析排行"></Title>
              </div>
              <Select
                onChange={get_sort}
                className={"board-select " + styles.select}
                defaultValue="desc"
              >
                <Option value="desc">从高到低</Option>
                <Option value="asc">从低到高</Option>
              </Select>
            </div>
            <div className={styles.areaScoreList}>{areaScoreEle}</div>
          </div>
        </div>
      </div>
    </>
  );
}
