import React, { useState, useEffect } from "react";

import Canyindanwei from "./component/city/can-yin-dan-wei";
import Zongheanquan from "./component/city/zong-he-an-quan";
import Jianguanzongkuang from "./component/city/jian-guan-zong-kuang";
import Guoqiyujing from "./component/city/guo-qi-yu-jing";
import Shipinyujing from "./component/city/shi-pin-yu-jing";
import Zongheyujing from "./component/city/zong-he-yu-jing";
import Jianyijiance from "./component/city/jian-yi-jian-ce";
import Wulianyujing from "./component/city/wu-lian-yu-jing";

import { Row, Col } from "antd";

import GetTheme from "../../utils/getTheme";

export default function IndexCity(props: any) {
  let curWidth: any = window.innerWidth;
  const [windowWidth, setWindowWidth] = useState(curWidth);
  const handleResize = (e: any) => {
    setWindowWidth(e.target.innerWidth);
  };
  let styles: any = GetTheme();
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <div className={styles.background}>
      <Row gutter={[16, 16]}>
        <Col xl={8} xxl={6} span={6}>
          <Canyindanwei></Canyindanwei>
          <div className={styles.blank}></div>
          <Zongheanquan></Zongheanquan>
          <div className={styles.blank}></div>
          <Jianyijiance></Jianyijiance>
        </Col>
        <Col xl={16} xxl={12} span={12}>
          <Jianguanzongkuang></Jianguanzongkuang>
          <div className={styles.blank}></div>
          <Zongheyujing></Zongheyujing>
        </Col>
        {windowWidth >= 1600 ? (
          <Col xl={24} xxl={6} span={6}>
            <Guoqiyujing></Guoqiyujing>
            <div className={styles.blank}></div>
            <Shipinyujing></Shipinyujing>
            <div className={styles.blank}></div>
            <Wulianyujing></Wulianyujing>
          </Col>
        ) : (
          <Col xl={24} xxl={6} span={6}>
            <Row gutter={16}>
              <Col span={8}>
                <Guoqiyujing></Guoqiyujing>
              </Col>
              <Col span={8}>
                <Shipinyujing></Shipinyujing>
              </Col>
              <Col span={8}>
                <Wulianyujing></Wulianyujing>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}
