import jianguanSheet from "../pages/board/index.module.css";
import jiaoyuSheet from "../pages/board/index-jiaoyu.module.css";

import $storeLocal from "storejs";

export default function GetTheme() {
  let loginType = $storeLocal.get("customPCType");
  let styles = null;
  if (loginType === "5") {
    styles = jiaoyuSheet;
  } else {
    styles = jianguanSheet;
  }
  return styles
}