import React, { useEffect } from 'react'
import { Breadcrumb, Image } from 'antd';
import './less/recommend-info.less'
import { useHistory } from 'react-router-dom';
import { recommendInfo } from "../../apis/meal/recommend"
import { useState } from 'react';

export default function Recommend(props: any) {
  let info = {
    dietitian:
    {
      http_url_image_appends: '',
      name: '',
      profile: '',
    },
    title: '',
    created_at: '',
    content: '',
  }

  const [datainfo, setDataList] = useState(info)
  const history = useHistory()
  console.log("==========?>", datainfo)
  const backUp = () => {
    history.goBack()
  }
  function to_init_data() {
    recommendInfo({ id: props.location.state }).then(res => {
      console.log("=======<", res.data)
      const data = res.data
      setDataList(data)
    })
  }

  useEffect(() => {
    to_init_data()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>膳食管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <span onClick={backUp}>菜谱推荐</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          菜谱详情
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="recommend-wrap">
        <div className="recommend-info-tit">
          <div className="tit-ft">菜谱详情</div>
          <div className="tit-cont">
            <div className="tit-cont-img"><Image preview={false}
              src={datainfo.dietitian.http_url_image_appends}
            /></div>
            <div className="tit-cont-btm">
              <div className="btm-ft-one">{datainfo.dietitian.name}</div>
              <div>
                {
                  datainfo.dietitian.profile.split('、').map((item: any, index: any) => {
                    return <span className="btm-ft-two" key={index}>{item}</span>
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="recommend-main">
          <div className="main-tit">
            <div className="main-tit-one">{datainfo.title}</div>
            <div className="main-tit-two">{datainfo.created_at}</div>
          </div>
          <div className="recommend-wrap">
            <div className="main-wrap" dangerouslySetInnerHTML={{ __html: datainfo.content }}>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};