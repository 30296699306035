import React, { useState, useEffect, useCallback } from "react"
import { Row, Col, Image } from "antd"
import ZgCard from "../../../../components/zg-card"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import IconFont from "../../../../components/icon-font"
import noDataImg from '../../../../assets/images/noData.png'
import ZgColumn from '../../../../components/zg-column';

import { getInspection } from "../../../../apis/analysis/index"
import { afeterDate, getCurDate } from "../../../../utils/formatDate"
import { useHistory } from "react-router-dom"

// 大数据分析首页--实时纠偏巡查
export default function DataInspection() {
  let history = useHistory()
  function showImageModal() {
    history.push("/correction/list")
  }

  let list = [
    {
      canteen_name: "",
      correct_type: "",
      http_url_image_appends:
        "",
      state: 1,
    },
  ]
  const [dataList, setDataList] = useState(list)

  let to_get_info = useCallback(() => {
    let sdate = afeterDate(-30)
    let edate = getCurDate()
    let data = { sdate: sdate, edate: edate, page_size: 3 }
    return getInspection(data).then((res) => {
      let arr: any = []
      res.data.data.forEach((item: any, key: any) => {
        // 插入数据
        arr.push({
          canteen_name: item.canteen.canteen_name,
          correct_type: item.correct_type,
          http_url_image_appends: item.http_url_image_appends,
          state: item.state,
        })
      })
      setDataList(arr)
      // console.log(arr)
    })
  }, [])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  return (
    <>
      <div className="stac-info-wrap1">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start" onClick={showImageModal}>
            <ZgTitle title="实时纠偏巡查"></ZgTitle>
            <IconFont type="iconicon-test3" className="icon" />
          </ZgRow>
          <Row className="inspection-wrap">
            {dataList.map((item: any, key: any) => (
              <Col span={8} key={key}>
                <div className="card">
                  <div className="img">
                    {/* <Image height={157} src={item.http_url_image_appends} /> */}
                    <ZgRow justify="flex-start" className="card-info">
                      <img style={{ display: (item.http_url_image_appends === '' ? 'none' : 'block') }} className="img" src={item.http_url_image_appends} alt="" />
                      <ZgColumn className="none-wrap" style={{ display: (item.http_url_image_appends === '' ? 'flex' : 'none') }}>
                        <Image className="no-img" preview={false} src={noDataImg}></Image>
                        暂无图片
                      </ZgColumn>
                    </ZgRow>
                  </div>
                  <div className="info">
                    <div>{item.canteen_name}</div>
                    {/* {["tag", item.count > 0 ? "blue" : "yellow"].join(" ")} */}
                    <div style={{ width: '60px' }} className={[item.state === 1 ? "red" : ""].join(" ")}>
                      {item.state === 1 ? "未处理" : "已处理"}
                    </div>
                  </div>
                  <div className="msg">{item.correct_type}</div>
                </div>
              </Col>
            ))}
          </Row>
        </ZgCard>
      </div>
    </>
  )
}
