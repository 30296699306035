import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Image } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getAccomList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;

export default function AccomList() {
  const [timeList, setTimeList] = useState('')//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数

  function time_change(data: any) {
    let nu = "";
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList(nu)
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getAccomList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`,
      width: '5%'
    },
    // {
    //   title: '陪餐时间',
    //   dataIndex: 'accompany_date',
    //   key: 'accompany_date',
    //   width: '10%'
    // },
    {
      title: '陪餐时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '10%'
    },
    {
      title: '陪餐类型',
      dataIndex: 'type',
      key: 'type',
      width: '10%'
    },
    {
      title: '陪餐餐次',
      dataIndex: 'meal_times',
      key: 'meal_times',
      width: '8%'
    },
    {
      title: '配餐人',
      dataIndex: 'companions_name',
      key: 'companions_name',
      width: '9%'
    },
    {
      title: '陪餐人职务',
      dataIndex: 'companions_job',
      key: 'companions_job',
      width: '10%'
    },
    {
      title: '陪餐图片',
      dataIndex: 'images_appends',
      render: (images_appends: any) => (
        images_appends !== "" ? <Image src={images_appends.split(",")[0]} alt="" style={{ width: '48px', height: '42px' }} /> : "无"
      ),
      width: '10%'
    }, {
      title: '评价',
      dataIndex: 'appraise',
      key: 'appraise',
      width: '30%'
    }, {
      title: '记录人',
      dataIndex: 'receive_name',
      key: 'receive_name',
      width: '8%'
    }

  ];

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">食堂陪餐</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>陪餐时间 :</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              scroll={{ x: 1280 }}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >
            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}