import ChartTitle from "./chartTitle";
import styles from '../less/order.module.less'
import { Radio, Space, DatePicker, Row, Col, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import MD5 from '../../../../utils/md5'

import { RankingList } from '../../../../apis/merchant/order'

export default function StatisticsTwo(props: any) {
  const { RangePicker } = DatePicker;

  /**
*  当前时间戳（10位）
*/
  const timestamp = () => {
    let outcome = Math.round(new Date().getTime() / 1000).toString();
    return Number(outcome)
  }


  const [rankingList, setRankingList] = useState([]);
  const [time_frame, setTime_frame] = useState('')
  const [method, setMethod] = useState(1)

  // 月统计 --  年统计
  const onRadioChange = (val: any) => {
    setMethod(Number(val.target.value))
  }

  // 统计时间
  const onPickerChange = (dates: any, dateString: any) => {
    if (dates) {
      setMethod(3)
      setTime_frame(dateString.join("~"))
    } else {
      setMethod(1)
      setTime_frame('')
    }
  }

  // 商户订单排行榜 列表数据
  const get_rankingList = useCallback(async () => {
    // signcode 32位 MD5加密
    let sign = MD5('1046' + timestamp() + 'E80AB7149', 32)
    let data = {
      method: method,
      time: timestamp(),
      app_id: 1046,
      signcode: sign,
      time_frame: time_frame
    }
    const res = await RankingList(data)
    if (res.status === 200) {
      // console.log(res.data);
      setRankingList(res.data.data)
    }
  }, [method, time_frame])

  useEffect(() => {
    get_rankingList()
  }, [get_rankingList])



  const columns = [
    {
      title: "排名",
      dataIndex: "rank",
      render: (id: any, record: any, index: number) => `${record.rank}`,
    },
    {
      title: "商户名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "商户类型",
      dataIndex: "type",
      key: 'type'
    },
    {
      title: "订单数量",
      dataIndex: "order_nums",
      key: 'order_nums'
    },
    {
      title: "订单占比",
      dataIndex: "ratio",
      key: 'ratio'
    },
  ]

  return (
    <>
      <div className={styles.statistics_table}>
        <div className={styles.table_title}>
          <ChartTitle text='商户订单排行榜' unit=''></ChartTitle>
          <div>
            <Space>
              <Radio.Group onChange={onRadioChange} defaultValue="1" >
                <Radio.Button value="1">月统计</Radio.Button>
                <Radio.Button value="2">年统计</Radio.Button>
              </Radio.Group>
              <div className={styles.statistical_time} >
                <span>统计时间</span>
                <RangePicker
                  style={{ width: '230px', }}
                  onChange={onPickerChange}
                  bordered={false}
                />
              </div>
            </Space>
          </div>
        </div>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={rankingList}
              rowKey={(columns) => columns.rank}
              pagination={false}
              scroll={{ y: 'calc(100vh - 300px)' }}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
