import { Breadcrumb, Col, Row, Tabs } from "antd";
import InformationIndex from './components/information'
import './less/information.less'

export default function InformationList() {
  const { TabPane } = Tabs

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>新闻资讯</Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row >
            <Col span={24} className="gutter-row">
              <Tabs defaultActiveKey="0" >
                <TabPane tab="新闻资讯" key="0">
                  <InformationIndex></InformationIndex>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}