import React, { useCallback, useState, useEffect } from "react"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import "../index.less"
import ReactECharts from "echarts-for-react"
import { dailyIndexTrend } from "../../../../apis/intelligence-rectify/intelligence-rectify"

// 风险指数区域--评估分数曲线
export default function ScoreCurve(props: any) {
  const [data, setData] = useState([])
  const [time, setTime] = useState([])
  const option = {
    color: ["#FFB668"],
    grid: {
      left: "3%",
      right: "4%",
      top: "5%",
      bottom: "0%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: time,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "邮件营销",
        type: "line",
        stack: "总量",
        data: data,
      }
    ],
  }
  const get_data = useCallback(
    () => {
      return dailyIndexTrend({ canteen_number: props.canteenNumber, sdate: props.sdate, edate: props.edate }).then(res => {
        let timeList: any = []
        let dateList: any = []
        res.data.forEach((item: any) => {
          dateList.push(item.score)
          timeList.push(item.date)
        })
        setData(dateList)
        setTime(timeList)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate, props.edate],
  )
  useEffect(() => {
    get_data()
  }, [get_data])
  return (
    <div style={{ paddingBottom: 12 }}>
      <div className="work-wrap">
        <ZgRow justify="space-between" align="flex-start">
          <ZgTitle title="厨房工作完成指标分析"></ZgTitle>
          {/* <IconFont type="iconicon-test3" className="icon" /> */}
        </ZgRow>
        <div className="card">
          <ReactECharts option={option} style={{ height: 292 }} />
        </div>
      </div>
    </div>
  )
}
