import { Col, Row, Select, Space, DatePicker, Table, Modal, Button, Image } from "antd"
import { useCallback, useEffect, useState } from "react"
import { complaintList } from '../../../apis/interactive/interactive_list';
import { getCanteenList } from "../../../apis/warningManage/correction/list";
import Detail from './complaintDetail'
import ZgCascader from '../../../components/zg-cascader'


export default function ComplaintList() {
  const { Option } = Select
  const { RangePicker } = DatePicker

  const [currentPage, setCurrentPage] = useState(1); //当前页码
  const [totalCount, setTotalCount] = useState(0); //总数
  const [perPage, setPerPage] = useState(15); //每页条数
  const [date, setDate] = useState([]); // 时间查询
  const [canteen_name, setCanteenName] = useState("") //食堂名称
  const [shoolname, setShoolName] = useState<any[]>([])
  const [dataSource, setDataSource] = useState([])
  const [checkId, setCheckId] = useState("") //查看详情id
  const [showDetail, setShowDetail] = useState(false) //详情弹窗
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区


  //单位名称查询
  function setName(val: any) {
    console.log("单位名称", val)
    setCanteenName(val);
    setCurrentPage(1);
  }
  //时间查询
  function handDate(dates: any, date: any) {
    console.log("dates", dates)
    console.log("date", date)
    setDate(date)
    setCurrentPage(1);
  }
  //checkDetail
  function checkDetail(id: any) {
    console.log("查看回复", id)
    setCheckId(id)
    return setShowDetail(true)
  }

  // 分页数据
  function changePage(val: number) {
    setCurrentPage(val)
  }

  // 关闭弹窗
  function to_close() {
    return setShowDetail(false)
  }
  function search() {
    to_get_List()
  }

  // 查询数据
  const to_get_List = useCallback(() => {
    return complaintList({
      canteen_name: canteen_name,
      sdate: date[0],
      edate: date[1],
      city: cityName,
      // is_reply: 1, 
      district: districtName,
    }).then(res => {
      console.log("res", res.data.data)
      setTotalCount(res.data.total)
      setDataSource(res.data.data)
      setPerPage(res.data.per_page)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canteen_name, date[0], date[1], cityName, districtName])


  useEffect(() => {
    to_get_List()
  }, [to_get_List])

  //列表数据
  const columns = [
    {
      title: '序号',
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
      width: "80",
    },
    {
      title: '餐饮单位',
      dataIndex: 'canteen_name',
      key: 'canteen_name',
      render: (text: any, record: any) => <span>{record.canteen.canteen_name}</span>,
    },
    // {
    //   title: '投诉内容',
    //   dataIndex: 'type_name',
    //   key: 'type_name',
    // },
    {
      title: '投诉内容',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: '投诉图片',
      dataIndex: 'pic_appends',
      key: 'pic_appends',
      // render: (record: any) => ()
      render: (pic_appends: any) => (
        pic_appends.split(',').map((e: any) => {
          return <Image key={e} className="img" width={50} height={50} src={e} />
        })
      )
    },
    {
      title: '提交人',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '联系方式',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '提交时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        record.is_reply === 1 ? <>
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail(record.id)}
            ghost={true}
          >
            查看回复
          </Button>
        </> : ''

      ),
    },
  ];
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  //渲染下拉框数据
  useEffect(() => {
    getCanteenList({}).then(res => {
      setShoolName(res.data)
    })
  }, [])
  const shoolNames = shoolname.map(e => <Option key={e.id} value={e.canteen_name} >{e.canteen_name}</Option>);
  return (
    <>
      <div className="zg-container" style={{ overflow: "auto", height: "100%" }}>
        <div className="zg-container-content inspection-check-wrap">
          {/* <Row>
            <Col span={24} className="gutter-row">
              <Tabs defaultActiveKey="1" onChange={changeType}>
                <TabPane tab="评价建议" key="1"></TabPane>
                <TabPane tab="消费者投诉" key="2"></TabPane>
              </Tabs>
            </Col>
          </Row> */}
          <Row style={{ marginBottom: "20px" }}>
            <Col span={20}>
              <span>提交时间：</span>
              <Space
                direction="vertical"
                size={12}
                style={{ marginRight: "20px" }}
              >
                <RangePicker onChange={handDate} />
              </Space>
              <span>单位名称：</span>
              <Select
                showSearch
                placeholder="请输入单位名称"
                style={{ width: "150px", marginRight: "20px" }}
                onChange={setName}
                allowClear
              >
                <Option value="">全部</Option>
                {shoolNames}
              </Select>
              {/* 所属区域查询功能 */}
              <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              <Button type="primary" onClick={search}>
                查询
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataSource}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />

            </Col>
          </Row>
        </div>
      </div>
      <Modal
        visible={showDetail}
        title="详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
    </>
  )
}