import React, { useState, useCallback, useEffect } from "react"
import { Breadcrumb, Row, Col, Button, Image } from "antd"
import { useHistory } from "react-router-dom"
import "../../../style/inspection.less"
import { taskDetail } from "../../../apis/inspection-check/inspection"
export default function InspectionDetail(props: any) {
  let history = useHistory()
  const [id] = useState(props.location.state)
  const [detail, setDetail] = useState({
    state: 1,
    title: "",
    canteensmanage: { realname: "" },
    canteens: { canteen_name: "" },
    problem_describe: "",
    inspection_time: "",
    reply: [
      {
        content: "",
        reply_user_name: "",
        reply_user_role: "",
        reply_images: [],
        updated_at: "",
      },
    ],
    imgs1: [{ image_url: "" }],
    imgs2: [{ image_url: "" }],
  })
  function to_url() {
    history.push({
      pathname: "/inspection/list",
    })
  }
  const get_detail = useCallback(() => {
    return taskDetail({ id: id }).then((res) => {
      setDetail(res.data)
    })
  }, [id])
  useEffect(() => {
    get_detail()
  }, [get_detail])
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>日常巡检</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/inspection/list">日常巡检</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row
            style={{
              fontSize: "20px",
              paddingBottom: "24px",
              borderBottom: "1px solid #E8EBF9",
            }}
          >
            <Col span={22}>巡检详情</Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Button
                type="default"
                onClick={to_url}
                style={{
                  border: "1px solid #409EFF",
                  borderRadius: 0,
                  color: "#409EFF",
                }}
              >
                返回
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            {detail.state === 1 ? (
              <Col
                style={{
                  border: "1px solid #F5DAB1",
                  backgroundColor: "#FDF6EC",
                  color: "#E6A23C",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：
                <span className="">待回复</span>
              </Col>
            ) : (
              <Col
                style={{
                  border: "1px solid #C2E7B0",
                  backgroundColor: "#F0F9EB",
                  color: "#67C23A",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：<span className="green-color">已回复</span>
              </Col>
            )}
          </Row>
          <div className="title first-title">基本信息</div>
          <div className="info-line info-flex">
            <div className="info-item" style={{ width: "100vw" }}>
              巡检主题：{detail.title}
            </div>
          </div>
          <div className="info-line info-flex">
            <div className="info-item">
              巡检单位：{detail.canteens.canteen_name}
            </div>
          </div>
          <div className="info-line info-flex">
            <div className="info-item">
              巡检人员：{detail.canteensmanage.realname}
            </div>
            <div className="info-item">巡检时间：{detail.inspection_time}</div>
          </div>
          <div className="info-line">
            <div className="info-item">巡检单：</div>
            <Image.PreviewGroup>
              {detail.imgs1.map((item: any, index: any) => (
                <Image
                  key={index}
                  className="detail-img"
                  src={item.image_url}
                  style={{
                    display:
                      item.image_url === "" || item.image_url == null
                        ? "none"
                        : "",
                  }}
                />
              ))}
            </Image.PreviewGroup>
          </div>
          <div className="info-line">任务说明：</div>
          <div style={{ marginTop: "10px", marginLeft: "20px" }}>
            <div className="info-item">
              {detail.problem_describe === ""
                ? "暂无巡检说明。"
                : detail.problem_describe}
            </div>
            <Image.PreviewGroup>
              {detail.imgs2.map((item: any, index: any) => (
                <Image
                  key={index}
                  className="detail-img"
                  src={item.image_url}
                  style={{
                    display:
                      item.image_url === "" || item.image_url == null
                        ? "none"
                        : "",
                  }}
                />
              ))}
            </Image.PreviewGroup>
          </div>
          <div style={{ display: detail.state === 2 ? "block" : "none" }}>
            <div className="title">处理说明</div>
            <div className="info-line">
              <div className="info-item">
                <span>处理人：</span>
                <span>
                  {detail.reply.length > 0
                    ? detail.reply[0].reply_user_name
                    : ""}
                </span>
              </div>
              <div className="info-item">
                <span>处理时间：</span>
                <span>
                  {detail.reply.length > 0 ? detail.reply[0].updated_at : ""}
                </span>
              </div>
              <div className="info-item">
                <span>处理情况说明：</span>
                <span>
                  {detail.reply.length > 0 ? detail.reply[0].content : ""}
                </span>
              </div>
              <Image.PreviewGroup>
                {detail.reply.length > 0
                  ? detail.reply[0].reply_images.map(
                      (item: any, index: any) => (
                        <Image key={index} className="detail-img" src={item} />
                      )
                    )
                  : ""}
              </Image.PreviewGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
