import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd';
import BaseInfo from './baseInfo'
import StacInfo from './stacInfo'
import Video from './video'
import Score from './score'
import { useHistory } from "react-router-dom";
import $storeLocal from 'storejs'
import { getRemoteToken } from '../../../apis/canteen-info/list'

export default function CanteenInfoIndex() {
  const [score, setScore] = useState(0)
  let history = useHistory()
  let number = history.location.search.split('=')[1]

  // 不能依赖于number，否则会出现奇怪的bug（进入右侧内页后，在点击左侧切换食堂，右侧数据不更新问题）
  const [canteenNumber, setNumber] = useState('')

  function to_set_score(data: any) {
    setScore(data)
  }

  const updateNumber = () => {
    let host = 'https://proxy.deyicy.com/' + number
    getRemoteToken({ number: number }).then(res => {
      $storeLocal.set('canteenRemoteInfo', { host: host, number: number, token: res.data.token })
      setNumber(number)
    })
  }

  useEffect(() => {
    updateNumber()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number])

  return (
    <>
      <Row gutter={12} className="index-wrap">
        <Col span={17}>
          <BaseInfo number={canteenNumber}></BaseInfo>
          <StacInfo set_score={to_set_score} number={canteenNumber}></StacInfo>
        </Col>
        <Col span={7}>
          <Video number={canteenNumber}></Video>
          <Score score={score} number={canteenNumber}></Score>
        </Col>
      </Row>
    </>
  )
};