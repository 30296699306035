import React, { useCallback, useState, useEffect } from "react"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import "../index.less"
import ReactEcharts from "echarts-for-react"
import { dailyIndexTrend } from "../../../../apis/intelligence-rectify/intelligence-rectify"

// 风险指数区域--风险预警趋势
export default function RiskTrend(props: any) {
  const [correctCount, setCorrectCount] = useState([])
  const [goodsExpiredCount, setGoodsExpiredCount] = useState([])
  const [healthCertificateExpiredCount, setHealthCertificateExpiredCount] = useState([])
  const [time, setTime] = useState([])
  const [tbIndex, setTbIndex] = useState(1)
  const echartsOption = {
    color: ["#80FFA5"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      top: "10%",
      bottom: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: time,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        type: "line",
        stack: "总量",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: {
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FFFFFF",
              },
              {
                offset: 1,
                color: "#0062FF",
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: correctCount,
      },
    ],
  }

  const goodsExpiredCountOption = {
    color: ["#80FFA5"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      top: "10%",
      bottom: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: time,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        type: "line",
        stack: "总量",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: {
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FFFFFF",
              },
              {
                offset: 1,
                color: "#0062FF",
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: goodsExpiredCount,
      },
    ],
  }

  const healthCertificateExpiredCountOption = {
    color: ["#80FFA5"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      top: "10%",
      bottom: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: time,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        type: "line",
        stack: "总量",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: {
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FFFFFF",
              },
              {
                offset: 1,
                color: "#0062FF",
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: healthCertificateExpiredCount,
      },
    ],
  }

  function tbClick(val: any) {
    setTbIndex(val)
  }
  const get_data = useCallback(
    () => {
      return dailyIndexTrend({ canteen_number: props.canteenNumber, sdate: props.sdate, edate: props.edate }).then(res => {
        console.log("风险预警趋势")
        console.log(res)
        let timeList: any = []
        let correctCountList: any = []
        let goodsExpiredCountList: any = []
        let healthCertificateExpiredCountList: any = []
        res.data.forEach((item: any) => {
          correctCountList.push(item.correct_count)
          goodsExpiredCountList.push(item.goods_expired_count)
          healthCertificateExpiredCountList.push(item.health_certificate_expired_count)
          timeList.push(item.date)
        })
        setCorrectCount(correctCountList)
        setGoodsExpiredCount(goodsExpiredCountList)
        setHealthCertificateExpiredCount(healthCertificateExpiredCountList)
        setTime(timeList)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate, props.edate],
  )
  useEffect(() => {
    get_data()
  }, [get_data])
  return (
    <div>
      <div className="work-wrap">
        <ZgRow justify="space-between" align="flex-start">
          <ZgTitle title="风险预警趋势"></ZgTitle>
          {/* <IconFont type="iconicon-test3" className="icon" /> */}
        </ZgRow>
        <div className="card">
          <div className="select">
            <div onClick={() => tbClick(1)} className={["sel-left", tbIndex === 1 ? "black-color" : ""].join(" ")}>
              视频预警
            </div>
            <div onClick={() => tbClick(2)} className={["sel-con", tbIndex === 2 ? "black-color" : ""].join(" ")}>
              原料预警
            </div>
            <div onClick={() => tbClick(3)} className={["sel-right", tbIndex === 3 ? "black-color" : ""].join(" ")}>
              证件预警
            </div>
          </div>
          {
            tbIndex === 1 ? <ReactEcharts
              option={echartsOption}
              theme="clear"
              style={{ height: 279 }}
            ></ReactEcharts> : tbIndex === 2 ? <ReactEcharts
              option={goodsExpiredCountOption}
              theme="clear"
              style={{ height: 279 }}
            ></ReactEcharts> : <ReactEcharts
              option={healthCertificateExpiredCountOption}
              theme="clear"
              style={{ height: 279 }}
            ></ReactEcharts>
          }
        </div>
      </div>
    </div>
  )
}
