import { Image, Radio, DatePicker, Space } from 'antd'
import ChartTitle from './chartTitle'
import styles from '../less/order.module.less'
import AirICon from '../../../../assets/images/shape.png'
import echarts from 'echarts'
import { useCallback, useEffect, useState } from 'react'
import MD5 from '../../../../utils/md5'

import { MerchantTypeRatio, Statistics } from '../../../../apis/merchant/order'

export default function StatisticsOne(props: any) {
  /**
   *  当前时间戳（10位）
   */
  const timestamp = () => {
    let outcome = Math.round(new Date().getTime() / 1000).toString()
    return Number(outcome)
  }

  const { RangePicker } = DatePicker
  const [method, setMethod] = useState(1)
  const [time_frame, setTime_frame] = useState('')
  const [statistics, setStatistics] = useState({
    count: 0,
    list: [
      {
        nums: '0',
        time: '2022-06-01'
      }
    ]
  })
  // 当前时间戳
  // function timestamp() {
  //   let outcome: number = Math.round(new Date().getTime() / 1000)
  //   return outcome
  // }

  // 统计时间
  const onPickerChange = (dates: any, dateString: any) => {
    if (dates) {
      setMethod(3)
      setTime_frame(dateString.join('~'))
    } else {
      setMethod(1)
      setTime_frame('')
    }
  }
  // 月统计 --  年统计
  const onRadioChange = (val: any) => {
    // console.log(val.target.value);
    setMethod(Number(val.target.value))
  }

  const get_statistics = useCallback(async () => {
    // signcode 32位 MD5加密
    let sign = MD5('1046' + timestamp() + 'E80AB7149', 32)
    let data = {
      method: method,
      time: timestamp(),
      app_id: 1046,
      signcode: sign,
      time_frame: time_frame
    }
    const res = await Statistics(data)
    if (res.status === 200) {
      // console.log("get_statistics", res.data);
      setStatistics(res.data.data)
      // 日期
      let dateList: any = []
      // 值
      let dataVal: any = []
      res.data.data.list.forEach((el: any) => {
        // console.log("el====", el);
        if (method === 2 || method === 3) {
          dateList.push(el.time)
        } else {
          dateList.push(el.time.substring(5, 10))
        }
        dataVal.push(el.nums)
      })

      var ele1: any = document.getElementById('chartDom1')
      var myChart1 = echarts.init(ele1)
      myChart1.setOption({
        color: ['#5473E8'],
        tooltip: {
          trigger: 'item',
          backgroundColor: '#FFFFFF',
          borderColor: '#E8EBF9',
          borderWidth: 0.5,
          textStyle: {
            color: '#00000'
          },
          formatter: function (params: any) {
            var htmlStr = '<div>'
            // htmlStr += params.value + '<br/>';   //获取纵坐标的值
            // htmlStr += params.name + '<br/>';   //获取横坐标的值
            htmlStr += `订单数量：${params.value} <br/>`
            return htmlStr
          }
        },
        xAxis: {
          name: '日期',
          nameLocation: 'start',
          nameTextStyle: {
            padding: [25, 0, 0, 0]
          },
          data: dateList
        },
        yAxis: {
          splitLine: { show: false },
          name: '订单数量',
          nameTextStyle: {
            align: 'center'
          }
        },
        series: [
          {
            data: dataVal,
            type: 'line',
            smooth: true
          }
        ]
      })
    }
  }, [method, time_frame])

  useEffect(() => {
    get_statistics()
  }, [get_statistics])

  //js随机产生颜色
  // const randomColor = () => {
  //   var str = '#'
  //   for (var i = 0; i < 6; i++) {
  //     str += Math.floor(Math.random() * 16).toString(16)
  //   }
  //   return str
  // }

  const get_merchantTypeRatio = useCallback(async () => {
    const res = await MerchantTypeRatio({})
    if (res.status === 200) {
      var ele2: any = document.getElementById('chartDom2')
      var myChart2 = echarts.init(ele2)

      //定义存放颜色的数组
      const colors: any = []

      const dataList: { value: any; name: any }[] = []

      res.data.data.list.forEach((item: any) => {
        colors.push(item.color)

        let list = {
          value: item.nums,
          name: item.cat_name
          // cat_name: item.cat_name, //分类名
          // hotel: item.hotel, //数量
          // hotel_ratio: item.hotel_ratio //占比
        }
        dataList.push(list)
      })
      console.log(dataList)

      myChart2.setOption({
        color: colors,
        title: {
          text: `${res.data.data.merchant_total_nums}`,
          subtext: '入驻总数',
          left: '33.33%',
          top: 'center',
          textStyle: {
            fontSize: 35
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          bottom: '40%',
          right: '20%'
          // data: ['商务酒店', '趣味烧烤', '田园农庄', '便携快餐']
        },
        series: [
          {
            type: 'pie',
            right: '26%',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              formatter: '{d}%',
              position: 'inside'
            },
            labelLine: {
              show: false
            },
            data: dataList
          }
        ]
      })
    }
  }, [])

  useEffect(() => {
    get_merchantTypeRatio()
  }, [get_merchantTypeRatio])

  return (
    <>
      <div className="zg-container-content" style={{ width: '100%', height: 'calc((100vh - 169px) / 2)' }}>
        <div style={{ height: '30%' }}>
          <ChartTitle text="订单趋势统计" unit="单位：个"></ChartTitle>
          <div className={styles.statistics_count}>
            <div className={styles.statistics_left}>
              <div className={styles.count_icon}>
                <Image width={19} height={19} preview={false} src={AirICon} />
              </div>
              <div className={styles.count_order}>
                <div className={styles.count_title}>订单总数</div>
                <div className={styles.count_val}>
                  {statistics.count} <span>个</span>
                </div>
              </div>
            </div>
            <div>
              <Space>
                <Radio.Group onChange={onRadioChange} defaultValue="1">
                  <Radio.Button value="1">月统计</Radio.Button>
                  <Radio.Button value="2">年统计</Radio.Button>
                </Radio.Group>
                <div className={styles.statistical_time}>
                  <span>统计时间</span>
                  <RangePicker style={{ width: '230px' }} onChange={onPickerChange} bordered={false} />
                </div>
              </Space>
            </div>
          </div>
        </div>
        <div id="chartDom1" style={{ width: '96%', height: '70%' }}></div>
      </div>
      <div
        className="zg-container-content"
        style={{ width: '100%', height: 'calc((100vh - 169px) / 2)', marginTop: '15px' }}
      >
        <ChartTitle text="商户类型占比" unit="单位：家"></ChartTitle>
        <div id="chartDom2" style={{ width: '90%', height: '90%' }}></div>
      </div>
    </>
  )
}
