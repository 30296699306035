import React, { useEffect, useState, useCallback } from 'react'
import { Table, Row, Col, Button, Modal } from 'antd';
import ZgSearch from '../../components/zg-search';
import '../../style/button.less';
import ZgRow from '../../components/zg-row';
import Detail from '../canteen/supplier/detail'
import { getSignSupplierList } from '../../apis/canteen-info/proxy-info'

export default function SupplierList(props: any) {
  const [showDetail, setShowDetail] = useState(false)
  const [supplierList, setSupplier] = useState([])
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  const [supplierName, setSupplierName] = useState('')  //食堂名称
  const [checkId, setCheckId] = useState('')  //查看详情id

  //获取供应商
  let to_get_supplier = useCallback(() => {
    return getSignSupplierList({ page: currentPage, name: supplierName }).then(res => {
      setSupplier(res.data.data)
      setTotalCount(res.data.total)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, supplierName])

  useEffect(() => {
    to_get_supplier()
  }, [to_get_supplier])

  // 获取供应商名称
  let getSupplierName = (data: any) => {
    setSupplierName(data)
  }

  // 设置查看的食堂id
  let to_set_id = (data: any) => {
    setCheckId(data)
  }

  function checkDetail(data: any) {
    to_set_id(data)
    // console.log(checkId)
    return setShowDetail(true)
  }

  function to_close() {
    return setShowDetail(false)
  }

  function changePage(val: number) {
    setCurrentPage(val)
  }

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      key: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '供应商名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '注册类型',
      dataIndex: 'type',
      key: 'type',
      render: (text: any, record: any) => `${text === 0 ? "公司" : "个人"}`

    }, {
      title: '法人姓名',
      dataIndex: 'contact_name',
      key: 'contact_name',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '签约时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '操作',
      key: 'action',
      render: (record: any) => (
        <Button className="btn" type="primary" onClick={() => checkDetail(record.number)} ghost={true}>查看详情</Button>
      )
    }
  ];

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">供应商列表</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <ZgSearch getInputValue={getSupplierName} placeholder="请输入供应商名称"></ZgSearch>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={supplierList}
              rowKey={columns => columns.id}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >
            </Table>
          </Col>
        </Row>
      </div>
      <Modal
        visible={showDetail}
        title="供应商详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
    </>
  )
}