import request from "../../../utils/request"

/**
 * 获取食堂列表
 */
export function getCertList(data: any) {
  return request({
    url: "/app/warning/cert/pc-list",
    method: "get",
    params: data,
  })
}

/**
 * 生成整改单
 */
export function addTask(data: any) {
  return request({
    url: "/app/inspection-tasks/add",
    method: "post",
    data,
  })
}

/**
* 获取食堂名称列表
*/
export function getDevicesList(data: any) {
  return request({
    url: '/app/canteen/all',
    method: 'get',
    params: data
  })
}
/**
 * 获取我管辖的单位
 */
export function mineCanteens(data: any) {
  return request({
    url: "/app/inspection-tasks/mine-canteens-list",
    method: "get",
    params: data,
  })
}
