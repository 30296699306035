// 当前日期
export const getCurDate = function () {
  let curDate = new Date()
  let year = curDate.getFullYear()
  let month = curDate.getMonth() + 1
  let date = curDate.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }
  return year + '-' + month + '-' + date
}

// 日期后几天转化
export const afeterDate = function (n) {
  let time = new Date(new Date().getTime() + n * 24 * 60 * 60 * 1000)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }
  return year + '-' + month + '-' + date
}