import React, { useState, useCallback, useEffect } from "react"
import { Breadcrumb, Row, Col, Button, Image } from "antd"
import { useHistory } from "react-router-dom"
import { getCanteenPartyInfo } from '../../apis/potluck-user/potluck'
import './less/detail.less'

export default function InspectionDetail(props: any) {
  let history = useHistory()
  const [id] = useState(props.location.state)
  const [detail, setDetail] = useState({
    address: '',
    approve_desc: '',
    approve_time: '',
    sdate: '', // 聚餐开始时间
    edate: '', // 聚餐结束时间
    approve_user: {
      name: '',
      organization: ''
    },
    approve_user_id: '',
    area_name: '',
    canteen: {
      canteen_name: ''
    },
    canteen_number: '',
    created_at: '',
    dishes: '',
    updated_at: '2020-02-02 ',
    id: '',
    pics: '',
    pics1: [],
    scale: '',
    state: '',
    title: '',
    user_name: '',
    village_code: '',
  })
  function to_url() {
    history.push({
      pathname: "/PotluckUser/list",
    })
  }
  const get_detail = useCallback(() => {
    return getCanteenPartyInfo({ id: id }).then((res) => {
      res.data.pics1 = res.data.pics.split(',')
      console.log("====>图片", res.data.pics);
      setDetail(res.data)
    })
  }, [id])
  useEffect(() => {
    get_detail()
  }, [get_detail])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>聚餐申报</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/PotluckUser/list">聚餐申报</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content putluck-check-wrap">
          <Row
            style={{
              fontSize: "20px",
              paddingBottom: "24px",
              borderBottom: "1px solid #E8EBF9",
            }}
          >
            <Col span={22}>聚餐详情</Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Button
                type="default"
                onClick={to_url}
                style={{
                  border: "1px solid #409EFF",
                  borderRadius: 0,
                  color: "#409EFF",
                }}
              >
                返回
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            {
              detail.state == "0" ? (<Col style={{
                border: "1px solid rgba(217, 224, 255, 1)",
                backgroundColor: "rgba(239, 242, 255, 1)",
                color: "rgba(79, 111, 255, 1)",
                padding: "10px 0px 10px 15px",
                fontWeight: 600,
              }}
                span={24}
              >
                状态：
                <span className="">待审批</span>
              </Col>) : detail.state == "1" ? (
                <Col
                  style={{
                    border: "1px solid #rgba(176, 255, 208, 1)",
                    backgroundColor: "rgba(239.7, 255, 245.82, 1)",
                    color: "rgba(67, 207, 124, 1)",
                    padding: "10px 0px 10px 15px",
                    fontWeight: 600,
                  }}
                  span={24}
                >
                  状态：<span className="">审批通过</span>
                </Col>
              ) : detail.state == "2" ? (<Col
                style={{
                  border: "1px solid rgba(255, 224, 217, 1)",
                  backgroundColor: "rgba(255, 250.835, 249.9, 1)",
                  color: "rgba(212, 48, 48, 1)",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：<span className="">不予审批</span>
              </Col>) : (<Col
                style={{
                  border: "1px solid rgba(230, 230, 230, 1)",
                  backgroundColor: "rgba(247.35, 247.35, 247.35, 1)",
                  color: "rgba(128, 128, 128, 1)",
                  padding: "10px 0px 10px 15px",
                  fontWeight: 600,
                }}
                span={24}
              >
                状态：<span className="">已取消
                  <span style={{
                    paddingLeft: "20px",

                  }}>取消时间:  {detail.updated_at}</span>
                </span>
              </Col>)
            }
          </Row>
          <div className="title first-title">申报人信息</div>
          <div className="info-line info-flex">
            <div className="info-item  monad">
              申报单位 <span className="canteen_name">{detail.canteen.canteen_name}</span>
            </div>
            <div className="info-item  monad">
              申报时间 <span className="canteen_name">{detail.created_at}</span>
            </div>
            <div className="info-item  monad">
              申报人 <span className="canteen_name">{detail.user_name}</span>
            </div>
          </div>

          <div className="title first-title">聚餐申报详情</div>
          <div className="info-flex info-left">
            <div className="info-item monad" style={{ width: '100vw' }}>聚餐事由
              <span className="canteen_name">{detail.title} </span>
            </div>
          </div>
          <div className="info-flex info-left">
            <div className="info-item monad" style={{ width: '100vw' }}>聚餐时间
              <span className="canteen_name">{detail.sdate == '' ? '暂无' : `${detail.sdate}~${detail.edate}`}  </span>
            </div>
          </div>
          <div className="info-flex info-left">
            <div className="info-item monad" style={{ width: '100vw' }}>聚餐地址
              <span className="canteen_name">{detail.area_name} </span>
            </div>
          </div>
          <div className="info-flex info-left">
            <div className="info-item monad" style={{ width: '100vw' }}>聚餐规模
              <span className="canteen_name">{detail.scale} 桌</span>
            </div>
          </div>
          <div className="info-flex info-left" >
            <div className="info-item monad" style={{ width: '100vw' }}>聚餐菜单
              <span className="canteen_name">{detail.dishes} </span>
            </div>
          </div>
          <div className="info-flex info-left">
            <div className="info-item monad" style={{ width: '100vw', display: 'flex' }}>现场环境
              <Image.PreviewGroup>
                {detail.pics1.map((item: any, index: any) => (
                  <Image
                    key={index}
                    className="detail-img"
                    src={'https://img.hbzgyc.com//' + item}
                    style={{
                      display: item === "" || item == null ? "none" : "",
                    }}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>

          {detail.state == "1" || detail.state == '2' ? (
            <>
              <div className="title first-title">审批人信息</div><div className="info-line info-flex">
                <div className="info-item  monad">
                  申批单位<span className="canteen_name">{detail.approve_user.organization}</span>
                </div>
                <div className="info-item  monad">
                  申批时间<span className="canteen_name">{detail.approve_time}</span>
                </div>
                <div className="info-item  monad">
                  申批人<span className="canteen_name">{detail.approve_user.name}</span>
                </div>
              </div>
              {detail.approve_desc ?
                (<>
                  <div className="info-flex info-left">
                    <div className="info-item monad">审批说明
                      <span className="canteen_name">{detail.approve_desc} </span>
                    </div>
                  </div>
                </>)
                : ("")
              }
            </>
          ) : ('')}

        </div>
      </div >
    </>
  )
}
