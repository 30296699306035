import request from '../../../utils/request'
/**
 * 获取原料过期列表
 */
export function getMaterial (data: any) {
    return request({
      url: '/app/warning/goods/list',
      method: 'get',
      params: data
    })
  }
  
  /**
 * 获取食堂名称列表
 */
export function getCanteenList(data: any) {
  return request({
    url: '/app/canteen/all',
    method: 'get',
    params: data
  })
}
/**
* 生成整改单
*/
export function addTask(data: any) {
  return request({
    url: "/app/inspection-tasks/add",
    method: "post",
    data,
  })
}
