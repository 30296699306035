/**
 * api接口调用
 */
// import { message } from "antd";
import axios from "axios";
import $storeLocal from 'storejs'
// 创建axios实例
const service = axios.create({
  baseURL: $storeLocal.get('canteenRemoteInfo') ? $storeLocal.get('canteenRemoteInfo').host : '', // api的base_url
  headers: { 'content-type': 'application/json' },
  timeout: 0 // 请求超时时间,指定请求超时的毫秒数(0 表示无超时时间)
})

// request拦截器
service.interceptors.request.use(
  config => {
    let token = ''
    if ($storeLocal.get('canteenRemoteInfo')) {
      token = $storeLocal.get('canteenRemoteInfo').token
    }
    config.headers['Authorization'] = 'Bearer ' + token
    config.url = ($storeLocal.get('canteenRemoteInfo') ? $storeLocal.get('canteenRemoteInfo').host : '') + config.url

    return config
  },
  error => {
    // 请求错误的时候
    Promise.reject(error)
  })

// respone拦截器
service.interceptors.response.use(
  response => {
    // console.log('url..' + JSON.stringify(response))
    // response.url = $storeLocal.get('canteenRemoteInfo').url + response.url
    return response
  },
  error => {
    // if (error.response.status === 401) {

    // }
    return Promise.reject(error)
  }
)

export default service
