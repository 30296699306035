import React, { useState } from 'react'
import { Button, Modal, Space, DatePicker, message } from 'antd';
import { getExportIndex } from '../apis/board'
import dayjs from 'dayjs'
export default function ZgCascader(props: any) {
  const { RangePicker } = DatePicker
  const [isModalVisible, setIsModalVisible] = useState(false); // 导出框显示

  const [exportStart, setExportStartdate] = useState() // 导出开始时间
  const [exportEnd, setExportEnddate] = useState() // 导出结束时间

  // 导出框确认
  const onOkModal = () => {
    if (!exportStart && !exportEnd) {
      message.warning('请选择导出的时间');
      return
    }
    setIsModalVisible(false);
    const sdate = dayjs(exportStart).format("YYYY-MM-DD")
    const edate = dayjs(exportEnd).format("YYYY-MM-DD")
    console.log('传入的时间', sdate, edate)
    getExportIndex({ sdate, edate }).then((res) => {
      console.log(res.data)
      const a = document.createElement('a');
      const csvData = new Blob([res.data]);
      let file_name = '区域数据统计表.xlsx'
      document.body.appendChild(a);
      a.style.display = "none"
      let url = window.URL.createObjectURL(csvData);
      a.href = url;
      a.download = file_name;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      // let link = document.createElement('a') //创建一个a标签
      // link.style.display = 'none' //不显示
      // link.href = res.data //将地址填入
      // link.setAttribute('download', '表格.xlsx') //设置下载属性,取个文件名
      // document.body.appendChild(link) //添加到页面上
      // link.click() //点击触发下载
    })
  };
  const onCancelModal = () => {
    setIsModalVisible(false);
  };
  // showExport  显示导出时间
  const showExport = () => {
    console.log("导出功能")
    setIsModalVisible(true)
  }
  //获取时间
  function goExportWarning(dates: any, dateString: any) {
    console.log(dateString)
    setExportStartdate(dateString[0])
    setExportEnddate(dateString[1])
  }
  return (
    <>
      <Button
        style={{ marginLeft: "15px", float: "right" }}
        type="primary"
        onClick={showExport}
        ghost={true}
        className="down btn"
      >
        导出
      </Button>
      <Modal title="导出" visible={isModalVisible} onOk={onOkModal} onCancel={onCancelModal}>
        <>
          <div style={{ display: 'flex' }}>
            <p style={{ lineHeight: '30px' }}>导出时间：</p>
            <Space
              direction="vertical"
            >
              <RangePicker onChange={goExportWarning} />
            </Space>
          </div>
          <p style={{ marginTop: '15px', color: '#999999', lineHeight: '25px' }}>
            注：对管辖区域范围内所有的餐饮单位的预警信
            息按照不同风险等级进行汇总分析，（主要包含
            视频预警、物联预警、原料预警、健康证预警、
            餐饮证件预警5大类）</p>
        </>

      </Modal>
    </>
  )
}