import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getMorning } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;

export default function MorningList() {
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数

  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getMorning({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])

  function changePage(val: number) {
    setCurrentPage(val)

  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  // currentPage设置为1
  function searchList() {
    setCurrentPage(1)
  }

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    // {
    //   title: '检测时间',
    //   dataIndex: 'check_date',
    //   key: 'check_date',
    // },
    {
      title: '检测时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '姓名',
      dataIndex: 'check_user_name',
      key: 'check_user_name',
    }, {
      title: '发热',
      dataIndex: 'is_heat',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '咳嗽',
      dataIndex: 'is_cough',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '咽痛',
      dataIndex: 'is_sore_throat',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '恶心',
      dataIndex: 'is_nausea',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '呕吐',
      dataIndex: 'is_vomit',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '腹泻',
      dataIndex: 'is_diarrhea',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '服装',
      dataIndex: 'clothes',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '首饰',
      dataIndex: 'jewelry',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '皮肤感染',
      dataIndex: 'is_eczema',
      render: (text: any) => (
        <div>{text === 0 ? '否' : '是'}</div>
      )
    }, {
      title: '处理意见',
      dataIndex: 'is_normal',
      render: (text: any) => (
        <div>{text === 0 ? '异常' : '正常'}</div>
      )
    }
  ];

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">晨检记录</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>填报日期：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={searchList}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >
            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}