import TextArea from "antd/lib/input/TextArea";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";

export default function StateModal(props: any) {
  const [placeholder, setPlaceholder] = useState('')
  const [str, setStr] = useState()
  const [textArea, setTextArea] = useState('')
  useImperativeHandle(props.onRef, () => ({
    // onChild 就是暴露给父组件的方法
    textArea: textArea,
    vals: props.val
  }));

  const onChange = (e: any) => {
    setTextArea(e.target.value)
  }

  const getTextAny = useCallback(() => {

    let textAny1: any = (<div style={{ lineHeight: '30px', marginBottom: '8px' }}>禁用商户后，宜点平台将不再显示该商户，且商户平台部分功能无法使用，确定禁用该商户？</div>)
    let textAny2: any = (<div style={{ lineHeight: '30px', marginBottom: '8px' }}>启用商户后，宜点平台将展示该商户，商户平台功能恢复使用，确定启用该商户？</div>)
    if (props.val.status == '0') {
      setStr(textAny2)
      setPlaceholder('请输入商户启用原因（必填）')
    }
    if (props.val.status == '1') {
      setStr(textAny1)
      setPlaceholder('请输入商户禁用原因（选填）')
    }
    setTextArea('')
  }, [props])

  useEffect(() => {
    getTextAny()
    console.log("控件====", props.val.status);
  }, [props, getTextAny])

  return (
    <>
      {str}
      <div>
        <TextArea rows={4} placeholder={placeholder} onChange={onChange} value={textArea} />
      </div>
    </>
  )
}
