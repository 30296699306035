import request from "../../utils/request2"

/**
 * https://3c-supplier.deyicy.com/api/suppliers/statistics
 * 供应商总数
 * @param data
 */
export function getSuppliersStatistics(data: any) {
  return request({
    url: "/api/suppliers/statistics",
    method: "get",
    params: data,
  })
}

 