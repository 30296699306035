import React from 'react'
import { Breadcrumb } from 'antd'
import '../../../style/intelligence-rectify.less'
import DataLine from './components/data-line'
import DataCake from './components/data-cake'
import DataTit from './components/data-tit'
import DataRanking from './components/data-ranking'
export default function IntelligenceRectify() {
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>大数据分析</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/canteen/list">视频预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="home-wrap">
        <div className="wrap-left">
          <div className="wrap-left-tit">
            <DataTit></DataTit>
          </div>
          <div className="wrap-left-map">
            <div className="map-line"><DataLine></DataLine></div>
            <div className="map-cake"><DataCake></DataCake></div>
          </div>
        </div>
        <div className="wrap-right">
          <DataRanking></DataRanking>
        </div>
      </div>
    </>
  )
}