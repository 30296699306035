import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Modal } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getSample } from '../../../apis/canteen-info/proxy-checks'
import Detail from './detail'
const { RangePicker } = DatePicker;

export default function SampleList() {
  const [checkId, setCheckId] = useState('')
  const [showDetail, setShowDetail] = useState(false)
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数

  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getSample({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  function checkDetail(id: any) {
    setCheckId(id)
    return setShowDetail(true)
  }

  function to_close() {
    return setShowDetail(false)
  }

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '留样时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '留样餐次',
      dataIndex: 'meal_type',
      render: (text: any) => (
        <div>{text === 0 ? '早餐' : text === 1 ? '中餐' : text === 2 ? '晚餐' : '其他'}</div>
      )
    }, {
      title: '留样人',
      dataIndex: 'user_name',
      key: 'user_name',
    }, {
      title: '处理时间',
      key: 'handle_date',
      render: (item: any) => {
        return (
          item.handle_date ? item.handle_date : '--'
        )
      }
    }, {
      title: '处理人',
      key: 'handle_name',
      render: (item: any) => {
        return (
          !!item.handle_name ? item.handle_name : '--'
        )
      }
    },
    // {
    //   title: '处理情况',
    //   key: 'handle_descr',
    //   render: (item: any) => {
    //     return (
    //       item.handle_descr ? item.handle_descr : '无'
    //     )
    //   }
    // },
    {
      title: '处理状态',
      render: (record: any) => (
        <div>{record.handle_name === '' ? '未处理' : '已处理'}</div>
      )
    }, {
      title: '操作',
      key: 'action',
      render: (record: any) => (
        <Button type="default" onClick={() => checkDetail(record.id)} >查看详情</Button>
      )
    }
  ];

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">食品留样</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>留样时间：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >
            </Table>
          </Col>
        </Row>
      </div>

      <Modal
        visible={showDetail}
        title="留样记录详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
    </>
  )
}