import React from "react"

export default function ZgCard(props: any) {
  return (
    <>
      <div
        className={props.className}
        style={{ padding: "20px", background: "white", borderRadius: "5px" }}
      >
        {props.children}
      </div>
    </>
  )
}
