import { Breadcrumb, Button, Checkbox, Col, Form, Input, message, Row } from "antd";
import { useHistory } from "react-router-dom";
import '../less/add.less'
import ImageDragger from '../../../components/imageDragger'
// import WgEditor from '../../../components/wgEditor'
import NewEditor from '../../../components/NewEditor'
import $storeLocal from "storejs"
import { contentsSave, contentsInfo } from '../../../apis/information/information'
import { useCallback, useEffect, useState } from "react";


export default function AddInformation(props: any) {


  let info = {
    title: '',// 标题
    descr: '',//摘要
    content: '',
    thumbnail: '',
    user_name: '',
    recommend: '',
  }
  const [detail, setDetail] = useState(info)//数据
  const [detailId] = useState(props.location.state)//传过来的id
  const [recommend] = useState('')//状态
  const [showRegister, setimageUrl1] = useState('')//图片
  const [content, setcontent] = useState('')//内容
  const user_name = $storeLocal.get('UserBaseInfo').realname
  const history = useHistory();
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const [form] = Form.useForm();
  // 查询详情数据
  const get_detail = useCallback(() => {
    if (detailId) {
      return contentsInfo({ id: detailId }).then((res) => {
        console.log("res ", res.data)
        setDetail(res.data)
        let rec = res.data.recommend === 1 ? true : false
        setimageUrl1(res.data.thumbnail)
        form.setFieldsValue({
          title: res.data.title,
          descr: res.data.descr,
          user_name: res.data.user_name,
          recommend: rec
        });
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailId])
  useEffect(() => {
    get_detail()
  }, [get_detail])



  function onBack() {
    history.goBack()
  }

  // 保存
  function onFinish(val: any) {
    console.log(val)

    if (detailId) {
      let rqs: any = {
        id: detailId,
        content: content,//内容
        descr: val.descr,//摘要
        recommend: val.recommend ? 1 : 0,//状态
        title: val.title,//标题
        user_name: user_name,//发布人
        thumbnail: showRegister,//图片
      }
      console.log("修改", rqs)
      contentsSave(rqs).then(res => {
        console.log(res)
        message.success('保存成功');
        history.go(-1)
      })
    } else {
      let rqs: any = {
        content: content,//内容
        descr: val.descr,//摘要
        recommend: recommend ? 1 : 0,//状态
        title: val.title,//标题
        user_name: user_name,//发布人
        thumbnail: showRegister
      }
      console.log("保存", rqs)
      contentsSave(rqs).then(res => {
        console.log(res)
        message.success('保存成功');
        history.go(-1)
      })
    }
  }

  // 取消
  function onReset(val: any) {
    console.log("取消", val)
    history.go(-1)
  }

  // // 状态 置顶
  // function onTopChange(val: any) {
  //   console.log("置顶", val.target.checked)
  //   let num = val.target.checked
  //   setrecommend(num)
  // }

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>
          <span onClick={onBack}>新闻资讯</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          新增文章
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row style={{ fontSize: "20px", marginBottom: "10px" }}>
            <Col span={4}>编辑文章</Col>
          </Row>
          <Form name="nest-messages"
            form={form}
            onFinish={onFinish}
          >
            <Row>
              <Col span={18} className="title_wrap">
                <div className="title_name">
                  {/* <div className=""></div> */}
                  <Form.Item label="文章标题" rules={[{ required: true, message: '请输入文章标题' }]} colon={false} name="title">
                    < Input placeholder="请输入文章标题" />
                  </Form.Item>
                </div>
                <div>
                  <div className="">摘要</div>
                  <Form.Item name='descr' rules={[{ required: false }]}>
                    <Input placeholder="请输入摘要" />
                  </Form.Item>
                </div>
                <div className="content_name">
                  <div className="" style={{ marginBottom: '10px' }}>内容</div>
                  <Form.Item label="" name='content' rules={[{ required: false }]}>
                    {/* <WgEditor getContent={detail.content} setcontent={setcontent} ></WgEditor> */}
                    <NewEditor getContent={detail.content} setcontent={setcontent} ></NewEditor>
                  </Form.Item>
                </div>
              </Col>
              <Col span={6} className="title_wrap">
                <div className="">
                  <div>缩略图 <span>（展示在新闻资讯列表）</span> </div>
                  <Form.Item name='thumbnail' rules={[{ required: false }]}>
                    <div className="images">
                      <ImageDragger getimageUrl1={detail.thumbnail} setimageUrl1={setimageUrl1}  ></ImageDragger>
                    </div>
                  </Form.Item>
                </div>
                <div>
                  <div>发布人</div>
                  <Form.Item name='user_name' rules={[{ required: false }]}>
                    <Input disabled placeholder={user_name} />
                  </Form.Item>
                </div>
                <div>
                  <div>状态</div>
                  <Form.Item name="recommend" valuePropName="checked" noStyle>
                    <Checkbox>置顶</Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="title_wrap">
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit" className="submit_left">
                    保存
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    取消
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div >
    </>
  )
}