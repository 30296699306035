import React, { useEffect, useState, useCallback } from 'react'
import ZgTitle from "../../../components/zg-title";
import ZgRow from "../../../components/zg-row";
import { Select, Table } from "antd"
import styles from './index.module.css'
import echarts from 'echarts'
import { getAreaList } from '../../../apis/board'
import { deviceTypeSum, deviceStatusRank } from '../../../apis/analysis/warn'
import $storeLocal from "storejs"
const { Option } = Select;
export default function LeftSide(props: any) {
  // 获取区级列表
  const [curAreaId, setCurAreaId] = useState('')
  let selectAreaTemp = [{ id: 0, name: '' }]
  const [selectArea, setSelectArea] = useState(selectAreaTemp)
  const get_area_list = () => {
    getAreaList({}).then((res: any) => {
      if (res.data instanceof Array) {
        props.getId('')
        setCurAreaId('')
        let ls: any = [{ id: '', name: '全部' }]
        res.data.forEach((item: any) => {
          ls.push({ id: item.id, name: item.unit_name })
        })
        setSelectArea(ls)
      }
    })
  }
  function changeArea(id: any) {
    props.getId(id)
    setCurAreaId(id)
  }
  const [deviceAmount, setDeviceAmount] = useState(0)
  let sortList: any = [
    { id: 1, name: '---', normal: 0, abnormal: 0, rate: 0 }
  ]
  const [legendList, setLegendList] = useState([''])
  const [tableList, setTableList] = useState(sortList)
  // 渲染图表
  const renderChart = (data: any) => {
    let ls: any = []
    data.forEach((item: any) => {
      ls.push({ name: item.name, value: item.value })
    })
    setLegendList(ls)
    var ele: any = document.getElementById('deviceSum')
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      color: ['#1eb38b', '#3582fe', '#dc8320', '#a5a5a5'],
      series: [
        {
          name: '物联概况',
          type: 'pie',
          radius: ['40%', '80%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'inner'
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'inner',
                formatter: '{d}%'
              },
              labelLine: {
                show: false,
              }
            }
          },
          data: data
        }
      ]
    });
  }
  let columns = [
    {
      title: "排名",
      dataIndex: "id",
      key: 'id',
      render: (index: any) => (
        <span>{index}</span>
      )
    },
    {
      title: "餐饮单位",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "正常",
      dataIndex: "normal",
      key: "normal",
      render: (normal: any) => (
        <span className={styles.greenColor}>{normal ? normal : 0}</span>
      ),
    },
    {
      title: "异常",
      dataIndex: "abnormal",
      key: "abnormal",
      render: (abnormal: any) => (
        <span className={styles.redColor}>{abnormal ? abnormal : 0}</span>
      ),
    },
    {
      title: "设备异常率",
      dataIndex: "rate",
      key: "rate",
      render: (rate: any) => (
        <span>{rate ? rate : 0}%</span>
      ),
      sorter: {
        compare: (a: any, b: any) => a.rate - b.rate,
        multiple: 3,
      },
    }
  ]
  const get_sum = useCallback(
    () => {
      deviceTypeSum({ area_id: curAreaId }).then((res: any) => {
        setDeviceAmount(res.data.amount)
        let data: any = [
          { name: '设备监测类', value: res.data.gt0001 === "" ? 0 : res.data.gt0001 },
          { name: '环境监测类', value: res.data.gt0002 === "" ? 0 : res.data.gt0002 },
          { name: '空气质量监测类', value: res.data.gt0003 === "" ? 0 : res.data.gt0003 },
          { name: '其他', value: res.data.gt0004 === "" ? 0 : res.data.gt0004 },
        ]
        renderChart(data)
      })
    },
    [curAreaId],
  )

  const get_rank = useCallback(() => {
    return deviceStatusRank({ area_id: curAreaId }).then((res: any) => {
      let sortList: any = []
      if (JSON.stringify(res.data) !== "{}") {
        res.data.forEach((item: any, index: any) => {
          sortList.push({ id: index + 1, name: item.unit_name, normal: item.iot_normal_amount, abnormal: item.iot_abnormal_amount, rate: item.ratio })
        })
      }
      setTableList(sortList)
    })
  },
    [curAreaId],
  )
  useEffect(() => {
    get_sum()
    get_rank()
  }, [get_sum, get_rank])

  useEffect(() => {
    let grade = $storeLocal.get("UserBaseInfo").grade
    if (grade === 'city') {
      get_area_list()
    } else {
      let ls: any = []
      ls.push({ id: $storeLocal.get("UserBaseInfo").id, name: $storeLocal.get("UserBaseInfo").district })
      setSelectArea(ls)
      changeArea(ls[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={styles.box + ' ' + styles.leftSide}>
      <ZgRow justify="space-between" align="center">
        <ZgTitle title="物联设备监测" className={styles.sumTitle}></ZgTitle>
        <div>实时数据</div>
        <Select onChange={changeArea} className={styles.select} key={selectArea[0].id} defaultValue={selectArea[0].id}>
          {selectArea.map((item: any) => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </ZgRow>
      <ZgRow justify="space-between">
        <div className={styles.leftChart}>
          <div id="deviceSum" style={{ width: '100%', height: '100%' }}></div>
        </div>
        <div className={styles.legendWrap}>
          <ZgRow justify="space-between" className={styles.deviceSum}>总物联设备数<span>{deviceAmount}</span></ZgRow>
          {
            legendList.map((item: any, index: any) => (
              <div className={styles.flexRow + ' ' + styles.legendItem} key={index}>
                <ZgRow align="center">
                  <span className={styles.legendSpan + ' ' + (index === 0 ? styles.greenBg : index === 1 ? styles.blueBg : index === 2 ? styles.yellowBg : styles.greyBg)}></span>
                  <span className={styles.legendText}>{item.name}</span>
                </ZgRow>
                <span className={styles.legendNumber}>{item.value}</span>
              </div>
            ))
          }
        </div>
      </ZgRow>
      <div className={'networkAnalyze ' + styles.tableWrap}>
        <Table
          columns={columns}
          dataSource={tableList}
          rowKey={columns => (columns.id)}
          pagination={false}
          scroll={{ y: 400 }}
        >
        </Table>
      </div>
    </div>
  )
}