import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Select, Image } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getCorrectList } from '../../../apis/canteen-info/warn'
import { useHistory } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default function CorrectList() {
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [state, setSelState] = useState('')//状态选择
  let history = useHistory()
  let numberTemp = history.location.search.split('=')[1]
  const [number] = useState(numberTemp)//食堂number
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }
  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getCorrectList({ page: currentPage, sdate: timeList[0], edate: timeList[1], number: number, state: state }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList, number, state])
  function change_state(data: any) {
    setSelState(data)
  }
  function changePage(val: number) {
    setCurrentPage(val)
  }
  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])
  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '纠偏时间',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '食品纠偏区域',
      dataIndex: 'area',
      key: 'area',
    },
    {
      title: '类型',
      dataIndex: 'correct_type',
      key: 'correct_type',
    },
    {
      title: '纠偏图像',
      dataIndex: 'http_url_image_appends',
      render: (text: any) => (
        <div><Image style={{ width: '50px', height: '50px ' }} src={text} alt="" /></div>

      ),
    }, {
      title: '状态',
      dataIndex: 'state',
      render: (text: any) => (
        <div style={{ color: (text === 1 ? "red" : "#999") }}>{text === 1 ? "待处理" : "已处理"}</div>
      ),
    }, {
      title: '处理时间',
      dataIndex: 'handle_time',
      key: 'handle_time',
    }, {
      title: '处理人',
      dataIndex: 'handle_user_name',
      key: 'handle_user_name',
    }
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">视频预警</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>纠偏时间：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <span style={{ marginLeft: '16px' }}>处理状态：</span>
            <Select onChange={change_state} defaultValue="0" style={{ width: 120 }}>
              <Option value="0">全部</Option>
              <Option value="1">待处理</Option>
              <Option value="2">已处理</Option>
            </Select>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => columns.id}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}