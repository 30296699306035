import React, { useState, useCallback, useEffect } from "react"
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Space,
  DatePicker,
  Button,
  Table,
  Tabs,
  Select,
} from "antd"
import {
  selfCheckListData,
  downloadExport,
} from "../../../apis/inspection-check/inspection"
import { useHistory } from "react-router-dom"
import "../../../style/inspection.less"
import ZgCascader from '../../../components/zg-cascader'

const { TabPane } = Tabs
export default function SelfCheckList() {
  const { Option } = Select
  let history = useHistory()
  const { MonthPicker } = DatePicker
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [canteen_name, setCanteenName] = useState("") //食堂名称
  const [date, setDate] = useState()
  const [curType, setType] = useState(0)
  const [curType1, setType1] = useState(0)
  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区


  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
      width: "80",
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
      render: (text: any, record: any) => (
        <span>
          {record.submit_date.substr(0, 4)}年{record.month}月自查自评表
        </span>
      ),
    },
    {
      title: "自查单位",
      dataIndex: "canteen_name",
      render: (text: any, record: any) => <span>{record.canteen_name}</span>,
    },
    {
      title: "自查时间",
      dataIndex: "submit_date",
      key: "submit_date",
    },
    {
      title: "提交人",
      dataIndex: "submit_name",
      key: "submit_name",
    },
    {
      title: "提交时间",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "状态",
      dataIndex: "check_state",
      render: (check_state: any) =>
        check_state === 1 ? (
          <span style={{ color: "red" }}>待审查</span>
        ) : (
          <span>已审查</span>
        ),
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <>
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail(record.result_number)}
            ghost={true}
          >
            查看
          </Button>
          <Button
            onClick={() => handExportExcel(record.result_number)}
            style={{ marginLeft: "15px" }}
          >
            下载
          </Button>
        </>
      ),
    },
  ]

  const [list, setList] = useState([])

  //按时间查询
  function handDate(dates: any, date: any) {
    setDate(date)
    setCurrentPage(1)
  }
  function checkDetail(id: any) {
    history.push("/self-check/detail", id)
  }
  //输入查询
  function handName(value: any) {
    setCanteenName(value.target.value)
    setCurrentPage(1)
  }

  // 导出excel
  function handExportExcel(id: any) {
    downloadExport({
      result_number: id,
    }).then((res) => {
      const link = document.createElement("a")
      const evt = document.createEvent("MouseEvents")
      link.style.display = "none"
      link.href = res.data.data

      link.download = "自查自评问卷.xls"
      document.body.appendChild(link) // 此写法兼容可火狐浏览器
      evt.initEvent("click", false, false)
      link.dispatchEvent(evt)
      document.body.removeChild(link)
    })
  }

  // 获取列表
  const to_get_list = useCallback(() => {
    return selfCheckListData({
      type: curType,
      type1: curType1,
      page: currentPage,
      date: date,
      canteen_name: canteen_name,
      city: cityName,
      district: districtName,
    }).then((res) => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, date, canteen_name, curType, curType1, cityName, districtName])
  function changePage(val: number) {
    setCurrentPage(val)
  }
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  function search() {
    to_get_list()
  }
  function handleChange(value: any) {
    console.log(`selected ${value}`)
    setType1(value)
  }
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  function changeType(idx: any) {
    let id = idx - 1
    setType(id)
    to_get_list()
  }
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管巡查</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/self-check/list">自查自评</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row
        style={{
          fontSize: "20px",
          paddingBottom: "10px",
          background: "white",
          paddingLeft: "40px",
        }}
      >
        <Col span={4}>自查自评列表</Col>
      </Row>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Tabs defaultActiveKey="1" onChange={changeType}>
            <TabPane tab="全部" key="1"></TabPane>
            <TabPane tab="待审查" key="2"></TabPane>
            <TabPane tab="已审查" key="3"></TabPane>
          </Tabs>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={20} className="gutter-row">
              <span>自查时间：</span>
              <Space
                direction="vertical"
                size={12}
                style={{ marginRight: "20px" }}
              >
                <MonthPicker onChange={handDate} />
              </Space>
              <span>餐饮单位名称：</span>
              <Input
                placeholder="请输入"
                type="text"
                onChange={handName}
                style={{ width: 140, marginRight: "15px" }}
              />
              <span>状态选择：</span>
              <Select
                defaultValue="0"
                style={{
                  width: 120,
                  marginRight: "15px",
                  display: curType === 0 ? "" : "none",
                }}
                onChange={handleChange}
              >
                <Option value="0">全部</Option>
                <Option value="1">待审查</Option>
                <Option value="2">已审查</Option>
              </Select>
              {/* 所属区域查询功能 */}
              <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              <Button type="primary" onClick={search}>
                查询
              </Button>
            </Col>
            <Col span={4}>
              <div className="add-btn-wrap"></div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={list}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
