import { Breadcrumb, Col, Divider, Row, Image, Tag } from 'antd'
import BasicInfo from './component/basicInfo'
import { useHistory } from 'react-router-dom'
import { getMerchantDetail } from '../../../apis/merchant/massage'
import styles from './less/index.module.less'
import { useCallback, useEffect, useState } from 'react'

import MD5 from '../../../utils/md5'

export default function Detail(props: any) {
  let history = useHistory()
  /**
   *  当前时间戳（10位）
   */
  const timestamp = () => {
    let outcome = Math.round(new Date().getTime() / 1000).toString()
    return Number(outcome)
  }

  const to_url = () => {
    history.push({
      pathname: '/merchant/messageIndex'
    })
  }

  let numberTemp = history.location.search.split('=')[1]
  const [detail, setDetail] = useState({
    mer_id: '52',
    status: '3',
    prohibit_time: '2022-05-22 11:06:38',
    admin_id: 1,
    admin_name: '1',
    reason: '该商户违规操作',
    mer_nums: 'sh1234455',
    class_name: '便捷快餐',
    name: '百慧轩',
    service_type: '包厢预定',
    person_charge: '茶馆',
    phone: '19908600005',
    address: ' 点军区银河路118号百联慧谷',
    coordinate: '111.200721,30.67937',
    business_license: '营业执照',
    business_license_no: 'ffggz1111',
    business_license_date: '2024-06-04',
    business_license_img: 'https://yidian.ftnet84.cn/upload/system/2022/06/629ab1c120d26.jpg',
    food_business_license: '食品经营许可证',
    food_business_license_no: '960258',
    food_business_license_date: '2026-06-04',
    food_business_license_img: 'https://yidian.ftnet84.cn/upload/system/2022/06/629abca6e2cd8.jpg',
    safety_liability_insurance: '安全责任险',
    safety_liability_insurance_no: 'weewwww1',
    safety_liability_insurance_date: '2022-06-28',
    safety_liability_insurance_img: 'https://yidian.ftnet84.cn/upload/system/image/000/039/720/62a6a7a0bcfde827.jpeg',
    business_hours: '8：00～19：00',
    headpic: 'https://yidian.ftnet84.cn/upload/system/image/000/019/927/62bd59bfa8217862.jpg',
    tag: ['可摘草莓', '免费停车场', '网红打卡']
  })

  const get_detail = useCallback(async () => {
    // signcode 32位 MD5加密
    let sign = MD5('1046' + timestamp() + 'E80AB7149', 32)
    let data = {
      mer_id: numberTemp,
      time: timestamp(),
      app_id: 1046,
      signcode: sign
    }
    const res = await getMerchantDetail(data)
    if (res.status == 200) {
      console.log(res.data)
      setDetail(res.data.data)
    }
  }, [numberTemp])

  useEffect(() => {
    get_detail()
  }, [get_detail])

  // 详情页面
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>商户管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <span onClick={to_url}>商户信息表</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>商户详情</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Row style={{ fontSize: '18px', margin: '10px 0 10px 20px' }}>
          <Col span={4}>商户信息表</Col>
        </Row>
      </div>
      <div
        className={styles.tableData}
        style={{ overflow: 'auto', height: 'calc(100vh - 182px)', padding: '0 20px 0 20px' }}
      >
        <div className={styles.tableData_content} style={{ overflow: 'auto' }}>
          <div className={styles.conent}>
            {detail.status == '1' ? (
              <>
                <div className={styles.state}>
                  <span className={styles.state_name}>状态：已启用</span>
                </div>
              </>
            ) : detail.status == '0' ? (
              <>
                <div className={styles.Nostate}>
                  <div className={styles.state_name}>状态：已禁用</div>
                  <span className={styles.state_name} style={{ fontSize: '12px' }}>
                    禁用时间：{detail.prohibit_time}
                  </span>
                  <br />
                  <span className={styles.state_name} style={{ fontSize: '12px' }}>
                    操作人：{detail.admin_name}
                  </span>
                  <br />
                  <span className={styles.state_name} style={{ fontSize: '12px' }}>
                    禁用原因：{detail.reason}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={styles.info_name}>基本信息</div>
            <Divider dashed />
            <div className={styles.merchantNumber}>
              <BasicInfo detail={detail}></BasicInfo>
            </div>
            <div className={styles.info_name}>资质信息</div>
            <Divider dashed />
            <div className={styles.merchantNumber}>
              <div>证件01</div>
              <div className={styles.border_top}>
                <Row className={styles.info_Row}>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    证件名称
                  </Col>
                  <Col className={styles.info_Col} span={8}>
                    营业执照
                  </Col>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    有效期至
                  </Col>
                  <Col className={styles.info_Col} span={8}>
                    {detail.business_license_date}
                  </Col>
                </Row>
                <Row className={styles.info_Row}>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    证件编号
                  </Col>
                  <Col className={styles.info_Col} span={8}>
                    {detail.business_license_no}
                  </Col>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    证件图片
                  </Col>
                  <Col className={styles.info_Col} span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Image width={54} height={50} src={detail.business_license_img} />
                  </Col>
                </Row>
              </div>
              <div style={{ paddingTop: '20px' }}>证件02</div>
              <div className={styles.border_top}>
                <Row className={styles.info_Row}>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    证件名称
                  </Col>
                  <Col className={styles.info_Col} span={8}>
                    食品经营许可证
                  </Col>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    有效期至
                  </Col>
                  <Col className={styles.info_Col} span={8}>
                    {detail.food_business_license_date}
                  </Col>
                </Row>
                <Row className={styles.info_Row}>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    证件编号
                  </Col>
                  <Col className={styles.info_Col} span={8}>
                    {detail.food_business_license_no}
                  </Col>
                  <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                    证件图片
                  </Col>
                  <Col className={styles.info_Col} span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Image width={54} height={50} src={detail.food_business_license_img} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className={styles.info_name}>资质信息</div>
            <Divider dashed />
            <div className={[styles.merchantNumber, styles.border_top].join(' ')}>
              <Row className={styles.info_Row}>
                <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                  门头图片
                </Col>
                <Col className={styles.info_Col} span={8} style={{ display: 'flex', alignItems: 'center' }}>
                  <Image width={54} height={50} src={detail.headpic} />
                </Col>
                <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                  运营时间
                </Col>
                <Col className={styles.info_Col} span={8}>
                  {detail.business_hours}
                </Col>
              </Row>
              <Row className={styles.info_Row}>
                <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}>
                  商户标签
                </Col>
                <Col className={styles.info_Col} span={20}>
                  {detail.tag.map((item: any, index: number) => {
                    return (
                      <Tag color="blue" key={index}>
                        {item}
                      </Tag>
                    )
                  })}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
