import React, { useState, useCallback, useEffect } from "react"
// import ZgCard from "../../../../components/zg-card"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"

import ReactECharts from "echarts-for-react"

import { getWork } from "../../../../apis/analysis/index"
// import { afeterDate, getCurDate } from "../../../../utils/formatDate"

// 大数据分析首页--厨房工作完成指标分析
export default function DataWork() {
  const [dataList, setDataList] = useState([
    { name: "食品留样", max: 100 },
    { name: "餐具消毒", max: 100 },
    { name: "安全自查", max: 100 },
    { name: "食堂清洁", max: 100 },
    { name: "人员晨检", max: 100 },
  ])

  const [valueList, setValueList] = useState([])

  let to_get_info = useCallback(() => {
    let data = {}
    return getWork(data).then((res) => {
      // "day": "所有食堂工作天数",
      // "safe_check": "安全检查百分比",
      // "morning_check": "晨检百分比",
      // "disinfections": "餐具消毒百分比",
      // "clean_check": "食堂清洁百分比",
      // "count": "开餐次数",
      // "samples": "留样百分比"

      let arrValue: any = [
        parseInt(res.data.samples),
        parseInt(res.data.disinfections),
        parseInt(res.data.safe_check),
        parseInt(res.data.clean_check),
        parseInt(res.data.morning_check),
      ]

      //获取数组arrValue中最大的值
      let maxCount = Math.max(...arrValue)

      let arr: any = [
        { name: `食品留样\n（${arrValue[0]}%）`, max: maxCount },
        { name: `餐具消毒\n（${arrValue[1]}%）`, max: maxCount },
        { name: `安全自查\n（${arrValue[2]}%）`, max: maxCount },
        { name: `食堂清洁\n（${arrValue[3]}%）`, max: maxCount },
        { name: `人员晨检\n（${arrValue[4]}%）`, max: maxCount },
      ]
      setDataList(arr)
      setValueList(arrValue)
    })
  }, [])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  const option = {
    color: ["#5473E8"],
    // 鼠标移上去显示数据
    // tooltip: {},
    radar: {
      // 改变为圆
      // shape: 'circle',
      indicator: dataList,
      radius: '60%'
    },
    series: [
      {
        name: "完成情况",
        type: "radar",
        data: [
          {
            value: valueList,
            name: "实际完成情况",
            areaStyle: {
              color: "#5473E8",
            },
          },
        ],
      },
    ],
  }
  let timer: any

  useEffect(() => {
    return () => clearTimeout(timer)
  })

  return (
    <>
      <div className="stac-info-wrap1">
        <div className="work-wrap">
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="厨房工作完成率分析"></ZgTitle>
            {/* <IconFont type="iconicon-test3" className="icon" /> */}
          </ZgRow>
          <div className="card">
            <ReactECharts option={option} style={{ height: 220 }} />
          </div>
        </div>
      </div>
    </>
  )
}
