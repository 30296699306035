import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import $storeLocal from "storejs";
import { useState } from "react";
import { useEffect } from "react";

export default function ImageDragger(props: any, ref: any) {
  const imageUrl = props.getimageUrl1
  const [loading1, setloading1] = useState(false);
  const [imageUrl1, setimageUrl1] = useState("");

  function beforeUpload(file: any) {
    console.log(file);
    const isJpgOrPng =
      file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("您只能上传 JPG/PNG 文件！ ");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("图片必须小于2MB！");
    }
    return isJpgOrPng && isLt2M;
  }

  function handleChange_list1(info: any) {
    console.log("info", info)
    if (info.file.status === "uploading") {
      setloading1(true);
      return;
    }

    if (info.file.status === "done") {
      setimageUrl1(info.file.response.absolute_path);
      props.setimageUrl1(info.file.response.absolute_path)
    }
  }

  useEffect(() => {
    console.log(imageUrl)
    setimageUrl1(imageUrl)
  }, [imageUrl])

  const uploadButton1 = (
    <div>
      {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
    </div>
  );

  return (
    <>
      <div>
        <Upload
          style={{ width: "278px" }}
          headers={{
            authorization:
              "Bearer " +
              $storeLocal.get("customPCToken"),
          }}
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://3c-central.deyicy.com/admin/file-manage/upfile"
          beforeUpload={beforeUpload}
          onChange={handleChange_list1}
        >
          {imageUrl1 ? (
            <img
              src={imageUrl1}
              alt="avatar"
              style={{
                height: "100px",
                width: "100px",
              }}
            />
          ) : (
            uploadButton1
          )}
        </Upload>
      </div>
    </>
  )
}
