import React, { useState, useEffect } from "react";
import Title from "../title";
import echarts from "echarts";
import { getIotWarnStac } from "../../../../apis/board";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../../utils/getTheme";

export default function Wulianyujing(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  const [IOTDeviceCount, setIOTCount] = useState(0);
  const [IOTWarnCount, setIOTWarnCount] = useState(0);
  function check_more(path: any) {
    history.push(path);
  }
  // 渲染物联检测图表
  const renderDeviceChart1 = (data: any) => {
    let labels: any = [];
    let counts: any = [];
    let chartData: any = [];
    data.forEach((item: any, index: any) => {
      // console.log("item", item)
      labels.push(item.name);
      counts.push(item.value);
      chartData.push({ value: item.value, name: item.name });
    });
    var ele: any = document.getElementById("deviceChart1");
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        bottom: 0,
        left: "center",
        textStyle: { color: "#fff" },
        formatter: function (name) {
          var index = 0;
          var clientlabels = ["在线", "离线", "停用"];
          var clientcounts = counts;
          clientlabels.forEach(function (value, i) {
            if (value === name) {
              index = i;
            }
          });
          return name + "  " + clientcounts[index];
        },
      },
      color: ["#1EB38B", "#E3C60B", "#8F8F8F"],
      series: [
        {
          name: "设备占比状态",
          type: "pie",
          center: ["50%", "45%"],
          radius: "50%",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "inner",
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: "inner",
              },
              labelLine: {
                show: false,
              },
            },
          },
          data: chartData,
        },
      ],
    });
  };
  // 渲染物联检测图表
  const renderDeviceChart2 = (data: any) => {
    let labels: any = [];
    let counts: any = [];
    let chartData: any = [];
    data.forEach((item: any, index: any) => {
      labels.push(item.name);
      counts.push(item.value);
      chartData.push({ value: item.value, name: item.name });
    });
    var ele: any = document.getElementById("deviceChart2");
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        bottom: 0,
        left: "center",
        textStyle: { color: "#fff" },
        formatter: function (name) {
          var index = 0;
          var clientlabels = labels;
          var clientcounts = counts;
          clientlabels.forEach(function (value: any, i: any) {
            if (value === name) {
              index = i;
            }
          });
          return name + "  " + clientcounts[index];
        },
      },
      color: ["#1EB38B", "#E84D4F"],
      series: [
        {
          name: "设备正常异常占比",
          type: "pie",
          radius: "50%",
          center: ["50%", "45%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "inner",
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: "inner",
              },
              labelLine: {
                show: false,
              },
            },
          },
          data: chartData,
        },
      ],
    });
  };
  //物联预警总数
  const IOTWarnStac = () => {
    getIotWarnStac({}).then((res: any) => {
      // console.log("renderDeviceChart1", res)
      let ls1: any = [
        { name: "在线", value: res.data.online },
        { name: "离线", value: res.data.offline },
      ];
      let ls2: any = [
        { name: "正常", value: res.data.normal },
        { name: "异常", value: res.data.abnormal },
      ];
      renderDeviceChart1(ls1);
      renderDeviceChart2(ls2);
      setIOTCount(parseInt(res.data.offline) + parseInt(res.data.online));
      setIOTWarnCount(res.data.abnormal);
    });
  };
  useEffect(() => {
    IOTWarnStac();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div>
        <div className={styles.rightSide}>
          <div className={styles.box + " " + styles.deviceAllBox}>
            <div className={styles.flexRow + " " + styles.titleLine}>
              <div className={styles.flexRow + " " + styles.checkLine}>
                <Title title="物联预警分析"></Title>
                <span
                  onClick={() => check_more("/equipment-iot/list")}
                  className={styles.checkMore + " " + styles.yellowColor}
                >
                  查看
                </span>
              </div>
              {/* <Select onChange={iDateChange} className={'board-select ' + styles.select} key={timeList[0].type} defaultValue={timeList[0].type}>
                {timeList.map((item: any) => (
                  <Option key={item.type} value={item.type}>{item.name}</Option>
                ))}
              </Select> */}
            </div>
            <div className={styles.flexRow}>
              <div
                className={
                  styles.divBlock +
                  " " +
                  styles.recBlock +
                  " " +
                  styles.recBlock1
                }
              >
                设备总数
                <span className={styles.yellowColor}>{IOTDeviceCount}</span>
              </div>
              <div className={styles.divBlock + " " + styles.recBlock}>
                预警设备数
                <span className={styles.yellowColor}>{IOTWarnCount}</span>
              </div>
            </div>
            <div className={styles.flexRow}>
              <div className={styles.chartItem + " " + styles.chartItem1}>
                <div
                  className={
                    styles.flexRow +
                    " " +
                    styles.dotTitle +
                    " " +
                    styles.purpleColor
                  }
                >
                  <span className={styles.dotBg}>
                    <span className={styles.dot}></span>
                  </span>
                  设备在线离线占比
                </div>
                <div className={styles.deviceChart} id="deviceChart1"></div>
              </div>
              <div className={styles.chartItem}>
                <div
                  className={
                    styles.flexRow +
                    " " +
                    styles.dotTitle +
                    " " +
                    styles.purpleColor
                  }
                >
                  <span className={styles.dotBg}>
                    <span className={styles.dot}></span>
                  </span>
                  设备正常异常占比
                </div>
                <div className={styles.deviceChart} id="deviceChart2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
