import { Breadcrumb, Col, Row, Tabs } from "antd"
import ComplaintList from "./components/complaint"
import SuggestionList from "./components/suggestion"
export default function InteractiveList(props: any) {
  const { TabPane } = Tabs
  function changeType(val: any) {
    console.log("qiehuan", val)
  }
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>共治监管</Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row>
            <Col span={24} className="gutter-row">
              <Tabs defaultActiveKey="1" onChange={changeType}>
                <TabPane tab="评价建议" key="1">
                  <SuggestionList></SuggestionList>
                </TabPane>
                <TabPane tab="消费者投诉" key="2">
                  <ComplaintList></ComplaintList>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}