import React, { useEffect, useState } from "react";
import ZgTitle from "../../../../components/zg-title";
import ZgCard from "../../../../components/zg-card";
import ZgRow from "../../../../components/zg-row";
import GradientChart from "echarts-for-react";
import { warningIndex } from "../../../../apis/intelligence-rectify/intelligence-rectify"
import { afeterDate, getCurDate } from "../../../../utils/formatDate"

export default function RiskTrend() {
  const [data, setData] = useState([])
  const [time, setTime] = useState([])

  const option = {
    color: ["#4F8CFC"],
    // title: {
    //     text: '渐变堆叠面积图'
    // },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      left: "3%",
      right: "6%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: time,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Line 1",
        type: "line",
        stack: "总量",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#9EC3FF",
              },
              {
                offset: 1,
                color: "#FDFDFF",
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: data,
      },
    ],
  }

  function to_init_data(val1: any, val2: any) {
    warningIndex({ sdate: val1, edate: val2 }).then(res => {
      console.log("综合预警")
      console.log(res)
      let dataList: any = []
      let timeList: any = []
      res.data.goods_expire_trends_list.forEach((item: any) => {
        timeList.push(item.date)
        dataList.push(item.count)
      })
      setData(dataList)
      setTime(timeList)
    })
  }
  useEffect(() => {
    to_init_data(afeterDate(-30), getCurDate())
  }, [])
  return (
    <>
      <div className="stac-info-wrap" >
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="原料预警趋势"></ZgTitle>
            <div className="all_flex">
              <div className="inside">30日内</div>
            </div>
          </ZgRow>

          <div style={{ height: '240px' }}>
            <GradientChart option={option} className="chart" style={{ height: '280px' }}></GradientChart>
          </div>
        </ZgCard>
      </div>
    </>
  );
}
