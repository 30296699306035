import React, { useState } from 'react'
import styles from './index.module.css'
import LeftSide from './leftSide'
import Center from './center'
import RightSide from './rightSide'
import { Row, Col, Breadcrumb } from "antd"
export default function Index(props: any) {
  const [curId, setCurId] = useState('')
  const get_area_id = (id: any) => {
    console.log(id)
    setCurId(id)
  }
  return (
    <div style={{ height: '100%', width: '100%', boxSizing: 'border-box' }}>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>大数据分析</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/analysis/networkIndex">物联预警分析</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={16} className={styles.background}>
        <Col span={6}><LeftSide getId={get_area_id}></LeftSide></Col>
        <Col span={12}><Center id={curId}></Center></Col>
        <Col span={6}><RightSide id={curId}></RightSide></Col>
      </Row>
    </div>
  )
}