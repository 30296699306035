import { Col, Row } from "antd";
import AMapLoader from '@amap/amap-jsapi-loader'
import first from '../../../../assets/images/yidian/one.png'
import second from '../../../../assets/images/yidian/two.png'
import thirdly from '../../../../assets/images/yidian/three.png'
import dc from '../../../../assets/images/yidian/dc.png'
import { useEffect, useState } from "react";
const style: React.CSSProperties = {
  background: 'rgba(237, 245, 255, 1)',
  border: '0.5px solid rgba(59, 150, 255, 1)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px',
  padding: '12px'
}
const name: React.CSSProperties = {
  color: 'rgba(56, 56, 56, 1)',
  fontSize: ' 14px'
}
const count: React.CSSProperties = {
  color: 'rgba(56, 56, 56, 1)',
  fontSize: '16px'
}
export default function MapData(props: any) {
  const [business, setBusiness] = useState(props.business)

  // //绑定按钮事件，改变地图中心点
  // function omphalos() {
  //   var lng = 121.138398 + Math.floor(Math.random() * 589828) / 1e6; //经度范围[121.138398, 121.728226]
  //   var lat = 30.972688 + Math.floor(Math.random() * 514923) / 1e6; //纬度范围[30.972688, 31.487611]
  //   map.setCenter([lng, lat]); //设置地图中心点
  //   log.info(`当前中心点已设为 ${lng.toFixed(6)},${lat.toFixed(6)}`);
  // }


  useEffect(() => {
    setBusiness(props.business)
    let markers: Array<any> = []

    AMapLoader.load({
      key: "b970bbf41025f93ec47892d34a6d8ab0",
      version: '1.3.0',
    }).then(AMap => {
      const map = new AMap.Map('container', {
        center: [111.178309, 30.654369],
        mapStyle: "amap://styles/021981e1781074e215441507a954df4b", //设置地图的显示样式
        zoom: 12,
        showIndoorMap: false,//关闭室内地图
        resizeEnable: true,
      })
      map.clearMap();  // 清除地图覆盖物
      if (props.business.list) {
        props.business.list.forEach((item: any, index: number) => {


          if (index + 1 === 1) {
            markers.push({
              icon: new AMap.Icon({
                image: first,
                size: new AMap.Size(39, 52),//设置icon的大小
                imageSize: new AMap.Size(39, 52)
              }), position: [Number(item.longitude), Number(item.dimension)],
              title: item.name
            })
          }
          if (index + 1 === 2) {
            markers.push({
              icon: new AMap.Icon({
                image: second,
                size: new AMap.Size(39, 52),//设置icon的大小
                imageSize: new AMap.Size(39, 52)
              }),
              position: [Number(item.longitude), Number(item.dimension)],
              title: item.name
            })
          }
          if (index + 1 === 3) {
            markers.push({
              icon: new AMap.Icon({
                image: thirdly,
                size: new AMap.Size(39, 52),//设置icon的大小
                imageSize: new AMap.Size(39, 52)
              }), position: [Number(item.longitude), Number(item.dimension)],
              title: item.name
            })
          }
          if (index + 1 > 3) {
            markers.push({
              icon: new AMap.Icon({
                image: dc,
                size: new AMap.Size(45, 52),//设置icon的大小
                imageSize: new AMap.Size(45, 52)
              }),
              position: [Number(item.longitude), Number(item.dimension)],
              title: item.name
            })
          }
        })
      }

      // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
      markers.forEach(function (marker) {
        console.log("作为参照", marker);
        new AMap.Marker({
          map: map,
          icon: marker.icon,
          position: [marker.position[0], marker.position[1]],
          title: marker.title,
          offset: new AMap.Pixel(-13, -30)
        });
      });


      AMap.service('AMap.DistrictSearch', () => {
        const opts = {
          subdistrict: 0,
          extensions: "all",
          level: "district"
        };
        const districtSearch = new AMap.DistrictSearch(opts)
        districtSearch.search('点军区', (status: any, result: any) => {
          const bounds = result.districtList[0].boundaries
          // 地图描边
          for (let i = 0; i < bounds.length; i += 1) {
            new AMap.Polyline({
              path: bounds[i],
              strokeStyle: "dashed",
              strokeColor: "#0054FC",
              strokeWeight: 2,
              strokeOpacity: 0.5,
              map: map
            });
            // 地图填充颜色
            new AMap.Polygon({
              path: bounds[i],
              fillColor: '#D8E5FA',
              strokeOpacity: 1,
              fillOpacity: 0.5,
              strokeWeight: 1,
              strokeStyle: 'dashed',
              strokeDasharray: [5, 5],
              map: map
            });
          }
        })
      })
    })
      .catch(e => {
        console.log("====>", e)
      })
  }, [props.business])


  return (
    <>
      <div style={{ height: 'calc(100% - 85px)' }}>
        <Row gutter={8}>
          <Col className="gutter-row" span={7}>
            <div style={style}>
              <div style={name}>入驻商户数</div>
              <div style={count}>{business.count} 家</div>
            </div>
          </Col>
          <Col className="gutter-row" span={7}>
            <div style={style}>
              <div style={name}>摄像头总数</div>
              <div style={count}>{business.video_config_count} 个</div>
            </div>
          </Col>
          <Col className="gutter-row" span={10}>
            <div style={style}>
              <div style={name}>从业人员总数</div>
              <div style={count}>{business.workers_number} 人  <span style={{ color: 'red' }}> （{business.nocert_number}人缺失）</span></div>
            </div>
          </Col>
        </Row>
        <div id="container" style={{ height: '100%', marginTop: '15px' }}>
        </div>
      </div>
    </>
  )
}