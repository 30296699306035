import request from '../../utils/request'

/**
 * app/appraise/list
 * APP_评价建议管理
 * @param data
 */
export function contentsList(data: any) {
  return request({
    url: '/app/contents/list',
    method: 'get',
    params: data
  })
}

/**
 * /app/contents/delete
 * APP_评价建议管理
 * @param data
 */
export function contentsDelete(data: any) {
  return request({
    url: '/app/contents/delete',
    method: 'get',
    params: data
  })
}


/**
 * app/appraise/info
 * APP_评价建议管理
 * @param data
 */
export function contentsInfo(data: any) {
  return request({
    url: '/app/contents/info',
    method: 'get',
    params: data
  })
}


/**
 * /app​/contents​/save
 * 保存
 * @param data
 */
export function contentsSave(data: any) {
  return request({
    url: '/app/contents/save',
    method: "post",
    data,
  })
}



/**
 * /admin/file-manage/upfile
 * 上传功能
 * @param data
 */
export function uploadToServer(data: any) {
  return request({
    url: '/admin/file-manage/upfile',
    method: 'post',
    data
  })
}