import echarts from 'echarts'
import { useCallback, useEffect, useState } from 'react';
import styles from '../less/statistics.module.less'
import index from '../less/index.module.less'

export default function Statistics(props: any) {
  const [enter, setEnter] = useState(props.enter)
  useEffect(() => {
    setEnter(props.enter)
  }, [props.enter])

  const get_echarts_list = useCallback(() => {

    var ele1: any = document.getElementById('warnType1')
    var myChart1 = echarts.init(ele1);

    myChart1.setOption({
      color: ["#00BAAD", "#2A82E4", "#FFC300", "#D43030"],
      title: {
        text: `${enter.count || 0}`,
        subtext: '入驻总数',
        left: 'center',
        top: 'center',
        textStyle: {
          fontSize: 35,
        },
      },
      tooltip: {
        trigger: 'item'
      },

      legend: {
        bottom: '0%',
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            formatter: '{d}%',
            position: 'inside',
          },
          labelLine: {
            show: false
          },
          data: [
            { value: enter.aproportion, name: 'A级' },
            { value: enter.bproportion, name: 'B级' },
            { value: enter.cproportion, name: 'C级' },
            { value: enter.dproportion, name: 'D级' },
          ]
        }
      ]
    })


    var ele2: any = document.getElementById('warnType2')
    var myChart2 = echarts.init(ele2);
    myChart2.setOption({
      title: {
        text: `${enter.count || 0}`,
        subtext: '入驻总数',
        top: 'center',
        right: 'center',
        textStyle: {
          fontSize: 35,
        }
      },
      color: ["#00BAAD", "#E33C64"],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0%',
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            formatter: '{d}%',
            position: 'inside',
          },
          labelLine: {
            show: false
          },
          data: [
            { value: enter.enableCount, name: '已启用' },
            { value: enter.disableCount, name: '已禁用' },
          ]
        }
      ]
    })
  }, [enter])

  useEffect(() => {
    get_echarts_list()
  }, [get_echarts_list])
  return (
    <>

      <div className={index.tableData_content} style={{ width: '100%', height: "calc((100vh - 169px) / 2)" }}>
        <div className={styles.score}>
          <div className={styles.biaoshi}></div>
          <div className={styles.title}>入驻商户量化等级分布</div>
          <div className={styles.day}>单位：家</div>
        </div>
        <div id="warnType1"
          style={{
            width: '320px',
            height: '90%',
            margin: 'auto'
          }}>
        </div>
      </div>
      <div className={index.tableData_content} style={{ width: '100%', height: "calc((100vh - 169px) / 2)", marginTop: '15px' }}>
        <div className={styles.score}>
          <div className={styles.biaoshi}></div>
          <div className={styles.title}>入驻商户状态占比</div>
          <div className={styles.day}>单位：家</div>
        </div>
        <div id="warnType2"
          style={{
            width: '320px',
            height: '90%',
            margin: 'auto'
          }}>
        </div>
      </div>

    </>
  )
}