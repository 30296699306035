import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from "antd";
import ZgIOT from "../../components/zg-iot";
import ZgCanteenList from "../../components/zg-canteen-list";
import "./list.less";
import { getRemoteToken } from "../../apis/iot/equipment-iot";
import $storeLocal from "storejs";

export default function EquipmentIOT(props: any) {
  let number = "";
  if (props.location.state && props.location.state.number) {
    // 获取传递过来的number
    number = props.location.state.number;
  }

  const [canteenNumber, setCanteenNumber] = useState(number); //食堂编号
  const childRef: any = useRef();

  function getCanteenNumber(data: any) {
    if (data !== canteenNumber) {
      setCanteenNumber(data);
    }
  }

  // 获取食堂列表
  // const to_init_data = useCallback(() => {
  //   return getCanteenAll({ name: canteenNumber }).then(res => {
  //     setCanteenList(res.data)
  //   })
  // }, [canteenNumber])

  function to_init_data(val: any) {
    if (val) {
      let host = "https://proxy.deyicy.com/" + val;
      console.log("canteenNumber:" + val);
      getRemoteToken({ number: val }).then((res) => {
        $storeLocal.set("canteenRemoteInfo", {
          host: host,
          number: val,
          token: res.data.token,
        });
        childRef.current.changeVal(val);
      });
    }
  }

  // hook函数
  useEffect(() => {
    to_init_data(canteenNumber);
    console.log("===>canteenNumber", canteenNumber);
  }, [canteenNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>物联监测</Breadcrumb.Item>
      </Breadcrumb>
      <div className="iot-wrap">
        <div className="iot-left">
          <ZgCanteenList
            number={canteenNumber}
            getCanteenNumber={getCanteenNumber}
          ></ZgCanteenList>
        </div>
        <div className="iot-right">
          <ZgIOT cRef={childRef}></ZgIOT>
        </div>
      </div>
    </>
  );
}
