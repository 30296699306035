import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button, Image } from 'antd'
import ZgRow from '../../../components/zg-row'
import ZgTab from '../../../components/zg-tab'
import moment from 'moment'
import { getQuaList } from '../../../apis/canteen-info/proxy-checks'
import { noimgBase64 } from 'utils/common'
const { RangePicker } = DatePicker

export default function QuaList() {
  const [curType, setType] = useState(1) //检测类型
  const [timeList, setTimeList] = useState([]) //时间数组
  const [dataList, setList] = useState([]) //列表数据
  const [currentPage, setCurrentPage] = useState(1) //设置当前页数
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数

  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }

  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getQuaList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1], type: curType }).then(
      res => {
        setTotalCount(res.data.total)
        setList(res.data.data)
        setPerPage(res.data.per_page)
      }
    )
  }, [currentPage, timeList, curType])

  function changePage(val: number) {
    setCurrentPage(val)
  }

  let tabList = ['农残检测', '兽残检测', 'ATP荧光检测']
  function get_type(data: any) {
    let tp = data + 1
    if (data === 2) {
      tp = 7
    }
    setType(tp)
  }

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${index + 1 + (currentPage - 1) * perPage}`
    },
    {
      title: '检测时间',
      dataIndex: 'created_at',
      key: 'check_created_atdate',
      width: '12%'
    },
    {
      title: '检测人',
      dataIndex: 'user_name',
      key: 'user_name'
    },
    {
      title: '物品名称',
      dataIndex: 'name',
      key: 'name',
      render: (item: any) => {
        if (curType === 2) return <div>肉</div>
        return <div>{item}</div>
      },
      width: '15%'
    },
    {
      title: '检测物品图片',
      dataIndex: 'sample_pics_appends',
      render: (text: any) => {
        const picStr = text as string
        let picArr = new Array<string>()
        if (picStr) {
          picArr = picStr.split(',')
        }
        if (picArr.length > 0) {
          return (
            <div>
              {picArr.map((v, i) => {
                return (
                  <Image
                    key={i}
                    style={{ width: '50px', height: '50px ', marginRight: '1px' }}
                    src={v}
                    fallback={noimgBase64}
                  />
                )
              })}
            </div>
          )
        } else {
          return (
            <div>
              {/*<Image style={{ width: '50px', height: '50px ' }} src={noimgBase64} />*/}
              --
            </div>
          )
        }
      },
      width: '25%'
    },
    {
      title: () => {
        if (curType === 1) return <div>抑制率</div>
        return <div>检测值</div>
      } ,
      dataIndex: 'inhibitory_rat',
      key: 'inhibitory_rat',
      render: (item: any) => {
        if (curType == 2) return <div>--</div>
        else if (item) return <div>{item}</div>
        return <div>--</div>
      }
    },
    {
      title: '检测结果图片',
      dataIndex: 'record_pics_appends',
      render: (text: any) => {
        const picStr = text as string
        let picArr = new Array<string>()
        if (picStr) {
          picArr = picStr.split(',')
        }
        if (picArr.length > 0) {
          return (
            <div>
              {picArr.map((v, i) => {
                return <Image key={i} style={{ width: '50px', height: '50px ' }} src={v} fallback={noimgBase64} />
              })}
            </div>
          )
        } else {
          return (
            <div>
              {/*<Image style={{ width: '50px', height: '50px ' }} src={noimgBase64} />*/}
              --
            </div>
          )
        }
      }
    },
    {
      title: '检测结果',
      dataIndex: 'result',
      key: 'result'
    }
  ]

  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">检疫检测</div>
        <div className="tab-wrap" style={{ marginBottom: '10px' }}>
          <ZgTab onClick={get_type} list={tabList}></ZgTab>
        </div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>填报日期：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>
              查询
            </Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey="id"
              pagination={{
                // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage
              }}
            ></Table>
          </Col>
        </Row>
      </div>
    </>
  )
}
