import React, { useState, useEffect, useCallback } from "react"
import { Row, Col } from "antd"
import ZgCard from "../../../../components/zg-card"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import IconFont from "../../../../components/icon-font"
import ZgProgress from "../../../../components/zg-progress"
import { useHistory } from 'react-router-dom';
import { getSafety } from "../../../../apis/analysis/index"
import { afeterDate, getCurDate } from "../../../../utils/formatDate"

// 大数据分析首页--安全指数分析（TOP5）
export default function DataSafety(props: any) {
  const [dataList, setDataList] = useState([])
  let history = useHistory()
  function showImageModal() {
    console.log("props", props)
    history.push("/analysis/risk-area",1)
  }

  let to_get_info = useCallback(() => {
    let sdate = afeterDate(-30)
    let edate = getCurDate()
    let data = { sdate: sdate, edate: edate, page_size: 5 }
    return getSafety(data).then((res) => {
      let arr: any = []
      res.data.data.forEach((item: any, key: any) => {
        // 插入数据
        arr.push(item)
      })
      setDataList(arr)
    })
  }, [])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])
  return (
    <>
      <div className="stac-info-wrap1">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start" onClick={showImageModal}>
            <ZgTitle title="安全指数分析（30日内-TOP5）"></ZgTitle>
            <IconFont type="iconicon-test3" className="icon" />
          </ZgRow>
          <div className="safety-wrap">
            {dataList.map((item: any, key: any) => (
              <Row className="item-data" key={key}>
                <Col span={10}>{item.canteen_name}</Col>
                <Col span={10} className="pro1">
                  <ZgProgress percentageNum={item.now_score} />
                </Col>
                <Col span={4} className="item-right">
                  {item.now_score}
                </Col>
              </Row>
            ))}
          </div>
        </ZgCard>
      </div>
    </>
  )
}
