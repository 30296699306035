import request from "../../utils/request"
// 风险评估排行
export function riskRank(data: any) {
  return request({
    url: "/app/area/index-day/area-avg-ranking",
    method: "get",
    params: data,
  })
}
// 区域基本信息
export function baseInfo(data: any) {
  return request({
    url: "/app/area/account-info",
    method: "get",
    params: data,
  })
}
// 日常管理
export function dayInfo(data: any) {
  return request({
    url: "/app/area/index-day/area-top-ranking",
    method: "get",
    params: data,
  })
}
// 风险分析
export function riskAna(data: any) {
  return request({
    url: "/app/area/warning/index/analysis-risk",
    method: "get",
    params: data,
  })
}
// 风险分析list
export function areaRisks(data: any) {
  return request({
    url: "/app/area/warning/index/warning-statistics",
    method: "get",
    params: data,
  })
}