import React, { useState, useEffect, useCallback } from "react";
import styles1 from "../../indexArea.module.css";
import Title from "../title";
import { Select, Image } from "antd";
import { areaQuarantList } from "../../../../apis/board";
import dayjs from "dayjs";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import defaultImage from "../../../../assets/images/noData.png";

import GetTheme from "../../../../utils/getTheme";

SwiperCore.use([Autoplay]);
const { Option } = Select;
export default function Jianyijiance(props: any) {
  let styles = GetTheme();
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  const [dateType, setDateType] = useState(1);
  const quarantData = [
    {
      img: "",
      name: "",
      desc: "",
      state: "",
    },
  ];
  const [quarantList, setQuarantList] = useState(quarantData);
  function dateChange(type: any) {
    setDateType(type);
  }
  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }
  //无数据提示
  const noEle = (
    <div className={styles.noWrap}>
      <span className={styles.purpleColor}>暂无数据</span>
    </div>
  );
  //检疫检测列表
  const quarantListData = useCallback(() => {
    return areaQuarantList({
      top: 30,
      sdate: formatDate(dateType)[0],
      edate: formatDate(dateType)[1],
    }).then((res: any) => {
      let ls: any = [];
      res.data.forEach((item: any) => {
        let pic = item.record_pics;
        if (pic !== "") {
          //"http://img-canteen.hbzgyc.com/200x/" + src;
          pic = "https://img.hbzgyc.com//" + item.record_pics.split(",")[0];
        } else {
          pic = "";
        }
        console.log("=======", pic);
        ls.push({
          img: pic,
          name: item.canteens.canteen_name,
          desc: "检测物品：" + item.name,
          state: item.is_good, //0否，1合格
        });
      });
      setQuarantList(ls);
    });
  }, [dateType]);

  // 检疫检测数据
  const correctEle = quarantList.map((item: any, index: any) => (
    <SwiperSlide
      className={styles.flexRow + " " + styles1.correctItem}
      key={index}
    >
      <div className={styles.flexRow + " " + styles1.correctLeft}>
        {item.img !== "" ? (
          <Image
            src={item.img}
            className={styles1.correctImg}
            alt=""
            placeholder
          />
        ) : (
          <img className={styles1.correctImg} src={defaultImage} alt="" />
        )}
        <div className={styles1.correctInfo}>
          <div className={styles1.correctName}>{item.name}</div>
          <div className={styles.purpleColor + " " + styles1.correctDesc}>
            {item.desc}
          </div>
        </div>
      </div>
      <div>
        {item.state === 0 ? (
          <span className={styles.redColor}>不合格</span>
        ) : (
          <span className={styles.blueColor}>合格</span>
        )}
      </div>
    </SwiperSlide>
  ));
  useEffect(() => {
    quarantListData();
  }, [quarantListData]);
  return (
    <div className={styles1.rightSideFlex}>
      <div className={styles.leftSide}>
        <div className={styles.box + " " + styles1.leftBox2}>
          <div className={styles.flexRow + " " + styles.titleLine}>
            <div className={styles.flexRow + " " + styles.checkLine}>
              <Title title="实时检疫检测数据"></Title>
            </div>
            <Select
              onChange={dateChange}
              className={"board-select " + styles.select}
              key={timeList[0].type}
              defaultValue={timeList[0].type}
            >
              {timeList.map((item: any) => (
                <Option key={item.type} value={item.type}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          {quarantList.length > 0 ? (
            <Swiper
              initialSlide={0} //初始化时的索引位置
              speed={1000} //页面切换速度，slider自动滑动开始到结束的时间（单位ms）
              loop={true} //是否形成环路
              spaceBetween={24} //页面直接的间距
              slidesPerView={3} //设置slider容器能够同时显示的slides数量(carousel模式)。
              direction="vertical"
              style={{ height: 200 }}
              //自动滚屏
              autoplay={{
                delay: 2000, //自动滚屏速度
                disableOnInteraction: false, //false: 鼠标操作屏幕后继续自动滚屏
              }}
            >
              {correctEle}
            </Swiper>
          ) : (
            <div style={{ height: 200 }}>{noEle}</div>
          )}
        </div>
      </div>
    </div>
  );
}
