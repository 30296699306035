import React, { useState, useEffect, useCallback } from 'react'
import { Table, Row, Col, DatePicker, Button } from 'antd';
import ZgRow from '../../../components/zg-row';
import moment from 'moment'
import { getCleanList } from '../../../apis/canteen-info/proxy-checks'
const { RangePicker } = DatePicker;
export default function CleanList() {
  const [timeList, setTimeList] = useState([])//时间数组
  const [dataList, setList] = useState([])//列表数据
  const [currentPage, setCurrentPage] = useState(1)//设置当前页数
  const [totalCount, setTotalCount] = useState(0)  //总数
  const [perPage, setPerPage] = useState(15)  //每页条数
  function time_change(data: any) {
    if (data) {
      let ls: any = [moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
      setTimeList(ls)
    } else {
      setTimeList([])
    }
  }
  // 获取数据列表
  const to_get_list = useCallback(() => {
    return getCleanList({ page: currentPage, page_size: 15, sdate: timeList[0], edate: timeList[1] }).then(res => {
      setTotalCount(res.data.total)
      setList(res.data.data)
      setPerPage(res.data.per_page)
    })
  }, [currentPage, timeList])
  function changePage(val: number) {
    setCurrentPage(val)
  }
  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])
  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      render: (text: any, record: any, index: number) => `${(index + 1) + (currentPage - 1) * perPage}`
    },
    {
      title: '记录时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width:'11%'
    },
    {
      title: '记录人',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: '所属区域',
      dataIndex: 'room',
      key: 'room',
      width:'11%'
    },
    {
      title: '食物规范存放',
      dataIndex: 'is_deposit',
      render: (text: any) => (
        <div>{text === 1 ? '正常' : '异常'}</div>
      )
    },
    {
      title: '地面、桌面、墙面擦拭',
      dataIndex: 'is_wipe',
      render: (text: any) => (
        <div>{text === 1 ? '正常' : '异常'}</div>
      )
    },
    {
      title: '工具用具、设备清洗、归位保洁',
      dataIndex: 'is_homing',
      render: (text: any) => (
        <div>{text === 1 ? '正常' : '异常'}</div>
      )
    },
    {
      title: '垃圾密封转运',
      dataIndex: 'is_transport',
      render: (text: any) => (
        <div>{text === 1 ? '正常' : '异常'}</div>
      )
    }
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">食堂清洁</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>清洁日期：</span>
            <RangePicker onChange={time_change}></RangePicker>
            <Button type="primary" style={{ marginLeft: '16px' }} onClick={to_get_list}>查询</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataList}
              rowKey={columns => (columns.id)}
              pagination={{  // 分页
                current: currentPage,
                total: totalCount,
                pageSize: perPage,
                onChange: changePage,
              }}
            >

            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}