import React, { useState, useEffect } from 'react'
import { Empty, Image } from 'antd';
import ZgColumn from '../../../components/zg-column';
import { proxySslImg } from 'utils/common';
export default function Member(props: any) {
  const [memberList, setMemberList] = useState([])

  useEffect(() => {
    setMemberList(props.memberList)
    console.log("props", props)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberList])

  function showEle(item: any) {
    return (
      <ZgColumn>
        <div style={{ display: (item.avatar_appends === "" ? "none" : "block") }}>
          <Image style={{ width: '50px', height: '50px' }} src={proxySslImg(item.avatar_appends)} />
        </div>
        <span className="base-title">{item.name}</span>
      </ZgColumn >
    )
  }

  return memberList.length > 0 ? (
    <>
      {
        memberList.map((item: any, index: any) => (
          <div style={{ padding: '20px', fontSize: '14px', display: 'flex' }} key={index}>
            <span className="base-msg" style={{ width: '150px' }}>{item.type_name}</span>
            {!!item ? showEle(item) : '无'}
          </div>
        ))
      }
    </>
  ) : (
      <Empty />
  )
};