import { Breadcrumb, Col, Row } from "antd";
import StatisticsOne from "./component/statisticsOne"
import StatisticsTwo from "./component/statisticsTwo"
import order from './less/order.module.less'

export default function OrdweIndex(props: any) {
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>商户管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/merchant/orderIndex">商户订单分析</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={order.tableData}>
        <Row gutter={12} style={{ height: '100%' }}>
          <Col span={12}>
            <StatisticsOne></StatisticsOne>
          </Col>
          <Col span={12} style={{ height: '100%' }}>
            <div className={order.tableData_content} >
              <StatisticsTwo></StatisticsTwo>
            </div>
          </Col>
        </Row>
      </div >
    </>
  )
}