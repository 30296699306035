import React, { useState, useCallback, useEffect } from "react"
import moment from "moment"
import {
  Breadcrumb,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Upload,
  Input,
  Form,
  Modal,
  message,
} from "antd"
import { PlusOutlined } from "@ant-design/icons"
import "../../../style/inspection.less"
import { uploadImg } from "../../../apis/smart-video/index"
import {
  addTask,
  mineCanteensList,
} from "../../../apis/inspection-check/inspection"
import lrz from "lrz"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
export default function InspectionAdd() {
  let history = useHistory()
  const { TextArea } = Input
  const { Option } = Select
  const [previewVisible, setPreviewVis] = useState(false)
  const [previewImage, setPreviewImg] = useState("")
  const [fileList1, setFileList1] = useState<Array<any>>([])
  const [img1, setImg1] = useState<Array<any>>([])
  const [img2] = useState<Array<any>>([])
  const [canteen_list, setCanteen] = useState<Array<any>>([])
  const [previewTitle, setPreviewTitle] = useState("")

  //提交数据
  function to_submit(values: any) {
    let time = dayjs(values.time).format("YYYY-MM-DD HH:mm")

    let arr1 = img1.map((item: any) => item.url)
    let arr2 = img2.map((item: any) => item.url)
    let rqs: any = {
      canteen_number: values.canteens_number,
      title: values.title,
      inspection_images: arr1.join(","),
      problem_describe: values.desc,
      problem_images: arr2.join(","),
      inspection_time: time,
    }

    addTask(rqs).then((res: any) => {
      if (res.status === 200) {
        message.info("提交成功")
        history.go(-1)
      }
    })
  }
  function handleCancel() {
    setPreviewVis(false)
  }

  function handlePreview(file: any) {
    let im: any = file.url || file.preview
    setPreviewImg(im)
    setPreviewVis(true)
    setPreviewTitle("预览图片")
  }
  // 删除图片
  function to_remove1(file: any) {
    const idx: any = fileList1.indexOf(file)
    const newFileList = fileList1.slice()
    newFileList.splice(idx, 1)
    setFileList1(newFileList)
  }
  // 上传之前控制
  function beforeUpload1(file: any) {
    console.log(file)
    const isLt2M = file.size / 1024 / 1000 < 1
    let upfile: any = file.file
    if (!isLt2M) {
      lrz(file.file, { quality: 0.8 }).then((rst: any) => {
        // 处理成功会执行
        upfile = rst
      })
    }
    let formData = new FormData()
    formData.append("file", upfile)
    uploadImg(formData).then((res: any) => {
      let data: any = {
        url: res.data.absolute_path,
        imgUrl: res.data.path,
        uid: file.file.uid,
        status: "done",
        name: file.file.name,
      }
      setFileList1([data, ...fileList1])
      setImg1([data, ...img1])
    })
  }
  function to_cancel() {
    history.go(-1)
  }
  function chose_canteen() {}

  function get_time(value: any) {
    console.log("onOk: ", value)
  }
  function onBlur() {}

  function onFocus() {}

  function onSearch(val: any) {}
  //获取食堂列表
  const get_canteen = useCallback(() => {
    return mineCanteensList({}).then((res) => {
      setCanteen(res.data)
    })
  }, [])
  useEffect(() => {
    get_canteen()
  }, [get_canteen])
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  )
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>日常巡检</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/inspection/list">日常巡检</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row style={{ fontSize: "20px", marginBottom: "10px" }}>
            <Col span={4}>新增巡检</Col>
          </Row>
          <div className="title title1">基本信息</div>
          <Form onFinish={to_submit}>
            <Row gutter={90}>
              <Col span={8}>
                <Form.Item
                  name="title"
                  label="巡检主题"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="请输入巡检主题" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="canteens_number"
                  label="巡检单位"
                  rules={[{ required: true }]}
                >
                  <Select
                    optionFilterProp="children"
                    placeholder="请选择"
                    onChange={chose_canteen}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    allowClear
                    showSearch
                  >
                    {canteen_list.map((item: any) => (
                      <Option value={item.canteen_number} key={item.id}>
                        {item.canteen_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={90}>
              <Col span={8}>
                <Form.Item
                  name="time"
                  label="巡检时间"
                  rules={[{ required: true }]}
                  initialValue={moment(new Date(), "YYYY-MM-DD HH:mm")}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ format: "HH:mm" }}
                    onOk={get_time}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="inspectionimg"
              label="整改通知（可上传整改通知单、现场图片等）"
              labelCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <div>
                <Upload
                  listType="picture-card"
                  action="2"
                  fileList={fileList1}
                  customRequest={beforeUpload1}
                  onRemove={to_remove1}
                  onPreview={handlePreview}
                  multiple
                >
                  {uploadButton}
                </Upload>
              </div>
            </Form.Item>
            {/* <div className="title">巡检任务</div> */}
            <div className="tip tip1">任务说明：</div>
            <Row gutter={90}>
              <Col span={16}>
                <Form.Item name="desc">
                  <TextArea placeholder="请输入" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            {/* <div className="tip">图片：</div>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              action="2"
              fileList={fileList2}
              customRequest={beforeUpload2}
              onRemove={to_remove2}
              multiple
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>
            </Upload> */}
            <Form.Item className="btn-list">
              <Button type="primary" htmlType="submit" className="btn1">
                提交巡检任务
              </Button>
              <Button
                htmlType="button"
                onClick={to_cancel}
                className="cancel-btn"
              >
                取消
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  )
}
