import React, { useState } from "react"
import ZgCard from "../../../../components/zg-card"
import dayjs from "dayjs"
// 大数据分析首页--头部组件
export default function DataHead() {
  let yearData: any = dayjs().format('YYYY') + '年'
  let monData: any = dayjs().format('MM') + '月'
  let dayData: any = dayjs().format('DD') + '日'
  let timeData: any = dayjs().format('HH:mm')
  let str = yearData + monData + dayData + ' ' + timeData
  const [today] = useState(str)
  return (
    <>
      <div className="base-info-wrap">
        <ZgCard>
          <div className="head-wrap">
            <div className="data-left">
              {today}
            </div>
            <div className="title">
              <div className="line-left"></div>
              <div>大数据可视化平台</div>
              <div className="line-right"></div>
            </div>
            <div className="data-right">实时数据更新·信息资料早知道</div>
          </div>
        </ZgCard>
      </div>
    </>
  )
}
