import React, { useState, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Breadcrumb,
  Row,
  Col,
  Button,
  Table,
  Radio,
  Input,
  message,
} from "antd"
import "../../../style/inspection.less"
import {
  selfCheckDetail,
  selfChecked,
  downloadExport,
} from "../../../apis/inspection-check/inspection"
export default function SelfCheckDetail(props: any) {
  let history = useHistory()
  const { TextArea } = Input
  const [id] = useState(props.location.state)
  const [detail, setDetail] = useState({
    realname: "",
    username: "",
    check_time: "",
    roles: [{ name: "" }],
    check_state: 1,
    title: "",
    canteen_name: "",
    province: "",
    city: "",
    district: "",
    address: "",
    canteen_phone: "",
    submit_name: "",
    submit_date: "",
    created_at: "",
    specific_liable: "",
    food_safe: "",
    food_safe_phone: "",
    check_content: "",
  })
  const [checkState, setCheckState] = useState(1)
  const [checkContent, setCheckContent] = useState("")
  const [qlist, setQlist] = useState([])
  const obj = {
    children: "",
    props: { rowSpan: 0, colSpan: 0 },
  }
  const columns = [
    {
      title: "自查项目",
      dataIndex: "type_tag",
      key: "type_tag",
      render: (text: any, record: any, index: any) => {
        if (index + 1 === record.count) {
          obj.children = text
          obj.props.rowSpan = 1
          obj.props.colSpan = 1

          return obj
        } else {
          obj.children = text
          obj.props.rowSpan = record.type_count
          obj.props.colSpan = 1

          return obj
        }
      },
    },
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) => {
        if (index + 1 === record.count) {
          obj.children = record.situation
          obj.props.colSpan = 5
          obj.props.rowSpan = 1

          return obj
        } else {
          return (
            <div
              style={{
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "60px",
              }}
            >
              {index + 1}
            </div>
          )
        }
      },
    },
    {
      title: "自查内容",
      dataIndex: "content",
      key: "content",
      width: "140",
      textWrap: "word-break",
      render: (text: any, record: any, index: any) => {
        if (index + 1 === record.count) {
          obj.children = ""
          obj.props.colSpan = 0
          obj.props.rowSpan = 1
          return obj
        } else {
          return text
        }
      },
    },
    {
      title: "自查结果",
      dataIndex: "state",
      render: (state: any, record: any, index: any) => {
        if (index + 1 === record.count) {
          obj.children = ""
          obj.props.colSpan = 0
          obj.props.rowSpan = 1

          return obj
        } else {
          return (
            <div
              style={{
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "80px",
              }}
            >
              {" "}
              {state === "Y" ? (
                <span style={{ color: "#387ddc" }}>是</span>
              ) : state === "N" ? (
                <span style={{ color: "red" }}>否</span>
              ) : (
                <span style={{ color: "#ff9454" }}>有待完善</span>
              )}{" "}
            </div>
          )
        }
      },
    },
    {
      title: "自查不符合项说明",
      dataIndex: "explain",
      key: "explain",
      render: (text: any, record: any, index: any) => {
        if (index + 1 === record.count) {
          obj.children = ""
          obj.props.colSpan = 0
          obj.props.rowSpan = 1

          return obj
        } else {
          return (
            <div
              style={{
                wordWrap: "break-word",
                wordBreak: "break-word",
                width: "120px",
              }}
            >
              {text}
            </div>
          )
        }
      },
    },
    {
      title: "整改情况（包括整改时间、措施、完成情况等）",
      dataIndex: "situation",
      key: "situation",
      render: (text: any, record: any, index: any) => {
        if (index + 1 === record.count) {
          obj.children = ""
          obj.props.colSpan = 0
          obj.props.rowSpan = 1
          return obj
        } else {
          return text
        }
      },
    },
  ]
  function onChangeRadio(val: any) {
    setCheckState(val.target.value)
  }
  function onChangeTextArea(val: any) {
    setCheckContent(val.target.value)
  }
  function toSubmit() {
    selfChecked({
      result_number: id,
      comment_type: checkState,
      check_content: checkContent,
    }).then((res) => {
      if (res.data.status === 1) {
        message.success("审查批复成功！", 3, () => {
          history.push("/self-check/list")
        })
      } else if (res.data.status === 2) {
        message.error(res.data.msg)
      } else {
        message.warning(res.data.msg)
      }
    })
  }
  // 导出excel
  function handExportExcel(e: any) {
    downloadExport({
      result_number: id,
    }).then((res) => {
      const link = document.createElement("a")
      const evt = document.createEvent("MouseEvents")
      link.style.display = "none"
      link.href = res.data.data

      link.download = "自查自评问卷.xls"
      document.body.appendChild(link) // 此写法兼容可火狐浏览器
      evt.initEvent("click", false, false)
      link.dispatchEvent(evt)
      document.body.removeChild(link)
    })
  }
  function to_url() {
    history.push({
      pathname: "/self-check/list",
    })
  }
  const get_detail = useCallback(() => {
    return selfCheckDetail({ result_number: id }).then((res) => {
      setDetail(res.data.info)
      setQlist(res.data.details)
    })
  }, [id])
  useEffect(() => {
    get_detail()
  }, [get_detail])
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>监管巡查</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/self-check/list">自查自评列表</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>自查详情</Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">
          <Row
            style={{
              fontSize: "20px",
              paddingBottom: "24px",
            }}
          >
            <Col span={18}>
              {detail.submit_date.substr(0, 4)}年
              {detail.submit_date.substr(5, 2)}月自查自评表
            </Col>
            <Col style={{ textAlign: "right" }} span={6}>
              <Button
                type="primary"
                href="#pifu"
                style={{
                  marginRight: "15px",
                  display: detail.check_state === 1 ? "" : "none",
                }}
              >
                审查批复
              </Button>
              <Button
                style={{
                  marginRight: "15px",
                }}
                onClick={handExportExcel}
              >
                下载
              </Button>
              <Button onClick={to_url} type="primary">
                返回
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingBottom: "24px",
            }}
          >
            <Col span={24}>
              {detail.check_state === 1 ? (
                <div>
                  <span>状态：</span>
                  <div
                    className="blue-color"
                    style={{ display: "inline-block" }}
                  >
                    待审查
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    border: "1px solid #E3E9FC",
                    backgroundColor: "#EFF2FF",
                    color: "#5473E8",
                    padding: "10px 0px 10px 15px",
                  }}
                >
                  <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                    <span style={{}}>状态：</span>
                    <div className="" style={{ display: "inline-block" }}>
                      已审查
                    </div>
                  </div>
                  <div>
                    <span style={{}}>审查批复：</span>
                    <div className="" style={{ display: "inline-block" }}>
                      {detail.check_content}
                    </div>
                  </div>
                  <div>
                    <span style={{}}>审查人员：</span>
                    <div className="" style={{ display: "inline-block" }}>
                      {detail.realname !== "" && detail.realname !== null
                        ? detail.realname
                        : detail.username}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{}}>审查时间：</span>
                    <div className="" style={{ display: "inline-block" }}>
                      {detail.check_time}
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <div className="title first-title">基本信息</div>
          <Row
            style={{
              fontSize: "14px",
              paddingTop: "10px",
              paddingBottom: "6px",
            }}
          >
            <Col span={6}>单位名称：{detail.canteen_name}</Col>
            <Col span={6}>
              单位地址：{detail.province}
              {detail.city}
              {detail.district}&nbsp;{detail.address}
            </Col>
            <Col span={6}>提交人：{detail.submit_name}</Col>
            <Col span={6}>提交时间：{detail.created_at}</Col>
          </Row>
          <Row
            style={{
              fontSize: "14px",
              paddingBottom: "6px",
            }}
          >
            <Col span={6}>法人：{detail.specific_liable}</Col>
            <Col span={6}>法定代表人电话：{detail.canteen_phone}</Col>
            <Col span={6}>食品安全管理员：{detail.food_safe}</Col>
            <Col span={6}>管理员电话：{detail.food_safe_phone}</Col>
          </Row>
          <div className="title first-title">自查详情</div>
          <Row>
            <Col span={24}>
              <Table
                bordered
                columns={columns}
                dataSource={qlist}
                rowKey={(columns) => columns.id}
                pagination={false}
              />
            </Col>
          </Row>
          <div style={{ display: detail.check_state === 1 ? "block" : "none" }}>
            <div id="pifu" className="title first-title">
              审查批复
            </div>
            <Row
              style={{
                fontSize: "14px",
                paddingTop: "10px",
                paddingBottom: "6px",
              }}
            >
              <Col span={24}>
                <span style={{ color: "red" }}>*</span>评价
                <span style={{ fontSize: "12px" }}>
                  &nbsp; (请对提交的自查自评进行评价)
                </span>
              </Col>
            </Row>
            <Row
              style={{
                fontSize: "14px",
                paddingTop: "10px",
                paddingBottom: "6px",
              }}
            >
              <Col span={24}>
                <Radio.Group
                  onChange={onChangeRadio}
                  buttonStyle="solid"
                  value={checkState}
                >
                  <Radio value={1}>很好</Radio>
                  <Radio value={2}>还可以</Radio>
                  <Radio value={3}>待改进</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row
              style={{
                fontSize: "14px",
                paddingTop: "10px",
                paddingBottom: "6px",
              }}
            >
              <Col span={24}>
                审查说明
                <span style={{ fontSize: "12px" }}>&nbsp; (非必填))</span>
              </Col>
            </Row>
            <Row
              style={{
                fontSize: "14px",
                paddingTop: "10px",
                paddingBottom: "6px",
              }}
            >
              <Col span={24}>
                <TextArea
                  rows={4}
                  onChange={onChangeTextArea}
                  value={checkContent}
                  placeholder="请输入"
                />
              </Col>
            </Row>
            <Row
              style={{
                fontSize: "14px",
                paddingTop: "10px",
                paddingBottom: "6px",
              }}
            >
              <Col span={24}>
                <Button
                  onClick={toSubmit}
                  style={{ backgroundColor: "#5473E8", color: "#fff " }}
                >
                  提交
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}
