import request from '../../utils/request'


/**
 * app/appraise/list
 * APP_评价建议管理
 * @param data
 */
export function appraiseList(data: any) {
  return request({
    url: '/app/appraise/list',
    method: 'get',
    params: data
  })
}



/**
 * app/appraise/list
 * APP_评价建议管理 详情数据
 * @param data
 */
export function appraiseInfo(data: any) {
  return request({
    url: '/app/appraise/info',
    method: 'get',
    params: data
  })
}


/**
 * app/appraise/list
 * APP_评价建议管理  类型查询
 * @param data
 */
export function appraiseTypes(data: any) {
  return request({
    url: '/app/appraise/types',
    method: 'get',
    params: data
  })
}


/**
 * /app/complaint/list
 * APP_获取投诉列表
 * @param data
 */
export function complaintList(data: any) {
  return request({
    url: '/app/complaint/list',
    method: 'get',
    params: data
  })
}


/**
 * /app/complaint/info
 * APP_获取投诉列表 详情数据
 * @param data
 */
export function complaintInfo(data: any) {
  return request({
    url: '/app/complaint/info',
    method: 'get',
    params: data
  })
}