

import { useEffect, useState } from 'react'
import { Image } from 'antd'
import { isBlank } from '../../../utils/is_blank'
import { useCallback } from 'react'
import { complaintInfo } from '../../../apis/interactive/interactive_list'


export default function ComplaintDetail(props: any) {
  let info = {
    canteen_number: '',// 食堂编号
    content: '', // 内容
    created_at: '', //创建时间
    is_reply: 0,//回复状态
    phone: '',//电话
    pic_appends: '',//拼接后的图片
    pics: '',//图片
    reply: [
      {
        username: '',
        pic_appends: '',
        created_at: '',
        content: '',
      }
    ],//回复数据
    reply_time: '',// 回复时间
    type: '',//type id 
    type_name: '',//type_name
    username: ''//创建人
  }
  const [detailInfo, setDetailInfo] = useState(info)



  const to_get_info = useCallback(() => {
    return complaintInfo({ id: props.id }).then(res => {
      console.log(res.data)
      setDetailInfo(res.data)
    })
  }, [props.id])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  const pic_appends = detailInfo.pic_appends.split(',').map(e => {
    return <Image key={e} className="img" width={100} src={e} />
  })

  const reply_picAppends = detailInfo.reply[0].pic_appends.split(',').map(e => {
    return <Image key={e} className="img" width={100} src={e} />
  })


  const replys = detailInfo.reply.length > 0 ? <div className='info' style={{ display: (detailInfo.is_reply === 1 ? 'block' : 'none') }} >
    <div className="size_style" style={{ marginTop: '20px' }}>回复时间</div>
    <div className="info_item_ds">回复人：{isBlank(detailInfo.reply[0].username) ? '暂无' : detailInfo.reply[0].username}</div>
    <div className="info_item_ds">回复时间：{isBlank(detailInfo.reply[0].created_at) ? '暂无' : detailInfo.reply[0].created_at}</div>
    <div className="info_item_ds"> 回复内容：{isBlank(detailInfo.reply[0].content) ? '暂无' : detailInfo.reply[0].content}</div>
    <div className="info_img">
      <div className="info_img">
        {reply_picAppends}
      </div>
    </div>
  </div> : ''


  return (
    <>
      <div className="detial-wrap">
        <div className="info">
          <div className="size_style">基础信息</div>
          <div className="info_bot">
            <div className="info_item">
              <div className="type">提交人：{isBlank(detailInfo.username) ? '暂无' : detailInfo.username}</div>
            </div>
            <div className="info_item">
              <div>联系方式：{isBlank(detailInfo.phone) ? '暂无' : detailInfo.phone}</div>
            </div>
          </div>
          <div>
            <div className="info_item_head"> 评价描述：</div>
            <div className="info_item_head">{isBlank(detailInfo.content) ? '暂无' : detailInfo.content}</div>
            <div className="info_img">
              {pic_appends}
            </div>
          </div>
        </div>
        {replys}
      </div>
    </>
  )
}