import React, { useState, useEffect } from 'react'
import { Breadcrumb, Input, Modal, Pagination } from 'antd'
import './less/dish.less'
import DishItem from './components/dish-item'
import ZgDishTab from './components/zg-dish-tab'
import IconFont from '../../components/icon-font'
import Detail from './components/detail'
import { dishTypes, dishList } from "../../apis/meal/dish"

export default function Dish() {
  const [typeList, setTypeList] = useState([])
  const [tabId, setTabId] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')
  const [dataList, setDataList] = useState([])
  const [dishId, setDishId] = useState([])
  const prefix = (
    <IconFont type="iconfangdajing" style={{ color: '#A6B6C6' }} />
  )
  const [isVisible, setIsVisible] = useState(false)

  // 分页
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(27)
  const [pageTotal, setPageTotal] = useState(0)

  const showModal = (val: any) => {
    // console.log(val)
    setDishId(val)
    setIsVisible(true)
  }

  const handleCancel = () => {
    setIsVisible(false)
  }

  function tabClick(val: any) {
    // 初始化页码
    setPage(1)

    setTabId(val)
    // tab_dish_list(val)
  }

  function tab_dish_list(val: any) {
    dishList({ type_id: val, page_size: pageSize, page, name: searchTxt }).then(res => {
      const data = res.data
      setDataList(data.data)
      setPage(data.current_page)
      setPageTotal(data.total)
    })
  }

  function to_init_data() {
    dishTypes({}).then(res => {
      res.data.unshift({ id: 0, name: '全部' })
      setTypeList(res.data)
      tabClick(0)
    })
  }

  function handTxt(val: any) {
    setSearchTxt(val.target.value)
  }

  useEffect(() => {
    to_init_data()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    tab_dish_list(tabId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, tabId])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>膳食管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/mea/info/dish">菜品管理</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="dish-wrap">
        <div className="dish-main">
          <div className="main-tit">菜品管理</div>
          <div className="main-tab">
            <ZgDishTab onClick={tabClick} list={typeList}></ZgDishTab>
            <div className="main-search"><Input style={{ width: '300px' }} onChange={(e) => handTxt(e)} placeholder={'请输入菜品名称'} prefix={prefix}></Input></div>
            <div className="main-btn" onClick={() => tab_dish_list(tabId)}>查询</div>
          </div>
          <div className="dish-list">
            {
              dataList.map((item: any, index) => {
                return <div onClick={() => showModal(item.id)} key={index}><DishItem dishInfo={item}></DishItem></div>
              })
            }
          </div>
          <div className="page-box">
            <Pagination defaultCurrent={page}
              current={page}
              pageSize={pageSize}
              total={pageTotal}
              onChange={(page, pageSize) => {
                setPage(page)

                if (pageSize) {
                  setPageSize(pageSize)
                }
              }} />
          </div>
        </div>
      </div>
      <Modal title="菜品详情" footer={null} visible={isVisible} width={900} onCancel={handleCancel}>
        <Detail id={dishId}></Detail>
      </Modal>
    </>
  )
}