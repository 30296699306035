import React, { useState, useCallback, useEffect } from "react"
import {
  Breadcrumb,
  Row,
  Col,
  Select,
  Space,
  DatePicker,
  Button,
  Table,
  Image,
  message,
} from "antd"
import {
  getCorrectionList,
  getCanteenList,
  addTask,
  getCorrectType
} from "../../../apis/warningManage/correction/list"
import ZgCascader from '../../../components/zg-cascader'
import moment from "moment"
import dayjs from 'dayjs'
import { ColumnProps } from "antd/lib/table"
import ShowModal from "components/show-modal"
import { mineCanteens } from "../../../apis/warningManage/certificates/list"
// 导出预警组件
import ZgExport from '../../../components/zg-export'

export default function Correction() {
  const { RangePicker } = DatePicker
  const { Option } = Select
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数
  const [canteenName, setCanteenName] = useState("") //食堂名称
  const [state, setState] = useState() //状态
  const [startdate, setStartdate] = useState<any>(
    dayjs().subtract(1, "month").format('YYYY-MM-DD')
  )
  const [enddate, setEnddate] = useState<any>(dayjs().format('YYYY-MM-DD'))

  const [cityName, setCityName] = useState("") // 城市
  const [districtName, setDistrictName] = useState("") // 区
  const [actionType, setActionType] = useState("") // 纠偏类型
  const [correctType, setCorrectType] = useState<any[]>([])// 纠偏类型
  const [level, setLevel] = useState<number>()

  const [shoolname, setShoolName] = useState<any[]>([])
  const [str, setStr] = useState()
  const showModal = () => {
    mineCanteens({ name: "" }).then((res) => {
      if (res.status === 200) {
        let str: any = (
          <ShowModal
            isShow={true}
            data={res.data}
            closeModal={closeModal}
          ></ShowModal>
        )
        setStr(str)
      }
    })
  }
  const closeModal = () => {
    let str: any = (
      <ShowModal isShow={false} data={[]} closeModal={closeModal}></ShowModal>
    )
    setStr(str)
  }
  const columns: ColumnProps<any>[] = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) => `${index + 1}`,
      width: "60px",
    },
    {
      title: "纠偏时间",
      dataIndex: "date",
      width: "12%",
    },
    {
      title: "餐饮名称",
      dataIndex: "canteen",
      render: (canteen: { canteen_name: any }) => `${canteen.canteen_name}`,
      width: '14%'
    },
    {
      title: "区域",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "类型",
      dataIndex: "correct_type",
      key: "correct_type",
      width: '12%'
    },
    {
      title: "纠偏图像",
      dataIndex: "http_url_image_appends",
      // http_url_image_appends
      key: "http_url_image_appends",
      render: (http_url_image_appends: any) => (
        <Image
          src={http_url_image_appends}
          alt=""
          style={{ width: "48px", height: "42px" }}
        />
        //  overflow: 'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap',
      ),
      width: "80px",
    },
    {
      title: "风险等级",
      dataIndex: "warn_level",
      render: (warn_level: any) =>
        warn_level === 1 ? (
          <div
            style={{
              background: "red",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅰ级</span>
          </div>
        ) : warn_level === 2 ? (
          <div
            style={{
              background: "#ff9900",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅱ级</span>
          </div>
        ) : warn_level === 3 ? (
          <div
            style={{
              background: "#3399ff",
              width: "33px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "white" }}>Ⅲ级</span>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "状态",
      dataIndex: "state",
      // render: (state: any, record: any) => `${state === "1" ? "待处理" :  "已处理" }`,
      render: (state: any) =>
        state === 1 ? (
          <span style={{ color: "red" }}>待处理</span>
        ) : (
          <span>已处理</span>
        ),
      width: "80px",
    },
    {
      title: "处理时间",
      dataIndex: "handle_time",
      render: (handle_time: any) => <span>{handle_time}</span>,
      width: "12%",
    },
    {
      title: "处理人",
      dataIndex: "handle_user_name",
      render: (handle_user_name: any) => <span>{handle_user_name}</span>,
      width: "80px",
    },
    {
      title: "操作",
      dataIndex: "state",
      render: (state: any, row: any) =>
        state === 1 ? (
          <Button
            className="btn"
            type="primary"
            onClick={() => checkDetail(row)}
            ghost={true}
          >
            {" "}
            生成整改单{" "}
          </Button>
        ) : (
          ""
        ),
    },
  ]

  const [correctionList, setCorrectionList] = useState([])


  //按时间查询
  function handDate(dates: any, date: any) {
    setStartdate(date[0])
    setEnddate(date[1])
    setCurrentPage(1)
  }
  //状态
  function handState(val: any) {
    setState(val)
    setCurrentPage(1)
  }
  // 生成整改单
  function checkDetail(val: any) {
    console.log("=======>", val)
    let data = {
      canteen_number: val.canteen_number,
      title: val.area + val.correct_type,
      type: 2,
      risk_type: "视频预警",
      is_auto: 1,
      inspection_time: val.created_at,
      problem_describe: val.area + val.correct_type,
      problem_images: val.http_url_image_appends,
    }
    addTask(data).then((res) => {
      console.log(res)
      const key = "updatable"
      message.loading({ content: "正在生成整改单...", key })
      setTimeout(() => {
        message.success({ content: "生成完成", key, duration: 2 })
      }, 1000)
    })
  }

  //下拉框查询
  function setName(value: any) {
    setCanteenName(value)
    setCurrentPage(1)
  }
  //渲染下拉框数据
  useEffect(() => {
    getCanteenList({}).then((res) => {
      setShoolName(res.data)
    })
  }, [])

  //渲染下拉框数据
  useEffect(() => {
    getCorrectType({}).then((res) => {
      setCorrectType(res.data)
    })
  }, [])

  // 纠偏类型
  const correct = correctType.map((e) => (
    <Option key={e} value={correctType[e]}>
      {e}
    </Option>
  ))

  const options = shoolname.map((e) => (
    <Option key={e.id} value={e.canteen_name}>
      {e.canteen_name}
    </Option>
  ))

  // 纠偏类型
  const onCorrect = (data: string) => {
    console.log("纠偏类型", data)
    setActionType(data)
  }
  // 风险等级
  const onRisk = (id: number) => {
    console.log("风险等级", id)
    setLevel(id)
  }
  // 查询地区
  const getCanteenRegion = (data: any) => {
    // console.log("查询地区", data[0], data[1], data[2])
    setDistrictName(data[2])
    setCityName(data[1])
  }
  // 获取视频预警列表
  const to_get_list = useCallback(() => {
    const sdate = dayjs(startdate).format("YYYY-MM-DD")
    const edate = dayjs(enddate).format("YYYY-MM-DD")
    return getCorrectionList({
      page: currentPage,
      city: cityName,
      correct_type: actionType,
      warn_level: level,
      district: districtName,
      canteen_name: canteenName,
      state: state,
      sdate,
      edate,
    }).then((res) => {
      setTotalCount(res.data.total)
      setCorrectionList(res.data.data)
      setPerPage(res.data.per_page)
      console.log(res)
    })
  }, [currentPage, canteenName, state, startdate, enddate, cityName, districtName, level, actionType])
  function changePage(val: number) {
    setCurrentPage(val)
  }
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  function search() {
    to_get_list()
  }
  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>预警管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/correction/list">视频预警</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content">
          <Row style={{ fontSize: "20px", marginBottom: "32px" }}>
            <Col span={4}>视频预警</Col>
          </Row>

          <Row>
            <Col span={24} className="gutter-row" style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center'
            }}>
              <div className="down">
                <span >纠偏时间：</span>
                <Space
                  direction="vertical"
                  size={12}
                  style={{ marginRight: "20px" }}
                >
                  <RangePicker
                    onChange={handDate}
                    defaultValue={[moment(startdate), moment(enddate)]}
                  />
                </Space>
              </div>
              <div className="down">
                <span>餐饮名称：</span>
                <Select
                  showSearch
                  placeholder="请输入单位名称"
                  style={{ width: "150px", marginRight: "20px" }}
                  onChange={setName}
                  allowClear
                  dropdownMatchSelectWidth={200}
                >
                  <Option value="">全部</Option>
                  {options}
                </Select>
              </div>
              <div className="down">
                <span>状态选择：</span>
                <Select
                  placeholder="状态选择"
                  style={{ width: "150px", marginRight: "20px" }}
                  onChange={handState}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="2">已处理</Option>
                  <Option value="1">待处理</Option>
                </Select>
              </div>
              <div className="down">
                <span>风险等级：</span>
                <Select
                  placeholder="全部"
                  style={{ width: "150px", marginRight: "20px" }}
                  onChange={onRisk}
                  allowClear
                >
                  <Option value="">全部</Option>
                  <Option value="1">Ⅰ级</Option>
                  <Option value="2">Ⅱ级</Option>
                  <Option value="3">Ⅲ级</Option>
                </Select>
              </div>
              <div className="down">
                <span>纠偏类型：</span>
                <Select
                  placeholder="全部"
                  style={{ width: "150px", marginRight: "20px" }}
                  onChange={onCorrect}
                  allowClear
                >
                  <Option value="">全部</Option>
                  {correct}
                </Select>
              </div>
              {/* 所属区域查询功能 */}
              <div className="down">
                <ZgCascader getInputValue={getCanteenRegion}></ZgCascader>
              </div>
              <Button className="down" type="primary" onClick={search}  >
                查询
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col span={24} className="gutter-row">
              {/* 导出 */}
              <ZgExport></ZgExport>
              <Button
                className="btn down"
                ghost={true}
                style={{ marginLeft: "15px", float: "right" }}
                type="primary"
                onClick={showModal}
              >
                一键生成整改单
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={correctionList}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {str}
    </>
  )
}
