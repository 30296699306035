import React, { useState, useEffect, useCallback } from 'react'
import { DatePicker, Modal, Image } from 'antd';
import moment from 'moment';
import ZgRow from '../../../components/zg-row';
import ZgTab from '../../../components/zg-tab';
import { getDayMenu } from '../../../apis/canteen-info/proxy-info'
import './list.less'
import Detail from './detail'
import { proxySslImg } from 'utils/common';
const dateFormat = 'YYYY-MM-DD'

export default function DayList() {
  const [showDetail, setShowDetail] = useState(false)
  const [timeDate, setTime] = useState(moment().format('YYYY-MM-DD'))//选择时间
  const [menuList, setMenuList] = useState([]) //菜谱数据
  const [typeId, setTypeId] = useState(0)  //设置餐次类型
  const [checkId, setCheckId] = useState('')  //查看详情id

  let to_set_id = (data: any) => {
    setCheckId(data)
  }

  function checkDetail(data: any) {
    to_set_id(data)
    return setShowDetail(true)
  }

  function time_change(data: any) {
    if (data) {
      setTime(moment(data).format('YYYY-MM-DD'))
    } else {
      setTime(moment().format('YYYY-MM-DD'))
    }
  }

  function to_close() {
    return setShowDetail(false)
  }

  let tabList = ['早餐', '中餐', '晚餐']

  function get_type(data: any) {
    setTypeId(data)
  }

  // 获取列表
  const to_get_list = useCallback(() => {
    return getDayMenu({ type: typeId, supply_date: timeDate, page_size: 999 }).then(res => {
      setMenuList(res.data.data)
    })
  }, [timeDate, typeId])

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  return (
    <>
      <div className="zg-container-content list-wrap">
        <div className="title-level-first">每日菜谱</div>
        <ZgRow className="title-wrap" justify="flex-start">
          <div className="tab-wrap">
            <ZgTab onClick={get_type} list={tabList}></ZgTab>
          </div>
          <span>供餐日期：</span>
          <DatePicker onChange={time_change} format={dateFormat} defaultValue={moment(timeDate)} />
          {/* <Button type="primary" style={{ marginLeft: '16px' }}>搜索</Button> */}
        </ZgRow>
        {/* <Row gutter={[12, 12]}>
          {
            menuList.map((item: any) => (
              <Col span={2} key={item.id}>
                <div className="img-block" onClick={() => checkDetail(item.dishes.id)}>
                  <ZgRow justify="flex-start" className="card-info">
                    <div style={{color:'red'}}>
                      <Image preview={false} style={{ display: (item.dishes.pics_appends === '' ? 'none' : 'block') }} className="img" src={item.dishes.pics_appends} alt="" />
                      <ZgColumn className="none-wrap" style={{ display: (item.dishes.pics_appends === '' ? 'flex' : 'none') }}>
                        <Image className="no-img" preview={false} src={noDataImg} />
                        暂无图片
                      </ZgColumn>
                    </div>
                  </ZgRow>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="name">{item.dishes_name}</div>
                    <span className="price">￥{item.dishes.cost_price}</span>
                  </div>
                </div>
              </Col>
            ))
          }

        </Row> */}
        <div className="dish-list_index">
          {
            menuList.map((item: any) => {
              return <div>
                <div className="dish-item" style={{ cursor: 'pointer' }} onClick={() => checkDetail(item.dishes.id)}>
                  <div className="item-img">
                    <Image width="100%" preview={false} style={{ display: (item.dishes.pics_appends === '' ? 'none' : 'block') }} className="img" src={ proxySslImg(item.dishes.pics_appends)} alt="" />
                  </div>
                  <div className="item-cont">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="name">{item.dishes_name}</div>
                      <span className="price">￥{item.dishes.cost_price}</span>
                    </div>
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
      <Modal
        visible={showDetail}
        title="菜品详情"
        onCancel={to_close}
        footer={null}
        width="900px"
      >
        <Detail id={checkId}></Detail>
      </Modal>
    </>
  )
}