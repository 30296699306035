import React, { useState, useEffect } from "react";

import Canyindanwei from "./component/area/can-yin-dan-wei";
import Jianyijiance from "./component/area/jian-yi-jian-ce";
import Jianguanzongkuang from "./component/area/jian-guan-zong-kuang";
import Guoqiyujing from "./component/area/guo-qi-yu-jing";
import Jiupianshuju from "./component/area/jiu-pian-shu-ju";
import Anquanzhishu from "./component/area/an-quan-zhi-shu";
import Wulianyujing from "./component/area/wu-lian-yu-jing";
import Shipinyujing from "./component/area/shi-pin-yu-jing";

import { Row, Col } from "antd";

import GetTheme from "../../utils/getTheme";

export default function IndexArea(props: any) {
  let styles = GetTheme();
  let curWidth: any = window.innerWidth;
  const [windowWidth, setWindowWidth] = useState(curWidth);
  const handleResize = (e: any) => {
    setWindowWidth(e.target.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <div className={styles.background}>
      <Row gutter={[16, 16]}>
        <Col xl={8} xxl={6} span={6}>
          <Canyindanwei></Canyindanwei>
          <div className={styles.blank}></div>
          <Anquanzhishu></Anquanzhishu>
        </Col>
        <Col xl={16} xxl={12} span={12}>
          <Jianguanzongkuang></Jianguanzongkuang>
          <div className={styles.blank}></div>
          <div className={styles.flexRow}>
            <Shipinyujing></Shipinyujing>
            <div className={styles.blankHeng}></div>
            <Wulianyujing></Wulianyujing>
          </div>
        </Col>
        {windowWidth >= 1600 ? (
          <Col xl={24} xxl={6} span={6}>
            <Guoqiyujing></Guoqiyujing>
            <div className={styles.blank}></div>
            <Jianyijiance></Jianyijiance>
            <div className={styles.blank}></div>
            <Jiupianshuju></Jiupianshuju>
          </Col>
        ) : (
          <Col xl={24} xxl={6} span={6}>
            <Row gutter={16}>
              <Col span={8}>
                <Guoqiyujing></Guoqiyujing>
              </Col>
              <Col span={8}>
                <Jianyijiance></Jianyijiance>
              </Col>
              <Col span={8}>
                <Jiupianshuju></Jiupianshuju>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}
