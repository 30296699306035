import React, { useState, useCallback, useEffect } from "react";
import Title from "../title";
import { Select } from "antd";
import echarts from "echarts";
import { getRiskStac } from "../../../../apis/board";
import dayjs from "dayjs";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Zongheyujing(props: any) {
  let styles = GetTheme();
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  let riskTemp: any = [{ name: "", value: 0 }];
  const [rDateType, setRDateType] = useState(1);
  const [riskData1, setRiskData1] = useState(riskTemp);
  const [riskData2, setRiskData2] = useState(riskTemp);
  const [riskData3, setRiskData3] = useState(riskTemp);
  function rDateChange(type: any) {
    setRDateType(type);
  }
  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }
  //数组对象排序
  function sortArr(a: any, b: any) {
    return b.value - a.value;
  }
  const riskStacData = useCallback(() => {
    return getRiskStac({
      sdate: formatDate(rDateType)[0],
      edate: formatDate(rDateType)[1],
    }).then((res: any) => {
      if (res.data instanceof Array) {
        let risk1: any = [];
        let risk2: any = [];
        let risk3: any = [];
        let charData: any = [
          { value: 0, name: "一类风险" },
          { value: 0, name: "二类风险" },
          { value: 0, name: "三类风险" },
        ];
        res.data.forEach((item: any) => {
          if (parseInt(item.level1) > 0) {
            risk1.push({ name: item.unit_name, value: parseInt(item.level1) });
            charData[0].value += parseInt(item.level1);
          }
          if (parseInt(item.level2) > 0) {
            risk2.push({ name: item.unit_name, value: parseInt(item.level2) });
            charData[1].value += parseInt(item.level2);
          }
          if (parseInt(item.level3) > 0) {
            risk3.push({ name: item.unit_name, value: parseInt(item.level3) });
            charData[2].value += parseInt(item.level3);
          }
        });
        renderChart(charData);
        setRiskData1(risk1.sort(sortArr));
        setRiskData2(risk2.sort(sortArr));
        setRiskData3(risk3.sort(sortArr));
      }
    });
  }, [rDateType]);
  // 渲染图表
  const renderChart = (data: any) => {
    let labels: any = [];
    let counts: any = [];
    data.forEach((item: any, index: any) => {
      if (index < 4) {
        labels.push(item.name);
        counts.push(item.value);
      }
    });
    var ele: any = document.getElementById("sumRisk");
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      legend: {
        bottom: 10,
        left: "center",
        textStyle: { color: "#fff" },
        formatter: function (name) {
          var index = 0;
          var clientlabels = labels;
          var clientcounts = counts;
          clientlabels.forEach(function (value: any, i: any) {
            if (value === name) {
              index = i;
            }
          });
          return name + " " + clientcounts[index];
        },
      },
      color: ["#E84D4F", "#DC8320", "#3582FE"],
      series: [
        {
          name: "风险等级占比",
          type: "pie",
          radius: ["20%", "60%"],
          center: ["50%", "40%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "inner",
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "inner",
                formatter: "{d}%",
              },
              labelLine: {
                show: false,
              },
            },
          },
          data: data,
        },
      ],
    });
  };
  useEffect(() => {
    riskStacData();
  }, [riskStacData]);
  return (
    <>
      <div className={styles.center}>
        <div className={styles.box + " " + styles.box2}>
          <div className={styles.flexRow + " " + styles.titleLine}>
            <Title title="综合预警风险评估"></Title>
            <Select
              onChange={rDateChange}
              className={"board-select " + styles.select}
              key={timeList[0].type}
              defaultValue={timeList[0].type}
            >
              {timeList.map((item: any) => (
                <Option key={item.type} value={item.type}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.flexRow + " " + styles.riskBox}>
            <div className={styles.riskBox1}>
              <div
                className={
                  styles.flexRow +
                  " " +
                  styles.dotTitle +
                  " " +
                  styles.purpleColor
                }
              >
                <span className={styles.dotBg}>
                  <span className={styles.dot}></span>
                </span>
                风险等级占比
              </div>
              <div className={styles.riskChart} id="sumRisk"></div>
            </div>
            <div style={{ flex: 1 }}>
              <div
                className={
                  styles.flexRow +
                  " " +
                  styles.dotTitle +
                  " " +
                  styles.purpleColor
                }
              >
                <span className={styles.dotBg}>
                  <span className={styles.dot}></span>
                </span>
                风险预警排行
              </div>
              <div className={styles.flexRow + " " + styles.riskList}>
                <div className={styles.riskItem}>
                  <div
                    className={
                      styles.riskItemTitle + " " + styles.redBackground
                    }
                  >
                    一类风险
                  </div>
                  <div className={styles.riskAreaList}>
                    {riskData1.map((item: any, index: any) => (
                      <div
                        key={index}
                        className={styles.flexRow + " " + styles.riskArea}
                      >
                        <span className={styles.label}>{item.name}</span>
                        <span className={styles.value + " " + styles.redColor}>
                          {item.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.riskItem}>
                  <div
                    className={
                      styles.riskItemTitle + " " + styles.yellowBackground
                    }
                  >
                    二类风险
                  </div>
                  <div className={styles.riskAreaList}>
                    {riskData2.map((item: any, index: any) => (
                      <div
                        key={index}
                        className={styles.flexRow + " " + styles.riskArea}
                      >
                        <span className={styles.label}>{item.name}</span>
                        <span
                          className={styles.value + " " + styles.yellowColor}
                        >
                          {item.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.riskItem}>
                  <div
                    className={
                      styles.riskItemTitle + " " + styles.blueBackground
                    }
                  >
                    三类风险
                  </div>
                  <div className={styles.riskAreaList}>
                    {riskData3.map((item: any, index: any) => (
                      <div
                        key={index}
                        className={styles.flexRow + " " + styles.riskArea}
                      >
                        <span className={styles.label}>{item.name}</span>
                        <span
                          className={styles.value + " " + styles.greenColor}
                        >
                          {item.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
