import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumb, Row, Col } from 'antd'
import CanteenList from './canteenList'
import ZgRow from '../../components/zg-row'
import $storeLocal from 'storejs'
import { getVideoList, getTypeConfig } from '../../apis/smart-video/index'
import './style.less'
import VideoItem from './videoItem'
import Empty from 'antd/es/empty'
import moment from 'moment'

export default function SmartVideo(props: any) {
  // console.log("SmartVideo location", props.location)
  let number = ''
  if (props.location.state && props.location.state.number) {
    // 获取传递过来的number
    number = props.location.state.number
  }

  const [isMul, setIsMul] = useState(false)
  const [activeIdx, setIdx] = useState(null)
  const [activeIdxs, setIdxs] = useState([] as any)
  const [curBlock, setCurBlock] = useState(0)
  const [singleItem, setSingleItem] = useState({ high_url: '', canteen: { canteen_name: '' } })
  const [blockItem0, setBlockInfo0] = useState({ high_url: '', canteen: { canteen_name: '' } })
  const [blockItem1, setBlockInfo1] = useState({ high_url: '', canteen: { canteen_name: '' } })
  const [blockItem2, setBlockInfo2] = useState({ high_url: '', canteen: { canteen_name: '' } })
  const [blockItem3, setBlockInfo3] = useState({ high_url: '', canteen: { canteen_name: '' } })
  const [areaList, setAreaList] = useState([{ high_url: '', canteen: { canteen_name: '' } }])
  //重置页面播放
  const [canteenNumber, setCanteenNumber] = useState(number)

  let itemList1 = areaList.map((item: any, idx: any) => (
    <span onClick={() => to_set(idx)} className={['item', activeIdx === idx ? 'active' : ''].join(' ')} key={idx}>
      {item.area}
    </span>
  ))
  let itemList2 = areaList.map((item: any, idx: any) => (
    <span
      onClick={() => to_set_list(idx)}
      className={['item', activeIdxs.includes(idx) ? 'active' : ''].join(' ')}
      key={idx}
    >
      {item.area}
    </span>
  ))

  function to_set_mul(param: any) {
    if (param === 1) {
      return setIsMul(false)
    } else {
      return setIsMul(true)
    }
  }
  function to_set(idx: any) {
    console.log('====> idx', idx)
    setSingleItem(areaList[idx])
    return setIdx(idx)
  }
  function to_set_list(idxs: number) {
    if (activeIdxs.length === 4) {
      activeIdxs.shift()
      setIdxs([...activeIdxs, idxs])
    } else {
      setIdxs([...activeIdxs, idxs])
    }
    setBlockInfo(areaList[idxs])
  }
  function setBlockInfo(info: any) {
    switch (curBlock) {
      case 0:
        setBlockInfo0(info)
        setCurBlock(1)
        break
      case 1:
        setBlockInfo1(info)
        setCurBlock(2)
        break
      case 2:
        setBlockInfo2(info)
        setCurBlock(3)
        break
      case 3:
        setBlockInfo3(info)
        setCurBlock(0)
        break
    }
  }
  function get_number(data: any) {
    setCanteenNumber(data)
  }
  // 获取监控列表
  const to_get_list = useCallback(() => {
    return getVideoList({ number: canteenNumber, page_size: 9999 }).then(res => {
      setAreaList(res.data.data)
    })
  }, [canteenNumber])

  // hook函数
  useEffect(() => {
    if (areaList.length > 0 && areaList[0]['high_url'] && !activeIdx) {
      to_set(0) // 点击默认监控
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaList])

  useEffect(() => {
    if (canteenNumber) {
      setIdx(null)
      setIdxs([])
      setCurBlock(0)
      setSingleItem({ high_url: '', canteen: { canteen_name: '' } })
      // setBlockInfo0({ high_url: '', canteen: { canteen_name: '' } })
      // setBlockInfo1({ high_url: '', canteen: { canteen_name: '' } })
      // setBlockInfo2({ high_url: '', canteen: { canteen_name: '' } })
      // setBlockInfo3({ high_url: '', canteen: { canteen_name: '' } })
      setAreaList([{ high_url: '', canteen: { canteen_name: '' } }])
      setIsMul(null as any)
      setIsMul(false)
      to_get_list()
      //获取纠偏类型
      getTypeConfig({}).then(res => {
        $storeLocal.set('correctConfigInfo', res.data.rectify)
      })
    }
  }, [to_get_list, canteenNumber])

  useEffect(() => {
    // console.log($storeLocal.get("customFirstCanteen"))
    if (!number) {
      setCanteenNumber($storeLocal.get('customFirstCanteen'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>明厨亮灶</Breadcrumb.Item>
        <Breadcrumb.Item>实时监控</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={12} className="zg-container" style={{ margin: '0' }}>
        <Col span={6} xxl={4} style={{ height: '100%' }}>
          <CanteenList getNumber={get_number} nowNumber={canteenNumber}></CanteenList>
        </Col>
        <Col span={18} xxl={20} className="zg-container-content">
          <div className="title-level-first">实时监控</div>
          {itemList1.length > 0 || itemList2.length > 0 ? (
            <>
              <ZgRow justify="space-between" align="flex-start">
                <ZgRow justify="flex-start" className={['area-list', isMul === false ? 'active' : ''].join(' ')}>
                  {itemList1}
                </ZgRow>
                <ZgRow justify="flex-start" className={['area-list', isMul === true ? 'active' : ''].join(' ')}>
                  {itemList2}
                </ZgRow>
                <ZgRow justify="flex-end" className="set-wrap" style={{ width: '200px' }}>
                  <span>分屏设置</span>
                  <span
                    className={['big-block', isMul === false ? 'active' : ''].join(' ')}
                    onClick={() => to_set_mul(1)}
                  ></span>
                  <ZgRow className="litle-block-wrap" onClick={() => to_set_mul(2)}>
                    <span className={['litle-block block1', isMul === true ? 'active' : ''].join(' ')}></span>
                    <span className={['litle-block block2', isMul === true ? 'active' : ''].join(' ')}></span>
                    <span className={['litle-block block3', isMul === true ? 'active' : ''].join(' ')}></span>
                    <span className={['litle-block', isMul === true ? 'active' : ''].join(' ')}></span>
                  </ZgRow>
                </ZgRow>
              </ZgRow>

              <Row
                className="video-item-wrap video-item-wrap-single"
                style={{ display: isMul === false ? 'flex' : 'none' }}
              >
                <Col span={24}>
                  <VideoItem vid={'singleBlockItem'} videoInfo={singleItem}></VideoItem>
                </Col>
              </Row>
              <Row
                gutter={[12, 12]}
                className="video-item-wrap video-item-wrap-multiple"
                style={{ display: isMul === true ? 'flex' : 'none' }}
              >
                <Col span={12} onClick={() => setCurBlock(0)}>
                  <VideoItem
                    vid={'blockItem0'}
                    // noZhuatu="1"
                    className={['block-item', curBlock === 0 ? 'active' : ''].join(' ')}
                    videoInfo={blockItem0}
                  ></VideoItem>
                </Col>
                <Col span={12} onClick={() => setCurBlock(1)}>
                  <VideoItem
                    vid={'blockItem1'}
                    // noZhuatu="1"
                    className={['block-item', curBlock === 1 ? 'active' : ''].join(' ')}
                    videoInfo={blockItem1}
                  ></VideoItem>
                </Col>
                <Col span={12} onClick={() => setCurBlock(2)}>
                  <VideoItem
                    vid={'blockItem2'}
                    // noZhuatu="1"
                    className={['block-item', curBlock === 2 ? 'active' : ''].join(' ')}
                    videoInfo={blockItem2}
                  ></VideoItem>
                </Col>
                <Col span={12} onClick={() => setCurBlock(3)}>
                  <VideoItem
                    vid={'blockItem3'}
                    // noZhuatu="1" // 不抓图
                    className={['block-item', curBlock === 3 ? 'active' : ''].join(' ')}
                    videoInfo={blockItem3}
                  ></VideoItem>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Empty description="暂无监控信息" />
            </>
          )}
        </Col>
      </Row>
    </>
  )
}
