import { Col, Row } from "antd";
import styles from '../less/index.module.less'
import { useEffect, useState } from "react";

export default function BasicInfo(props: any) {
  const [detail, setDetail] = useState(props.detail)
  useEffect(() => {
    setDetail(props.detail)
  }, [props.detail])

  return (
    <>
      <div className={styles.border_top}>
        <Row className={styles.info_Row} >
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4} >
            商户编号
          </Col>
          <Col className={styles.info_Col} span={8}  >
            {detail.mer_nums}
          </Col>
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}  >
            商户类型
          </Col>
          <Col className={styles.info_Col} span={8} >
            {detail.class_name}
          </Col>
        </Row>
        <Row className={styles.info_Row} >
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4} >
            商户名称
          </Col>
          <Col className={styles.info_Col} span={8}  >
            {detail.name}
          </Col>
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}  >
            服务类型
          </Col>
          <Col className={styles.info_Col} span={8} >
            {detail.service_type}
          </Col>
        </Row>
        <Row className={styles.info_Row} >
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4} >
            负责人
          </Col>
          <Col className={styles.info_Col} span={8}  >
            {detail.person_charge}
          </Col>
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}  >
            商户电话
          </Col>
          <Col className={styles.info_Col} span={8} >
            {detail.phone}
          </Col>
        </Row>
        <Row className={styles.info_Row} >
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4} >
            商户地址
          </Col>
          <Col className={styles.info_Col} span={8}  >
            {detail.address}
          </Col>
          <Col className={[styles.info_Col, styles.info_Color].join(' ')} span={4}  >
            地图坐标
          </Col>
          <Col className={styles.info_Col} span={8} >
            {detail.coordinate}
          </Col>
        </Row>
      </div>
    </>
  )
}