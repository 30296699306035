import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, Image } from 'antd';
import ZgInfoItem from '../../../components/zg-info-item';
import noImg from '../../../assets/images/noData.png';
import {isBlank} from '../../../utils/is_blank';
import { getMenuDetail } from '../../../apis/canteen-info/proxy-info'
import './list.less'
export default function Detail(props: any) {
  let info = {
    name: '',
    type_id: 1,
    cost_price: '',
    pics_appends: '',
    nutrition: { carbohydrate: '', protein: '', hot: '', fat: '' },
    dishes_type: { name: '' },
    burden_master: '',
    burden: '',
    formula: ''
  }
  const [detailInfo, setDetailInfo] = useState(info)
  // console.log(props.id)
  let to_get_info = useCallback(() => {
    return getMenuDetail({ id: props.id }).then(res => {
      setDetailInfo(res.data)
    })
  }, [props.id])
  useEffect(() => {
    to_get_info()
  }, [to_get_info])
  return (
    <div className="detail-wrap">
      <Row gutter={[12, 12]}>
        <Col span={8}><ZgInfoItem title="菜品名称" content={detailInfo.name}></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="菜品类别" content={detailInfo.dishes_type.name}></ZgInfoItem></Col>
        <Col span={8}><ZgInfoItem title="菜品价格" content={detailInfo.cost_price + '元'}></ZgInfoItem></Col>
      </Row>
      <div className="line"></div>
      <div className="title-level-second">食材明细</div>
      <ZgInfoItem title="主料" content={isBlank(detailInfo.burden_master) ? "暂无" : detailInfo.burden_master}></ZgInfoItem>
      <ZgInfoItem title="辅料" content={isBlank(detailInfo.burden) ? "暂无" : detailInfo.burden}></ZgInfoItem>
      <div className="line"></div>
      <div className="title-level-second">食材做法</div>
      <div className="desc-wrap">
        {isBlank(detailInfo.formula) ? "暂无" : detailInfo.formula}
      </div>
      <div className="line"></div>
      <div className="title-level-second">营养分析</div>
      <Row gutter={[12, 12]}>
        <Col span={6}><ZgInfoItem title="蛋白质" content={detailInfo.nutrition.protein === '' ? '--' : detailInfo.nutrition.protein + "g/100g"}></ZgInfoItem></Col>
        <Col span={6}><ZgInfoItem title="脂肪" content={detailInfo.nutrition.fat === '' ? '--' : detailInfo.nutrition.fat + "g/100g"}></ZgInfoItem></Col>
        <Col span={6}><ZgInfoItem title="碳水化合物" content={detailInfo.nutrition.carbohydrate === '' ? '--' : detailInfo.nutrition.carbohydrate + "g/100g"}></ZgInfoItem></Col>
        <Col span={6}><ZgInfoItem title="热量" content={detailInfo.nutrition.hot === '' ? '--' : detailInfo.nutrition.hot + "千卡/100g"}></ZgInfoItem></Col>
      </Row>
      <div className="line"></div>
      <div className="title-level-second">菜品图片</div>
      <div className="img-wrap">
        <Image className="img" style={{ display: (detailInfo.pics_appends === '' ? 'none' : 'inline-block') }} src={detailInfo.pics_appends} />
        <Image className="img" preview={false} style={{ display: (detailInfo.pics_appends === '' ? 'inline-block' : 'none') }} src={noImg} />
      </div>
    </div>
  )
}