import React from "react";
import GetTheme from "../../../utils/getTheme";

export default function Title(props: any) {
  let styles = GetTheme();
  return (
    <>
      <div className={styles.title}>{props.title}</div>
    </>
  );
}
