import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";
import "moment/locale/zh-cn"; //配置antd组件的英文转换成中文
import reportWebVitals from "./reportWebVitals";
import SetRoute from "./routes/index";

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <SetRoute />
  </ConfigProvider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
