import request from "../../utils/request"
/**
 * 获取县区列表
 */
export function getCanteenListByArea(data: any) {
  return request({
    url: "/app/canteen/by-district-list",
    method: "get",
    data,
  })
}
/**
 * 导出食堂信息
 */
export function getExportIndex(data: any) {
  return request({
    url: "/app/canteen/export",
    // method: "get",
    params: data,
    method: 'get',
    responseType: 'blob',
    // ...opt
  })
}
/**
 * 获取远程食堂token
 */
export function getRemoteToken(data: any) {
  return request({
    url: "/app/canteen/remote_token",
    method: "post",
    data,
  })
}
/**
 * 获取食堂列表
 */
export function getCanteenList(data: any) {
  return request({
    url: "/app/canteen/list",
    method: "get",
    params: data,
  })
}
/**
 * 获取所有食堂
 */
export function getCanteenAll(data: any) {
  return request({
    url: "/app/canteen/all",
    method: "get",
    params: data,
  })
}
/**
 * 获取食堂信息
 */
export function getCanteenInfo(data: any) {
  return request({
    url: "/app/canteen/info",
    method: "get",
    params: data,
  })
}
/**
 * 获取餐饮类型
 */
export function getCanteenType(data: any) {
  return request({
    url: "/api/canteen/type",
    method: "get",
    params: data,
  })
}
/**
 * 获取城市
 */
export function getCity(data: any) {
  return request({
    url: "/app/city",
    method: "get",
    params: data,
  })
}