import React, { useState, useCallback, useEffect } from "react"
import { Tree } from "antd"
import ZgSearch from "../../components/zg-search"
import ZgCard from "../../components/zg-card"
import "./style.less"
import {
  getCanteenListByArea,
} from "../../apis/canteen-info/list"
import $storeLocal from "storejs"
import { DownOutlined } from "@ant-design/icons"

export default function CanteenList(props: any) {
  let number = props.nowNumber
  if (!number) {
    number = $storeLocal.get("customFirstCanteen")
  }
  //console.log("当前number", number)

  const [canteenName, setCanteenName] = useState("") //食堂名称
  const [expandedKeys, setExpandedKeys] = useState([""])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [treeData, setTreeData] = useState([
    {
      title: "",
      key: "0",
      children: [
        {
          title: "",
          key: "0-1",
        },
      ],
    },
  ])
  const onExpand = (expandedKeysValue: any) => {
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }
  const [defaultSelectKey, setDefaultSelectKey] = useState([])
  const getParentKey: any = (key: any, tree: any) => {
    let parentKey: any = []
    tree.forEach((item: any) => {
      item.children.forEach((item1: any) => {
        if (item1.key.indexOf(key) > -1) {
          parentKey.push(item1.key)
        }
      })
    })
    return parentKey
  }
  const setTreeList = (data: any, cname: any) => {
    let treeData1: any = []

    data.forEach((item: any, index: any) => {
      let children1: any = []

      item.canteens.forEach((item1: any) => {
        children1.push({
          key: item1.canteen_number,
          title: item1.canteen_name,
        })
      })

      treeData1.push({ key: index, title: item.district, children: children1 })
    })

    generateList(treeData1, cname)
  }

  const loop = (data: any) =>
    data.map((item: any) => {
      const index = item.title.indexOf(canteenName)
      const beforeStr = item.title.substr(0, index)
      const afterStr = item.title.substr(index + canteenName.length)
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: "#f50" }}>{canteenName}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        )
      if (item.children) {
        return { title, key: item.key, children: loop(item.children) }
      }

      return {
        title,
        key: item.key,
      }
    })

  const generateList = (data: any, cname: any) => {
    let datalist: any = []
    let key1: any = []

    data.forEach((item: any, index: any) => {
      datalist.push({ key: item.key, title: item.title })
      item.children.forEach((item1: any) => {
        if (item1.key.indexOf(number) > -1) {
          key1.push(item1.key)
        }
        datalist.push({ key: item1.key, title: item1.title })
      })
    })
    const abc: any = []
    if (
      canteenName === undefined ||
      canteenName === null ||
      canteenName === ""
    ) {
    } else {
      datalist.forEach((element: any) => {
        let item: any = element

        if (item.title.indexOf(cname) > -1 && isNaN(item.key) === true) {
          let key2: any = getParentKey(item.key, data)
          abc.push(key2)
        }
      })
    }

    if (abc.length <= 0) {
      setTreeData(data)
      setExpandedKeys(key1)
      setDefaultSelectKey(key1)
      setAutoExpandParent(true)
    } else {
      let newData: any = []
      data.forEach((element: any) => {
        let newChildren: any = []
        let m = 1
        element.children.forEach((ele: any) => {
          abc.forEach((ele1: any) => {
            if (ele1.indexOf(ele.key) > -1) {
              newChildren.push({ key: ele.key, title: ele.title })
              m = 2
            }
          })
        })
        if (m === 2) {
          newData.push({
            key: element.key,
            title: element.title,
            children: newChildren,
          })
        }
      })
      setTreeData(newData)
      setDefaultSelectKey([])
      setExpandedKeys(abc)
      setAutoExpandParent(true)
    }
  }
  // 搜索的食堂
  function getCanteenName(data: any) {
    setCanteenName(data)
  }
  function handleClick(data: any) {
    if (isNaN(data) === true) {
      if (data !== number) {
        setDefaultSelectKey(data)
      }
      props.getNumber(data)
    }
  }
  // 获取食堂列表
  const to_get_list = useCallback(() => {
    return getCanteenListByArea({ name: canteenName }).then((res) => {
      setTreeList(res.data, canteenName)
    })
    //return getCanteenAll({ name: canteenName }).then((res) => {
    //setCanteenList(res.data)
    //})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canteenName])

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])

  return (
    <ZgCard className="canteen-list">
      <div className="title-level-first">餐饮列表</div>
      <div className="search-wrap">
        <ZgSearch
          getInputValue={getCanteenName}
          placeholder="请输入餐饮名称"
        ></ZgSearch>
      </div>
      <div className="menu-list" style={{ paddingLeft: "15px" }}>
        <Tree
          showLine
          switcherIcon={<DownOutlined />}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          selectedKeys={defaultSelectKey}
          treeData={loop(treeData)}
          onSelect={handleClick}
        />
      </div>
    </ZgCard>
  )
}
