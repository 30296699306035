import React, { useState, useEffect } from "react"
import { Table, Row, Col, Input, Modal, message } from "antd"
import { addTask } from "../apis/warningManage/certificates/list"

export default function ShowModal(props: any) {
  let data1: any = props.data
  let isShow1: any = props.isShow
  const [isModalVisible, setIsModalVisible] = useState(isShow1)
  const [dataList1, setDataList1] = useState(data1)
  const [canteenNumbers, setCanteenNumbers] = useState([])

  const columns1 = [
    {
      title: "序号",
      dataIndex: "id",
      //将数据排数
      render: (id: any, record: any, index: number) => `${index + 1}`,
      width:80
    },
    {
      title: "编号",
      dataIndex: "canteen_number",
      width:100
    },
    {
      title: "单位名称",
      dataIndex: "canteen_name",
    },
  ]
  const searchList1 = (val: any) => {
    let newData: any = []
    data1.forEach((element: any) => {
      if (
        element.canteen_name.indexOf(val) !== -1 ||
        element.canteen_number.indexOf(val) !== -1
      ) {
        newData.push(element)
      }
    })
    setDataList1(newData)
    /*mineCanteens({ name: val }).then((res) => {
      console.log("res", res)
      if (res.status === 200) {
        setDataList1(res.data)
      }
    })*/
  }

  const handleOk = () => {
    let data = {
      is_many: 2,
      canteen_number: canteenNumbers,
    }
    addTask(data)
      .then((res) => {
        const key = "updatable1"
        message.loading({ content: "正在生成整改单...", key })
        setTimeout(() => {
          message.success({ content: "生成完成", key, duration: 2 })
          props.closeModal()
        }, 1000)
      })
      .catch((e) => {
        console.log(e.response)
      })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    props.closeModal()
  }

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      let numbers: any = []
      selectedRows.forEach((element: any) => {
        console.log(element.canteen_number)
        numbers.push(element.canteen_number)
      })
      setCanteenNumbers(numbers)
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {},
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {},
  }
  function ShowM() {
    setIsModalVisible(props.isShow)
  }
  useEffect(() => {
    ShowM()
  })
  return (
    <>
      <Modal
        title="一键生成整改单"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleCancel}
      >
        <Row>
          <Col span={24}>
            针对选择的单位分别生成对应的预警信息整改任务（选择该单位后，其待处理状态下的视频预警、原料预警、健康证预警、餐饮证件预警、物联预警5类预警信息将会自动生成整改单发送至食堂端进行整改）。
          </Col>
          <Col span={24}>
            <Input.Search
              allowClear
              style={{
                width: "94%",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
              defaultValue=""
              onSearch={searchList1}
            />
          </Col>
          <Col span={24}>
            <Table
              rowSelection={{
                type: "checkbox",
                preserveSelectedRowKeys: true,
                ...rowSelection,
              }}
              columns={columns1}
              scroll={{ y: 300 }}
              dataSource={dataList1}
              rowKey={(columns1) => columns1.id}
              pagination={false}
            ></Table>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
