import request from '../../utils/request'

export function recommendList(data: any) {
  return request({
    url: '/app/dietitian/recipe/index',
    method: 'get',
    params: data
  })
}

export function recommendInfo(data: any) {
  return request({
    url: '/app/dietitian/recipe/info',
    method: 'get',
    params: data
  })
}