import { Breadcrumb, Col, Row } from "antd";
import EnterScore from './component/enterScore'
import MapData from './component/mapData'
import Statistics from './component/statistics'
import styles from './less/index.module.less'
import { getBusinessChart } from '../../../apis/merchant/enter'
import { useCallback, useEffect, useState } from "react";

import MD5 from '../../../utils/md5'


export default function MerchantIndex(props: any) {

  const [business, setBusiness] = useState([])
  const [enter, setEnter] = useState({})
  /**
 *  当前时间戳（10位）
 */
  const timestamp = () => {
    let outcome = Math.round(new Date().getTime() / 1000).toString();
    return Number(outcome)
  }
  const get_business = useCallback(async () => {
    // signcode 32位 MD5加密
    let sign = MD5('1046' + timestamp() + 'E80AB7149', 32)
    let data = {
      time: timestamp(),
      app_id: 1046,
      signcode: sign,
    }
    const res = await getBusinessChart(data)
    setBusiness(res.data)
    let enterData = {
      count: res.data.count,
      aproportion: res.data.aproportion,
      bproportion: res.data.bproportion,
      cproportion: res.data.cproportion,
      dproportion: res.data.dproportion,
      disableCount: res.data.disableCount, //禁用数量
      enableCount: res.data.enableCount,//	string	开启数量
    }
    setEnter(enterData)
  }, [])

  useEffect(() => {
    get_business()
  }, [get_business])


  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>商户管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/merchant/enterIndex">入驻商户分析</a>
        </Breadcrumb.Item>
      </Breadcrumb>


      <div className={styles.tableData} style={{ height: 'calc(100vh - 114px)' }} >
        <Row gutter={16} style={{ height: '100%' }}>
          <Col span={6} style={{ height: '100%' }}>
            <div className={styles.tableData_content}>
              <EnterScore business={business}></EnterScore>
            </div>
          </Col>
          <Col span={12} style={{ height: '100%' }}>
            <div className={styles.tableData_content}>
              <MapData business={business}></MapData>
            </div>
          </Col>
          <Col span={6}>
            <Statistics enter={enter}></Statistics>
          </Col>
        </Row>
      </div >
    </>
  )
}