import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Pagination } from 'antd'
import ZgImage from '../../components/zg-image'
import './less/recommend.less'
import { recommendList } from "../../apis/meal/recommend"
import { useHistory } from 'react-router-dom';

export default function Recommend(props: any) {
  const [dataList, setDataList] = useState([])

  // 分页
  const [page, setPage] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState(20)
  const [pageTotal, setPageTotal] = useState(0)
  let history = useHistory()
  const showImageModal = (e: any) => {
    console.log(e)
    history.push('/meal/recommend/info',e.id)
  }

  function to_init_data() {
    recommendList({ page_size: pageSize, page, state: 1 }).then(res => {
      const data = res.data
      setDataList(data.data)
      setPage(data.current_page)
      setPageTotal(data.total)
    })
  }

  useEffect(() => {
    to_init_data()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>膳食管理</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/meal/recommend">菜谱推荐</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container">
        <div className="zg-container-content">
          <Row style={{ marginBottom: '32px', fontSize: '20px' }}>
            <Col span={4}>
              菜谱推荐
            </Col>
          </Row>
          <div className="zg-flex-row">
            {
              dataList.map((item: any, index) => {
                return <div onClick={() => showImageModal(item)} key={index}><ZgImage src={item.http_url_image_appends} title={item.title} name={item.dietitian.name} time={item.dietitian.created_at}></ZgImage></div>
              })
            }
          </div>
          {
            pageTotal > 0 ?
              <div className="page-box">
                <Pagination defaultCurrent={page}
                  current={page}
                  pageSize={pageSize}
                  total={pageTotal}
                  onChange={(page, pageSize) => {
                    setPage(page)
                  }} />
              </div> : <></>
          }
        </div>
      </div>
    </>
  )
}