import React, { useEffect, useState } from "react"
import ZgTitle from "../../../../components/zg-title"
import ZgCard from "../../../../components/zg-card"
import ZgRow from "../../../../components/zg-row"
import { Row, Col, Table } from "antd"
import { warningIndex } from "../../../../apis/intelligence-rectify/intelligence-rectify"

export default function DocumentRanking() {
  const [data, setData] = useState([])
  const currentPage = 1
  const columns = [
    {
      title: '序号',
      //将数据排数
      render: (id: any, record: any, index: number) => `${(index + 1) + (currentPage - 1)}`,
    },
    {
      title: '餐饮名称',
      dataIndex: 'canteen_name',
      key: 'canteen_name',
    },
    {
      title: '证件预警总数',
      dataIndex: 'today',
      key: 'today',
      width:'20%'
    },
    {
      title: '证件预警待处理',
      dataIndex: 'notHandle',
      key: 'notHandle',
      width:'25%'
    }
  ]

  function to_init_data() {
    warningIndex({}).then(res => {
      setData(res.data.cert_expire_ranking_list)
    })
  }
  useEffect(() => {
    to_init_data()
  }, [])
  return (
    <>
      <div className="stac-info-wrap">
        <ZgCard>
          <ZgRow justify="space-between" align="flex-start">
            <ZgTitle title="证件预警排行"></ZgTitle>
            <div className="all_flex">
              <span className="inside">30日内</span>
            </div>
          </ZgRow>
          <Row>
            <Col span={24}>
              <Table rowKey={columns => columns.canteen_number} dataSource={data} columns={columns} pagination={false} />
            </Col>
          </Row>
        </ZgCard>
      </div>
    </>
  );
}
