import styles from '../less/order.module.less'

export default function ChartTitle(props: any) {
  return (
    <>
      <div className={styles.chart_title}>
        <div className={styles.title_left}></div>
        <div className={styles.title_text}>
          {props.text}
        </div>
        <div className={styles.title_right}>
          {props.unit}
        </div>
      </div>
    </>
  )
}
