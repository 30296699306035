import { List } from 'antd'
import { useEffect, useState } from 'react'
import styles from '../less/enterScore.module.less'

export default function EnterScore(props: any) {
  const [ranking, setRanking] = useState([
    {
      is_outside: "",
      mer_id: "",
      name: "",
      score: 0,
      sort: ""
    }
  ])
  const [business, setBusiness] = useState(props.business)
  useEffect(() => {
    setBusiness(props.business)
    setRanking(props.business.ranking)
  }, [props.business])



  return (
    <>
      <div className={styles.score}>
        <div className={styles.biaoshi}></div>
        <div className={styles.title}>入驻商户评分排行（加入用户评分）</div>
        <div className={styles.day}>昨日</div>
      </div>
      <div className={styles.scoreList}>
        <List
          itemLayout="horizontal"
          dataSource={ranking}
          renderItem={(item, index) => (
            <List.Item>
              <div className={[styles.scoreTag, index + 1 === 1 ? "blue-bg" : index + 1 === 2 ? "green-bg" : index + 1 === 3 ? "yellow-bg" : "grey-bg"].join(" ")}>
                {index + 1}
              </div>
              <List.Item.Meta
                className={styles.listItem}
                title={
                  <div className={styles.listTitle}>{item.name}</div>
                }
              // description="较前日无变化"
              />
              <div style={{
                color: 'rgba(42, 130, 228, 1)',
                fontSize: '16px'
              }}>{item.score}</div>
            </List.Item>
          )}
        />
      </div>
    </>
  )
}