import request from '../../utils/request_yidian'

/**
 * 商户图表数据
 */
export function getBusinessChart(data: any) {
  return request({
    url: '/businessChart',
    method: 'post',
    data
  })
}
