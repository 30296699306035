import { Breadcrumb, Button, Col, Modal, Row, Space, Table, Tabs, DatePicker, Input, Select } from "antd"
import {
  getCanteenList,
} from "../../apis/warningManage/materials/list"
import { getCanteenPartyList } from '../../apis/potluck-user/potluck'
import React, { useState, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
const { TabPane } = Tabs
const { RangePicker } = DatePicker
const { Option } = Select

export default function PotluckUserList(props: any) {
  let history = useHistory();
  const [currentPage, setCurrentPage] = useState(1) //当前页码
  const [totalCount, setTotalCount] = useState(0) //总数
  const [perPage, setPerPage] = useState(15) //每页条数 
  const [canteenList, setCanteenList] = useState([])
  const [startdate, setStartdate] = useState();
  const [enddate, setEnddate] = useState();
  const [checkId, setCheckId] = useState("") //查看详情id
  const [showDetail, setShowDetail] = useState(false) //详情弹窗
  const [shoolid, setshoolId] = useState("") //学校id
  const [shoolname, setShoolName] = useState([]) //食堂名称
  const [state, setState] = useState('') // 筛选状态
  const [approveName, setApproveName] = useState('')


  let columns = [
    {
      title: "序号",
      dataIndex: "id",
      render: (text: any, record: any, index: number) =>
        `${index + 1 + (currentPage - 1) * perPage}`,
    },
    {
      title: "聚餐事由",
      dataIndex: "title",
    },

    {
      title: "聚餐规模",
      dataIndex: "scale",
      render: (scale: any) => (
        <>
          <div>{scale}桌</div>
        </>
      ),
    },
    {
      title: "申报单位",
      dataIndex: "canteen",
      render: (canteen: any) => (
        <>
          <div>{canteen.canteen_name}</div>
        </>
      ),
    },
    {
      title: "申报时间",
      dataIndex: "created_at",
    },
    {
      title: "申报状态",
      dataIndex: "state",
      key: "state",
      render: (state: any) => (
        <>
          {
            <span key={state}>
              {
                state == "-1" ?
                  (<div style={{ color: "#8e8e8e" }}>已取消</div>) :
                  state === 0 ? (<div style={{ color: 'rgb(42, 130, 228)' }} >待审核</div>) :
                    state === 1 ? (<div style={{ color: "rgb(0, 186, 173)" }} >审批通过</div>) :
                      state === 2 ? (<div style={{ color: "rgb(212, 48, 48)" }} >不予审批</div>) :
                        ("")
              }
            </span>
          }
        </>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (record: any) => (
        <Button
          className="btn"
          type="primary"
          onClick={() => checkDetail(record.id)}
          ghost={true}
        >
          查看详情
        </Button>
      ),
    },
  ]
  useEffect(() => {
    getCanteenList({}).then((res) => {
      setShoolName(res.data)
    })
  }, [])
  //遍历出食堂名称
  const options = shoolname.map((e: any) => (
    <Option key={e.canteen_number} value={e.canteen_name}>
      {e.canteen_name}
    </Option>
  ))

  function checkDetail(id: any) {
    history.push("/PotluckUser/detail", id);
  }

  // 获取食堂列表
  const to_get_list = useCallback(() => {
    return getCanteenPartyList({
      page: currentPage, page_size: 15, state: state, sdate: startdate, edate: enddate, canteen_number: shoolid, approve_com: approveName
    }).then(
      (res) => {
        setTotalCount(res.data.total)
        setCanteenList(res.data.data)
        setPerPage(res.data.per_page)
      }
    )
  }, [currentPage, state, startdate, enddate, shoolid, approveName])

  // hook函数
  useEffect(() => {
    to_get_list()
  }, [to_get_list])


  function search() {
    to_get_list();
  }


  //按时间查询
  function handDate(dates: any, date: any) {
    setStartdate(date[0]);
    setEnddate(date[1]);
    setCurrentPage(1);
  }

  function changeType(val: any) {
    setState(val)
  }

  function changePage(val: number) {
    setCurrentPage(val)
  }

  //输入查询
  function handName(value: any) {
    console.log(value.target.value);

    setApproveName(value.target.value);
    setCurrentPage(1);
  }

  //下拉框查询
  function setName(val: any, key: any) {
    let numberID = ""
    if (val) {
      setshoolId(key.key)
    } else {
      setshoolId(numberID)
    }
    setCurrentPage(1)
  }

  return (
    <>
      <Breadcrumb className="zg-bread-nav">
        <Breadcrumb.Item>聚餐申报</Breadcrumb.Item>
      </Breadcrumb>
      <div className="zg-container" style={{ overflow: "auto", height: "94%" }}>
        <div className="zg-container-content inspection-check-wrap">

          <Row>
            <Col span={24} className="gutter-row">
              <Tabs defaultActiveKey="" onChange={changeType}>
                <TabPane tab="全部" key="">
                </TabPane>
                <TabPane tab="待审核" key="0">
                </TabPane>
                <TabPane tab="审核通过" key="1">
                </TabPane>
                <TabPane tab="审核驳回" key="2">
                </TabPane>
              </Tabs>

            </Col>
          </Row>
          <Row style={{ marginBottom: "16px" }}>
            <Col span={20} className="gutter-row">
              <span>申报时间：</span>
              <Space
                direction="vertical"
                size={12}
                style={{ marginRight: "20px" }}
              >
                <RangePicker onChange={handDate} />
              </Space>
              <span>申报单位：</span>
              <Select
                showSearch
                placeholder="请输入申报单位"
                style={{ width: 140, marginRight: "15px" }}
                onChange={setName}
                allowClear
                dropdownMatchSelectWidth={200}
              >
                <Option value="">全部</Option>
                {options}
              </Select>
              <span>审批单位：</span>
              <Input
                placeholder="请输入"
                type="text"
                onChange={handName}
                style={{ width: 140, marginRight: "15px" }}
              />
              {/* <Select
                showSearch
                placeholder="请输入审批单位"
                style={{ width: 140, marginRight: "15px" }}
                onChange={setName}
                allowClear
                dropdownMatchSelectWidth={200}
              >
                <Option value="">全部</Option>
                {options}
              </Select> */}
              <Button type="primary" onClick={search}>
                查询
              </Button>
            </Col>
            {/* <Col span={4}><div className="add-btn-wrap"><Button type="default" onClick={to_add}>新增巡检</Button></div></Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={canteenList}
                rowKey={(columns) => columns.id}
                pagination={{
                  // 分页
                  current: currentPage,
                  total: totalCount,
                  pageSize: perPage,
                  onChange: changePage,
                }}
              ></Table>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
