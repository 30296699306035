import request from '../../utils/request'
import proxy from '../../utils/proxy'
/**
 * 获取远程食堂token
 */
export function getRemoteToken(data: any) {
  return request({
    url: '/app/canteen/remote_token',
    method: 'post',
    data
  })
}

export function thingsInternetGroupIndex(data: any) {
  return proxy({
    url: '/api/things-internet/group-index',
    method: 'get',
    params: data
  })
}