import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import { RingProgress } from '@ant-design/charts';
import ZgCard from '../../../components/zg-card';
import ZgTitle from '../../../components/zg-title';
import ZgColumn from '../../../components/zg-column';
// import ZgRow from '../../../components/zg-row';
import ReactEcharts from 'echarts-for-react'
import { getSafeIndexDayInfo } from 'apis/canteen-info/proxy-info';

// 数据模型
class infoModel {
  day_num = 0
  evaluation_desc = ''
  info_desc = ''

  warn_descs = {
    correct: '',
    goods_expired: '',
    health_certificate_expired: '',
  }
}

export default function Score(props: any) {
  const [score, setScore] = useState(0)

  const option = {
    series: [{
      hoverAnimation: false,
      name: '访问',
      type: 'pie',
      label: {
        show: false
      },
      labelLine: {
        show: false
      },
      color: ['#5473E8', '#E9ECF7'],
      radius: ['95%', '100%'],
      data: [{
        value: score,
        name: 'n'
      }, {
        value: 100 - score,
        name: 'v'
      }]
    }]
  }

  // 评估说明
  const [info, setInfo] = useState(new infoModel())

  // 本月时间
  const sdate = moment().startOf('month').format('YYYY-MM-DD')
  const edate = moment().endOf('month').format('YYYY-MM-DD')

  useEffect(() => {
    if (!props.number) return

    // 获取评估指数说明（文字内容）
    getSafeIndexDayInfo({ canteen_number: props.number, sdate, edate }).then(r => {
      // console.log('获取评估指数说明（文字内容）', r)
      if (r.status === 200) {
        const data = r.data
        const dt = new infoModel()
        dt.day_num = data.day_num
        dt.evaluation_desc = data.evaluation_desc
        dt.info_desc = data.info_desc
        dt.warn_descs = data.warn_descs
        setInfo(dt)
      } else {
        console.error('获取评估指数说明（文字内容）接口报错', r)
      }
    }).catch(e => {
      console.error('获取评估指数说明（文字内容）请求异常', e)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.number])

  useEffect(() => {
    setScore(props.score)
  }, [props.score])

  return (
    <div className="score-wrap">
      <ZgCard>
        <div className="title-level-first">餐饮安全评估指数（本月）</div>
        <ZgColumn>
          <div className="chart-wrap">
            <ReactEcharts option={option} style={{ height: '180px', width: '180px' }} />
            <ZgColumn className="desc-wrap">
              <div className="chart-number">
                {score > 0 ? <>{score}<span className="unit">分</span></> : <span className="unit">未评分</span>}
              </div>
              <div className="chart-desc">评估分数</div>
            </ZgColumn>
          </div>
          {/* <div className="desc-line">餐饮安全评估指数</div> */}
        </ZgColumn>
        <ZgTitle title="评分说明"></ZgTitle>
        <div className="desc">
          {score > 0 ? info.evaluation_desc : '本月未参与评分'}
        </div>
        {/* <ZgTitle title="日常巡检"></ZgTitle>
        <div className="desc">
          {info.info_desc}
        </div> */}
        {score > 0 ?
          <>
            <ZgTitle title="纠偏预警"></ZgTitle>
            <div className="desc">
              {info.warn_descs.correct}
            </div>
            <ZgTitle title="原料过期"></ZgTitle>
            <div className="desc">
              {info.warn_descs.goods_expired}
            </div>
            <ZgTitle title="证件过期"></ZgTitle>
            <div className="desc">
              {info.warn_descs.health_certificate_expired}
            </div>
          </> : <></>}
      </ZgCard>
    </div>
  );
}