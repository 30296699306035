import { message, Spin } from 'antd'
import ReactDOM from 'react-dom';
import axios from "axios";
import $storeLocal from 'storejs'

var baseURL = 'https://3c-central.deyicy.com'

// 当前正在请求的数量
let requestCountSpin = 0

// 显示loading
function showLoading () {
  if (requestCountSpin === 0) {
    const dom = document.createElement('div')
    dom.setAttribute('id', 'loading-spin')
    document.body.appendChild(dom)
    ReactDOM.render(<Spin tip="加载中..." size="large" />, dom)
  }
  requestCountSpin++
}

// 隐藏loading
function hideLoading () {
  requestCountSpin--
  if (requestCountSpin === 0) {
    document.body.removeChild(document.getElementById('loading-spin'))
  }
}

/** 创建axios实例 */
const service = axios.create({
  timeout: 15000, // 请求超时时间
  baseURL: baseURL, // api的base_url
  headers: { 'content-type': 'application/json' }
});

/**
 * request拦截器
 */
service.interceptors.request.use(
  config => {
    let token = ''
    if ($storeLocal.get('customPCToken')) {
      token = $storeLocal.get('customPCToken')
    }
    config.headers['Authorization'] = 'Bearer ' + token

    // loading效果
    showLoading()

    return config;
  },
  error => {
    hideLoading()

    // 遇到错误或者超时
    Promise.reject(error);
  }
);

/**
 * respone 拦截器
 */
service.interceptors.response.use(
  response => {
    hideLoading()

    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response;
    if (res.status !== 200) {
      message.error(res.message);
    } else {
      return response;
    }
  },
  error => {
    hideLoading()

    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = '/login'
    }
    return Promise.reject(error);
  }
);
export default service;