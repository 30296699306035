import React, { useEffect, useState } from "react"
import "../../../../style/data-line.less"
import ZgTitle from "../../../../components/zg-title"
import ReactEcharts from "echarts-for-react"
import { afeterDate, getCurDate } from "../../../../utils/formatDate"
import { dailyIndexCorrectIndex } from "../../../../apis/intelligence-rectify/intelligence-rectify"

// 大数据视频预警--曲线面积图
export default function DataLine() {
  const [data, setData] = useState([])
  const [time, setTime] = useState([])
  const echartsOption = {
    color: ["#80FFA5"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: ["Line 1"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: time,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        type: "line",
        stack: "总量",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: {
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FFFFFF",
              },
              {
                offset: 1,
                color: "#0062FF",
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: data,
      },
    ],
  }

  function to_init_data(val1: any, val2: any) {
    dailyIndexCorrectIndex({ sdate: val1, edate: val2 }).then(res => {
      console.log('折线')
      console.log(res)
      let timeList: any = []
      let dataList: any = []
      res.data.list.forEach((item: any) => {
        timeList.push(item.date)
        dataList.push(item.correct_count)
      })
      setData(dataList)
      setTime(timeList)
    })
  }

  useEffect(() => {
    to_init_data(afeterDate(-30), getCurDate())
  }, [])
  return (
    <>
      <div className="line-cont">
        <div className="cont-tit">
          <ZgTitle title="视频预警预警趋势"></ZgTitle>
          <div className="tit-right">
            <div className="right-one">30日内</div>
          </div>
        </div>
        <div className="line-main">
          <ReactEcharts
            option={echartsOption}
            theme="clear"
            style={{ height: "80%" }}
          ></ReactEcharts>
        </div>
      </div>
    </>
  )
}
