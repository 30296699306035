import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Breadcrumb } from 'antd';
export interface headerProps {
  history: any
}
class canteenInfo extends Component<headerProps, {}> {
  constructor(props: headerProps) {
    super(props)
    this.state = {}
  }
  public render() {
    return (
      <>
        <Breadcrumb className="zg-bread-nav">
          <Breadcrumb.Item>平台总览</Breadcrumb.Item>
          <Breadcrumb.Item>
            首页
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="zg-container">
          平台总览
        </div>
      </>
    )
  }
};

export default withRouter(canteenInfo as any);