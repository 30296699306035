import request from "../utils/request"

/**
 * 登录
 */
export function login(data: any) {
  return request({
    url: "/app/login-jwt",
    method: "post",
    data,
  })
}

/**
 * 获取登录用户信息
 */
export function userInfo(data: any) {
  return request({
    url: "/app/user/info",
    method: "get",
    params: data,
  })
}
/**
 * 获取登录用户的行政信息
 */
export function userGradeInfo(data: any) {
  return request({
    url: "/app/user/current-info",
    method: "get",
    params: data,
  })
}
/**
 * 无状态获取验证码图片
 */
export function getCaptcha(data: any) {
  return request({
    url: "/captcha/api/math",
    method: "get",
    params: data,
  })
}

/**
 * 修改密码
 */
export function ChangePassword(data: any) {
  return request({
    url: "/app/user/password",
    method: "post",
    data,
  })
}