import request from '../../utils/request_yidian'

/**
 * 入住商户类型占比
 */
export function MerchantTypeRatio(data: any) {
  return request({
    url: '/MerchantTypeRatio',
    method: 'get',
    params: data
  })
}


/**
 * 入住商户订单统计
 */
export function Statistics(data: any) {
  return request({
    url: '/OrderStatistics',
    method: 'post',
    data
  })
}


/**
 * 商户订单排行榜
 */
export function RankingList(data: any) {
  return request({
    url: '/OrderRankingList',
    method: 'post',
    data
  })
}