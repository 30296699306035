import React, { useState, useEffect, useCallback } from "react"
import "../index.less"
import ZgTitle from "../../../../components/zg-title"
import IconFont from "../../../../components/icon-font"
import { indexBetween } from "../../../../apis/intelligence-rectify/intelligence-rectify"
import { message } from "antd"

// 大数据视频预警--餐饮安全指数排行榜
export default function RiskRanking(props: any) {
  const [activeIdx, setActiveIdx] = useState(0)
  const [dataList, setDataList] = useState([])
  const [canteenNumber, setCanteenNumber] = useState('')

  function tbIdx(index: any, item: any) {
    setActiveIdx(index)
    if (item.canteen_number !== canteenNumber) {
      setCanteenNumber(item.canteen_number)
      props.getCanteenNumber(item.canteen_number, item.now_score)
    }
  }

  const to_init_data = useCallback(
    () => {
      return indexBetween({ sdate: props.sdate, edate: props.edate, page_size: 100000 }).then(res => {
        console.log("props：", props)
        setDataList(res.data.data)
        if (res.data.data.length > 0) {
          setActiveIdx(0)
          setCanteenNumber(res.data.data[0].canteen_number)
          props.getCanteenNumber(res.data.data[0].canteen_number, res.data.data[0].now_score)
        } else {
          message.warning('暂无数据！')
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.sdate, props.edate],
  )

  useEffect(() => {

    to_init_data()
  }, [to_init_data])

  return (
    <div style={{ height: '100%' }}>
      <div className="zg-card" style={{ padding: "0" }}>
        <div className="rank-cont">
          <ZgTitle title="餐饮安全指数排行榜"></ZgTitle>
          <div className="rank-list" >
            {
              dataList.map((item: any, index) => {
                return <div key={index} onClick={() => tbIdx(index, item)} className={["list-item", index === activeIdx ? "active" : ""].join(" ")}>
                  <div className="list-row-left">
                    <div className={["list-left-tag", index + 1 === 1 ? "blue-bg" : index + 1 === 2 ? "green-bg" : index + 1 === 3 ? "yellow-bg" : "grey-bg"].join(" ")}>
                      {index + 1}
                    </div>
                    <div className="list-left-cln">
                      <div className="list-left-ft1">{item.canteen_name}</div>
                      <div className="list-left-ft2">
                        较前{props.dateIdx === -1 ? '日' : props.dateIdx === 0 ? '周' : '月'}
                        {item.before_score == null || item.before_score === 0 ? '---' : parseFloat(item.now_score) === parseFloat(item.before_score) ? '无变化' :
                          parseFloat(item.now_score) > parseFloat(item.before_score) ?
                            <span>
                              <IconFont
                                type="iconshangsheng"
                                style={{ color: "#72D7DD", fontSize: "16px" }}
                              />
                              {item.before_score == null ? parseFloat(item.now_score) : (parseFloat(item.now_score) - parseFloat(item.before_score)).toFixed(1)}
                            </span> :
                            <span>
                              <IconFont
                                type="iconxiajiang"
                                style={{ color: "#EA5752", fontSize: "16px" }}
                              />
                              {item.before_score == null ? parseFloat(item.now_score) : (parseFloat(item.now_score) - parseFloat(item.before_score)).toFixed(1)}
                            </span>
                        }

                      </div>
                    </div>
                  </div>
                  <div className="list-row-right">{item.now_score}</div>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
