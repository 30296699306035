import React, { useCallback, useState, useEffect } from "react"
// import ZgCard from "../../../../components/zg-card"
import ZgTitle from "../../../../components/zg-title"
import ZgRow from "../../../../components/zg-row"
import "../index.less"
import { dailyIndex } from "../../../../apis/intelligence-rectify/intelligence-rectify"

// 风险指数区域--餐饮工作完成情况分布
export default function RiskBe(props: any) {
  let info: any = {
    clean: 'level_1',
    disinfection: 'level_1',
    morning: 'level_1',
    safety: 'level_1',
    sample: 'level_1',
    waste: 'level_1',
  }
  const [data, setData] = useState(info)
  const get_data = useCallback(
    () => {
      return dailyIndex({ canteen_number: props.canteenNumber, sdate: props.sdate, edate: props.edate }).then(res => {
        let info: any = {
          clean: res.data.clean_checks,
          disinfection: res.data.disinfections,
          morning: res.data.record_morning_checks,
          safety: res.data.safety_checks,
          sample: res.data.samples,
          waste: res.data.waste_disposals,
        }
        setData(info)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canteenNumber, props.sdate, props.edate],
  )
  useEffect(() => {
    get_data()
  }, [get_data])
  return (
    <div>
      <div className="work-wrap">
        <ZgRow justify="space-between" align="flex-start">
          <ZgTitle title="餐饮工作完成情况分布"></ZgTitle>
        </ZgRow>
        <div className="card risk-be-card">
          <div className="complete-line-box">
            <div className="complete-line">
              <div className="label"></div>
              <div className="values">
                <span>一般</span>
                <span>良好</span>
                <span>优秀</span>
              </div>
            </div>
            <div className="complete-line">
              <div className="label">食品留样</div>
              <div className="values bg-values">
                <span className={"tag " + (data.sample === 'level_1' ? 'tag1' : data.sample === 'level_2' ? 'tag2' : 'tag3')}></span>
              </div>
            </div>
            <div className="complete-line">
              <div className="label">人员晨检</div>
              <div className="values bg-values">
                <span className={"tag " + (data.morning === 'level_1' ? 'tag1' : data.morning === 'level_2' ? 'tag2' : 'tag3')}></span>
              </div>
            </div>
            <div className="complete-line">
              <div className="label">食堂清洁</div>
              <div className="values bg-values">
                <span className={"tag " + (data.clean === 'level_1' ? 'tag1' : data.clean === 'level_2' ? 'tag2' : 'tag3')}></span>
              </div>
            </div>
            <div className="complete-line">
              <div className="label">安全自查</div>
              <div className="values bg-values">
                <span className={"tag " + (data.safety === 'level_1' ? 'tag1' : data.safety === 'level_2' ? 'tag2' : 'tag3')}></span>
              </div>
            </div>
            <div className="complete-line">
              <div className="label">废弃物处置</div>
              <div className="values bg-values">
                <span className={"tag " + (data.waste === 'level_1' ? 'tag1' : data.waste === 'level_2' ? 'tag2' : 'tag3')}></span>
              </div>
            </div>
            <div className="complete-line">
              <div className="label">餐具消毒</div>
              <div className="values bg-values">
                <span className={"tag " + (data.disinfection === 'level_1' ? 'tag1' : data.disinfection === 'level_2' ? 'tag2' : 'tag3')}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
