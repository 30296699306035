import React, { useState, useEffect, useCallback } from "react";
import Title from "../title";
import echarts from "echarts";
import { Select } from "antd";
import { getVideoCount, getHighError } from "../../../../apis/board";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import GetTheme from "../../../../utils/getTheme";

const { Option } = Select;
export default function Shipinyujing(props: any) {
  let styles = GetTheme();
  let history = useHistory();
  const timeList = [
    { type: 1, name: "3天内" },
    { type: 2, name: "30天内" },
  ];
  const [correctCount, setCorrectCount] = useState(0);
  const [videoDeviceCount, setVideoCount] = useState(0);
  const [videoDateType, setVideoDateType] = useState(1);
  const [legendList, setLegendList] = useState([""]);

  function check_more(path: any) {
    history.push(path);
  }

  function videoDateChange(type: any) {
    setVideoDateType(type);
  }
  function formatDate(type: any) {
    let date: any = [];
    let sdate: any = "";
    let edate: any = "";
    if (type === 1) {
      sdate = dayjs().subtract(3, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    } else {
      sdate = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      edate = dayjs().format("YYYY-MM-DD");
    }
    date = [sdate, edate];
    return date;
  }
  // 渲染视频直播图表
  const renderVideoChart = (data: any) => {
    let chartData: any = [];
    let ls: any = [];
    data.forEach((item: any, index: any) => {
      if (index < 4) {
        chartData.push({ value: item.count, name: item.correct_type });
        ls.push(item.correct_type);
      }
    });
    setLegendList(ls);
    var ele: any = document.getElementById("videoChart");
    var myChart = echarts.init(ele);
    // 绘制图表
    myChart.setOption({
      // legend: {
      //   orient: 'vertical',
      //   right: 10,
      //   top: "center",
      //   textStyle: { color: '#fff' },
      // },
      color: ["#1EB38B", "#3582FE", "#A682E6", "#F29961"],
      series: [
        {
          name: "纠偏类型占比",
          type: "pie",
          radius: ["20%", "90%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                fontSize: 12,
                formatter: "{d}%",
                position: "inner",
              },
              labelLine: {
                show: true,
              },
            },
          },
          data: chartData,
        },
      ],
    });
  };
  //摄像头数量
  const videoCountData = useCallback(() => {
    return getVideoCount({
      sdate: formatDate(videoDateType)[0],
      edate: formatDate(videoDateType)[1],
    }).then((res: any) => {
      setVideoCount(res.data.total);
    });
  }, [videoDateType]);
  //高频纠偏
  const highError = useCallback(() => {
    return getHighError({
      sdate: formatDate(videoDateType)[0],
      edate: formatDate(videoDateType)[1],
    }).then((res: any) => {
      renderVideoChart(res.data.info);
      setCorrectCount(res.data.count);
    });
  }, [videoDateType]);
  useEffect(() => {
    highError();
    videoCountData();
  }, [highError, videoCountData]);
  return (
    <>
      <div>
        <div className={styles.rightSide}>
          <div className={styles.box + " " + styles.box2}>
            <div className={styles.flexRow + " " + styles.titleLine}>
              <div className={styles.flexRow + " " + styles.checkLine}>
                <Title title="视频预警分析"></Title>
                <span
                  onClick={() => check_more("/correction/list")}
                  className={styles.checkMore + " " + styles.yellowColor}
                >
                  查看
                </span>
              </div>
              <Select
                onChange={videoDateChange}
                className={"board-select " + styles.select}
                key={timeList[0].type}
                defaultValue={timeList[0].type}
              >
                {timeList.map((item: any) => (
                  <Option key={item.type} value={item.type}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={styles.flexRow}>
              <div
                className={
                  styles.divBlock +
                  " " +
                  styles.recBlock +
                  " " +
                  styles.recBlock1
                }
              >
                摄像头数量<span className={styles.yellowColor}>{videoDeviceCount}</span>
              </div>
              <div className={styles.divBlock + " " + styles.recBlock}>
                视频纠偏<span className={styles.yellowColor}>{correctCount}</span>
              </div>
            </div>
            <div
              className={
                styles.flexRow +
                " " +
                styles.dotTitle +
                " " +
                styles.purpleColor
              }
            >
              <span className={styles.dotBg}>
                <span className={styles.dot}></span>
              </span>
              纠偏类型占比
            </div>
            <div className={styles.flexRow}>
              <div className={styles.videoChartWrap}>
                <div className={styles.videoChart} id="videoChart"></div>
              </div>
              <div className={styles.videoLegendWrap}>
                {legendList.map((item: any, index: any) => (
                  <div
                    className={styles.flexRow + " " + styles.videoLegendItem}
                    key={index}
                  >
                    <span
                      className={
                        styles.videoLegendSpan +
                        " " +
                        (index === 0
                          ? styles.blueBackground
                          : index === 1
                          ? styles.greenBackground
                          : index === 2
                          ? styles.yellowBackground
                          : styles.purpleBackground)
                      }
                    ></span>
                    <span className={styles.videoLegendText}>{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
