import React, { useEffect, useState, useCallback } from "react"
import { Col, Row, Image } from "antd"
import ZgColumn from "../../../components/zg-column"
import ZgInfoItem from "../../../components/zg-info-item"
// import resultImg from '../../../assets/images/bx.png'
import ZgRow from "../../../components/zg-row"
import noDataImg from "../../../assets/images/noData.png"
import "./list.less"
// import Button from 'antd/es/button';
import { getStacSupplierInfo1 } from "../../../apis/canteen-info/proxy-info"

export default function Detail1(props: any) {
  let info = {
    name: "",
    scope: "",
    range: "",
    type: 0,
    contact_name: "",
    phone: "",
    area: "",
    province: "",
    city: "",
    district: "",
    address: "",
    sanitation_license: "",
    business_license: "",
    supplier_info: {
      card_id: "",
      business_license_img: "",
      business_license_img_appends: "",
      card_id_img_appends: "",
      card_id_img_verso_appends: "",
      license_img: "",
      license_img_appends: "",
    },
  }

  const [detailInfo, setDetailInfo] = useState(info)
  // console.log(props.id)
  let to_get_info = useCallback(() => {
    return getStacSupplierInfo1({ number: props.id }).then((res) => {
      console.log("yyyyyyyyyyyyyyy", res)
      res.data.scope = ""
      res.data.business_scope.forEach((item: any) => {
        res.data.scope += item.name
      })
      setDetailInfo(res.data)
    })
  }, [props.id])

  useEffect(() => {
    to_get_info()
  }, [to_get_info])

  return (
    <div className="detail-wrap">
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <ZgInfoItem title="供应商名" content={detailInfo.name}></ZgInfoItem>
        </Col>
        <Col span={12}>
          <ZgInfoItem
            title="注册类型"
            content={detailInfo.type === 0 ? "公司" : "个体"}
          ></ZgInfoItem>
        </Col>
        <Col span={12}>
          <ZgInfoItem
            title="法人姓名"
            content={detailInfo.contact_name}
          ></ZgInfoItem>
        </Col>
        <Col span={12}>
          <ZgInfoItem title="联系方式" content={detailInfo.phone}></ZgInfoItem>
        </Col>
        <Col span={12}>
          <ZgInfoItem title="省市区" content={detailInfo.area}></ZgInfoItem>
        </Col>
        <Col span={12}>
          <ZgInfoItem
            title="详细地址"
            content={detailInfo.address}
          ></ZgInfoItem>
        </Col>
        <Col span={24}>
          <ZgInfoItem title="经营范围" content={detailInfo.scope}></ZgInfoItem>
        </Col>
      </Row>

      <div className="line"></div>
      <h3 className="faren-title">法人身份信息</h3>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <ZgInfoItem
            title="身份证号码"
            content={
              detailInfo.supplier_info.card_id === ""
                ? "无"
                : detailInfo.supplier_info.card_id
            }
          ></ZgInfoItem>
        </Col>
      </Row>

      <div className="line"></div>
      <Row gutter={[12, 12]} className="shengfenzhengRow">
        <Col span={8}>
          <h5>营业执照</h5>
          {/* <Image
            height={120}
            width="auto"
            src={detailInfo.supplier_info.business_license_img_appends}
          /> */}
          <ZgRow justify="flex-start" className="card-license">
            <div>
              <Image
                style={{
                  display:
                    detailInfo.supplier_info.business_license_img === ""
                      ? "none"
                      : "block",
                }}
                className="license_img"
                src={detailInfo.supplier_info.business_license_img}
                alt=""
              />
            </div>
            <ZgColumn
              className="none-wrap"
              style={{
                display:
                  detailInfo.supplier_info.business_license_img === ""
                    ? "flex"
                    : "none",
              }}
            >
              <Image className="no-img" preview={false} src={noDataImg}></Image>
              暂无图片
            </ZgColumn>
          </ZgRow>
          <p>
            营业执照号码：
            {detailInfo.business_license === ""
              ? "无"
              : detailInfo.business_license}
          </p>
        </Col>
        <Col span={8}>
          <h5>食品安全许可证</h5>
          {/* <Image
            height={120}
            width="auto"
            src={detailInfo.supplier_info.license_img_appends}
          /> */}
          <ZgRow justify="flex-start" className="card-license">
            <div>
              <Image
                style={{
                  display:
                    detailInfo.supplier_info.license_img === ""
                      ? "none"
                      : "block",
                }}
                className="license_img"
                src={detailInfo.supplier_info.license_img}
                alt=""
              />
            </div>
            <ZgColumn
              className="none-wrap"
              style={{
                display:
                  detailInfo.supplier_info.license_img === ""
                    ? "flex"
                    : "none",
              }}
            >
              <Image className="no-img" preview={false} src={noDataImg}></Image>
              暂无图片
            </ZgColumn>
          </ZgRow>
          <p>
            许可证编号：
            {detailInfo.sanitation_license === ""
              ? "无"
              : detailInfo.sanitation_license}
          </p>
        </Col>
      </Row>
    </div>
  )
}
