import request from '../../utils/request_yidian'

/**
 * 商户信息表
 */
export function getBusinessList(data: any) {
  return request({
    url: '/businessList',
    method: 'post',
    data
  })
}


/**
 * 商户禁用启用
 * 
 * mer_id -- 商户id
 * status -- 状态 0为禁用1为启用
 */
export function EditStatus(data: any) {
  return request({
    url: '/editBusinessStatus',
    method: 'post',
    data
  })
}

/**
 * 商户详情页面
 * 
 * mer_id -- 商户id
 */
export function getMerchantDetail(data: any) {
  return request({
    url: '/MerchantDetail',
    method: 'post',
    data
  })
}