import React from 'react'
import ZgRow from './zg-row';
export default function ZgTitle(props: any) {
  return (
    <>
      <ZgRow justify="flex-start" className={"title-level-second" + (props.className ? ' ' + props.className : '')}>
        <span className="vertical-line"></span>
        <span>{props.title}</span>
      </ZgRow>
    </ >
  )
}