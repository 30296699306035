import request from "../../utils/request"
// 物联概况
export function deviceTypeSum(data: any) {
  return request({
    url: "/app/area/iot/devices-group-type-count",
    method: "get",
    params: data,
  })
}
// 设备实时监测排行
export function deviceStatusRank(data: any) {
  return request({
    url: "/app/area/iot/devices-group-exception-ranking",
    method: "get",
    params: data,
  })
}
// 异常设备预警类型
export function deviceWarnType(data: any) {
  return request({
    url: "/app/area/iot/devices-abnormal-group-count",
    method: "get",
    params: data,
  })
}
// 预警趋势图
export function warnTrend(data: any) {
  return request({
    url: "/app/area/warning/index/abnormal-trend",
    method: "get",
    params: data,
  })
}