import React, { useState, useEffect, useCallback } from 'react'
import '../style/iot-fridge-daily.less'
import Bx from '../assets/images/bx.png'
import { Table, Image } from 'antd'

export default function IotFridge(props: any) {

  const [dataList, setDataList] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeChildNumber, setTypeChildNumber] = useState()
  const [columns, setColumns] = useState([{}])
  const to_get_info = useCallback(() => {
    let clns1 = [
      {
        title: '设备名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '传感器位置',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: '温度',
        dataIndex: 'cacheInfo',
        key: 'cacheInfo',
        render: (text: any, record: any) => (
          <>
            {
              <div>
                {
                  props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ?
                    <div><span className={[!!record.warning ? 'red-color' : 'green-color'].join(' ')}>{record.cacheInfo.temperature}℃</span><span>（最低{record.value_range_small}℃，最高{record.value_range_max}℃）</span></div>
                    : <div>-----</div>
                }
                {
                  props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ? <div className="red-color">{record.warning}</div> : ''
                }
              </div>
            }
          </>
        )
      }
    ]

    let clns2 = [
      {
        title: '设备名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '传感器位置',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: '责任人',
        dataIndex: 'person_name',
        key: 'person_name',
        render: (person_name: any) => (
          <>
            {
              props.fridgeInfo.state === '1' && props.fridgeInfo.online_state === '1' ? props.fridgeInfo.person_name : '-----'
            }
          </>
        )
      }
    ]
    setDataList(props.fridgeInfo.devicesgroup)
    setTypeChildNumber(props.fridgeInfo.type_child_number)
    if (props.fridgeInfo.type_child_number === 'GT0006') {
      setColumns(clns2)
    } else {
      setColumns(clns1)
    }
  }, [props.fridgeInfo])

  useEffect(() => {
    to_get_info()
  }, [to_get_info]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="fridge-daily">
        <div className="fridge-tit">
          <div className="tit-img">
            <Image
              width={44}
              height={42}
              preview={false}
              src={Bx}
            />
          </div>
          <div className="tit-cont">
            <div><span className="tit-ft-top1">{props.fridgeInfo.name}</span><span className="tit-ft-top2">（{props.fridgeInfo.number}）</span></div>
            <div className="tit-ft-down">{props.fridgeInfo.room}</div>
          </div>
          <div className={props.fridgeInfo.state === '0' || props.fridgeInfo.online_state === '0' ? 'tit-lx-state' : 'tit-zx-state'}>{props.fridgeInfo.state === '0' ? '停用' : props.fridgeInfo.online_state === '1' ? '在线' : '离线'}</div>
        </div>
        <div className="fridge-table">
          <Table
            columns={columns}
            dataSource={dataList}
            rowKey="id" pagination={false} />
        </div>
        <div className="foot-time">数据最后上传时间：{props.fridgeInfo['devicesgroup'][0].last_time}</div>
      </div>
    </ >
  )
}