import React, { useState } from 'react'
import { Table, Row, Col, DatePicker, Button, Select, Modal } from 'antd';
import ZgRow from '../../../components/zg-row';
import Detail from './detail'
const { RangePicker } = DatePicker;
const { Option } = Select
export default function StoreList() {
  const [showDetail, setShowDetail] = useState(false)
  function checkDetail() {
    return setShowDetail(true)
  }
  function to_close() {
    return setShowDetail(false)
  }
  let columns = [
    {
      title: '序号',
      dataIndex: 'idx',
      key: 'idx'
    },
    {
      title: '填报时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '填报人',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '检查是否完成',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '检查是否完备',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '检查结果',
      dataIndex: 'name',
      key: 'name',
    }, 
    {
      title: '操作',
      key: 'action',
      render: () => (
        <Button type="primary" onClick={checkDetail} ghost={true}>查看详情</Button>
      ),
    }
  ];
  let data = [
    {
      key: '1',
      idx: '1',
      time: '2020-09-09',
      name: '茄子'
    },
    {
      key: '2',
      idx: '2',
      time: '2020-09-09',
      name: '茄子'
    },
    {
      key: '3',
      idx: '3',
      time: '2020-09-09',
      name: '茄子'
    },
  ];
  return (
    <>
      <div className="zg-container-content">
        <div className="title-level-first">工作日志</div>
        <Row style={{ marginBottom: '24px' }}>
          <ZgRow>
            <span>填报日期：</span>
            <RangePicker></RangePicker>
            <span style={{marginLeft: '16px'}}>检查结果：</span>
            <Select defaultValue="检查结果" style={{ width: 120 }}>
              <Option value="合格">合格</Option>
              <Option value="不合格">不合格</Option>
            </Select>
            <Button type="primary" style={{ marginLeft: '16px' }}>搜索</Button>
          </ZgRow>
        </Row>
        <Row>
          <Col span={24}>
            <Table columns={columns} dataSource={data}></Table>
          </Col>
        </Row>
      </div>
      <Modal
        visible={showDetail}
        title="工作日志详情"
        onCancel={to_close}
        footer={null}
        width="700px"
      >
        <Detail></Detail>
      </Modal>
    </>
  )
}